import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Navbar,
	FormGroup,
	InputGroup,
	Checkbox,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';

import moment from 'moment';

import TextWithTooltip from './TextWithTooltip';
import { ResultHeader, Result } from './code_tables/ResultComponents';

function Results(props) {
	const uppercase_search_value = props.search_value.toUpperCase();
	const start_date_iso         = props.start_date === null ? null : moment(props.start_date).format('YYYY-MM-DD');
	const end_date_iso           = props.end_date   === null ? null : moment(props.end_date  ).format('YYYY-MM-DD');
	
	// find invoices
	const selected_invoice_types = Array.isArray(props.enabled_invoices_types) ? props.enabled_invoices_types : [];
	
	const found_invoices = props.enabled_invoices_types === false || (Array.isArray(props.enabled_invoices_types) && props.enabled_invoices_types.length == 0) ? [] :
		Object.values(props.invoices).filter(item => {
			if (selected_invoice_types.length > 0 && selected_invoice_types.indexOf(item.invoice_type) == -1) return false;
			
			const issued_iso = item.issued === null ? null : moment(item.issued).format('YYYY-MM-DD');
			if (
				start_date_iso !== null && end_date_iso !== null &&
				(issued_iso === null || issued_iso < start_date_iso || issued_iso > end_date_iso)
			) {
				return false;
			}
			
			const customer = item.id_customer === null ? null : props.customers[item.id_customer];
			
			return item.invoice_number_internal.toUpperCase().indexOf(uppercase_search_value) != -1
				||
				(customer !== null && (
					customer.internal_code.toString().indexOf(props.search_value) != -1 ||
					(customer.surname + ' ' + customer.name + ' ' + customer.company_name).toUpperCase().indexOf(uppercase_search_value) != -1
				));
		});
	
	// find reservations
	const found_reservations = !props.reservations_enabled ? [] :
		Object.values(props.reservations).filter(item => {
			const check_in_iso  = item.check_in  === null ? null : moment(item.check_in ).format('YYYY-MM-DD');
			const check_out_iso = item.check_out === null ? null : moment(item.check_out).format('YYYY-MM-DD');
			if (
				start_date_iso !== null && end_date_iso !== null &&
				(
					check_in_iso  === null ||
					check_out_iso === null ||
					check_out_iso < start_date_iso ||
					check_in_iso  > end_date_iso
				)
			) {
				return false;
			}
			
			const customer = item.id_customer === null ? null : props.customers[item.id_customer];
			
			return item.internal_code.toUpperCase().indexOf(uppercase_search_value) != -1
				||
				(customer !== null && (
					customer.internal_code.toString().indexOf(props.search_value) != -1 ||
					(customer.surname + ' ' + customer.name + ' ' + customer.company_name).toUpperCase().indexOf(uppercase_search_value) != -1
				));
		});
	
	if (found_invoices.length + found_reservations.length == 0) {
		return <div>Ni rezultatov</div>;
	}
	
	return <div>
		{found_invoices.length == 0 ? null :
			<InvoiceResults
				invoices={found_invoices}
				customers={props.customers}
				selectItem={props.selectItem} />
		}
		
		{found_reservations.length == 0 ? null :
			<ReservationResults
				reservations={found_reservations}
				customers={props.customers}
				accommodation_item_place_titles={props.accommodation_item_place_titles}
				countries={props.countries}
				selectItem={props.selectItem} />
		}
	</div>;
}

function InvoiceResults(props) {
	const toLocalStringWithTime = date => {
		if (date === null) return '';
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear() + ' ' +
			date.getHours().toString().padStart(2, '0') + ':' +
			date.getMinutes().toString().padStart(2, '0') + ':' +
			date.getSeconds().toString().padStart(2, '0');
	};
	
	const invoice_types = {
		'cash-invoice':                 { title: 'Blagajniški račun'            },
		'cash-prepayment-invoice':      { title: 'Račun za predplačilo'         },
		'invoice':                      { title: 'Faktura'                      },
		'prepayment-invoice':           { title: 'Avansni račun'                },
		'credit-note':                  { title: 'Dobropis'                     },
		'offer':                        { title: 'Ponudba'                      },
		'cash-register-invoice-income': { title: 'cash-register-invoice-income' },
		'cash-register-invoice-outlay': { title: 'cash-register-invoice-outlay' },
		'cash-register-journal':        { title: 'cash-register-journal'        },
		'gift-voucher':                 { title: 'gift-voucher'                 },
		'stock-acquisition':            { title: 'stock-acquisition'            },
		'stock-delivery-document':      { title: 'stock-delivery-document'      },
		'warehouse-transfer-order':     { title: 'warehouse-transfer-order'     },
		'delivery-report':              { title: 'delivery-report'              },
		'sales-invoice-book':           { title: 'sales-invoice-book'           },
		'proforma-invoice-book':        { title: 'proforma-invoice-book'        },
		'guest-book':                   { title: 'guest-book'                   },
	};
	
	return <div>
		<div className='font-thin text-lg mt-5 mb-5'>RAČUNI</div>
		<div className='document-search-invoices-list'>
			<div className='results'>
				<div className='results-table'>
					<ResultHeader columnIdx={1} title='Št. računa' />
					<ResultHeader columnIdx={2} title='Šifra' />
					<ResultHeader columnIdx={3} title='Naziv stranke' />
					<ResultHeader columnIdx={4} title='Datum računa' />
					<ResultHeader columnIdx={5} title='Vrsta dokumenta' />
					<ResultHeader columnIdx={6} title='Znesek' />
					<ResultHeader columnIdx={7} title='' />
					
					{Object.values(props.invoices).map(item => {
						const issued = (item.issued === null ? null : new Date(item.issued));
						
						return <React.Fragment key={'result-' + item.id_invoice}>
							<Result columnIdx={1} child={
								item.draft ? 'osnutek' : item.invoice_number_internal
							} />
							<Result columnIdx={2} child={
								props.customers[item.id_customer] === undefined ? '' :
									props.customers[item.id_customer].internal_code
							} />
							<Result columnIdx={3} child={
								props.customers[item.id_customer] === undefined ? '' :
									(props.customers[item.id_customer].type == 'natural' ?
										props.customers[item.id_customer].surname + ' ' + props.customers[item.id_customer].name
										:
										props.customers[item.id_customer].company_name
									)
							} />
							<Result columnIdx={4} child={
								toLocalStringWithTime(issued)
							} />
							<Result columnIdx={5} child={
								invoice_types[item.invoice_type] === undefined ?
									item.invoice_type : invoice_types[item.invoice_type].title
							} />
							<Result columnIdx={6} cls='text-right font-bold col-invoice-amount' child={
								(item.reversed ? '[S] ' : '') +
								(
									item.invoice_type == 'prepayment-invoice'
										? parseFloat(item.advance_payment_amount)
										: (
											parseFloat(item.invoice_amount) -
											parseFloat(item.advance_payment_amount)
										)
								).toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								) + ' EUR'
							} />
							<Result columnIdx={7} child={
								<Button
									className={'cell-button'}
									onClick={() => {
										props.selectItem(item.id_invoice, item.invoice_type);
									}}
									icon='tick'
									small={true}>
									Izberi
								</Button>
							} />
						</React.Fragment>;
					})}
				</div>
			</div>
		</div>
	</div>;
}

function ReservationResults(props) {
	const toLocalString = date => {
		if (date === null || date === undefined) return '';
		
		if (!(date instanceof Date)) return date;
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear();
	};
	
	const statuses = {
		'new':                      { title: 'Nova',           key: 'new'                       },
		'waiting-for-confirmation': { title: 'V potrjevanju',  key: 'waiting-for-confirmation'  },
		'offer-sent':               { title: 'Izdana ponudba', key: 'offer-sent'                },
		'confirmed':                { title: 'Potrjena',       key: 'confirmed'                 },
		'advance-invoice-sent':     { title: 'Izdan avans',    key: 'advance-invoice-sent'      },
		'closed':                   { title: 'Plačana',        key: 'closed'                    },
		'reversed':                 { title: 'Stornirana',     key: 'reversed',                 },
		'no-show':                  { title: 'Neprihod',       key: 'no-show',                  },
		'not-for-rent':             { title: 'Ni za oddajo',   key: 'not-for-rent'              },
	};
	
	return <div>
		<div className='font-thin text-lg mt-5 mb-5'>REZERVACIJE</div>
		<div className='document-search-reservations-list'>
			<div className='results'>
				<div className='results-table'>
					<ResultHeader columnIdx={1} title='Objekt' />
					<ResultHeader columnIdx={2} title='Status' />
					<ResultHeader columnIdx={3} title='Šifra' />
					<ResultHeader columnIdx={4} title='Nosilec' />
					<ResultHeader columnIdx={5} title='Država' />
					<ResultHeader columnIdx={6} title='Prihod' />
					<ResultHeader columnIdx={7} title='Odhod' />
					<ResultHeader columnIdx={8} title='' />
					
					{Object.values(props.reservations).map(item => {
						const customer = item.id_customer === null || props.customers[item.id_customer] === undefined ?
							null : props.customers[item.id_customer];
						
						return <React.Fragment key={'result-' + item.id_reservation}>
							<Result columnIdx={1} child={
								props.accommodation_item_place_titles[item.id_accommodation_item_place]
							} />
							<Result columnIdx={2} child={
								statuses[item.status].title
							} />
							<Result columnIdx={3} child={
								item.internal_code
							} />
							<Result columnIdx={4} child={
								customer === null ? null :
									(customer.type == 'natural' ? customer.surname + ' ' + customer.name : customer.company_name)
							} />
							<Result columnIdx={5} child={
								customer === null ? null :
									(customer.id_country === null || props.countries[customer.id_country] === undefined ? null :
										props.countries[customer.id_country].iso_3166_1_a2
									)
							} />
							<Result columnIdx={6} child={
								toLocalString(new Date(item.check_in))
							} />
							<Result columnIdx={7} child={
								toLocalString(new Date(item.check_out))
							} />
							<Result columnIdx={8} child={
								<Button
									className={'cell-button'}
									onClick={() => {
										props.selectItem(item.id_reservation, 'reservation');
									}}
									icon='tick'
									small={true}>
									Izberi
								</Button>
							} />
						</React.Fragment>;
					})}
				</div>
			</div>
		</div>
	</div>;
}

class DocumentSearchDialog extends Component {
	constructor(props) {
		super(props);
		
		this.toggleEnabledType          = this.toggleEnabledType         .bind(this);
		this.toggleEnabledInvoiceType   = this.toggleEnabledInvoiceType  .bind(this);
		this.generateAccommodationItemPlaceTitles = this.generateAccommodationItemPlaceTitles.bind(this);
		
		this.state = {
			search_value:              '',
			search_value_applied:      '',
			enabled_type_all:          true,
			enabled_types:             [],
			enabled_invoice_types:     [],
			
			start_date: moment().subtract(3, 'month').toDate(),
			end_date:   moment().toDate(),
		};
		
		this._search_timeout = null;
	}
	
	toLocalString(date) {
		if (date === null || date === undefined) return '';
		
		if (!(date instanceof Date)) return date;
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear();
	}
	
	toggleEnabledType(type, checked) {
		const enabled_types = this.state.enabled_types;
		if (checked && enabled_types.indexOf(type) == -1) {
			enabled_types.push(type);
		}
		else if (!checked && enabled_types.indexOf(type) != -1) {
			enabled_types.splice(enabled_types.indexOf(type), 1);
		}
		
		this.setState({
			enabled_type_all: false,
			enabled_types,
		});
	}
	
	toggleEnabledInvoiceType(type, checked) {
		const enabled_invoice_types = this.state.enabled_invoice_types;
		if (checked && enabled_invoice_types.indexOf(type) == -1) {
			enabled_invoice_types.push(type);
		}
		else if (!checked && enabled_invoice_types.indexOf(type) != -1) {
			enabled_invoice_types.splice(enabled_invoice_types.indexOf(type), 1);
		}
		
		this.setState({
			enabled_type_all: false,
			enabled_invoice_types,
		});
	}
	
	generateAccommodationItemPlaceTitles(
		accommodations,
		accommodation_items,
		accommodation_item_places,
	) {
		const accommodation_item_place_titles = {};
		
		if (accommodations !== null && accommodation_items !== null && accommodation_item_places !== null) {
			for (let id_accommodation in accommodations) {
				for (let id_accommodation_item in accommodation_items) {
					if (accommodation_items[id_accommodation_item].id_accommodation != id_accommodation) continue;
					
					for (let id_accommodation_item_place in accommodation_item_places) {
						if (accommodation_item_places[id_accommodation_item_place].id_accommodation_item != id_accommodation_item) continue;
						
						let title = accommodations[id_accommodation].title + ' • ';
						if (accommodation_items[id_accommodation_item].title != 'default') {
							title += accommodation_items[id_accommodation_item].title + ' • ';
						}
						title += accommodation_item_places[id_accommodation_item_place].title;
						
						accommodation_item_place_titles[id_accommodation_item_place] = title;
					}
				}
			}
		}
		
		return accommodation_item_place_titles;
	}
	
	render() {
		//TODO cache this?
		const accommodation_item_place_titles = this.generateAccommodationItemPlaceTitles(
			this.props.accommodations,
			this.props.accommodation_items,
			this.props.accommodation_item_places,
		);
		
		const limit_document_types = this.props.limit_document_types || [];
		
		let enabled_invoice_types = this.state.enabled_invoice_types;
		let enabled_types         = this.state.enabled_types;
		let enabled_type_all      = this.state.enabled_type_all;
		if (limit_document_types.length > 0) {
			enabled_invoice_types = enabled_type_all ? limit_document_types : enabled_invoice_types.filter(x => limit_document_types.indexOf(x) > -1);
			enabled_types         = enabled_type_all ? limit_document_types.filter(x => x == 'enabled_types') : (
				enabled_types.filter(x => limit_document_types.indexOf(x) > -1)
			);
			enabled_type_all = false;
		}
		
		const short_year = false;
		const formatDatePattern = short_year === true ? 'DD. MM. YY' : 'DD. MM. YYYY';
		
		let placeholderFrom  = '01. 01. ' + new Date().getFullYear();
		let placeholderUntil = '31. 12. ' + new Date().getFullYear();
		if (short_year) {
			placeholderFrom  = placeholderFrom.substring(0, placeholderFrom.length - 2);
			placeholderUntil = placeholderUntil.substring(0, placeholderUntil.length - 2);
		}
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => { this.props.closeDialog(); }}
			className='document-search-dialog'>
			<div className='flex flex-col h-full'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Iskanje dokumenta</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => { this.props.closeDialog(); }} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-row h-full box-border'>
						<div className='bg-gray-300 p-4 box-border left-side'>
							<FormGroup
								labelFor='search-input'>
								<TextWithTooltip
									label='Iskalni niz'
									tooltip='Št. računa, št. rezervacije, itd' />
								<InputGroup id='search-input'
									value={this.state.search_value}
									onChange={event => {
										this.setState({ search_value: event.target.value });
										
										if (this._search_timeout !== null) {
											clearTimeout(this._search_timeout);
										}
										this._search_timeout = setTimeout(() => {
											this.setState({ search_value_applied: this.state.search_value });
										}, 500);
									}} />
							</FormGroup>
							
							<FormGroup>
								<div className='flex flex-row filter-date-range'>
									<DateInput
										className='flex-1'
										locale='sl'
										formatDate={(date, locale) => moment(date).locale(locale).format(formatDatePattern)}
										parseDate={(str, locale) => moment(str, 'DD. MM. YYYY').locale(locale).toDate()}
										inputProps={{ placeholder: placeholderFrom }}
										popoverProps={{ position: 'left' }}
										selectAllOnFocus={true}
										minDate={new Date('1900-01-01')}
										maxDate={moment().add('10', 'years').toDate()}
										onChange={(selectedDate, isUserChange) => {
											if (isUserChange) {
												this.setState({
													start_date: selectedDate,
												});
											}
										}}
										value={this.state.start_date} />
									<DateInput
										className='flex-1'
										locale='sl'
										formatDate={(date, locale) => moment(date).locale(locale).format(formatDatePattern)}
										parseDate={(str, locale) => moment(str, 'DD. MM. YYYY').locale(locale).toDate()}
										inputProps={{ placeholder: placeholderUntil }}
										popoverProps={{ position: 'right' }}
										selectAllOnFocus={true}
										minDate={new Date('1900-01-01')}
										maxDate={moment().add('10', 'years').toDate()}
										onChange={(selectedDate, isUserChange) => {
											if (isUserChange) {
												this.setState({
													end_date: selectedDate,
												});
											}
										}}
										value={this.state.end_date} />
								</div>
							</FormGroup>
							
							<Checkbox
								checked={this.state.enabled_type_all}
								label='Vse'
								onChange={event => {
									if (!event.target.checked) return; // can't unselect all
									this.setState({
										enabled_type_all:      true,
										enabled_types:         [],
										enabled_invoice_types: [],
									});
								}} />
							
							{limit_document_types.length > 0 && limit_document_types.indexOf('reservations') == -1 ? null :
								<Checkbox
									checked={this.state.enabled_type_reservations}
									label='Rezervacija'
									className='mt-6 mb-6'
									onChange={event => this.toggleEnabledType('reservations', event.target.checked)} />
							}
							
							{[
								{ key: 'cash-invoice',            title: 'Blagajniški račun'    },
								{ key: 'cash-prepayment-invoice', title: 'Račun za predplačilo' },
								{ key: 'invoice',                 title: 'Faktura'              },
								{ key: 'prepayment-invoice',      title: 'Avansni račun'        },
								{ key: 'credit-note',             title: 'Dobropis'             },
								{ key: 'offer',                   title: 'Ponudba'              },
							].map(invoice_type =>
								limit_document_types.length > 0 && limit_document_types.indexOf(invoice_type.key) == -1 ? null :
									<Checkbox
										key={'filter-invoice-type--' + invoice_type.key}
										checked={enabled_invoice_types.indexOf(invoice_type.key) != -1}
										label={invoice_type.title}
										onChange={event => this.toggleEnabledInvoiceType(invoice_type.key, event.target.checked)} />
							)}
						</div>
						<div className='flex-1 p-4 box-border overflow-y-auto'>
							{this.state.search_value_applied.length < 3 ?
								<div>Vnesi iskalni niz</div>
								:
								<Results
									search_value                   ={this.state.search_value_applied}
									start_date                     ={this.state.start_date}
									end_date                       ={this.state.end_date}
									enabled_invoices_types         ={enabled_type_all || enabled_invoice_types}
									reservations_enabled           ={enabled_type_all || enabled_types.indexOf('reservations') != -1}
									invoices                       ={this.props.invoices}
									customers                      ={this.props.customers}
									reservations                   ={this.props.reservations}
									accommodation_item_place_titles={accommodation_item_place_titles}
									countries                      ={this.props.countries}
									selectItem                     ={this.props.closeDialog} />
							}
						</div>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
DocumentSearchDialog.propTypes = {
	closeDialog: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		customers:                 state.CodeTablesSlice.customers,
		invoices:                  state.BusinessSlice.invoices,
		reservations:              state.ReservationSlice.reservations,
		accommodations:            state.CodeTablesSlice.accommodations,
		accommodation_items:       state.CodeTablesSlice.accommodation_items,
		accommodation_item_places: state.CodeTablesSlice.accommodation_item_places,
		countries:                 state.CodeTablesSlice.countries,
	};
}

export default connect(mapStateToProps)(DocumentSearchDialog);
