import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	Navbar,
	FileInput,
} from '@blueprintjs/core';

class ImportStockAcquisitionFileDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeDialog()}
			className='import-stock-acquisition-file-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Uvoz iz Excela</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							className='flex-1'>
							<FileInput
								text="Izberi datoteko ..."
								onInputChange={async (e) => {
									e.preventDefault();
									const reader = new FileReader();
									reader.onload = async (e) => {
										this.props.closeDialog(new Uint8Array(e.target.result));
									};
									reader.readAsArrayBuffer(e.target.files[0]);
								}} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeDialog()}>
							Prekliči
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
ImportStockAcquisitionFileDialog.propTypes = {
	closeDialog: PropTypes.func,
};

export default ImportStockAcquisitionFileDialog;
