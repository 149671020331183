import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
} from '@blueprintjs/core';

class EditSettingsItemDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			value: props.item[props.property_key],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeDialog()}
			className='edit-settings-item-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Uredi nastavitev</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='edit-settings-item-dialog--value'
							className='flex-1'>
							<InputGroup
								id='edit-settings-item-dialog--value'
								value={this.state.value}
								onChange={event => {
									this.setState({
										value: event.target.value,
									});
								}} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								this.props.closeDialog(this.props.item, this.props.property_key, this.state.value);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
EditSettingsItemDialog.propTypes = {
	closeDialog: PropTypes.func,
	item: PropTypes.object,
};

export default EditSettingsItemDialog;
