import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Button,
	ButtonGroup,
	Intent,
	Checkbox,
	MenuItem,
	Card,
	Elevation,
} from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';

import moment from 'moment';

import { navigate } from 'raviger';

import { useQueryParams } from 'raviger';

import Reservation                 from './Reservation';
import TimelineActivities          from './TimelineActivities';
import DatePickerDialog            from '../DatePickerDialog';
import EditActivityItemDialog      from './EditActivityItemDialog';
import { addActivityReservation  } from '../../slices/ReservationSlice';
import { saveActivityReservation } from '../../api/Reservations';

function TimelineActivitiesWithFilter(props) {
	const sortAndExtractIdActivities = (activities) => {
		return Object.values(activities).sort((a, b) => {
			if (a.ord < b.ord)      return -1;
			else if (a.ord > b.ord) return  1;
			                        return  0;
		}).map(item => item.id_activity);
	};
	
	const all_activities_ids = props.activities === undefined || props.activities === null ? [] :
		sortAndExtractIdActivities(props.activities);
	
	const [ reservation_dialog_open,        setReservationDialogOpen      ] = useState(false);
	const [ current_reservation,            setCurrentReservation         ] = useState(null);
	const [ filter_date_picker_dialog_open, setFilterDatePickerDialogOpen ] = useState(false);
	const [ all_activities,                 setAllActivities              ] = useState(all_activities_ids);
	const [ selected_activities,            setSelectedActivities         ] = useState(all_activities_ids);
	const [ reservation_search_query,       setReservationSearchQuery     ] = useState('');
	
	const [ edit_activity_item_dialog_open,             setEditActivityItemDialogOpen            ] = useState(false);
	const [ edit_activity_item_dialog_item,             setEditActivityItemDialogItem            ] = useState(null);
	const [ edit_activity_item_dialog_reservation_date, setEditActivityItemDialogReservationDate ] = useState(null);
	const [
		edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity,
		setEditActivityItemDialogIdAccommodationItemScheduleTimeSlotActivity
	] = useState(-1);
	
	let [ { current_date, scale_days }, setQuery ] = useQueryParams();
	
	current_date = current_date === undefined ? new Date() : new Date(current_date);
	scale_days   = scale_days   === undefined ? 1          : (parseInt(scale_days, 10) || 1);
	
	const setCurrentDate = (value) => {
		setQuery({
			current_date: moment(value).format('YYYY-MM-DD'),
			scale_days,
		});
	};
	const setScaleDays = (value) => {
		setQuery({
			current_date: moment(current_date).format('YYYY-MM-DD'),
			scale_days: value,
		});
	};
	
	const openReservationDialog = (reservation) => {
		setReservationDialogOpen(true);
		setCurrentReservation(reservation);
	};
	const openFilterDatePickerDialog = () => {
		setFilterDatePickerDialogOpen(true);
	};
	const closeFilterDatePickerDialog = (newValue) => {
		setFilterDatePickerDialogOpen(false);
		
		if (newValue instanceof Date) {
			setCurrentDate(newValue);
		}
	};
	
	const offsetCurrentDate = (offset_days) => {
		setCurrentDate(moment(current_date).add(offset_days, 'days').toDate());
	};
	
	const isActivitySelected = (activity) => {
		return selected_activities.indexOf(activity) != -1;
	};
	const toggleActivity = (activity) => {
		const selected_activities_arr = Array.from(selected_activities);
		
		if (isActivitySelected(activity)) {
			selected_activities_arr.splice(selected_activities_arr.indexOf(activity), 1);
		}
		else {
			selected_activities_arr.push(activity);
		}
		
		setSelectedActivities(selected_activities_arr);
	};
	
	const openEditActivityItemDialog = (
		item,
		id_accommodation_item_schedule_time_slot_activity,
		reservation_date
	) => {
		setEditActivityItemDialogOpen(true);
		setEditActivityItemDialogItem(item);
		setEditActivityItemDialogReservationDate(reservation_date);
		setEditActivityItemDialogIdAccommodationItemScheduleTimeSlotActivity(id_accommodation_item_schedule_time_slot_activity);
	};
	const closeEditActivityItemDialog = async (original_item, item) => {
		setEditActivityItemDialogOpen(false);
		
		if (item !== undefined) {
			let new_item = {};
			
			if (original_item !== null) { // existing item
				new_item = {...original_item};
				new_item.guest_count       = item.guest_count;
				new_item.guest_child_count = item.guest_child_count;
				new_item.note              = item.note;
				new_item.id_channel        = item.id_channel;
				new_item.id_customer       = item.id_customer;
				new_item.phone             = '';
				new_item.email             = '';
				new_item.reservation_date  = item.reservation_date;
				new_item.status            = item.status;
				new_item.status_2          = item.status_2 == '' ? null : item.status_2;
				new_item.deleted           = item.deleted;
			}
			else { // new item
				new_item = {
					id_activity_reservation: -1,
					guest_count:             item.guest_count,
					guest_child_count:       item.guest_child_count,
					note:                    item.note,
					id_channel:              item.id_channel,
					id_customer:             item.id_customer,
					phone:                   '',
					email:                   '',
					status:                  item.status,
					status_2:                item.status_2 == '' ? null : item.status_2,
					reservation_date:        item.reservation_date,
					id_accommodation_item_schedule_time_slot_activity: edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity,
					deleted:                 false,
					username:                props.user.username,
				};
			}
			
			const dispatch = props.dispatch;
			const token    = props.token;
			
			dispatch(addActivityReservation({
				item: new_item,
				token,
			}));
			new_item = await saveActivityReservation(props.api_url, new_item, token);
			if (new_item !== null) {
				dispatch(addActivityReservation({
					item: new_item,
					token,
				}));
			}
		}
	};
	
	useEffect(() => {
		const all_activities = sortAndExtractIdActivities(props.activities);
		
		setAllActivities(all_activities);
		setSelectedActivities(all_activities);
	}, [ props.activities ]);
	
	const weekdays = {
		'sunday':    'nedelja',
		'monday':    'ponedeljek',
		'tuesday':   'torek',
		'wednesday': 'sreda',
		'thursday':  'četrtek',
		'friday':    'petek',
		'saturday':  'sobota',
	};
	
	const [ reservation_in_clipboard, setReservationInClipboard ] = useState(null);
	const cutReservation = (reservation) => {
		setReservationInClipboard(reservation);
	};
	const pasteReservation = async (id_accommodation_item_schedule_time_slot_activity, reservation_date) => {
		if (reservation_in_clipboard === null) return;
		
		let new_item = {
			...reservation_in_clipboard,
			id_accommodation_item_schedule_time_slot_activity,
			reservation_date,
		};
		
		const dispatch = props.dispatch;
		const token    = props.token;
		
		dispatch(addActivityReservation({
			item: new_item,
			token,
		}));
		new_item = await saveActivityReservation(props.api_url, new_item, token);
		if (new_item !== null) {
			dispatch(addActivityReservation({
				item: new_item,
				token,
			}));
		}
		
		setReservationInClipboard(null);
	};
	
	const customer = reservation_in_clipboard === null ? null : props.customers[reservation_in_clipboard.id_customer];
	
	return <>
		{!reservation_dialog_open ? null :
			<Reservation
				closeReservation={() => {
					setReservationDialogOpen(false);
				}}
				reservation={current_reservation} />
		}
		
		{!filter_date_picker_dialog_open ? null :
			<DatePickerDialog
				closeDatePickerDialog={closeFilterDatePickerDialog}
				value={current_date} />
		}
		
		{!edit_activity_item_dialog_open ? null :
			<EditActivityItemDialog
				closeEditActivityItemDialog={closeEditActivityItemDialog}
				item={edit_activity_item_dialog_item}
				reservation_date={edit_activity_item_dialog_reservation_date}
				time_slot={
					props.accommodation_item_schedule_time_slots[
						props.accommodation_item_schedule_time_slot_activities[
							edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity
						].id_accommodation_item_schedule_time_slot
					]
				}
				activity={
					props.activities[
						props.accommodation_item_schedule_time_slot_activities[
							edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity
						].id_activity
					]
				}
				accommodation_item_schedule_time_slot_activity={
					props.accommodation_item_schedule_time_slot_activities[
						edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity
					]
				}
				setCurrentDate={setCurrentDate} />
		}
		
		{reservation_in_clipboard === null ? null :
			<Card interactive={false} elevation={Elevation.FOUR} className='activity-reservation-clipboard-card'>
				<strong>ODLOŽIŠČE</strong>
				<p>
					Rezervacija
					{ ' ' }
					{ reservation_in_clipboard.internal_code }
					{ ', ' }
					{
						props.activities[
							props.accommodation_item_schedule_time_slot_activities[
								reservation_in_clipboard.id_accommodation_item_schedule_time_slot_activity
							].id_activity
						].title
					}
					{ ', ' }
					{
						moment(reservation_in_clipboard.reservation_date).format('DD.MM.YYYY')
					} ob {(() => {
						const time_slot = props.accommodation_item_schedule_time_slots[
							props.accommodation_item_schedule_time_slot_activities[
								reservation_in_clipboard.id_accommodation_item_schedule_time_slot_activity
							].id_accommodation_item_schedule_time_slot
						];
						const starts_at_arr = time_slot.starts_at.split(':');
						const time_label = starts_at_arr.length != 3 ? '00:00' : starts_at_arr[0] + ':' + starts_at_arr[1];
						return time_label;
					})()}
					
					<br />
					
					{
						customer === undefined ? '' : 
							(
								customer.type == 'company' ?
									customer.company_name
									:
									customer.surname + ' ' + customer.name
							)
					}
				</p>
				<Button
					icon='delete'
					className='mt-2'
					onClick={() => {
						setReservationInClipboard(null);
					}}>
					Počisti
				</Button>
			</Card>
		}
		
		<div className='pt-4 pb-2 flex flex-row justify-between'>
			<ButtonGroup>
				<Button
					active={scale_days == 1}
					onClick={() => { setScaleDays(1); }}>
					1 dan
				</Button>
				<Button
					active={scale_days == 2}
					onClick={() => { setScaleDays(2); }}>
					2 dni
				</Button>
				<Button
					active={scale_days == 3}
					onClick={() => { setScaleDays(3); }}>
					3 dni
				</Button>
			</ButtonGroup>
			
			<ButtonGroup>
				<Button onClick={() => { offsetCurrentDate(-30); }}>
					<svg viewBox='0 0 20 18' width='20' height='18' className='inline-block mr-1'><g fillRule='evenodd'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m14.589 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/></g></svg>
					30 dni
				</Button>
				<Button onClick={() => { offsetCurrentDate(-15); }}>
					<svg viewBox='0 0 14 18' width='14' height='18' className='inline-block mr-1'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
					15 dni
				</Button>
				<Button onClick={() => { offsetCurrentDate(-1); }}>
					<svg viewBox='0 0 9 18' width='9' height='18' className='inline-block mr-1'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
					1 dan
				</Button>
				<Button
					className='w-24'
					onClick={() => { openFilterDatePickerDialog() }}
					intent={Intent.PRIMARY}>
					{
						current_date.toLocaleString(
							'sl-SI',
							{ year: 'numeric', month: 'numeric', day: 'numeric' }
						)
					}
				</Button>
				<Button
					className='w-24'
					onClick={() => { setCurrentDate(new Date()); }}
					intent={Intent.PRIMARY}
					disabled={moment(current_date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')}>
					Danes
				</Button>
				<Button onClick={() => { offsetCurrentDate(1); }}>
					1 dan
					<svg viewBox='0 0 9 18' width='9' height='18' className='inline-block ml-1' transform='scale(-1,1)'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
				</Button>
				<Button onClick={() => { offsetCurrentDate(15); }}>
					15 dni
					<svg viewBox='0 0 14 18' width='14' height='18' className='inline-block ml-1' transform='scale(-1,1)'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
				</Button>
				<Button onClick={() => { offsetCurrentDate(30); }}>
					30 dni
					<svg viewBox='0 0 20 18' width='20' height='18' className='inline-block ml-1' transform='scale(-1,1)'><g fillRule='evenodd'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m14.589 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/></g></svg>
				</Button>
			</ButtonGroup>
			
			<Suggest
				className='mr-4'
				itemPredicate={(query, item, idx, exactMatch) => {
					if (query.length == 0) return false;
					
					return item.search_value.indexOf(query.toLowerCase()) > -1;
				}}
				itemRenderer={(item, { handleClick, modifiers }) => {
					const reservation = props.activity_reservations[item.id_activity_reservation];
					/*
					levo zgoraj: šifra aktivnosti
						desno: nosilec
					druga vrstica levo: datum in termin
					tretja vrstica levo: naziv aktivnosti
						desno: število oseb
					
					išče po: nosilcu, šifri aktivnosti 
					*/
					
					return <MenuItem
						active={modifiers.active}
						key={item.id_activity_reservation}
						onClick={handleClick}
						text={
							<>
								<div className='flex justify-between'>
									<div>{reservation.internal_code}</div>
									<div>{item.customer_name}</div>
								</div>
								<div className='flex justify-between'>
									<div>{
										item.reservation_date.format('DD. MM. YYYY') + ', ' +
										weekdays[item.day_of_week]
									}</div>
									<div>{item.time_label}</div>
								</div>
								<div className='flex justify-between'>
									<div>{item.activity_title}</div>
									<div>{'št. oseb: ' + (item.guest_count + item.guest_child_count)}</div>
								</div>
							</>
						}
						className='items-stretch' />;
				}}
				noResults={<div className='p-2'>Ni rezultatov</div>}
				items={Object.values(props.activity_reservations || {}).map(reservation => {
					let customer_name = '';
					let search_value  = reservation.internal_code;
					
					let customer = props.customers[reservation.id_customer];
					if (customer !== undefined) {
						customer_name = customer.type == 'natural' ? customer.name + ' ' + customer.surname : customer.company_name;
						search_value += ' ' + customer_name;
					}
					
					const time_slot = props.accommodation_item_schedule_time_slots[
						props.accommodation_item_schedule_time_slot_activities[
							reservation.id_accommodation_item_schedule_time_slot_activity
						].id_accommodation_item_schedule_time_slot
					];
					
					const starts_at_arr = time_slot.starts_at.split(':');
					const time_label    = starts_at_arr.length != 3 ? '00:00' : starts_at_arr[0] + ':' + starts_at_arr[1];
					
					return {
						id_activity_reservation: reservation.id_activity_reservation,
						search_value:            search_value.toLowerCase(),
						reservation_date:        moment(reservation.reservation_date),
						guest_count:             reservation.guest_count,
						guest_child_count:       reservation.guest_child_count,
						activity_title:          props.activities[
							props.accommodation_item_schedule_time_slot_activities[
								reservation.id_accommodation_item_schedule_time_slot_activity
							].id_activity
						].title,
						customer_name,
						day_of_week: time_slot.day_of_week,
						time_label,
					};
				}).sort((a, b) => {
					if (a.reservation_date > b.reservation_date) return -1;
					if (a.reservation_date < b.reservation_date) return  1;
					return 0;
				})}
				onItemSelect={item => {
					const reservation = props.activity_reservations[item.id_activity_reservation];
					
					openEditActivityItemDialog(
						reservation,
						reservation.id_accommodation_item_schedule_time_slot_activity,
						reservation.reservation_date
					);
				}}
				openOnKeyDown={true}
				popoverProps={{
					targetProps: { style: { display: 'block' } },
					minimal: true,
				}}
				selectedItem={null}
				inputProps={{
					leftIcon: 'search',
					placeholder: 'Išči',
					rightElement:
						<Button
							icon='cross'
							minimal={true}
							onClick={() => {
								setReservationSearchQuery('');
							}} />,
				}}
				inputValueRenderer={item => item.search_value}
				resetOnSelect={true}
				query={reservation_search_query}
				onQueryChange={query => {
					setReservationSearchQuery(query);
				}} />
		</div>
		<div className='pt-4 flex flex-row justify-between items-center'>
			<ButtonGroup className='mr-2'>
				<Button onClick={() => {
					setSelectedActivities(all_activities);
				}}>
					Izberi vse
				</Button>
				<Button onClick={() => {
					setSelectedActivities([]);
				}}>
					Izberi nobenega
				</Button>
			</ButtonGroup>
			
			<div className='flex-1 h-4'>
				{all_activities.map(id_activity => {
					return <Checkbox
						key={'activity-' + id_activity}
						inline={true}
						checked={isActivitySelected(id_activity)}
						label={props.activities[id_activity].title}
						onChange={() => {
							toggleActivity(id_activity);
						}} />;
				})}
			</div>
			
			<div className='mr-4'>
				<Button
					icon='th'
					onClick={() => navigate('/reservation-activities-list')}>
					Seznam rezervacij
				</Button>
			</div>
		</div>
		<div className='flex-1' style={{ position: 'relative' }}>
			<TimelineActivities
				openReservation            ={reservation => {
					openReservationDialog(reservation);
				}}
				currentDate                ={current_date}
				scaleDays                  ={scale_days}
				selectedActivities         ={selected_activities}
				idAccommodationItem        ={props.idAccommodationItem}
				setCurrentDate             ={date => setCurrentDate(date)}
				openEditActivityItemDialog ={openEditActivityItemDialog}
				closeEditActivityItemDialog={closeEditActivityItemDialog}
				reservationInClipboard     ={reservation_in_clipboard}
				cutReservation             ={cutReservation}
				pasteReservation           ={pasteReservation} />
		</div>
	</>;
}

function mapStateToProps(state) {
	return {
		accommodations:                                   state.CodeTablesSlice.accommodations,
		accommodation_items:                              state.CodeTablesSlice.accommodation_items,
		activities:                                       state.CodeTablesSlice.activities,
		customers:                                        state.CodeTablesSlice.customers,
		activity_reservations:                            state.ReservationSlice.activity_reservations,
		accommodation_item_schedules:                     state.CodeTablesSlice.accommodation_item_schedules,
		accommodation_item_schedule_time_slots:           state.CodeTablesSlice.accommodation_item_schedule_time_slots,
		accommodation_item_schedule_time_slot_activities: state.CodeTablesSlice.accommodation_item_schedule_time_slot_activities,
		token:                                            state.UserSlice.token,
		user:                                             state.UserSlice.user,
		api_url:                                          state.ConstantsSlice.api_url,
	};
}
export default connect(mapStateToProps)(TimelineActivitiesWithFilter);
