import React, { useState, Component } from 'react';
import { connect } from 'react-redux';

import { useBus } from 'react-bus';

import {
	Button,
	ButtonGroup,
	Intent,
	Alert,
	Tooltip,
	Icon,
	Checkbox,
} from '@blueprintjs/core';

import moment from 'moment';

import { ResultHeader, ResultHeaderWithSort, Result, MultiSelectFilter, DateRangeFilter, DateFilter, TextFilter } from '../code_tables/ResultComponents';
import Reservation from '../reservations/Reservation';

import DateTimePickerDialog from '../DateTimePickerDialog';

import {
	batchAddReservationCustomers,
} from '../../slices/ReservationSlice';
import { batchAddGuestBookItems } from '../../slices/GuestSlice';
import { sendReservationCustomersToETourism } from '../../api/Reservations';
import { loadGuestBookItems } from '../../api/Guests';
import { batchSaveReservationCustomer } from '../../api/Reservations';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		identification_types,
		tourist_taxes,
		setTableItemsSort,
		openReservationDialog,
		tableItemsFilters,
		handleFilterChange,
		originalTableItemsFilters,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
		id_reservation_customers_by_id_guest_book,
		reservation_customers,
		accommodations,
		countries,
		disable_filters,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const id_no_items = {};
	for (let id in table_items) {
		const id_no = table_items[id].id_no;
		if (id_no_items[id_no] === undefined) {
			let accommodation_title = '';
			for (let id_accommodation in accommodations) {
				if (accommodations[id_accommodation].etourism_id_rno == id_no) {
					accommodation_title = accommodations[id_accommodation].title;
					break;
				}
			}
			
			id_no_items[id_no] = {
				title: id_no + ' • ' + accommodation_title,
				value: id_no,
			};
		}
	}
	
	const etourism_statuses = {
		none:    'NEPOSLAN',
		waiting: 'ČAKA NA POŠILJANJE',
		success: 'POSLANO USPEŠNO',
		failure: 'NAPAKA PRI POŠILJANJU',
	};
	const etourism_status_colors = {
		none:    '#cccccc',
		waiting: 'orange',
		success: 'green',
		failure: 'red',
	};
	const statuses = {
		1: 'Veljaven',
		2: 'Storniran',
	};
	
	const identification_types_by_internal_codes = {};
	for (let id_identification_type in identification_types) {
		identification_types_by_internal_codes[identification_types[id_identification_type].internal_code] =
			identification_types[id_identification_type];
	}
	
	const tourist_taxes_by_internal_codes = {};
	for (let id_tourist_tax in tourist_taxes) {
		tourist_taxes_by_internal_codes[tourist_taxes[id_tourist_tax].internal_code] =
			tourist_taxes[id_tourist_tax];
	}
	
	const etourism_status_items = {
		none:    { title: 'Neposlan',              value: 'none'    },
		waiting: { title: 'Čaka na pošiljanje',    value: 'waiting' },
		success: { title: 'Poslano uspešno',       value: 'success' },
		failure: { title: 'Napaka pri pošiljanju', value: 'failure' },
	};
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	const now = new Date();
	
	const countries_by_iso_3166_a1_a2 = {};
	for (let id_country in countries) {
		countries_by_iso_3166_a1_a2[countries[id_country].iso_3166_1_a2] = countries[id_country];
	}
	
	const country_list = {};
	for (let id in table_items) {
		const drzava = table_items[id].drzava;
		if (country_list[drzava] === undefined) {
			const country = countries_by_iso_3166_a1_a2[drzava];
			
			country_list[drzava] = {
				key:   drzava,
				value: country === undefined ? drzava : drzava + ' • ' + country.common_name_local,
			};
		}
	}
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table without-vertical-lines'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeader
					columnIdx={2} title='Status' />
				<ResultHeaderWithSort
					columnIdx={3} {...sort_props} title='Odjavljen' fieldName='checked_out' />
				<ResultHeaderWithSort
					columnIdx={4} {...sort_props} title='RNO' fieldName='id_no' />
				<ResultHeaderWithSort
					columnIdx={5} {...sort_props} title='Zst' fieldName='zst' />
				<ResultHeaderWithSort
					columnIdx={6} {...sort_props} title='Ime in priimek' fieldName='name_surname' />
				<ResultHeaderWithSort
					columnIdx={7} {...sort_props} title='Datum roj.' fieldName='dt_roj' />
				<ResultHeaderWithSort
					columnIdx={8} {...sort_props} title='Spol' fieldName='sp' />
				<ResultHeaderWithSort
					columnIdx={9} {...sort_props} title='Država' fieldName='drzava' />
				<ResultHeaderWithSort
					columnIdx={10} {...sort_props} title='Vrsta oseb. dok.' fieldName='vrsta_dok' />
				<ResultHeaderWithSort
					columnIdx={11} {...sort_props} title='Št. oseb. dok.' fieldName='id_st_dok' />
				<ResultHeaderWithSort
					columnIdx={12} {...sort_props} title='Datum in ura prihoda' fieldName='cas_prihoda' />
				<ResultHeaderWithSort
					columnIdx={13} {...sort_props} title='Datum in ura odhoda' fieldName='cas_odhoda' />
				<ResultHeaderWithSort
					columnIdx={14} {...sort_props} title='Datum prijave' fieldName='created' />
				<ResultHeaderWithSort
					columnIdx={15} {...sort_props} title='Status' fieldName='status' />
				<ResultHeaderWithSort
					columnIdx={16} {...sort_props} title='Št. noč.' fieldName='night_count' />
				<ResultHeaderWithSort
					columnIdx={17} {...sort_props} title='Tip TT' fieldName='tt_obracun' />
			</div>
			
			<div className='results-table without-vertical-lines filters-row'>
				<Result columnIdx={1} />
				<Result columnIdx={2} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='value'
							title_field    ='title'
							items          ={etourism_status_items}
							filtered_keys  ={tableItemsFilters.etourism_statuses}
							onFilterChange ={values => handleFilterChange('etourism_statuses', values)}
							disabled       ={disable_filters} />
					</div>
				} {...sort_props} />
				<Result columnIdx={3} child={
					<Checkbox
						checked={tableItemsFilters.checked_out === 1}
						indeterminate={tableItemsFilters.checked_out === 0}
						onChange={event => {
							let checked = 0;
							if (tableItemsFilters.checked_out === 1) {
								checked = -1;
							}
							else if (tableItemsFilters.checked_out === 0) {
								checked = 1;
							}
							
							handleFilterChange('checked_out', checked);
						}}
						disabled={disable_filters} />
				} />
				<Result columnIdx={4} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='value'
							title_field    ='title'
							items          ={id_no_items}
							filtered_keys  ={tableItemsFilters.id_no}
							displayFilter  ={x => true}
							displaySort    ={(a, b) => 1}
							onFilterChange ={values => handleFilterChange('id_no', values)}
							disabled       ={disable_filters} />
					</div>
				} {...sort_props} />
				<Result columnIdx={5} child={
					<TextFilter
						value={tableItemsFilters.zst}
						onChange={ event => handleFilterChange('zst', event) }
						disabled={disable_filters} />
				} {...sort_props} />
				<Result columnIdx={6} child={
					<TextFilter
						value={tableItemsFilters.name_surname}
						onChange={ event => handleFilterChange('name_surname', event) }
						disabled={disable_filters} />
				} {...sort_props} />
				<Result columnIdx={7} child={
					<DateFilter
						filter_value={tableItemsFilters.dt_roj}
						short_year={true}
						original_date={null}
						onFilterChange={value => handleFilterChange('dt_roj', value)}
						disabled={disable_filters} />
				} {...sort_props} />
				<Result columnIdx={8} child={
					<Checkbox
						checked={tableItemsFilters.sp === 1}
						indeterminate={tableItemsFilters.sp === 0}
						onChange={event => {
							let checked = 0;
							if (tableItemsFilters.sp === 1) {
								checked = -1;
							}
							else if (tableItemsFilters.sp === 0) {
								checked = 1;
							}
							
							handleFilterChange('sp', checked);
						}}
						disabled={disable_filters} />
				} {...sort_props} />
				<Result columnIdx={9} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='key'
							title_field    ='value'
							items          ={country_list}
							filtered_keys  ={tableItemsFilters.drzava}
							onFilterChange ={values => handleFilterChange('drzava', values)}
							disabled       ={disable_filters} />
					</div>
				} {...sort_props} />
				<Result columnIdx={10} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='internal_code'
							title_field    ='title'
							items          ={identification_types_by_internal_codes}
							filtered_keys  ={tableItemsFilters.vrsta_dok}
							displayFilter  ={x => 1}
							displaySort    ={(a, b) => a.ord - b.ord}
							onFilterChange ={values => handleFilterChange('vrsta_dok', values)}
							disabled       ={disable_filters} />
					</div>
				} {...sort_props} />
				<Result columnIdx={11} child={
					<TextFilter
						value={tableItemsFilters.id_st_dok}
						onChange={ event => handleFilterChange('id_st_dok', event) }
						disabled={disable_filters} />
				} {...sort_props} />
				<Result columnIdx={12} child={
					<DateRangeFilter
						filter_values={tableItemsFilters.cas_prihoda_range}
						short_year={true}
						original_start_date={originalTableItemsFilters.cas_prihoda_range[0]}
						original_end_date={originalTableItemsFilters.cas_prihoda_range[1]}
						onFilterChange={values => handleFilterChange('cas_prihoda_range', values)}
						disabled={disable_filters} />
				} {...sort_props} />
				<Result columnIdx={13} child={
					<DateRangeFilter
						filter_values={tableItemsFilters.cas_odhoda_range}
						short_year={true}
						original_start_date={originalTableItemsFilters.cas_odhoda_range[0]}
						original_end_date={originalTableItemsFilters.cas_odhoda_range[1]}
						onFilterChange={values => handleFilterChange('cas_odhoda_range', values)}
						disabled={disable_filters} />
				} {...sort_props} />
				<Result columnIdx={14} />
				<Result columnIdx={15} child={
					<Checkbox
						checked={tableItemsFilters.status === 1}
						indeterminate={tableItemsFilters.status === 0}
						onChange={event => {
							let checked = 0;
							if (tableItemsFilters.status === 1) {
								checked = -1;
							}
							else if (tableItemsFilters.status === 0) {
								checked = 1;
							}
							
							handleFilterChange('status', checked);
						}}
						disabled={disable_filters} />
				} />
				<Result columnIdx={16} />
				<Result columnIdx={17} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='internal_code'
							title_field    ='title'
							items          ={tourist_taxes_by_internal_codes}
							filtered_keys  ={tableItemsFilters.tt_obracun}
							onFilterChange ={values => handleFilterChange('tt_obracun', values)}
							disabled       ={disable_filters} />
					</div>
				} {...sort_props} />
			</div>
			
			{table_items_sort_ids.map((id, idx) => {
				const item = table_items[id];
				
				const etourism_status_title = etourism_statuses[
					item.zst === null || item.etourism_status === null ? 'none' : item.etourism_status
				];
				const etourism_status_color = etourism_status_colors[
					item.zst === null || item.etourism_status === null ? 'none' : item.etourism_status
				];
				
				const etourism_tooltip_content = <div>
					<div>Stanje: {etourism_status_title}</div>
					{item === null || item.log === null || item.log.length == 0 ? null :
						<>
							<div className='mt-6 mb-1'>Dnevnik dogodkov:</div>
							{[...item.log]
								.sort((log_item_a, log_item_b) => log_item_a.created.localeCompare(log_item_b.created) * -1)
								.map(guest_book_log => {
									let status_title = 'neznano';
									switch (guest_book_log.status) {
										case 'success':
											status_title  = 'POSLANO USPEŠNO';
											break;
										case 'failure':
											status_title  = 'NAPAKA PRI POŠILJANJU';
											break;
									}
									
									return <div key={'guest-book-log-' + guest_book_log.id_guest_book_log} style={{ maxWidth: 480, marginBottom: '1rem' }}>
										<div className='flex flex-row justify-between mb-1'>
											<div className='mr-12'>{status_title}</div>
											<div>{new Date(guest_book_log.created).toLocaleString('sl-SI')}</div>
										</div>
										<div>
											{
												JSON.parse(guest_book_log.message)
													.map(message_item => message_item.error_message)
													.join(', ')
											}
										</div>
									</div>;
								})
							}
						</>
					}
				</div>;
				
				const id_reservation_customer = id_reservation_customers_by_id_guest_book[id];
				const reservation_customer    = id_reservation_customer === undefined ? null : reservation_customers[id_reservation_customer];
				const checked_out             = reservation_customer === null ? null : reservation_customer.checked_out;
				
				const highlighted = checked_out !== false && new Date(item.cas_odhoda) < now && item.etourism_status == 'success';
				
				return <div className={'results-table without-vertical-lines ' + (highlighted ? 'warning' : '')} key={'items-result--result-' + id}>
					<Result columnIdx={1} child={
						<Checkbox
							checked={table_items_checked_ids[id] === true}
							onChange={event => rowCheckStateChanged(id, event.target.checked)} />
					} />
					<Result columnIdx={2} child={
						<>
							<Tooltip content={etourism_tooltip_content}>
								<Icon
									icon={reservation_customer !== null && reservation_customer.checked_out === false ? 'upload' : 'tick'}
									className='ml-1'
									color={etourism_status_color} />
							</Tooltip>
							{reservation_customer === null ? null :
								<Button
									icon='edit'
									intent='primary'
									minimal={true}
									small={true}
									onClick={() => openReservationDialog(reservation_customer.id_reservation_customer)} />
							}
						</>
					} />
					<Result columnIdx={3} child={
						checked_out === null ?
							<Icon icon='help' color='#cccccc' /> :
							(checked_out === true ?
								<Icon icon='tick' color='#394b59' />
								:
								<Icon icon='cross' color='#ff0000' />
							)
					} />
					<Result columnIdx={4} child={item.id_no} />
					<Result columnIdx={5} child={item.zst} />
					<Result columnIdx={6} cls='truncate' child={item.ime + ' ' + item.pri} />
					<Result columnIdx={7} child={moment(item.dt_roj).format('DD. MM. YYYY')} />
					<Result columnIdx={8} child={item.sp == 'M' ? 'M' : 'Ž'} />
					<Result columnIdx={9} child={item.drzava} />
					<Result columnIdx={10} cls='truncate' child={identification_types_by_internal_codes[item.vrsta_dok].title} />
					<Result columnIdx={11} child={item.id_st_dok} />
					<Result columnIdx={12} child={moment(item.cas_prihoda).format('DD. MM. YYYY HH:mm:ss')} />
					<Result columnIdx={13} child={moment(item.cas_odhoda).format('DD. MM. YYYY HH:mm:ss')} />
					<Result columnIdx={14} child={moment(item.created).format('DD. MM. YYYY HH:mm:ss')} />
					<Result columnIdx={15} cls={item.status == 2 ? 'text-red-600' : ''} child={statuses[item.status]} />
					<Result columnIdx={16} child={
						moment(item.cas_odhoda).set('second', 0).set('minute', 0).set('hour', 12).diff(
							moment(item.cas_prihoda).set('second', 0).set('minute', 0).set('hour', 12),
							'days'
						)
					} />
					<Result columnIdx={17} cls='truncate' child={tourist_taxes_by_internal_codes[item.tt_obracun].title} />
				</div>;
			})}
		</div>
		
		<div className='results-sum pb-4'>
			<div className='col'>
				Število zapisov: {table_items_sort_ids.length}
			</div>
		</div>
	</>;
}

function UploadButton(props) {
	const bus = useBus();
	const [ dialog_visible, setDialogVisible ] = useState(false);
	
	const sendToETourism = async (dialog_result) => {
		console.log({ dialog_result });
		if (dialog_result === undefined) {
			setDialogVisible(true);
			return;
		}
		else {
			setDialogVisible(false);
			if (dialog_result === false) {
				return;
			}
		}
		
		const dispatch = props.dispatch;
		const token    = props.token;
		
		const checked_ids_normal  = [];
		const checked_ids_reverse = [];
		for (let i=0; i<props.checked_ids.length; i++) {
			const id = props.checked_ids[i];
			if (props.guest_book_items[id] === undefined || props.guest_book_items[id].status == 1) {
				checked_ids_normal.push(id);
			}
			else {
				checked_ids_reverse.push(id);
			}
		}
		
		//this.increaseLoading();
		if (checked_ids_normal.length > 0) {
			await sendReservationCustomersToETourism(
				props.api_url,
				checked_ids_normal,
				'normal',
				token
			);
		}
		if (checked_ids_reverse.length > 0) {
			await sendReservationCustomersToETourism(
				props.api_url,
				checked_ids_reverse,
				'reverse',
				token
			);
		}
		await loadGuestBookItems(props.api_url, dispatch, token);
		//this.decreaseLoading();
		
		props.finished();
		
		//TODO really check for response, if it was successful or not
		bus.emit('notification-message', { message: 'Uspešno poslano', type: 'success' });
	};
	
	return <>
		<Alert
			cancelButtonText='Prekliči'
			confirmButtonText='Pošlji'
			canEscapeKeyCancel={true}
			canOutsideClickCancel={true}
			icon='upload'
			intent={Intent.WARNING}
			isOpen={dialog_visible}
			onConfirm={() => {
				sendToETourism(true);
			}}
			onCancel={() => {
				sendToETourism(false);
			}}>
			Res želite poslati podatke na e-Turizem?
		</Alert>
		
		<Button
			intent='warning'
			icon='upload'
			onClick={() => { sendToETourism() }}
			disabled={props.checked_ids.length == 0}>
			Pošlji na e-Turizem
		</Button>
	</>;
}

class GuestBook extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps  = this.updateTableItemsFromProps .bind(this);
		this.setTableItemsSort          = this.setTableItemsSort         .bind(this);
		this.refreshTableItemsSort      = this.refreshTableItemsSort     .bind(this);
		this.toIsoString                = this.toIsoString               .bind(this);
		this.filterTableItems           = this.filterTableItems          .bind(this);
		this.handleFilterChange         = this.handleFilterChange        .bind(this);
		this.openAddEditItemDialog      = this.openAddEditItemDialog     .bind(this);
		this.closeAddEditItemDialog     = this.closeAddEditItemDialog    .bind(this);
		this.openDateRangePickerDialog  = this.openDateRangePickerDialog .bind(this);
		this.closeDateRangePickerDialog = this.closeDateRangePickerDialog.bind(this);
		this.deleteItem                 = this.deleteItem                .bind(this);
		this.openReservationDialog      = this.openReservationDialog     .bind(this);
		this.closeReservationDialog     = this.closeReservationDialog    .bind(this);
		this.getResetState              = this.getResetState             .bind(this);
		this.checkOutCustomers          = this.checkOutCustomers         .bind(this);
		this.openDateTimePickerDialog   = this.openDateTimePickerDialog  .bind(this);
		this.closeDateTimePickerDialog  = this.closeDateTimePickerDialog .bind(this);
		
		this.generateReservationCustomersByIdGuestBook           = this.generateReservationCustomersByIdGuestBook          .bind(this);
		this.setMainTableItemsUnsentChecked                      = this.setMainTableItemsUnsentChecked                     .bind(this);
		this.setMainTableItemsUnsentUnchecked                    = this.setMainTableItemsUnsentUnchecked                   .bind(this);
		this.setMainTableItemsLateCustomersChecked               = this.setMainTableItemsLateCustomersChecked              .bind(this);
		this.setMainTableItemsLateCustomersUnchecked             = this.setMainTableItemsLateCustomersUnchecked            .bind(this);
		this.setMainTableItemsActiveCustomersChecked             = this.setMainTableItemsActiveCustomersChecked            .bind(this);
		this.setMainTableItemsActiveCustomersUnchecked           = this.setMainTableItemsActiveCustomersUnchecked          .bind(this);
		this.unsetUnsentAndLateAndActiveCustomersCheckedIfNeeded = this.unsetUnsentAndLateAndActiveCustomersCheckedIfNeeded.bind(this);
		
		const table_items_filters = {
			etourism_statuses: [ 'none', 'waiting', 'failure' ],
			checked_out:       0,
			id_no:             [],
			zst:               '',
			name_surname:      '',
			dt_roj:            null,
			sp:                0,
			drzava:            [],
			vrsta_dok:         [],
			id_st_dok:         '',
			cas_prihoda_range: [moment().toDate(), null],
			cas_odhoda_range:  [null, null],
			status:            0,
			tt_obracun:        [],
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'cas_prihoda',
			table_items_sort_direction: 'DESC',
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'cas_prihoda',
			table_items_sort_direction: 'DESC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			item_delete_requested: null,
			date_range_picker_dialog_open:        false,
			date_range_picker_dialog_filter_name: null,
			table_items_checked_ids: {},
			id_reservation_customers_by_id_guest_book: this.generateReservationCustomersByIdGuestBook(this.props.reservation_customers),
			reservation_dialog_open:                    false,
			reservation_dialog_id_reservation:          -1,
			reservation_dialog_id_reservation_customer: -1,
			date_picker_dialog_open:                     false,
			date_picker_dialog_initial_date:             null,
			date_picker_dialog_id_reservation_customers: [],
			unsent_customers_selected:                   false,
			late_customers_selected:                     false,
			active_customers_selected:                   false,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.guest_book_items != this.state.table_items) {
			let new_state = {
				...this.state,
				...this.updateTableItemsFromProps(this.props, this.state),
			};
			
			if (this.state.unsent_customers_selected) {
				new_state = this.setMainTableItemsUnsentChecked(new_state, true);
			}
			if (this.state.late_customers_selected) {
				new_state = this.setMainTableItemsLateCustomersChecked(new_state, true);
			}
			if (this.state.active_customers_selected) {
				new_state = this.setMainTableItemsActiveCustomersChecked(new_state, true);
			}
			
			this.setState(
				new_state
			);
		}
		
		if (this.props.reservation_customers != prevProps.reservation_customers) {
			let new_state = {
				...this.state,
				id_reservation_customers_by_id_guest_book: this.generateReservationCustomersByIdGuestBook(this.props.reservation_customers),
			};
			
			if (this.state.unsent_customers_selected) {
				new_state = this.setMainTableItemsUnsentChecked(new_state, true);
			}
			if (this.state.late_customers_selected) {
				new_state = this.setMainTableItemsLateCustomersChecked(new_state, true);
			}
			if (this.state.active_customers_selected) {
				new_state = this.setMainTableItemsActiveCustomersChecked(new_state, true);
			}
			
			this.setState(
				new_state
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const ids = Object.keys(props.guest_book_items);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			table_items: props.guest_book_items,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const identification_types_by_internal_codes = {};
		for (let id_identification_type in this.props.identification_types) {
			identification_types_by_internal_codes[this.props.identification_types[id_identification_type].internal_code] =
				this.props.identification_types[id_identification_type];
		}
		
		const tourist_taxes_by_internal_codes = {};
		for (let id_tourist_tax in this.props.tourist_taxes) {
			tourist_taxes_by_internal_codes[this.props.tourist_taxes[id_tourist_tax].internal_code] =
				this.props.tourist_taxes[id_tourist_tax];
		}
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a   = '';
			let b   = '';
			let dir = direction;
			
			a = state.table_items[a_key].etourism_status;
			b = state.table_items[b_key].etourism_status;
			const both_waiting = a == b;
			const none_waiting = a != 'waiting' && b != 'waiting';
			
			if (a == 'waiting' && b != 'waiting') {
				a = 0;
				b = 1 * (dir == 'ASC' ? 1 : -1);
			}
			else if (a != 'waiting' && b == 'waiting') {
				a = 1 * (dir == 'ASC' ? 1 : -1);
				b = 0;
			}
			
			// sort secondarily by existence of zst
			if (a == b) {
				a = state.table_items[a_key].zst === null ? 0 : (dir == 'ASC' ? 1 : -1);
				b = state.table_items[b_key].zst === null ? 0 : (dir == 'ASC' ? 1 : -1);
			}
			
			if (both_waiting || none_waiting) {
				if (field == 'checked_out') {
					const a_id_reservation_customer = this.state.id_reservation_customers_by_id_guest_book[state.table_items[a_key].id_guest_book];
					const a_reservation_customer    = a_id_reservation_customer === undefined ? null : this.props.reservation_customers[a_id_reservation_customer];
					a                               = a_reservation_customer === null ? false : a_reservation_customer.checked_out;
					
					const b_id_reservation_customer = this.state.id_reservation_customers_by_id_guest_book[state.table_items[b_key].id_guest_book];
					const b_reservation_customer    = b_id_reservation_customer === undefined ? null : this.props.reservation_customers[b_id_reservation_customer];
					b                               = b_reservation_customer === null ? false : b_reservation_customer.checked_out;
				}
				else if (field == 'name_surname') {
					const item_a = state.table_items[a_key];
					const item_b = state.table_items[b_key];
					
					a = item_a.ime + ' ' + item_a.pri;
					b = item_b.ime + ' ' + item_b.pri;
					
					return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
				}
				else if (field == 'sp') {
					a = state.table_items[a_key].sp == 'M' ? 'M' : 'Ž';
					b = state.table_items[b_key].sp == 'M' ? 'M' : 'Ž';
				}
				else if (field == 'vrsta_dok') {
					a = identification_types_by_internal_codes[state.table_items[a_key].vrsta_dok].title;
					b = identification_types_by_internal_codes[state.table_items[b_key].vrsta_dok].title;
				}
				else if (field == 'night_count') {
					const item_a = state.table_items[a_key];
					const item_b = state.table_items[b_key];
					
					a = moment(item_a.cas_odhoda).set('second', 0).set('minute', 0).set('hour', 12).diff(
						moment(item_a.cas_prihoda).set('second', 0).set('minute', 0).set('hour', 12),
						'days'
					);
					b = moment(item_b.cas_odhoda).set('second', 0).set('minute', 0).set('hour', 12).diff(
						moment(item_b.cas_prihoda).set('second', 0).set('minute', 0).set('hour', 12),
						'days'
					);
				}
				else if (field == 'tt_obracun') {
					a = tourist_taxes_by_internal_codes[state.table_items[a_key].tt_obracun].title;
					b = tourist_taxes_by_internal_codes[state.table_items[b_key].tt_obracun].title;
				}
				else {
					a = state.table_items[a_key][field];
					b = state.table_items[b_key][field];
				}
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filter_etourism_statuses = filters.etourism_statuses;
		const filter_checked_out       = filters.checked_out;
		const filter_id_no             = filters.id_no;
		const filter_zst               = filters.zst;
		const filter_name_surname      = filters.name_surname.toUpperCase();
		const filter_dt_roj            = filters.dt_roj;
		const filter_sp                = filters.sp;
		const filter_drzava            = filters.drzava;
		const filter_vrsta_dok         = filters.vrsta_dok;
		const filter_id_st_dok         = filters.id_st_dok.toUpperCase();
		const filter_cas_prihoda_start = filters.cas_prihoda_range[0] === null ? null : this.toIsoString(filters.cas_prihoda_range[0]);
		const filter_cas_prihoda_end   = filters.cas_prihoda_range[1] === null ? null : this.toIsoString(filters.cas_prihoda_range[1]);
		const filter_cas_odhoda_start  = filters.cas_odhoda_range[0] === null ? null : this.toIsoString(filters.cas_odhoda_range[0]);
		const filter_cas_odhoda_end    = filters.cas_odhoda_range[1] === null ? null : this.toIsoString(filters.cas_odhoda_range[1]);
		const filter_status            = filters.status;
		const filter_tt_obracun        = filters.tt_obracun;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filter_etourism_statuses.length > 0 && !filter_etourism_statuses.some(x => x == item.etourism_status)) return false;
			
			if (filter_checked_out !== 0) {
				const id_reservation_customer = this.state.id_reservation_customers_by_id_guest_book[id];
				const reservation_customer    = id_reservation_customer === undefined ? null : this.props.reservation_customers[id_reservation_customer];
				const checked_out             = reservation_customer === null ? null : reservation_customer.checked_out;
				
				if (
					checked_out === null ||
					(checked_out === true  && filter_checked_out === -1) ||
					(checked_out === false && filter_checked_out ===  1)
				) {
					return false;
				}
			}
			
			if (filter_id_no.length > 0 && !filter_id_no.some(x => x == item.id_no)) return false;
			
			if (filter_zst != '' && (item.zst === null || item.zst.toString().indexOf(filter_zst) == -1)) {
				return false;
			}
			
			if (filter_name_surname != '' && (item.ime + ' ' + item.pri).toUpperCase().indexOf(filter_name_surname) == -1) {
				return false;
			}
			
			if (filter_dt_roj != null && item.dt_roj != this.toIsoString(filter_dt_roj)) {
				return false;
			}
			
			if (filter_sp !== 0) {
				if (
					(item.sp === 'M' && filter_sp === -1) ||
					(item.sp === 'F' && filter_sp ===  1)
				) {
					return false;
				}
			}
			
			if (filter_drzava.length > 0 && !filter_drzava.some(x => x == item.drzava)) return false;
			
			if (filter_vrsta_dok.length > 0 && !filter_vrsta_dok.some(x => x == item.vrsta_dok)) return false;
			
			if (filter_id_st_dok != '' && item.id_st_dok.toUpperCase().indexOf(filter_id_st_dok) == -1) {
				return false;
			}
			
			if (filter_cas_prihoda_start !== null && item.cas_prihoda !== null && this.toIsoString(new Date(item.cas_prihoda)) < filter_cas_prihoda_start) {
				return false;
			}
			if (filter_cas_prihoda_end !== null && item.cas_prihoda !== null && this.toIsoString(new Date(item.cas_prihoda)) > filter_cas_prihoda_end) {
				return false;
			}
			
			if (filter_cas_odhoda_start !== null && item.cas_odhoda !== null && this.toIsoString(new Date(item.cas_odhoda)) < filter_cas_odhoda_start) {
				return false;
			}
			if (filter_cas_odhoda_end !== null && item.cas_odhoda !== null && this.toIsoString(new Date(item.cas_odhoda)) > filter_cas_odhoda_end) {
				return false;
			}
			
			if (filter_status !== 0) {
				if (
					(item.status === 1 && filter_status === -1) ||
					(item.status === 2 && filter_status ===  1)
				) {
					return false;
				}
			}
			
			if (filter_tt_obracun.length > 0 && !filter_tt_obracun.some(x => x == item.tt_obracun)) return false;
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			etourism_statuses: this.state.table_items_filters.etourism_statuses,
			checked_out:       this.state.table_items_filters.checked_out,
			id_no:             this.state.table_items_filters.id_no,
			zst:               this.state.table_items_filters.zst,
			name_surname:      this.state.table_items_filters.name_surname,
			dt_roj:            this.state.table_items_filters.dt_roj,
			sp:                this.state.table_items_filters.sp,
			drzava:            this.state.table_items_filters.drzava,
			vrsta_dok:         this.state.table_items_filters.vrsta_dok,
			id_st_dok:         this.state.table_items_filters.id_st_dok,
			cas_prihoda_range: this.state.table_items_filters.cas_prihoda_range,
			cas_odhoda_range:  this.state.table_items_filters.cas_odhoda_range,
			status:            this.state.table_items_filters.status,
			tt_obracun:        this.state.table_items_filters.tt_obracun,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item });
	}
	closeAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			//this.props.dispatch(addItem({ item, token: this.props.token, save_to_api: true }));
		}
	}
	deleteItem(item) {
		this.setState({ item_delete_requested: item, token: this.props.token });
	}
	
	openDateRangePickerDialog(filter_name) {
		this.setState({
			date_range_picker_dialog_open:        true,
			date_range_picker_dialog_filter_name: filter_name,
		});
	}
	closeDateRangePickerDialog(start_date, end_date) {
		this.setState({
			date_range_picker_dialog_open: false,
		});
		
		if (start_date !== undefined && end_date !== undefined) {
			this.handleFilterChange(
				this.state.date_range_picker_dialog_filter_name,
				[ start_date, (end_date === null ? start_date : end_date) ]
			);
		}
	}
	
	generateReservationCustomersByIdGuestBook(reservation_customers) {
		const items = {};
		for (let id_reservation_customer in reservation_customers) {
			const reservation_customer = reservation_customers[id_reservation_customer];
			if (reservation_customer.id_guest_book !== null) {
				items[reservation_customer.id_guest_book] = id_reservation_customer;
			}
		}
		return items;
	}
	
	openReservationDialog(id_reservation_customer) {
		this.setState({
			reservation_dialog_open:                    true,
			reservation_dialog_id_reservation:          this.props.reservation_customers[id_reservation_customer].id_reservation,
			reservation_dialog_id_reservation_customer: id_reservation_customer,
		});
	}
	closeReservationDialog() {
		this.setState({
			reservation_dialog_open:                    false,
			reservation_dialog_id_reservation:          -1,
			reservation_dialog_id_reservation_customer: -1,
		});
	}
	
	getResetState(set_to_original) {
		set_to_original = set_to_original || false;
		
		const new_state = {
			table_items:                this.props.guest_book_items,
			table_items_sort_ids:       [],
			table_items_filtered_ids:   [],
			table_items_sort_field:     this.state.table_items_sort_field,
			table_items_sort_direction: this.state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(
			set_to_original ?
				this.state.original_table_items_filters
				:
				{
					etourism_statuses: [],
					checked_out:       0,
					id_no:             [],
					zst:               '',
					name_surname:      '',
					dt_roj:            null,
					sp:                0,
					drzava:            [],
					vrsta_dok:         [],
					id_st_dok:         '',
					cas_prihoda_range: [null, null],
					cas_odhoda_range:  [null, null],
					status:            0,
					tt_obracun:        [],
				},
			new_state,
			true
		);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setMainTableItemsUnsentChecked(state, return_only) {
		state       = state       || this.state;
		return_only = return_only || false;
		
		const new_state = this.getResetState();
		
		const table_items_checked_ids = {};
		for (let i=0; i<new_state.table_items_sort_ids.length; i++) {
			const id = new_state.table_items_sort_ids[i];
			if (new_state.table_items[id].zst === null || new_state.table_items[id].etourism_status == 'waiting') {
				table_items_checked_ids[id] = true;
			}
		}
		
		new_state.table_items_checked_ids  = table_items_checked_ids;
		new_state.table_items_filtered_ids = Object.keys(table_items_checked_ids);
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			new_state.table_items_filtered_ids,
			{
				table_items_filtered_ids: new_state.table_items_filtered_ids,
				table_items_filters:      new_state.table_items_filters,
				table_items:              state.table_items,
			},
			true
		);
		
		new_state.table_items_sort_ids      = table_items_sort_ids;
		new_state.unsent_customers_selected = true;
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	setMainTableItemsLateCustomersChecked(state, return_only) {
		state       = state       || this.state;
		return_only = return_only || false;
		
		let new_state = this.getResetState();
		
		const now = new Date();
		
		const table_items_checked_ids = {};
		for (let i=0; i<new_state.table_items_sort_ids.length; i++) {
			const id = new_state.table_items_sort_ids[i];
			
			if (state.id_reservation_customers_by_id_guest_book === undefined) {
				console.log('id_reservation_customers_by_id_guest_book is undefined, but should not be');
				continue;
			}
			
			const id_reservation_customer = state.id_reservation_customers_by_id_guest_book[id];
			const reservation_customer    = id_reservation_customer === undefined ? null : this.props.reservation_customers[id_reservation_customer];
			const checked_out             = reservation_customer === null ? null : reservation_customer.checked_out;
			
			if (checked_out === false && new Date(new_state.table_items[id].cas_odhoda) < now) {
				table_items_checked_ids[id] = true;
			}
		}
		
		new_state.table_items_checked_ids  = table_items_checked_ids;
		new_state.table_items_filtered_ids = Object.keys(table_items_checked_ids);
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			new_state.table_items_filtered_ids,
			{
				table_items_filtered_ids: new_state.table_items_filtered_ids,
				table_items_filters:      new_state.table_items_filters,
				table_items:              state.table_items,
			},
			true
		);
		
		new_state.table_items_sort_ids    = table_items_sort_ids;
		new_state.late_customers_selected = true;
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	setMainTableItemsActiveCustomersChecked(state, return_only) {
		state       = state       || this.state;
		return_only = return_only || false;
		
		let new_state = this.getResetState();
		
		const today_iso = moment().format();
		
		const table_items_checked_ids = {};
		for (let i=0; i<new_state.table_items_sort_ids.length; i++) {
			const id = new_state.table_items_sort_ids[i];
			
			if (state.id_reservation_customers_by_id_guest_book === undefined) {
				console.log('id_reservation_customers_by_id_guest_book is undefined, but should not be');
				continue;
			}
			
			const id_reservation_customer = state.id_reservation_customers_by_id_guest_book[id];
			const reservation_customer    = id_reservation_customer === undefined ? null : this.props.reservation_customers[id_reservation_customer];
			const checked_out             = reservation_customer === null ? null : reservation_customer.checked_out;
			
			if (
				checked_out === false &&
				moment(new_state.table_items[id].cas_prihoda).format() <= today_iso &&
				moment(new_state.table_items[id].cas_odhoda ).format() >= today_iso
			) {
				table_items_checked_ids[id] = true;
			}
		}
		
		new_state.table_items_checked_ids  = table_items_checked_ids;
		new_state.table_items_filtered_ids = Object.keys(table_items_checked_ids);
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			new_state.table_items_filtered_ids,
			{
				table_items_filtered_ids: new_state.table_items_filtered_ids,
				table_items_filters:      new_state.table_items_filters,
				table_items:              state.table_items,
			},
			true
		);
		
		new_state.table_items_sort_ids      = table_items_sort_ids;
		new_state.active_customers_selected = true;
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	setMainTableItemsUnsentUnchecked() {
		this.setState({
			unsent_customers_selected: false,
			table_items_checked_ids: {},
			...this.getResetState(true),
		});
	}
	setMainTableItemsLateCustomersUnchecked() {
		this.setState({
			late_customers_selected: false,
			table_items_checked_ids: {},
			...this.getResetState(true),
		});
	}
	setMainTableItemsActiveCustomersUnchecked() {
		this.setState({
			active_customers_selected: false,
			table_items_checked_ids:   {},
			...this.getResetState(true),
		});
	}
	
	unsetUnsentAndLateAndActiveCustomersCheckedIfNeeded() {
		if (this.state.late_customers_selected) {
			this.setMainTableItemsLateCustomersUnchecked();
		}
		if (this.state.unsent_customers_selected) {
			this.setMainTableItemsUnsentUnchecked();
		}
		if (this.state.active_customers_selected) {
			this.setMainTableItemsActiveCustomersUnchecked();
		}
	}
	
	async checkOutCustomers(id_reservation_customers, set_check_out_to_now) {
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		const customers = id_reservation_customers.map(id_reservation_customer => {
			const reservation_customer = {...this.props.reservation_customers[id_reservation_customer]};
			
			if (set_check_out_to_now) {
				reservation_customer.check_out = moment().toISOString(true);
			}
			reservation_customer.checked_out = true;
			
			return reservation_customer;
		});
		const new_items = await batchSaveReservationCustomer(
			this.props.api_url,
			customers,
			token
		);
		
		dispatch(batchAddReservationCustomers({
			items: new_items.reservation_customers,
			token,
		}));
		dispatch(batchAddGuestBookItems({
			items: new_items.guest_book_items,
			token,
		}));
		
		this.setState({ table_items_checked_ids: {} });
		this.unsetUnsentAndLateAndActiveCustomersCheckedIfNeeded();
	}
	openDateTimePickerDialog(id_reservation_customers) {
		if (id_reservation_customers.length == 0) return;
		
		const reservation_customer     = this.props.reservation_customers    [id_reservation_customers[0]];
		const reservation              = this.props.reservations             [reservation_customer.id_reservation];
		const accommodation_item_place = this.props.accommodation_item_places[reservation.id_accommodation_item_place];
		const accommodation_item       = this.props.accommodation_items      [accommodation_item_place.id_accommodation_item];
		const accommodation            = this.props.accommodations           [accommodation_item.id_accommodation];
		
		const initial_date = moment().add(1, 'day');
		
		const default_check_out_time = accommodation.default_check_out_time;
		const default_check_out_time_arr = default_check_out_time.split(':');
		if (default_check_out_time_arr.length == 3) {
			initial_date.hour  (parseInt(default_check_out_time_arr[0], 10));
			initial_date.minute(parseInt(default_check_out_time_arr[1], 10));
		}
		
		this.setState({
			date_picker_dialog_open:                     true,
			date_picker_dialog_initial_date:             initial_date.toDate(),
			date_picker_dialog_id_reservation_customers: id_reservation_customers,
		});
		this.unsetUnsentAndLateAndActiveCustomersCheckedIfNeeded();
	}
	async closeDateTimePickerDialog(newValue) {
		this.setState({ date_picker_dialog_open: false });
		
		if (newValue instanceof Date) {
			const id_reservation_customers = this.state.date_picker_dialog_id_reservation_customers;
			const new_date                 = newValue;
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			const customers = id_reservation_customers.map(id_reservation_customer => {
				const reservation_customer = {...this.props.reservation_customers[id_reservation_customer]};
				
				const new_date_moment = moment(new_date);
				new_date_moment.second(0);
				reservation_customer.check_out = new_date_moment.toISOString(true);
				
				return reservation_customer;
			});
			const new_items = await batchSaveReservationCustomer(
				this.props.api_url,
				customers,
				token
			);
			
			dispatch(batchAddReservationCustomers({
				items: new_items.reservation_customers,
				token,
			}));
			dispatch(batchAddGuestBookItems({
				items: new_items.guest_book_items,
				token,
			}));
		}
		
		this.setState({ table_items_checked_ids: {} });
	}
	
	render() {
		const selected_id_reservation_customers = [];
		const selected_id_reservation_customers_not_checked_out = [];
		let   can_log_out_customers = true;
		
		for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
			const id_guest_book           = this.state.table_items_sort_ids[i];
			const id_reservation_customer = this.state.id_reservation_customers_by_id_guest_book[id_guest_book];
			
			if (this.state.table_items_checked_ids[id_guest_book] === true && id_reservation_customer !== undefined) {
				selected_id_reservation_customers.push(id_reservation_customer);
				
				if (this.props.reservation_customers[id_reservation_customer].checked_out !== true) {
					selected_id_reservation_customers_not_checked_out.push(id_reservation_customer);
				}
				
				const guest = this.props.guest_book_items[id_guest_book];
				console.log({ guest, zst: guest.zst });
				if (guest === null || guest.zst === null) {
					can_log_out_customers = false;
				}
			}
		}
		
		const checked_ids = [];
		for (let id_guest_book in this.state.table_items_checked_ids) {
			if (this.state.table_items_checked_ids[id_guest_book] === true) {
				checked_ids.push(id_guest_book);
			}
		}
		
		return <>
			{this.state.item_delete_requested === null ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Briši'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						//this.props.dispatch(deleteItem(this.state.item_delete_requested.id_item));
						this.setState({ item_delete_requested: null });
					}}
					onCancel={() => {
						this.setState({ item_delete_requested: null });
					}}>
					Ali res želite izbrisati ta zapis?
				</Alert>
			}
			
			{/*!this.state.add_edit_item_dialog_open ? null :
				<AddEditItem
					closeAddEditItemDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item}
					measuring_units={this.props.measuring_units}
					tax_rates={this.props.tax_rates} />
			*/}
			
			{!this.state.reservation_dialog_open ? null :
				<Reservation
					closeReservation={this.closeReservationDialog}
					deleteReservation={null}
					reservation={this.props.reservations[this.state.reservation_dialog_id_reservation]}
					open_id_reservation_customer={this.state.reservation_dialog_id_reservation_customer} />
			}
			
			{!this.state.date_picker_dialog_open ? null :
				<DateTimePickerDialog
					closeDateTimePickerDialog={this.closeDateTimePickerDialog}
					value={this.state.date_picker_dialog_initial_date} />
			}
			
			<div className='flex flex-col flex-grow guest-book-list'>
				<div className='pl-4 pt-4'>
					<ButtonGroup className='align-middle mr-2'>
						<Button
							intent='success'
							icon='multi-select'
							className='w-40'
							onClick={() => {
								if (this.state.unsent_customers_selected) {
									this.setMainTableItemsUnsentUnchecked();
								}
								else {
									this.setMainTableItemsUnsentChecked();
								}
							}}
							disabled={this.state.late_customers_selected || this.state.active_customers_selected}>
							{ this.state.unsent_customers_selected ? 'Počisti filter' : 'Izberi neposlane' }
						</Button>
						<Button
							intent='success'
							icon='multi-select'
							className='w-48'
							onClick={() => {
								if (this.state.late_customers_selected) {
									this.setMainTableItemsLateCustomersUnchecked();
								}
								else {
									this.setMainTableItemsLateCustomersChecked();
								}
							}}
							disabled={this.state.unsent_customers_selected || this.state.active_customers_selected}>
							{ this.state.late_customers_selected ? 'Počisti filter' : 'Izberi in prikaži zamudnike' }
						</Button>
						<Button
							intent='success'
							icon='multi-select'
							className='w-48'
							onClick={() => {
								if (this.state.active_customers_selected) {
									this.setMainTableItemsActiveCustomersUnchecked();
								}
								else {
									this.setMainTableItemsActiveCustomersChecked();
								}
							}}
							disabled={this.state.late_customers_selected || this.state.unsent_customers_selected}>
							{ this.state.active_customers_selected ? 'Počisti filter' : 'Trenutni gosti' }
						</Button>
					</ButtonGroup>
					<UploadButton
						checked_ids      ={checked_ids}
						guest_book_items ={this.props.guest_book_items}
						dispatch         ={this.props.dispatch}
						token            ={this.props.token}
						api_url          ={this.props.api_url}
						finished={() => {
							this.setState({ table_items_checked_ids: {} });
							this.unsetUnsentAndLateAndActiveCustomersCheckedIfNeeded();
						}} />
					<ButtonGroup className='ml-2 align-middle'>
						<Button
							intent='primary'
							icon='print'
							onClick={() => {}}>
							Natisni knjigo gostov
						</Button>
						<Button
							intent='primary'
							icon='cloud-download'
							className='w-40'
							onClick={() => {
								if (checked_ids.length > 0) {
									const checked_ids_joined = checked_ids.join(',');
									
									const id_rnos = Object.values(this.props.accommodations)
										.map(x => x.etourism_id_rno)
										.join(',');
									
									const params = [
										'id_rnos=' + id_rnos,
									];
									const data = {
										ids: checked_ids_joined
									};
									
									const form = document.createElement('form');
									form.target = '_blank';
									form.method = 'POST';
									form.action = this.props.api_url +
										'Guests/v1/export-guest-book-to-excel?' + 
										params.join('&');
									form.style.display = 'none';
									
									for (let key in data) {
										const input = document.createElement('input');
										input.type = 'hidden';
										input.name = key;
										input.value = data[key];
										form.appendChild(input);
									}
									
									document.body.appendChild(form);
									form.submit();
									document.body.removeChild(form);
								}
								else {
									const cas_prihoda_from = this.state.table_items_filters.cas_prihoda_range[0] === null ? null :
										moment(this.state.table_items_filters.cas_prihoda_range[0]).format('YYYY-MM-DD');
									const cas_prihoda_until = this.state.table_items_filters.cas_prihoda_range[1] === null ? null :
										moment(this.state.table_items_filters.cas_prihoda_range[1]).format('YYYY-MM-DD');
									
									const cas_odhoda_from = this.state.table_items_filters.cas_odhoda_range[0] === null ? null :
										moment(this.state.table_items_filters.cas_odhoda_range[0]).format('YYYY-MM-DD');
									const cas_odhoda_until = this.state.table_items_filters.cas_odhoda_range[1] === null ? null :
										moment(this.state.table_items_filters.cas_odhoda_range[1]).format('YYYY-MM-DD');
									
									const id_rnos = (
										this.state.table_items_filters.id_no.length > 0 ?
											this.state.table_items_filters.id_no
											:
											Object.values(this.props.accommodations).map(x => x.etourism_id_rno)
												.filter(x => x > 0)
									).join(',');
									
									const params = [
										'date_check_in_from='   + (cas_prihoda_from  === null ? '' : cas_prihoda_from ),
										'date_check_in_until='  + (cas_prihoda_until === null ? '' : cas_prihoda_until),
										'date_check_out_from='  + (cas_odhoda_from   === null ? '' : cas_odhoda_from  ),
										'date_check_out_until=' + (cas_odhoda_until  === null ? '' : cas_odhoda_until ),
										'id_rnos='              + id_rnos,
									];
									
									window.open(
										this.props.api_url +
										'Guests/v1/export-guest-book-to-excel?' + 
										params.join('&')
									);
								}
							}}
							disabled={
								(
									this.state.unsent_customers_selected ||
									this.state.late_customers_selected   ||
									this.state.active_customers_selected
								)
								&&
								checked_ids.length == 0
							}>
							{ checked_ids.length == 0 ? 'Izvoz v Excel' : 'Izvoz izbranih v Excel' }
						</Button>
					<ButtonGroup className='ml-2 align-middle'>
					</ButtonGroup>
						<Button
							intent='warning'
							icon='log-out'
							onClick={() => { this.checkOutCustomers(selected_id_reservation_customers, true); }}
							disabled={selected_id_reservation_customers == 0 || !can_log_out_customers}>
							Odjavi s trenutnim datumom/časom
						</Button>
						<Button
							intent='warning'
							icon='log-out'
							onClick={() => { this.checkOutCustomers(selected_id_reservation_customers, false); }}
							disabled={selected_id_reservation_customers == 0 || !can_log_out_customers}>
							Označi kot odjavljen
						</Button>
						<Button
							intent='warning'
							icon='arrows-horizontal'
							onClick={() => {
								this.openDateTimePickerDialog(selected_id_reservation_customers_not_checked_out);
							}}
							disabled={selected_id_reservation_customers_not_checked_out == 0}>
							Podaljšaj odjavo
						</Button>
					</ButtonGroup>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items=               {this.state.table_items}
							table_items_sort_ids=      {this.state.table_items_sort_ids}
							table_items_sort_field=    {this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort=         {this.setTableItemsSort}
							openReservationDialog=     {this.openReservationDialog}
							tableItemsFilters=         {this.state.table_items_filters}
							handleFilterChange=        {this.handleFilterChange}
							measuring_units=           {this.props.measuring_units}
							tourist_taxes=             {this.props.tourist_taxes}
							identification_types=      {this.props.identification_types}
							countries=                 {this.props.countries}
							table_items_checked_ids=   {this.state.table_items_checked_ids}
							rowCheckStateChanged={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							originalTableItemsFilters=                {this.state.original_table_items_filters}
							id_reservation_customers_by_id_guest_book={this.state.id_reservation_customers_by_id_guest_book}
							reservation_customers=                    {this.props.reservation_customers}
							accommodations=                           {this.props.accommodations}
							disable_filters=                          {
																		this.state.late_customers_selected   ||
																		this.state.unsent_customers_selected ||
																		this.state.active_customers_selected
																	} />
					</div>
				</div>
			</div>
		</>;
	}
}
GuestBook.propTypes = {
};

function mapStateToProps(state) {
	return {
		guest_book_items:      state.GuestSlice.guest_book_items,
		reservation_customers: state.ReservationSlice.reservation_customers,
		reservations:          state.ReservationSlice.reservations,
		reservation_customers_by_id_reservations: state.ReservationSlice.reservation_customers_by_id_reservations,
		measuring_units:       state.CodeTablesSlice.measuring_units,
		tax_rates:             state.CodeTablesSlice.tax_rates,
		tourist_taxes:         state.CodeTablesSlice.tourist_taxes,
		identification_types:  state.CodeTablesSlice.identification_types,
		accommodations:        state.CodeTablesSlice.accommodations,
		accommodation_item_places: state.CodeTablesSlice.accommodation_item_places,
		accommodation_items:   state.CodeTablesSlice.accommodation_items,
		countries:             state.CodeTablesSlice.countries,
		token:                 state.UserSlice.token,
		api_url:               state.GuestSlice.api_url,
	};
}

export default connect(mapStateToProps)(GuestBook);
