import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import moment from 'moment';

import {
	Button,
	ButtonGroup,
	Checkbox,
	Alert,
	Intent,
	Icon,
} from '@blueprintjs/core';

import EditInvoiceDialog from './EditInvoiceDialog';
import WaitFiscalVerificationDialog from './WaitFiscalVerificationDialog';
import { addInvoice, addInvoices, deleteInvoice, addAdvanceInvoiceConsumptions } from '../../slices/BusinessSlice';
import { saveInvoice, deleteInvoice as apiDeleteInvoice, increaseInvoicePrintCount, loadInvoices, loadAdvanceInvoiceConsumptions, loadInvoiceFiscalVerification } from '../../api/Business';
import { addDocumentAssociations } from '../../slices/DocumentSlice';
import DateRangePickerDialog from '../DateRangePickerDialog';
import InvoiceCreator from '../../helpers/InvoiceCreator';
import AdvanceInvoiceListDialog from './AdvanceInvoiceListDialog';
import InvoiceHelper from '../../helpers/Invoice';

import { enqueueInvoiceOnA4PrinterQueue, enqueueInvoiceOnPosPrinterQueue } from '../../slices/AppSlice';

import { ResultHeader, ResultHeaderWithSort, Result, MultiSelectFilter, DateRangeFilter, TextFilter } from '../code_tables/ResultComponents';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openAddEditItemDialog,
		tableItemsFilters,
		originalTableItemsFilters,
		handleFilterChange,
		customers,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
		deleteItem,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const toIsoString = date => {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	};
	
	const toLocalString = date => {
		if (date === null || date === undefined) return '';
		
		if (!(date instanceof Date)) return date;
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear();
	};
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	const invoice_types = {
		'cash-invoice':            { ord: 1, visible: false, key: 'cash-invoice',            title: 'Blagajniški račun' },
		'cash-prepayment-invoice': { ord: 2, visible: false, key: 'cash-prepayment-invoice', title: 'Račun za predplačilo' },
		'invoice':                 { ord: 3, visible: true,  key: 'invoice',                 title: 'Faktura'           },
		'prepayment-invoice':      { ord: 4, visible: true,  key: 'prepayment-invoice',      title: 'Avansni račun'     },
		'credit-note':             { ord: 5, visible: true,  key: 'credit-note',             title: 'Dobropis'          },
	};
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-auto sticky-header'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeader columnIdx={2} title='Urejanje' />
				<ResultHeaderWithSort
					columnIdx={3} {...sort_props} title='Št. računa' fieldName='invoice_number_internal' />
				<ResultHeaderWithSort
					columnIdx={4} {...sort_props} title='Šifra' fieldName='customer_internal_code' />
				<ResultHeaderWithSort
					columnIdx={5} {...sort_props} title='Naziv stranke' fieldName='id_customer' />
				<ResultHeaderWithSort
					columnIdx={6} {...sort_props} title='Datum dokumenta' fieldName='invoice_date' />
				<ResultHeaderWithSort
					columnIdx={7} {...sort_props} title='Datum valute/plačila' fieldName='due_date' />
				<ResultHeaderWithSort
					columnIdx={8} {...sort_props} title='Datum DDV' fieldName='vat_date' />
				<ResultHeaderWithSort
					columnIdx={9} {...sort_props} title='Plačana' fieldName='paid' />
				<ResultHeader columnIdx={10} title='Vrsta dokumenta' />
				<ResultHeaderWithSort
					columnIdx={11} {...sort_props} title='Uporabnik' fieldName='username' />
				<ResultHeaderWithSort
					columnIdx={12} {...sort_props} cls='text-right' title='Znesek' fieldName='invoice_amount' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' />
				<Result columnIdx={3} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.invoice_number_internal}
						onChange={ event => handleFilterChange('invoice_number_internal', event) } />
				} />
				<Result columnIdx={4} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.customer_internal_code}
						onChange={ event => handleFilterChange('customer_internal_code', event) } />
				} />
				<Result columnIdx={5} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.customer_name}
						onChange={ event => handleFilterChange('customer_name', event) } />
				} />
				<Result columnIdx={6} cls='filters-col' child={
					<DateRangeFilter
						filter_values={tableItemsFilters.invoice_date_range}
						original_start_date={originalTableItemsFilters.invoice_date_range[0]}
						original_end_date={originalTableItemsFilters.invoice_date_range[1]}
						onFilterChange={values => handleFilterChange('invoice_date_range', values)} />
				} />
				<Result columnIdx={7} cls='filters-col' child={
					<DateRangeFilter
						filter_values={tableItemsFilters.payment_date_range}
						original_start_date={originalTableItemsFilters.payment_date_range[0]}
						original_end_date={originalTableItemsFilters.payment_date_range[1]}
						onFilterChange={values => handleFilterChange('payment_date_range', values)} />
				} />
				<Result columnIdx={8} cls='filters-col' />
				<Result columnIdx={9} cls='filters-col' child={
					<Checkbox
						checked={tableItemsFilters.paid === true}
						indeterminate={tableItemsFilters.paid === null}
						inline={true}
						onChange={event => {
							let checked = null;
							if (tableItemsFilters.paid === true) {
								checked = false;
							}
							else if (tableItemsFilters.paid === null) {
								checked = true;
							}
							
							handleFilterChange('paid', checked);
						}} />
				} />
				<Result columnIdx={10} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='key'
							title_field    ='title'
							items          ={invoice_types}
							filtered_keys  ={tableItemsFilters.invoice_types}
							displayFilter  ={x => x.visible}
							displaySort    ={(a, b) => a.ord - b.ord}
							onFilterChange ={values => handleFilterChange('invoice_types', values)} />
					</div>
				} />
				<Result columnIdx={11} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.username}
						onChange={ event => handleFilterChange('username', event) } />
				} />
				<Result columnIdx={12} cls='filters-col text-right' />
				
				{table_items_sort_ids.map((id, idx) => {
					const item         = table_items[id];
					const invoice_date = (item.invoice_date === null ? null : new Date(item.invoice_date));
					
					let payment_date = item.payment_date === null ? null : new Date(item.payment_date);
					let vat_date     = null;
					if (item.invoice_type == 'invoice') {
						vat_date = new Date(item.service_date_end !== null ? item.service_date_end : item.service_date_start);
					}
					else if (item.invoice_type == 'prepayment-invoice') {
						vat_date = payment_date;
					}
					else if (item.invoice_type == 'credit-note') {
						payment_date = null;
						vat_date = item.payment_date === null ? null : new Date(item.payment_date);
					}
					
					let cls = '';
					if (item.reversed       ) cls += 'reversed ';
					if (item.reverse_invoice) cls += 'reverse-invoice ';
					
					return <React.Fragment
						key={'result--result-' + id}>
						<Result columnIdx={1} cls={cls} child={
							<Checkbox
								checked={table_items_checked_ids[id] === true}
								onChange={event => rowCheckStateChanged(id, event.target.checked)} />
						} />
						<Result columnIdx={2} cls={cls} child={
							<>
								<Button
									icon='edit'
									intent='primary'
									minimal={true}
									small={true}
									onClick={() => openAddEditItemDialog(item, item.type)} />
								{ !item.draft ? null :
									<Button
										icon='trash'
										intent='danger'
										minimal={true}
										small={true}
										onClick={() => {
											deleteItem(id);
										}} />
								}
							</>
						} />
						<Result columnIdx={3} cls={cls} child={
							item.draft ? 'osnutek' : item.invoice_number_internal
						} />
						<Result columnIdx={4} cls={cls} child={
							customers[item.id_customer] === undefined ? '' :
								customers[item.id_customer].internal_code
						} />
						<Result columnIdx={5} cls={cls} child={
							customers[item.id_customer] === undefined ? '' :
								(customers[item.id_customer].type == 'natural' ?
									customers[item.id_customer].surname + ' ' + customers[item.id_customer].name
									:
									customers[item.id_customer].company_name)
						} />
						<Result columnIdx={6} cls={cls} child={
							toLocalString(invoice_date)
						} />
						<Result columnIdx={7} cls={cls} child={
							toLocalString(payment_date)
						} />
						<Result columnIdx={8} cls={cls} child={
							toLocalString(vat_date)
						} />
						<Result columnIdx={9} cls={cls} child={
							item.paid === true
								? <Icon icon='tick' color='#394b59' />
								: <Icon icon='cross' color='#ff0000' />
						} />
						<Result columnIdx={10} cls={cls} child={
							invoice_types[item.invoice_type].title
						} />
						<Result columnIdx={11} cls={cls} child={
							item.username
						} />
						<Result columnIdx={12} cls={cls + 'text-right font-bold col-invoice-amount'} child={
							/*item.invoice_type == 'prepayment-invoice' ?
							(
								'[' +
								parseFloat(item.invoice_amount)
									.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) +
								']'
								+
								' ' +
								(item.reversed ? '[S] ' : '') +
								parseFloat(item.advance_payment_amount)
									.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
							)
							:*/
							(
								(item.reversed ? '[S] ' : '') +
								(
									item.invoice_type == 'prepayment-invoice' ?
										parseFloat(item.advance_payment_amount)
										:
										parseFloat(item.invoice_amount)
								)
								.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
							)
							+ (true ? '' : (
								' [' +
								(parseFloat(item.advance_payment_amount) - parseFloat(item.used_advance_payment_amount))
									.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
								+ ']'
							))
						} />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results-sum'>
			<div className='col'>
				Število zapisov: {table_items_sort_ids.length}
			</div>
			<div className='col text-right font-bold'>
				{
					table_items_sort_ids
						.map((id, idx) => {
							const item = table_items[id];
							
							// don't include drafts in SUMs
							if (item.draft) {
								return 0;
							}
							else if (item.invoice_type == 'prepayment-invoice') {
								return parseFloat(item.advance_payment_amount);
							}
							return parseFloat(item.invoice_amount);
						})
						.reduce((acc, val) => acc + val, 0)
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
				}
			</div>
		</div>
	</>;
}

class BusinessInvoices extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.updateTableItemsFromProps  = this.updateTableItemsFromProps .bind(this);
		this.setTableItemsSort          = this.setTableItemsSort         .bind(this);
		this.refreshTableItemsSort      = this.refreshTableItemsSort     .bind(this);
		this.filterTableItems           = this.filterTableItems          .bind(this);
		this.handleFilterChange         = this.handleFilterChange        .bind(this);
		this.openAddEditItemDialog      = this.openAddEditItemDialog     .bind(this);
		this.closeAddEditItemDialog     = this.closeAddEditItemDialog    .bind(this);
		this.openDateRangePickerDialog  = this.openDateRangePickerDialog .bind(this);
		this.closeDateRangePickerDialog = this.closeDateRangePickerDialog.bind(this);
		this.reverseItems               = this.reverseItems              .bind(this);
		this.createInvoiceFromOriginal  = this.createInvoiceFromOriginal .bind(this);
		this.deleteItem                 = this.deleteItem                .bind(this);
		this.printA4                    = this.printA4                   .bind(this);
		
		const table_items_filters = {
			invoice_number_internal: '',
			customer_internal_code:  '',
			customer_name:           '',
			invoice_date_range:      [moment().subtract(1, 'month').toDate(), moment().toDate()],
			payment_date_range:      [null, null],
			invoice_types:           [],
			username:                '',
			paid:                    null,
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'invoice_number_internal',
			table_items_sort_direction: 'DESC',
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'invoice_number_internal',
			table_items_sort_direction: 'DESC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			table_items_checked_ids: {},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			add_edit_item_dialog_type: null,
			add_edit_item_dialog_advance_invoices_amounts: {},
			date_range_picker_dialog_open:        false,
			date_range_picker_dialog_filter_name: null,
			delete_item_dialog_open: false,
			delete_item_id_item:     null,
			create_invoice_from_ids:              null,
			create_invoice_from_ids_invoice_type: null,
			wait_fiscal_verification_dialog_open:       false,
			wait_fiscal_verification_dialog_id_invoice: null,
		};
		
		//this.state.table_items_sort_ids = this.refreshTableItemsSort(
		//	this.state.table_items_sort_field,
		//	this.state.table_items_sort_direction,
		//	this.state.table_items_filtered_ids,
		//	table_items,
		//	true
		//);
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.invoices != this.state.invoices) {
			this.setState(
				this.updateTableItemsFromProps(this.props, this.state)
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const filtered_invoices = {};
		const invoice_types = [
			'invoice',
			'prepayment-invoice',
			'credit-note',
		];
		for (let id_invoice in props.invoices) {
			const invoice = props.invoices[id_invoice];
			
			if (invoice_types.indexOf(invoice.invoice_type) != -1) {
				filtered_invoices[invoice.id_invoice] = invoice;
			}
		}
		
		const ids = Object.keys(filtered_invoices);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			invoices: props.invoices,
			table_items: filtered_invoices,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a   = '';
			let b   = '';
			let dir = direction;
			
			if (state.table_items[a_key].draft != state.table_items[b_key].draft) {
				a = state.table_items[a_key].draft;
				b = state.table_items[b_key].draft;
				dir = 'DESC';
			}
			else if (field == 'id_customer') {
				const customer_a = this.props.customers[state.table_items[a_key].id_customer];
				const customer_b = this.props.customers[state.table_items[b_key].id_customer];
				
				if (customer_a === undefined && customer_b === undefined) {
					a = 0;
					b = 0;
				}
				else {
					a = customer_a === undefined ? '' : customer_a.name + ' ' + customer_a.surname;
					b = customer_b === undefined ? '' : customer_b.name + ' ' + customer_b.surname;
				}
				
				return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'username') {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
				
				return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'customer_internal_code') {
				const customer_a = this.props.customers[state.table_items[a_key].id_customer];
				const customer_b = this.props.customers[state.table_items[b_key].id_customer];
				
				if (customer_a === undefined && customer_b === undefined) {
					a = 0;
					b = 0;
				}
				else {
					a = customer_a === undefined ? 0 : customer_a.internal_code;
					b = customer_b === undefined ? 0 : customer_b.internal_code;
				}
			}
			else {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_invoice_number_internal = filters.invoice_number_internal.toUpperCase();
		const filters_customer_internal_code  = filters.customer_internal_code .toUpperCase();
		const filters_customer_name           = filters.customer_name          .toUpperCase();
		const filters_invoice_date_start      = filters.invoice_date_range[0] === null ? null : this.toIsoString(filters.invoice_date_range[0]);
		const filters_invoice_date_end        = filters.invoice_date_range[1] === null ? null : this.toIsoString(filters.invoice_date_range[1]);
		const filters_payment_date_start      = filters.payment_date_range[0] === null ? null : this.toIsoString(filters.payment_date_range[0]);
		const filters_payment_date_end        = filters.payment_date_range[1] === null ? null : this.toIsoString(filters.payment_date_range[1]);
		const filters_invoice_types           = filters.invoice_types;
		const filters_username                = filters.username.toUpperCase();
		const filters_paid                    = filters.paid;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_paid !== null && item.paid !== filters_paid) {
				return false;
			}
			
			if (filters_invoice_number_internal != '' && (item.draft ? 'osnutek' : item.invoice_number_internal).toUpperCase().indexOf(filters_invoice_number_internal) == -1) {
				return false;
			}
			
			if (filters_customer_internal_code != '' && (item.id_customer === null || this.props.customers[item.id_customer] === undefined || this.props.customers[item.id_customer].internal_code != filters_customer_internal_code)) {
				return false;
			}
			
			if (filters_customer_name != '' && (item.id_customer === null || this.props.customers[item.id_customer] === undefined || (this.props.customers[item.id_customer].surname + ' ' + this.props.customers[item.id_customer].name + ' ' + this.props.customers[item.id_customer].company_name).toUpperCase().indexOf(filters_customer_name) == -1)) {
				return false;
			}
			
			if (!item.draft && filters_invoice_date_start !== null && item.invoice_date < filters_invoice_date_start) {
				return false;
			}
			if (!item.draft && filters_invoice_date_end !== null && item.invoice_date > filters_invoice_date_end) {
				return false;
			}
			
			let payment_date = item.invoice_type == 'credit-note' ? null : item.payment_date;
			if (filters_payment_date_start !== null && payment_date !== null && payment_date < filters_payment_date_start) {
				return false;
			}
			if (filters_payment_date_end !== null && payment_date !== null && payment_date > filters_payment_date_end) {
				return false;
			}
			
			if (filters_invoice_types.length > 0) {
				let found = false;
				for (let i=0; i<filters_invoice_types.length; i++) {
					if (item.invoice_type == filters_invoice_types[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (filters_username != '' && (item.username === null || item.username == '' || item.username.toUpperCase().indexOf(filters_username) == -1)) {
				return false;
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			invoice_number_internal: this.state.table_items_filters.invoice_number_internal,
			customer_internal_code:  this.state.table_items_filters.customer_internal_code,
			customer_name:           this.state.table_items_filters.customer_name,
			invoice_date_range:      this.state.table_items_filters.invoice_date_range,
			payment_date_range:      this.state.table_items_filters.payment_date_range,
			invoice_types:           this.state.table_items_filters.invoice_types,
			username:                this.state.table_items_filters.username,
			paid:                    this.state.table_items_filters.paid,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item, type, advance_invoices_amounts) {
		advance_invoices_amounts = advance_invoices_amounts || {};
		
		this.setState({
			add_edit_item_dialog_open: true,
			add_edit_item_dialog_item: item,
			add_edit_item_dialog_type: type,
			add_edit_item_dialog_advance_invoices_amounts: advance_invoices_amounts,
		});
	}
	async closeAddEditItemDialog(item, open_create_invoice_type, finish_invoice) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			this.props.dispatch(addInvoice({
				item,
				token: this.props.token,
			}));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			const new_items = await saveInvoice(this.props.api_url, item, token, dispatch);
			if (new_items !== null) {
				if (new_items.invoices !== undefined) {
					dispatch(addInvoices(new_items.invoices));
				}
				if (new_items.document_associations !== undefined) {
					dispatch(addDocumentAssociations(new_items.document_associations));
				}
				if (new_items.advance_invoice_consumptions !== undefined) {
					dispatch(addAdvanceInvoiceConsumptions(new_items.advance_invoice_consumptions));
				}
				
				if (finish_invoice && this.props.document_types[item.invoice_type].fiscal_verification_enabled) {
					this.setState({
						wait_fiscal_verification_dialog_open:       true,
						wait_fiscal_verification_dialog_id_invoice: item.id_invoice,
					});
				}
			}
			
			if (open_create_invoice_type !== undefined) {
				setTimeout(() => {
					if (!this._ismounted) return;
					this.createInvoiceFromOriginal(item.id_invoice, open_create_invoice_type, [ item.id_invoice ]);
				}, 1000);
			}
		}
	}
	
	openDateRangePickerDialog(filter_name) {
		this.setState({
			date_range_picker_dialog_open:        true,
			date_range_picker_dialog_filter_name: filter_name,
		});
	}
	closeDateRangePickerDialog(start_date, end_date) {
		this.setState({
			date_range_picker_dialog_open: false,
		});
		
		if (start_date !== undefined && end_date !== undefined) {
			this.handleFilterChange(
				this.state.date_range_picker_dialog_filter_name,
				[ start_date, (end_date === null ? start_date : end_date) ]
			);
		}
	}
	
	reverseItems(ids) {
		for (let i=0; i<ids.length; i++) {
			const id   = ids[i];
			const item = this.state.table_items[id];
			
			// skip drafts
			if (item.draft) continue; //TODO tell the user why we skipped it
			
			// skip already reversed items
			if (item.reversed) continue; //TODO tell the user why we skipped it
			
			// prepare advance invoices
			const selected_advance_invoices_amounts = {};
			const advance_id_invoices = this.props.id_advance_invoice_consumption_by_id_consumer_invoices[item.id_invoice] || [];
			for (let i=0; i<advance_id_invoices.length; i++) {
				const item = this.props.advance_invoice_consumptions[advance_id_invoices[i]];
				selected_advance_invoices_amounts[item.advance_id_invoice] = -item.amount;
			}
			
			// create inverse document
			const new_item = InvoiceCreator.CreateInvoiceFromInitialData({
				id_invoice:         uuid.v4(),
				reverse_invoice:    true,
				id_customer:        item.id_customer,
				invoice_date:       moment().toISOString(true),
				invoice_type:       item.invoice_type,
				note:               '',
				payment_date:       moment().toISOString(true),
				reference:          item.invoice_number_internal,
				reservation:        item.reservation,
				service_date_end:   item.service_date_end,
				service_date_start: item.service_date_start,
				invoice_amount:     -item.invoice_amount,
				id_cash_register:   this.props.current_id_cash_register,
				advance_payment_amount: -(item.advance_payment_amount - item.used_advance_payment_amount),
				credit_note_type:   item.credit_note_type,
				items: item.items.map(item_item => {
					return InvoiceCreator.CreateInvoiceItemFromInitialData({
						id_invoice_item:                         uuid.v4(),
						description:                             item_item.description,
						discount:                                item_item.discount,
						id_item:                                 item_item.id_item,
						id_item_price_list:                      item_item.id_item_price_list,
						id_tax_rate:                             item_item.id_tax_rate,
						item_measuring_unit_code:                item_item.item_measuring_unit_code,
						item_measuring_unit_decimal_digit_count: item_item.item_measuring_unit_decimal_digit_count,
						price:                                   item_item.price,
						quantity:                                -item_item.quantity,
						tax_rate:                                item_item.tax_rate,
						approved_percent:                        item_item.approved_percent,
						item_type:                               item_item.item_type,
						item_ean:                                item_item.item_ean,
						item_title:                              item_item.item_title,
						ord:                                     item_item.ord,
						taxable:                                 item_item.taxable,
						item_internal_code:                      item_item.item_internal_code,
						id_warehouse: item_item.item_type != 'item' ? null : InvoiceHelper.findCurrentIdWarehouse(
							this.props.current_id_cash_register === undefined || this.props.current_id_cash_register === null ? null :
								this.props.cash_registers[this.props.current_id_cash_register],
							this.props.warehouses
						),
					});
				}),
				payments: item.payments.length == 0 ? [] :
					[
						InvoiceCreator.CreateInvoicePaymentFromInitialData({
							id_invoice_payment: uuid.v4(),
							id_payment_type:    item.payments.length == 1 ? item.payments[0].id_payment_type : '2db820c7-a652-4f9e-8c93-7e1b09d399d5', // cash
							amount:             item.payments.reduce((acc, item) => acc + item.amount, 0),
							payment_date:       new Date(),
						}),
					],
				reverse_invoice_reference: id,
				original_id_invoice: id,
				original_invoice_type: item.invoice_type,
				max_advance_payment_amount: item.advance_payment_amount - item.used_advance_payment_amount,
			});
			this.openAddEditItemDialog(
				new_item,
				new_item.invoice_type,
				selected_advance_invoices_amounts
			);
		}
		
		this.setState({
			table_items_checked_ids: {},
		});
	}
	
	createInvoiceFromOriginal(ids, invoice_type, advance_id_invoices) {
		ids = ids === null ? [] : (Array.isArray(ids) ? ids : [ ids ]);
		advance_id_invoices = advance_id_invoices || [];
		
		let first_item = ids.length == 0 ? null : this.state.table_items[ids[0]];
		
		const amount_factor = invoice_type == 'credit-note' ? -1 : 1;
		
		const original_id_invoices              = [];
		const original_invoice_types            = [];
		const selected_advance_invoices_amounts = {};
		let   advance_payment_amount            = 0;
		let   invoice_amount                    = 0;
		let   items                             = [];
		let   payments                          = [];
		
		for (let i=0; i<advance_id_invoices.length; i++) {
			const item = this.state.table_items[advance_id_invoices[i]];
			
			const advance_amount = Math.max(0, parseFloat(item.advance_payment_amount) - parseFloat(item.used_advance_payment_amount));
			advance_payment_amount += advance_amount;
			
			if (advance_amount > 0) {
				selected_advance_invoices_amounts[item.id_invoice] = advance_amount;
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const item = this.state.table_items[ids[i]];
			
			if (original_id_invoices.indexOf(ids[i]) == -1) {
				original_id_invoices  .push(ids[i]);
				original_invoice_types.push(item.invoice_type);
			}
			
			invoice_amount += item.invoice_amount * amount_factor;
			
			items = [
				...items,
				...item.items.map(item_item => {
					return InvoiceCreator.CreateInvoiceItemFromInitialData({
						id_invoice_item:                         uuid.v4(),
						description:                             item_item.description,
						discount:                                item_item.discount,
						id_item:                                 item_item.id_item,
						id_item_price_list:                      item_item.id_item_price_list,
						id_tax_rate:                             item_item.id_tax_rate,
						item_measuring_unit_code:                item_item.item_measuring_unit_code,
						item_measuring_unit_decimal_digit_count: item_item.item_measuring_unit_decimal_digit_count,
						price:                                   item_item.price,
						quantity:                                item_item.quantity * amount_factor,
						tax_rate:                                item_item.tax_rate,
						approved_percent:                        item_item.approved_percent,
						item_type:                               item_item.item_type,
						item_ean:                                item_item.item_ean,
						item_title:                              item_item.item_title,
						ord:                                     item_item.ord,
						taxable:                                 item_item.taxable,
						item_internal_code:                      item_item.item_internal_code,
						id_warehouse: item_item.item_type != 'item' ? null : InvoiceHelper.findCurrentIdWarehouse(
							this.props.current_id_cash_register === undefined || this.props.current_id_cash_register === null ? null :
								this.props.cash_registers[this.props.current_id_cash_register],
							this.props.warehouses
						),
					});
				}),
			];
			payments = [
				...payments,
				//...item.payments.map(item_payment => {
				//	return InvoiceCreator.CreateInvoicePaymentFromInitialData({
				//		id_invoice_payment: uuid.v4(),
				//		id_payment_type:    item_payment.id_payment_type,
				//		amount:             item_payment.amount,
				//		payment_date:       item_payment.payment_date,
				//	});
				//}),
			];
		}
		
		// create new document
		let payment_date       = null;
		let invoice_date       = null;
		let service_date_end   = null;
		let service_date_start = null;
		
		if (first_item !== null) {
			payment_date       = first_item.payment_date;
			invoice_date       = first_item.invoice_date;
			service_date_end   = first_item.service_date_end;
			service_date_start = first_item.service_date_start;
			
			if (invoice_type == 'credit-note' || invoice_type == 'prepayment-invoice' || invoice_type == 'cash-prepayment-invoice') {
				payment_date = new Date();
				invoice_date = new Date();
			}
			else if (invoice_type == 'invoice') {
				payment_date       = null;
				invoice_date       = new Date();
				service_date_end   = new Date();
				service_date_start = new Date();
			}
		}
		
		const new_item = InvoiceCreator.CreateInvoiceFromInitialData({
			id_invoice:         uuid.v4(),
			id_customer:        first_item === null ? null : first_item.id_customer,
			invoice_date,
			invoice_type,
			note:               '',
			payment_date,
			reference:          first_item === null ? null : first_item.invoice_number_internal,
			reservation:        first_item === null ? null : first_item.reservation,
			service_date_end,
			service_date_start,
			invoice_amount,
			id_cash_register:   this.props.current_id_cash_register,
			advance_payment_amount,
			items,
			payments,
			original_id_invoice:   original_id_invoices,
			original_invoice_type: original_invoice_types,
		});
		this.openAddEditItemDialog(
			new_item,
			new_item.invoice_type,
			selected_advance_invoices_amounts
		);
		
		this.setState({
			table_items_checked_ids: {},
		});
	}
	
	deleteItem(id, confirmed) {
		if (!confirmed) {
			this.setState({
				delete_item_dialog_open: true,
				delete_item_id_item:     id,
			});
			return;
		}
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		apiDeleteInvoice(this.props.api_url, id, token).then(data => {
			if (data === null) return;
			
			for (let i=0; i<data.id_invoices.length; i++) {
				dispatch(deleteInvoice({
					id_invoice: data.id_invoices[i],
				}));
			}
		});
	}
	
	async printA4(lang) {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		const ids = check_count == 0 ? this.state.table_items_sort_ids :
			this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
		
		for (let i=0; i<ids.length; i++) {
			const invoice = this.state.table_items[ids[i]];
			
			let invoice_fiscal_verification = null;
			if (invoice.id_invoice_fiscal_verification !== null && invoice.id_invoice_fiscal_verification !== undefined) {
				if (this.props.invoice_fiscal_verifications[invoice.id_invoice_fiscal_verification] !== undefined) {
					invoice_fiscal_verification = this.props.invoice_fiscal_verifications[invoice.id_invoice_fiscal_verification];
				}
				else {
					invoice_fiscal_verification = await loadInvoiceFiscalVerification(
						this.props.api_url,
						invoice.id_invoice_fiscal_verification,
						this.props.dispatch,
						this.props.token,
					);
				}
			}
			
			if (
				invoice_fiscal_verification            !== null    &&
				invoice_fiscal_verification.eor        !== null    &&
				invoice_fiscal_verification.signed_zoi !== null    &&
				invoice_fiscal_verification.eor        .length > 0 &&
				invoice_fiscal_verification.signed_zoi .length > 0
			) {
				await increaseInvoicePrintCount(this.props.api_url, ids[i], this.props.token);
			}
			
			this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'invoice', lang, invoice }));
		}
	}
	
	render() {
		const check_ids           = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
		const check_count         = check_ids.length;
		const checked_draft_count = check_ids.filter(id => this.props.invoices[id].draft).length;
		
		const checked_prepayment_invoice_ids = Object.keys(this.state.table_items_checked_ids)
			.filter(id => this.state.table_items[id].invoice_type == 'prepayment-invoice');
		
		return <>
			{!this.state.add_edit_item_dialog_open ? null :
				<EditInvoiceDialog
					closeEditInvoiceDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item}
					type={this.state.add_edit_item_dialog_type}
					selected_advance_invoices_amounts={this.state.add_edit_item_dialog_advance_invoices_amounts} />
			}
			
			{!this.state.date_range_picker_dialog_open ? null :
				<DateRangePickerDialog
					startValue={this.state.table_items_filters[this.state.date_range_picker_dialog_filter_name][0]}
					endValue=  {this.state.table_items_filters[this.state.date_range_picker_dialog_filter_name][1]}
					closeDialog={(start_date, end_date) => {
						this.closeDateRangePickerDialog(start_date, end_date);
					}} />
			}
			
			{!this.state.delete_item_dialog_open ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Izbriši'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.deleteItem(
							this.state.delete_item_id_item,
							true
						);
						this.setState({
							delete_item_dialog_open: false,
							delete_item_id_item:     null,
						});
					}}
					onCancel={() => {
						this.setState({
							delete_item_dialog_open: false,
							delete_item_id_item:     null,
						});
					}}>
					Res želite izbrisati ta dokument?
				</Alert>
			}
			
			{
				this.state.create_invoice_from_ids        === undefined ||
				this.state.create_invoice_from_ids        === null      ||
				this.state.create_invoice_from_ids.length == 0 ?
					null
					:
					<AdvanceInvoiceListDialog
						closeDialog={id_invoice => {
							if (id_invoice !== undefined) {
								this.createInvoiceFromOriginal(
									id_invoice,
									this.state.create_invoice_from_ids_invoice_type,
									this.state.create_invoice_from_ids
								);
							}
							
							this.setState({
								create_invoice_from_ids_invoice_type: null,
								create_invoice_from_ids:              null,
							});
						}}
						invoices={this.state.create_invoice_from_ids.map(id => this.state.table_items[id])} />
			}
			
			{!this.state.wait_fiscal_verification_dialog_open ? null :
				<WaitFiscalVerificationDialog
					closeWaitFiscalVerificationDialog={(print) => {
						this.setState({ wait_fiscal_verification_dialog_open: false });
						
						if (print) {
							const invoice = this.props.invoices[this.state.wait_fiscal_verification_dialog_id_invoice];
							if (this.props.general_settings.auto_print_type == 'a4') {
								this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'invoice', lang: 'sl', invoice }));
							}
							else if (this.props.general_settings.auto_print_type == 'pos') {
								this.props.dispatch(enqueueInvoiceOnPosPrinterQueue({ type: 'invoice', invoice }));
							}
						}
					}}
					id_invoice={this.state.wait_fiscal_verification_dialog_id_invoice} />
			}
			
			<div className='flex flex-col flex-grow business-invoices-list'>
				<div className='pl-4 pt-4'>
					<ButtonGroup className='align-middle'>
						<Button intent='success' icon='plus' onClick={() => this.openAddEditItemDialog(null, 'invoice')}>
							Nova faktura
						</Button>
						<Button intent='success' icon='plus' onClick={() => this.openAddEditItemDialog(null, 'prepayment-invoice')}>
							Nov avansni račun
						</Button>
						<Button intent='success' icon='plus' onClick={() => this.openAddEditItemDialog(null, 'credit-note')}>
							Nov dobropis
						</Button>
					</ButtonGroup>
					<ButtonGroup className='ml-2 align-middle'>
						<Button intent='primary' icon='print' onClick={() => this.printA4('sl')} disabled={check_count <= 0 || checked_draft_count > 0}>
							Natisni
						</Button>
						<Button intent='primary' className='pl-4 pr-4' onClick={() => this.printA4('en')} disabled={check_count <= 0 || checked_draft_count > 0}>
							EN
						</Button>
						<Button intent='primary' className='pl-4 pr-4' onClick={() => this.printA4('de')} disabled={check_count <= 0 || checked_draft_count > 0}>
							DE
						</Button>
					</ButtonGroup>
					<ButtonGroup className='ml-2 align-middle'>
						<Button intent='primary' icon='cloud-download' disabled={check_count <= 0} onClick={() => {
						}}>
							Izvoz v Excel
						</Button>
					</ButtonGroup>
					<Button intent='danger' icon='delete' onClick={() => {
						const ids = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
						this.reverseItems(ids);
					}} className='ml-2' disabled={check_count != 1}>
						Storniraj
					</Button>
					{true /*if at least one of items has confirming to FURS enabled*/ ?
						<Button intent='warning' icon='changes' onClick={() => {}} className='ml-2' disabled={check_count == 0}>
							Pošlji na FURS
						</Button>
						:
						null
					}
					<ButtonGroup className='ml-2 align-middle'>
						<Button intent='warning' icon='duplicate' onClick={() => {
							const ids = Object.keys(this.state.table_items_checked_ids);
							if (ids.length == 0) return;
							
							const item = this.state.table_items[ids[0]];
							
							const new_item = InvoiceCreator.Duplicate(item);
							new_item.id_invoice              = uuid.v4();
							new_item.invoice_number          = '';
							new_item.invoice_number_internal = '';
							new_item.draft                   = true;
							new_item.note                    = '';
							new_item.invoice_date            = moment().toISOString(true);
							new_item.payment_date            = null;
							new_item.service_date_start      = moment().toISOString(true);
							new_item.service_date_end        = moment().toISOString(true);
							
							const due_day_count = item.id_customer !== null
								? this.props.customers[item.id_customer].due_day_count
								: this.props.settings.due_day_count;
							new_item.payment_date = moment(new_item.invoice_date).add(due_day_count, 'days').toDate();
							
							for (let i=0; i<new_item.payments.length; i++) {
								new_item.payments[i].id_invoice_payment = null;
							}
							for (let i=0; i<new_item.items.length; i++) {
								new_item.items[i].id_invoice_item = uuid.v4();
							}
							
							this.openAddEditItemDialog(
								new_item,
								item.invoice_type
							);
							
							this.setState({
								table_items_checked_ids: {},
							});
						}} disabled={check_count != 1}>
							Podvoji
						</Button>
						<Button intent='warning' icon='inheritance' onClick={() => {
							const ids = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
							if (ids.length > 0) {
								this.createInvoiceFromOriginal(ids[0], 'invoice', ids);
							}
						}} disabled={
							check_count == 0 ||
							!Object.keys(this.state.table_items_checked_ids).every(x => this.state.table_items[x].invoice_type == 'prepayment-invoice')
						}>
							Izdelaj fakturo
						</Button>
						<Button intent='warning' icon='inheritance' onClick={() => {
							const ids = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
							if (ids.length == 1) {
								this.createInvoiceFromOriginal(ids[0], 'cash-invoice', ids);
							}
							else if (ids.length > 1) {
								this.setState({
									create_invoice_from_ids: ids,
									create_invoice_from_ids_invoice_type: 'cash-invoice',
								});
							}
						}} disabled={this.props.current_id_cash_register == 0 || checked_prepayment_invoice_ids.length == 0}>
							Izdelaj blag. račun
						</Button>
						<Button intent='warning' icon='inheritance' onClick={() => {
							const ids = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
							if (ids.length > 0) {
								this.createInvoiceFromOriginal(ids[0], 'credit-note');
							}
						}} disabled={
							check_count != 1 ||
							this.state.table_items[Object.keys(this.state.table_items_checked_ids)[0]].invoice_type != 'invoice'
						}>
							Izdelaj dobropis
						</Button>
					</ButtonGroup>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={this.openAddEditItemDialog}
							tableItemsFilters={this.state.table_items_filters}
							originalTableItemsFilters={this.state.original_table_items_filters}
							handleFilterChange={this.handleFilterChange}
							customers={this.props.customers}
							payment_types={this.props.payment_types}
							selectDateRangeFilter={filter_name => this.openDateRangePickerDialog(filter_name)}
							table_items_checked_ids={this.state.table_items_checked_ids}
							rowCheckStateChanged={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							deleteItem={this.deleteItem} />
					</div>
				</div>
			</div>
		</>;
	}
}
BusinessInvoices.propTypes = {
};

function mapStateToProps(state) {
	return {
		customers:     state.CodeTablesSlice.customers,
		payment_types: state.CodeTablesSlice.payment_types,
		invoices:      state.BusinessSlice.invoices,
		api_url:       state.BusinessSlice.api_url,
		advance_invoice_consumptions:                           state.BusinessSlice.advance_invoice_consumptions,
		id_advance_invoice_consumption_by_id_consumer_invoices: state.BusinessSlice.id_advance_invoice_consumption_by_id_consumer_invoices,
		token:         state.UserSlice.token,
		current_id_cash_register: state.SettingsSlice.current_id_cash_register,
		document_types: state.DocumentSlice.document_types,
		general_settings: state.SettingsSlice.general,
		settings:         state.SettingsSlice,
		cash_registers: state.CashRegisterSlice.cash_registers,
		warehouses:     state.CodeTablesSlice.warehouses,
	};
}

export default connect(mapStateToProps)(BusinessInvoices);
