import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import moment from 'moment';

import {
	Button,
	ButtonGroup,
	Checkbox,
	Alert,
	Intent,
	Tooltip,
	Icon,
	Navbar,
	Dialog,
	Alignment,
	Classes,
	Tree,
} from '@blueprintjs/core';
import { IDateFormatProps, DateRangeInput } from '@blueprintjs/datetime';

/*import { deleteInvoice } from '../../slices/BusinessSlice';
import {
	deleteInvoice as apiDeleteInvoice,
	fiscallyVerifyInvoices,
	increaseInvoicePrintCount,
	loadInvoiceFiscalVerificationsByIds,
} from '../../api/Business';*/
import {
	deleteReservation,
	addReservationRequest,
} from '../../slices/ReservationSlice';
import { changeReservationRequestStatus, confirmReservationRequest, cancelReservationRequest, saveReservationRequest } from '../../api/Reservations';
import Reservation from '../reservations/Reservation';

import { addDocumentAssociation } from '../../slices/DocumentSlice';
import { saveDocumentAssociation } from '../../api/Documents';

import { ResultHeader, ResultHeaderWithSort, Result, MultiSelectFilter, DateRangeFilter, TextFilter, DateFilter } from '../code_tables/ResultComponents';

import PeopleCountInput from '../reservations/PeopleCountInput';
import { getReservationRequestItemServices } from './Utils';

import ReservationHelper from '../../helpers/ReservationHelper';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openAddEditItemDialog,
		tableItemsFilters,
		originalTableItemsFilters,
		handleFilterChange,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
		countries,
		calculateDayCount,
		getAccommodationTitle,
		general_settings,
		openQuickEditCheckInOutDialog,
		openQuickEditPeopleDialog,
		reservation_request_accommodations,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	const statuses = {
		'new':       { ord: 1, visible: true, key: 'new',       title: 'Novo'       },
		'confirmed': { ord: 2, visible: true, key: 'confirmed', title: 'Potrjeno'   },
		'processed': { ord: 3, visible: true, key: 'processed', title: 'Obdelano'   },
		'closed':    { ord: 4, visible: true, key: 'closed',    title: 'Zaprto'     },
		'canceled':  { ord: 5, visible: true, key: 'canceled',  title: 'Stornirano' },
	};
	
	const items_by_ids = {};
	for (let type in reservation_request_accommodations) {
		for (let id in reservation_request_accommodations[type].accommodation_items) {
			items_by_ids[id] = reservation_request_accommodations[type].accommodation_items[id];
		}
	}
	
	let group = 0;
	let prev_internal_code = null;
	
	return <>
		<div className='results mt-4'>
			<div className='results-table overflow-y-scroll sticky-header'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeaderWithSort
					columnIdx={2} {...sort_props} title='Šifra' fieldName='internal_code' />
				<ResultHeader columnIdx={3} title='Vrsta namestitve' />
				<ResultHeader columnIdx={4} title='Prihod - odhod (dnevi)' />
				<ResultHeader columnIdx={5} title='Nosilec povpraševanja' />
				<ResultHeader columnIdx={6} title='Osebe' />
				<ResultHeaderWithSort
					columnIdx={7} {...sort_props} title='Znesek' fieldName='amount' />
				<ResultHeader columnIdx={8} title='Status' />
				<ResultHeader columnIdx={9} title='Storitve' />
				<ResultHeader columnIdx={10} title='Opomba' />
				<ResultHeaderWithSort
					columnIdx={11} {...sort_props} title='Datum prejema' fieldName='created' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.internal_code}
						onChange={ event => handleFilterChange('internal_code', event) } />
				} />
				<Result columnIdx={3} cls='filters-col' />
				<Result columnIdx={4} cls='filters-col' child={
					null
				} />
				<Result columnIdx={5} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.customer}
						onChange={ event => handleFilterChange('customer', event) } />
				} />
				<Result columnIdx={6} cls='filters-col' />
				<Result columnIdx={7} cls='filters-col' />
				<Result columnIdx={8} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='key'
							title_field    ='title'
							items          ={statuses}
							filtered_keys  ={tableItemsFilters.statuses}
							displayFilter  ={x => x.visible}
							displaySort    ={(a, b) => a.ord - b.ord}
							onFilterChange ={values => handleFilterChange('statuses', values)} />
					</div>
				} />
				<Result columnIdx={9} cls='filters-col' />
				<Result columnIdx={10} cls='filters-col' />
				<Result columnIdx={11} cls='filters-col' child={
					<DateRangeFilter
						filter_values={tableItemsFilters.created}
						original_start_date={originalTableItemsFilters.created[0]}
						original_end_date={originalTableItemsFilters.created[1]}
						onFilterChange={values => handleFilterChange('created', values)}
						short_year={true} />
				} {...sort_props} />
				
				{table_items_sort_ids.map((id, idx) => {
					const item = table_items[id];
					
					let reservation_status_for_coloring = item.status;
					if (item.status == 'canceled') reservation_status_for_coloring = 'reversed';
					
					let cls = '';
					cls += 'status-' + reservation_status_for_coloring;
					
					let customer_info =
						item.customer_name + ' ' + item.customer_surname +
						' (' + countries[item.customer_id_country].iso_3166_1_a2 + ')';
					if (general_settings.scanner_change_case == 'uppercase') {
						customer_info = customer_info.toUpperCase();
					}
					if (general_settings.scanner_change_case == 'lowercase') {
						customer_info = customer_info.toLowerCase();
					}
					if (general_settings.scanner_change_case == 'title-case') {
						// from https://stackoverflow.com/a/37931321
						customer_info = customer_info.replace(/\S+/g, str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase());
					}
					
					customer_info =
						<>
							<div>{ customer_info }</div>
							<div>
								<strong>{ 'T: ' }</strong><span>{ item.customer_phone + ' ' }</span>
								<span style={{ paddingLeft: 16 }}><strong>{ 'E: ' }</strong></span>
								<a href={ 'mailto:' + item.customer_email }>{ item.customer_email }</a>
							</div>
						</>;
					
					let accommodation = null;
					for (let key in reservation_request_accommodations) {
						const acc = reservation_request_accommodations[key].accommodation_items[item.id_accommodation_item_place_raw];
						if (acc !== undefined) {
							accommodation = acc;
							break;
						}
					}
					
					let services_info = null;
					if (accommodation !== null) {
						const services_html            = getReservationRequestItemServices(item, accommodation, 'services', true);
						const additional_services_html = getReservationRequestItemServices(item, accommodation, 'additional_services', true);
						
						services_info = [];
						if (services_html            !== null) services_info.push(services_html);
						if (additional_services_html !== null) services_info.push(additional_services_html);
					}
					
					// figure out group coloring
					const next_id   = idx + 1 == table_items_sort_ids.length ? null : table_items_sort_ids[idx + 1];
					const next_item = next_id === null ? null : table_items[next_id];
					
					// last item
					if (next_item === null) {
						if (prev_internal_code !== null && prev_internal_code !== item.internal_code) {
							group = 0;
						}
					}
					else {
						// first item, in same group as next item
						if (prev_internal_code === null && next_item.internal_code === item.internal_code) {
							group++;
						}
						// middle item, in same group as next item, but different group than previous item
						else if (prev_internal_code !== item.internal_code && next_item.internal_code === item.internal_code) {
							group = group == 1 ? 2 : 1;
						}
						// middle item, not in group
						else if (prev_internal_code !== item.internal_code && next_item.internal_code !== item.internal_code) {
							group = 0;
						}
					}
					
					prev_internal_code = item.internal_code;
					
					cls += ' group-' + group;
					
					return <React.Fragment
						key={'result--result-' + id}>
						<Result columnIdx={1} cls={cls} child={
							<Checkbox
								checked={table_items_checked_ids[id] === true}
								onChange={event => rowCheckStateChanged(id, event.target.checked)} />
						} />
						<Result columnIdx={2} cls={cls} child={
							item.internal_code
						} />
						<Result columnIdx={3} cls={cls} child={
							getAccommodationTitle(item)
						} />
						<Result columnIdx={4} cls={cls} child={
							<div className='quick-edit-check-in-out' onClick={() => openQuickEditCheckInOutDialog(item.id_reservation_request)}>{
								moment(item.check_in).format('DD.MM.YYYY') +
								' - ' +
								moment(item.check_out).format('DD.MM.YYYY') +
								' (' + calculateDayCount(item) + ')'
							}</div>
						} />
						<Result columnIdx={5} cls={cls + ' customer-info'} child={ customer_info } />
						<Result columnIdx={6} cls={cls + ' person-count text-right'} child={
							<div className='quick-edit-person-count' onClick={() => openQuickEditPeopleDialog(item.id_reservation_request)}>
								<div><strong>{ (item.guest_adult_count + item.guest_child_2_count + item.guest_child_1_count) }</strong></div>
								<div>{ ' (' + [ item.guest_adult_count, item.guest_child_2_count, item.guest_child_1_count ].join(' / ') + ')' }</div>
							</div>
						} />
						<Result columnIdx={7} cls={cls + ' text-right'} child={
							item.total_amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
						} />
						<Result columnIdx={8} cls={cls} child={
							statuses[item.status].title
						} />
						<Result columnIdx={9} cls={cls + ' note text-center'} child={
							services_info === null || services_info.length == 0
								? null
								: <Tooltip content={services_info} position='left' popoverClassName='reservation-requests-list--tooltip-note'>
									<Icon icon='comment' />
								</Tooltip>
						} />
						<Result columnIdx={10} cls={cls + ' note text-center'} child={
							item.customer_note === null || item.customer_note.length == 0
								? null
								: <Tooltip content={item.customer_note} position='left' popoverClassName='reservation-requests-list--tooltip-note'>
									<Icon icon='comment' />
								</Tooltip>
						} />
						<Result columnIdx={11} cls={cls} child={
							moment(item.created).format('DD. MM. YYYY HH:mm:ss')
						} />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results borderless mb-4 pr-4'>
			<div className='results-table'>
				<Result cls='sum' columnIdx={1} span={3} child={
					'Število zapisov: ' + table_items_sort_ids.length
				} />
				<Result cls='sum' columnIdx={4} child={null} />
				<Result cls='sum' columnIdx={5} child={null} />
				<Result cls='sum text-right' columnIdx={6} child={<>
					<span className='font-bold'>{
						table_items_sort_ids
							.map((id, idx) => table_items[id].guest_adult_count + table_items[id].guest_child_2_count + table_items[id].guest_child_1_count)
							.reduce((acc, val) => acc + val, 0)
							.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
					}</span>
					<span>{
						' (' +
						table_items_sort_ids
							.map((id, idx) => table_items[id].guest_adult_count)
							.reduce((acc, val) => acc + val, 0)
							.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) +
						' / ' +
						table_items_sort_ids
							.map((id, idx) => table_items[id].guest_child_2_count)
							.reduce((acc, val) => acc + val, 0)
							.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) +
						' / ' +
						table_items_sort_ids
							.map((id, idx) => table_items[id].guest_child_1_count)
							.reduce((acc, val) => acc + val, 0)
							.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) +
						')'
					}</span>
				</>} />
				<Result cls='sum font-bold text-right' columnIdx={7} child={
					table_items_sort_ids
						.map((id, idx) => table_items[id].total_amount)
						.reduce((acc, val) => acc + val, 0)
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
				} />
				<Result cls='sum' columnIdx={8} child={null} />
				<Result cls='sum' columnIdx={9} child={null} />
				<Result cls='sum' columnIdx={10} child={null} />
				<Result cls='sum' columnIdx={11} child={null} />
			</div>
		</div>
	</>;
}

function AccommodationSelectorDialog(props) {
	const {
		isOpen,
		onClose,
		accommodations,
		accommodation_items,
		accommodation_item_places,
		reservation_request,
		reservations,
	} = props;
	
	const acc_items       = Object.values(accommodation_items); //.filter(x => x.id_accommodation == '6c2fd01c-f8d1-4061-97bd-6820408122aa');
	const acc_item_places = Object.values(accommodation_item_places);
	
	const [ expandedIds, setExpandedIds ] = useState(acc_items.map(item => item.id_accommodation_item));
	
	const toggleExpanded = (id, expand) => {
		if (expand === undefined) {
			const idx = expandedIds.indexOf(id);
			expand = idx === -1;
		}
		
		if (expand) {
			setExpandedIds([
				...expandedIds,
				id,
			]);
		}
		else {
			const idx = expandedIds.indexOf(id);
			if (idx === -1) return;
			
			let items = [ ...expandedIds ];
			items.splice(idx, 1);
			
			setExpandedIds(items);
		}
	};
	
	const reservations_by_id_accommodation_item_place = {};
	for (let id_reservation in reservations) {
		const reservation = reservations[id_reservation];
		if (reservations_by_id_accommodation_item_place[reservation.id_accommodation_item_place] === undefined) {
			reservations_by_id_accommodation_item_place[reservation.id_accommodation_item_place] = [];
		}
		reservations_by_id_accommodation_item_place[reservation.id_accommodation_item_place].push(reservation);
	}
	
	return <Dialog
		isOpen={isOpen}
		onClose={() => onClose(null)}
		className='reservation-requests-list--accommodation-selector-dialog'>
		<div className='flex flex-col flex-grow'>
			<Navbar fixedToTop={false} className='bp3-dark'>
				<Navbar.Group>
					<Navbar.Heading>Izbor</Navbar.Heading>
				</Navbar.Group>
				<Navbar.Group align={Alignment.RIGHT}>
					<Button minimal={true} icon='cross' onClick={() => onClose(null)} />
				</Navbar.Group>
			</Navbar>
			<div className={Classes.DIALOG_BODY}>
				<div className='flex flex-row content-start justify-between overflow-y-scroll'>
					{!isOpen ? null : acc_items.sort((a, b) => a.ord - b.ord).map(item => {
						return <div key={'item-' + item.id_accommodation_item}>
							<Tree
								className='tree-top-node'
								contents={[
									{
										id: item.id_accommodation_item,
										icon: expandedIds.indexOf(item.id_accommodation_item) !== -1 ? 'folder-open' : 'folder-close',
										label: <div>
											<div>{ accommodations[item.id_accommodation].title }</div>
											{ item.title == 'default' ? null : <div>{ item.title }</div> }
										</div>,
										hasCaret: false,
										isExpanded: expandedIds.indexOf(item.id_accommodation_item) !== -1,
										childNodes: acc_item_places
											.filter(x => x.id_accommodation_item == item.id_accommodation_item)
											.sort((a, b) => a.ord - b.ord)
											.map(subitem => {
												const accommodation_item_place = accommodation_item_places[subitem.id_accommodation_item_place];
												const is_same_type =
													(
														reservation_request.id_accommodation_item_place_raw == 'camp-motorhome' &&
														accommodation_item_place.tags.indexOf('online-for-motorhome') !== -1
													) ||
													(
														reservation_request.id_accommodation_item_place_raw == 'camp-tent' &&
														accommodation_item_place.tags.indexOf('online-for-tent') !== -1
													) ||
													(
														reservation_request.id_accommodation_item_place_raw == 'camp-caravan' &&
														accommodation_item_place.tags.indexOf('online-for-caravan') !== -1
													);
												
												const overlapping_reservation = ReservationHelper.FindOverlappingReservation(
													{
														id_reservation: -1,
														id_accommodation_item_place: subitem.id_accommodation_item_place,
														check_in: reservation_request.check_in,
														check_out: reservation_request.check_out,
													},
													reservations_by_id_accommodation_item_place[subitem.id_accommodation_item_place] ?? []
												);
												
												return {
													id: subitem.id_accommodation_item_place,
													icon: null,
													label: <div className={
														'text-white text-center pt-1 pb-1 ' + (
															overlapping_reservation === null
																? (!is_same_type ? 'bg-blue-500' : 'bg-green-500')
																: 'bg-red-600'
														)
													}>{ subitem.title }</div>,
												};
											}),
									}
								]}
								onNodeClick={(item, path) => {
									if (path.length == 1) {
										toggleExpanded(item.id);
									}
									else if (path.length == 2) {
										onClose(item.id);
									}
								}}
								onNodeCollapse={item => toggleExpanded(item.id, false) }
								onNodeExpand={item => toggleExpanded(item.id, true) } />
						</div>;
					})}
				</div>
			</div>
		</div>
	</Dialog>;
}

class ReservationRequestsList extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.updateTableItemsFromProps         = this.updateTableItemsFromProps        .bind(this);
		this.setTableItemsSort                 = this.setTableItemsSort                .bind(this);
		this.refreshTableItemsSort             = this.refreshTableItemsSort            .bind(this);
		this.filterTableItems                  = this.filterTableItems                 .bind(this);
		this.handleFilterChange                = this.handleFilterChange               .bind(this);
		this.calculateDayCount                 = this.calculateDayCount                .bind(this);
		this.getAccommodationTitle             = this.getAccommodationTitle            .bind(this);
		this.changeStatus                      = this.changeStatus                     .bind(this);
		this.openReservationDialog             = this.openReservationDialog            .bind(this);
		this.closeReservationDialog            = this.closeReservationDialog           .bind(this);
		this.delReservation                    = this.delReservation                   .bind(this);
		this.confirmReservationRequest         = this.confirmReservationRequest        .bind(this);
		this.cancelReservationRequest          = this.cancelReservationRequest         .bind(this);
		this.openQuickEditCheckInOutDialog     = this.openQuickEditCheckInOutDialog    .bind(this);
		this.closeQuickEditCheckInOutDialog    = this.closeQuickEditCheckInOutDialog   .bind(this);
		this.openQuickEditPeopleDialog         = this.openQuickEditPeopleDialog        .bind(this);
		this.closeQuickEditPeopleDialog        = this.closeQuickEditPeopleDialog       .bind(this);
		this.openAccommodationSelectionDialog  = this.openAccommodationSelectionDialog .bind(this);
		this.closeAccommodationSelectionDialog = this.closeAccommodationSelectionDialog.bind(this);
		this.openSelectNewStatusDialog         = this.openSelectNewStatusDialog        .bind(this);
		this.closeSelectNewStatusDialog        = this.closeSelectNewStatusDialog       .bind(this);
		this.clearSelection                    = this.clearSelection                   .bind(this);
		this.openReviewReservationConfirmationDialog  = this.openReviewReservationConfirmationDialog .bind(this);
		this.closeReviewReservationConfirmationDialog = this.closeReviewReservationConfirmationDialog.bind(this);
		this.openReviewReservationCancellationDialog  = this.openReviewReservationCancellationDialog .bind(this);
		this.closeReviewReservationCancellationDialog = this.closeReviewReservationCancellationDialog.bind(this);
		
		const table_items_filters = {
			internal_code: '',
			accommodation: '',
			check_in_out:  [ null, null ],
			customer:      '',
			statuses:      [],
			created:       [ moment().subtract(3, 'days').toDate(), null ],
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'internal_code',
			table_items_sort_direction: 'DESC',
		});
		console.log({ table_items_sort_ids });
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'internal_code',
			table_items_sort_direction: 'DESC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			table_items_checked_ids: {},
			button_confirm_loading: false,
			reservation_dialog_open: false,
			current_reservation: null,
			current_id_reservation_request: null,
			quick_edit_check_in_out_id_reservation_request: null,
			quick_edit_check_in_out_check_in: null,
			quick_edit_check_in_out_check_out: null,
			quick_edit_people_id_reservation_request: null,
			quick_edit_people_adult: null,
			quick_edit_people_child_1: null,
			quick_edit_people_child_2: null,
			accommodation_selection_dialog_open: false,
			accommodation_selection_dialog_id: null,
			select_new_status_dialog_open: false,
			review_reservation_confirmation_dialog_open: false,
			review_reservation_cancellation_dialog_open: false,
		};
	}
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.reservation_requests != prevProps.reservation_requests) {
			this.setState(
				this.updateTableItemsFromProps(this.props, this.state)
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const filtered_items = {};
		for (let id_reservation_request in props.reservation_requests) {
			const item = props.reservation_requests[id_reservation_request];
			filtered_items[item.id_reservation_request] = item;
		}
		
		const ids = Object.keys(filtered_items);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			reservation_requests: props.reservation_requests,
			table_items: filtered_items,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a = '';
			let b = '';
			let dir = direction;
			
			/*if (state.table_items[a_key].draft != state.table_items[b_key].draft) {
				a = state.table_items[a_key].draft;
				b = state.table_items[b_key].draft;
				dir = 'DESC';
			}
			else if (field == 'id_customer') {
				const customer_a = this.props.customers[state.table_items[a_key].id_customer];
				const customer_b = this.props.customers[state.table_items[b_key].id_customer];
				
				if (customer_a === undefined && customer_b === undefined) {
					a = 0;
					b = 0;
				}
				else {
					a = customer_a === undefined ? '' : (customer_a.name + ' ' + customer_a.surname);
					b = customer_b === undefined ? '' : (customer_b.name + ' ' + customer_b.surname);
					
					return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
				}
			}
			else if (field == 'username') {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
				
				return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'customer_internal_code') {
				const customer_a = this.props.customers[state.table_items[a_key].id_customer];
				const customer_b = this.props.customers[state.table_items[b_key].id_customer];
				
				if (customer_a === undefined && customer_b === undefined) {
					a = 0;
					b = 0;
				}
				else {
					a = customer_a === undefined ? 0 : customer_a.internal_code;
					b = customer_b === undefined ? 0 : customer_b.internal_code;
				}
			}
			else if (field == 'cash_register_internal_code') {
				const cash_register_a = this.props.cash_registers[state.table_items[a_key].id_cash_register];
				const cash_register_b = this.props.cash_registers[state.table_items[b_key].id_cash_register];
				
				if (cash_register_a === undefined && cash_register_b === undefined) {
					a = 0;
					b = 0;
				}
				else {
					a = cash_register_a === undefined ? 0 : cash_register_a.internal_code;
					b = cash_register_b === undefined ? 0 : cash_register_b.internal_code;
				}
			}*/
			if (field == 'amount') {
				a = state.table_items[a_key].total_amount;
				b = state.table_items[b_key].total_amount;
			}
			else {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_internal_code      = filters.internal_code.toUpperCase();
		const filters_accommodation      = filters.accommodation.toUpperCase();
		const filters_check_in_out_start = filters.check_in_out[0] === null ? null : this.toIsoString(filters.check_in_out[0]);
		const filters_check_in_out_end   = filters.check_in_out[1] === null ? null : this.toIsoString(filters.check_in_out[1]);
		const filters_customer           = filters.customer.toUpperCase();
		const filters_statuses           = filters.statuses;
		const filters_created_start      = filters.created[0] === null ? null : this.toIsoString(filters.created[0]);
		const filters_created_end        = filters.created[1] === null ? null : this.toIsoString(filters.created[1]);
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_internal_code != '' && item.internal_code.toUpperCase().indexOf(filters_internal_code) == -1) {
				return false;
			}
			
			if (filters_statuses.length > 0) {
				if (filters_statuses.indexOf(item.status) == -1) {
					return false;
				}
			}
			
			if (filters_created_start !== null && moment(item.created).format('YYYY-MM-DD') < filters_created_start) {
				return false;
			}
			if (filters_created_end !== null && moment(item.created).format('YYYY-MM-DD') > filters_created_end) {
				return false;
			}
			
			if (filters_customer.length > 0 && (item.customer_name + ' ' + item.customer_surname).toUpperCase().indexOf(filters_customer) == -1) {
				return false;
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			internal_code: this.state.table_items_filters.internal_code,
			accommodation: this.state.table_items_filters.accommodation,
			check_in_out:  this.state.table_items_filters.check_in_out,
			customer:      this.state.table_items_filters.customer,
			statuses:      this.state.table_items_filters.statuses,
			created:       this.state.table_items_filters.created,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	calculateDayCount(item) {
		let count = 0;
		if (item.prices === null) return 0;
		
		for (let price_key in item.prices.main) {
			for (let date_key in item.prices.main[price_key]) {
				count = Math.max(count, item.prices.main[price_key][date_key].day_count);
			}
		}
		
		return count;
	}
	getAccommodationTitle(item) {
		const titles = {
			'camp-tent':      'Kampirno mesto - šotor',
			'camp-motorhome': 'Kampirno mesto - avtodom',
			'camp-caravan':   'Kampirno mesto - kamp prikolica',
		};
		
		if ([ 'camp-tent', 'camp-motorhome', 'camp-caravan' ].indexOf(item.id_accommodation_item_place_raw) !== -1) {
			return titles[item.id_accommodation_item_place_raw] ?? '';
		}
		if (item.id_accommodation_item_place === null) return '';
		
		const accommodation_item_place = this.props.accommodation_item_places[item.id_accommodation_item_place];
		const accommodation_item       = this.props.accommodation_items      [accommodation_item_place.id_accommodation_item];
		const accommodation            = this.props.accommodations           [accommodation_item.id_accommodation];
		
		return accommodation.title + ' • ' +
			(accommodation_item.title == 'default' ? '' : (accommodation_item.title + ' • ')) +
			accommodation_item_place.title;
	}
	
	async changeStatus(id_reservation_requests, status) {
		await changeReservationRequestStatus(
			this.props.api_url,
			this.props.dispatch,
			this.props.token,
			id_reservation_requests,
			status
		);
	}
	async confirmReservationRequest(id_reservation_requests) {
		await confirmReservationRequest(
			this.props.api_url,
			this.props.dispatch,
			this.props.token,
			id_reservation_requests
		);
	}
	async cancelReservationRequest(id_reservation_requests) {
		await cancelReservationRequest(
			this.props.api_url,
			this.props.dispatch,
			this.props.token,
			id_reservation_requests
		);
	}
	
	openReservationDialog(id_reservation_request, id_accommodation_item_place) {
		const reservation_request = this.state.table_items[id_reservation_request];
		
		if (id_accommodation_item_place === undefined) {
			id_accommodation_item_place = reservation_request.id_accommodation_item_place;
		}
		
		let accommodation = null;
		for (let key in this.props.reservation_request_accommodations) {
			const acc = this.props.reservation_request_accommodations[key].accommodation_items[reservation_request.id_accommodation_item_place_raw];
			if (acc !== undefined) {
				accommodation = acc;
				break;
			}
		}
		
		let note_items = [
			'Povpraševanje št. ' + reservation_request.internal_code +
			'  ' + moment(reservation_request.check_in).format('DD.MM.YYYY') + '-' + moment(reservation_request.check_out).format('DD.MM.YYYY') + '  ' + moment(reservation_request.created).format('DD.MM.YYYY HH:mm:ss'),
			'Odrasli (' + this.props.general_settings.reservation_child_age_limit_2 + '+): ' + reservation_request.guest_adult_count +
				' - Otroci (' + this.props.general_settings.reservation_child_age_limit_1 + '-' + this.props.general_settings.reservation_child_age_limit_2 + '): ' + reservation_request.guest_child_2_count +
				' - Otroci (0-' + this.props.general_settings.reservation_child_age_limit_1 + '): ' + reservation_request.guest_child_1_count,
			'',
		];
		if (accommodation !== null) {
			const services_text            = getReservationRequestItemServices(reservation_request, accommodation, 'services', false);
			const additional_services_text = getReservationRequestItemServices(reservation_request, accommodation, 'additional_services', false);
			
			note_items.push(
				(
					(services_text ?? '').trim() +
					'\n' +
					(additional_services_text ?? '').trim()
				).trim()
			);
		}
		
		note_items.push(
			'Znesek rezervacije: ' +
			reservation_request.total_amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €' +
			'  Predplačilo (30%): ' + (reservation_request.total_amount * 0.3).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €'
		);
		
		if (reservation_request.customer_note.length > 0) {
			note_items.push('');
			note_items.push(reservation_request.customer_note.trim() + '\n');
		}
		
		this.setState({
			reservation_dialog_open: true,
			current_reservation: {
				id_reservation:              -1,
				id_accommodation_item_place: id_accommodation_item_place,
				internal_code:               '',
				id_customer:                 -1,
				check_in:                    reservation_request.check_in,
				check_out:                   reservation_request.check_out,
				guest_adult_count:           reservation_request.guest_adult_count,
				guest_child_1_count:         reservation_request.guest_child_1_count,
				guest_child_2_count:         reservation_request.guest_child_2_count,
				note:                        note_items.filter(x => x !== null).join('\n'),
				status:                      'new',
				status_2:                    null,
				id_channel:                  (Object.values(this.props.channels).find(x => x.internal_code == 'w') ?? { id_channel: -1 }).id_channel,
				custom_status:               '',
				deleted:                     false,
				guests_checked_in:           false,
				items:                       {},
			},
			current_id_reservation_request: id_reservation_request,
		});
	}
	closeReservationDialog() {
		this.setState({
			reservation_dialog_open: false,
		});
		this.clearSelection();
	}
	delReservation(id_reservation) {
		this.props.dispatch(deleteReservation(id_reservation));
	}
	
	openQuickEditCheckInOutDialog(id_reservation_request) {
		const item = this.state.table_items[id_reservation_request];
		this.setState({
			quick_edit_check_in_out_id_reservation_request: id_reservation_request,
			quick_edit_check_in_out_check_in:               item.check_in,
			quick_edit_check_in_out_check_out:              item.check_out,
		});
	}
	async closeQuickEditCheckInOutDialog(save) {
		if (save) {
			const item = {
				...this.state.table_items[this.state.quick_edit_check_in_out_id_reservation_request],
				check_in:  this.state.quick_edit_check_in_out_check_in,
				check_out: this.state.quick_edit_check_in_out_check_out,
			};
			
			this.props.dispatch(addReservationRequest({ item }));
			
			await saveReservationRequest(this.props.api_url, this.props.token, item);
		}
		
		this.setState({ quick_edit_check_in_out_id_reservation_request: null });
	}
	openQuickEditPeopleDialog(id_reservation_request) {
		const item = this.state.table_items[id_reservation_request];
		this.setState({
			quick_edit_people_id_reservation_request: id_reservation_request,
			quick_edit_people_adult:                  item.guest_adult_count,
			quick_edit_people_child_1:                item.guest_child_1_count,
			quick_edit_people_child_2:                item.guest_child_2_count,
		});
	}
	async closeQuickEditPeopleDialog(save) {
		if (save) {
			const item = {
				...this.state.table_items[this.state.quick_edit_people_id_reservation_request],
				guest_adult_count:   this.state.quick_edit_people_adult,
				guest_child_1_count: this.state.quick_edit_people_child_1,
				guest_child_2_count: this.state.quick_edit_people_child_2,
			};
			
			this.props.dispatch(addReservationRequest({ item }));
			
			await saveReservationRequest(this.props.api_url, this.props.token, item);
		}
		
		this.setState({ quick_edit_people_id_reservation_request: null });
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return {
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
			parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
		};
	}
	
	getMaxDate() {
		const date = new Date();
		date.setFullYear(date.getFullYear() + 10);
		return date;
	}
	
	openAccommodationSelectionDialog(id) {
		this.setState({
			accommodation_selection_dialog_open: true,
			accommodation_selection_dialog_id:   id,
		});
	}
	closeAccommodationSelectionDialog(id) {
		this.setState({ accommodation_selection_dialog_open: false });
		if (id !== null) {
			this.openReservationDialog(
				this.state.accommodation_selection_dialog_id,
				id
			);
		}
	}
	
	openSelectNewStatusDialog() {
		this.setState({
			select_new_status_dialog_open: true,
		});
	}
	async closeSelectNewStatusDialog(status) {
		this.setState({
			select_new_status_dialog_open: false,
		});
		
		if (status !== false) {
			const check_ids = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
			await this.changeStatus(check_ids, status);
		}
		
		this.clearSelection();
	}
	
	openReviewReservationConfirmationDialog() {
		this.setState({
			review_reservation_confirmation_dialog_open: true,
		});
	}
	async closeReviewReservationConfirmationDialog(confirm) {
		this.setState({
			review_reservation_confirmation_dialog_open: false,
		});
		
		if (confirm) {
			this.setState({ button_confirm_loading: true });
			await this.confirmReservationRequest(
				this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true)
			);
			this.setState({ button_confirm_loading: false });
		}
		
		this.clearSelection();
	}
	
	openReviewReservationCancellationDialog() {
		this.setState({
			review_reservation_cancellation_dialog_open: true,
		});
	}
	async closeReviewReservationCancellationDialog(confirm) {
		this.setState({
			review_reservation_cancellation_dialog_open: false,
		});
		
		if (confirm) {
			this.setState({ button_confirm_loading: true });
			await this.cancelReservationRequest(
				this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true)
			);
			this.setState({ button_confirm_loading: false });
		}
		
		this.clearSelection();
	}
	
	clearSelection() {
		const table_items_checked_ids = {};
		for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
			table_items_checked_ids[this.state.table_items_sort_ids[i]] = false;
		}
		
		this.setState({ table_items_checked_ids });
	}
	
	render() {
		const check_ids   = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
		const check_count = check_ids.length;
		
		let initial_customer_query = null;
		if (this.state.reservation_dialog_open) {
			const reservation_request = this.state.table_items[this.state.current_id_reservation_request];
			initial_customer_query = {
				name:        reservation_request.customer_name,
				surname:     reservation_request.customer_surname,
				email:       reservation_request.customer_email,
				id_country:  reservation_request.customer_id_country,
				phone:       reservation_request.customer_phone,
				post_office: reservation_request.customer_post_code_name,
				address:     reservation_request.customer_address,
			};
			
			if (this.props.general_settings.scanner_change_case == 'uppercase') {
				initial_customer_query.name    = initial_customer_query.name.toUpperCase();
				initial_customer_query.surname = initial_customer_query.surname.toUpperCase();
			}
			if (this.props.general_settings.scanner_change_case == 'lowercase') {
				initial_customer_query.name    = initial_customer_query.name.toLowerCase();
				initial_customer_query.surname = initial_customer_query.surname.toLowerCase();
			}
			if (this.props.general_settings.scanner_change_case == 'title-case') {
				// from https://stackoverflow.com/a/37931321
				initial_customer_query.name    = initial_customer_query.name   .replace(/\S+/g, str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase());
				initial_customer_query.surname = initial_customer_query.surname.replace(/\S+/g, str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase());
			}
		}
		
		return <>
			{!this.state.reservation_dialog_open ? null :
				<Reservation
					closeReservation={this.closeReservationDialog}
					deleteReservation={this.delReservation}
					reservation={this.state.current_reservation}
					savedReservationCallback={async (reservation) => {
						if (this.state.current_id_reservation_request !== null) {
							await this.changeStatus([ this.state.current_id_reservation_request ], 'processed');
							
							const item = {
								id_document_association: uuid.v4(),
								source_id_document:      reservation.id_reservation,
								source_type:             'reservation',
								target_id_document:      this.state.current_id_reservation_request,
								target_type:             'reservation-request'
							};
							
							const new_item = await saveDocumentAssociation(this.props.api_url, item, this.props.token);
							if (new_item !== null) {
								this.props.dispatch(addDocumentAssociation({
									item: new_item,
									token: this.props.token,
								}));
							}
						}
					}}
					initial_customer_query={initial_customer_query} />
			}
			
			<Dialog
				isOpen={this.state.quick_edit_check_in_out_id_reservation_request !== null}
				onClose={() => this.closeQuickEditCheckInOutDialog(false)}
				className='dialog--note-popup-data'>
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp3-dark'>
						<Navbar.Group>
							<Navbar.Heading>Prihod - odhod</Navbar.Heading>
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button minimal={true} icon='cross' onClick={() => this.closeQuickEditCheckInOutDialog(false)} />
						</Navbar.Group>
					</Navbar>
					<div className={Classes.DIALOG_BODY}>
						<DateRangeInput
							locale='sl'
							{...this.getMomentFormatter('DD. MM. YYYY')}
							allowSingleDayRange={true}
							contiguousCalendarMonths={false}
							shortcuts={false}
							startInputProps={{ placeholder: 'Od', className: 'from-until' }}
							endInputProps={{ placeholder: 'Do', className: 'from-until' }}
							minDate={new Date('1900-01-01')}
							maxDate={ this.getMaxDate() }
							selectAllOnFocus={true}
							onChange={selected_range => {
								this.setState({
									quick_edit_check_in_out_check_in:  moment(selected_range[0]).toISOString(true),
									quick_edit_check_in_out_check_out: moment(selected_range[1]).toISOString(true),
								});
							}}
							value={[
								moment(this.state.quick_edit_check_in_out_check_in).toDate(),
								moment(this.state.quick_edit_check_in_out_check_out).toDate(),
							]} />
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button
								minimal={true}
								onClick={() => this.closeQuickEditCheckInOutDialog(false)}>
								Prekliči
							</Button>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.closeQuickEditCheckInOutDialog(true)}>
								Shrani
							</Button>
						</div>
					</div>
				</div>
			</Dialog>
			
			<Dialog
				isOpen={this.state.quick_edit_people_id_reservation_request !== null}
				onClose={() => this.closeQuickEditPeopleDialog(false)}
				className='dialog--note-popup-data'>
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp3-dark'>
						<Navbar.Group>
							<Navbar.Heading>Osebe</Navbar.Heading>
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button minimal={true} icon='cross' onClick={() => this.closeQuickEditPeopleDialog(false)} />
						</Navbar.Group>
					</Navbar>
					<div className={Classes.DIALOG_BODY}>
						<div className='flex flex-row content-start justify-between pt-3'>
							<PeopleCountInput
								adult_title={'Odrasli'}
								child_1_title={'0-' + this.props.general_settings.reservation_child_age_limit_1 + ' let'}
								child_2_title={this.props.general_settings.reservation_child_age_limit_1 + '-' + this.props.general_settings.reservation_child_age_limit_2 + ' let'}
								adult_count={this.state.quick_edit_people_adult}
								child_1_count={this.state.quick_edit_people_child_1}
								child_2_count={this.state.quick_edit_people_child_2}
								onChangeAdultCount={val => {
									this.setState({ quick_edit_people_adult: val });
								}}
								onChangeChild1Count={val => {
									this.setState({ quick_edit_people_child_1: val });
								}}
								onChangeChild2Count={val => {
									this.setState({ quick_edit_people_child_2: val });
								}}
								disabled={false} />
						</div>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button
								minimal={true}
								onClick={() => this.closeQuickEditPeopleDialog(false)}>
								Prekliči
							</Button>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.closeQuickEditPeopleDialog(true)}>
								Shrani
							</Button>
						</div>
					</div>
				</div>
			</Dialog>
			
			<AccommodationSelectorDialog
				isOpen                   ={this.state.accommodation_selection_dialog_open}
				onClose                  ={this.closeAccommodationSelectionDialog}
				accommodations           ={this.props.accommodations}
				accommodation_items      ={this.props.accommodation_items}
				accommodation_item_places={this.props.accommodation_item_places}
				reservation_request      ={this.props.reservation_requests[this.state.accommodation_selection_dialog_id]}
				reservations             ={this.props.reservations} />
			
			
			<Dialog
				isOpen={this.state.select_new_status_dialog_open}
				onClose={() => this.closeSelectNewStatusDialog(false)}
				className=''>
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp3-dark'>
						<Navbar.Group>
							<Navbar.Heading>Sprememba statusa</Navbar.Heading>
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button minimal={true} icon='cross' onClick={() => this.closeSelectNewStatusDialog(false)} />
						</Navbar.Group>
					</Navbar>
					<div className={Classes.DIALOG_BODY}>
						<div className='flex flex-row content-start justify-between pt-3'>
							<Button onClick={() => this.closeSelectNewStatusDialog('new'      )}>Novo</Button>
							<Button onClick={() => this.closeSelectNewStatusDialog('confirmed')}>Potrjeno</Button>
							<Button onClick={() => this.closeSelectNewStatusDialog('processed')}>Obdelano</Button>
							<Button onClick={() => this.closeSelectNewStatusDialog('closed'   )}>Zaprto</Button>
							<Button onClick={() => this.closeSelectNewStatusDialog('canceled' )}>Stornirano</Button>
						</div>
					</div>
				</div>
			</Dialog>
			
			<Dialog
				isOpen={this.state.review_reservation_confirmation_dialog_open}
				onClose={() => this.closeReviewReservationConfirmationDialog(false)}
				className=''>
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp3-dark'>
						<Navbar.Group>
							<Navbar.Heading>Potrditev povpraševanj</Navbar.Heading>
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button minimal={true} icon='cross' onClick={() => this.closeReviewReservationConfirmationDialog(false)} />
						</Navbar.Group>
					</Navbar>
					<div className={Classes.DIALOG_BODY}>
						<div>
							<div>Potrdili boste naslednja povpraševanja:</div>
							<div>{ check_ids.map(id => <div key={id}>{ this.state.table_items[id].internal_code }</div>) }</div>
						</div>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button
								minimal={true}
								onClick={() => this.closeReviewReservationConfirmationDialog(false)}>
								Prekliči
							</Button>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.closeReviewReservationConfirmationDialog(true)}>
								Potrdi
							</Button>
						</div>
					</div>
				</div>
			</Dialog>
			
			<Dialog
				isOpen={this.state.review_reservation_cancellation_dialog_open}
				onClose={() => this.closeReviewReservationCancellationDialog(false)}
				className=''>
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp3-dark'>
						<Navbar.Group>
							<Navbar.Heading>Stornacija povpraševanj</Navbar.Heading>
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button minimal={true} icon='cross' onClick={() => this.closeReviewReservationCancellationDialog(false)} />
						</Navbar.Group>
					</Navbar>
					<div className={Classes.DIALOG_BODY}>
						<div>
							<div>Stornirali boste naslednja povpraševanja:</div>
							<div>{ check_ids.map(id => <div key={id}>{ this.state.table_items[id].internal_code }</div>) }</div>
						</div>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button
								minimal={true}
								onClick={() => this.closeReviewReservationCancellationDialog(false)}>
								Prekliči
							</Button>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.closeReviewReservationCancellationDialog(true)}>
								Potrdi
							</Button>
						</div>
					</div>
				</div>
			</Dialog>
			
			<div className='flex flex-col flex-grow reservation-requests-list'>
				<div className='pl-4 pt-4'>
					<ButtonGroup className='ml-2 align-middle'>
						<Button intent='success' disabled={check_count == 0} loading={this.state.button_confirm_loading} onClick={this.openReviewReservationConfirmationDialog}>
							Potrdi povpraševanje
						</Button>
						<Button intent='primary' disabled={check_count != 1} onClick={() => {
							if (this.state.table_items[check_ids[0]].id_accommodation_item_place !== null) {
								this.openReservationDialog(check_ids[0]);
							}
							else {
								this.openAccommodationSelectionDialog(check_ids[0]);
							}
						}}>
							Ustvari rezervacijo
						</Button>
						<Button intent='success' disabled={check_count == 0} onClick={this.openSelectNewStatusDialog}>
							Spremeni status
						</Button>
						<Button intent='danger' disabled={check_count == 0} loading={this.state.button_confirm_loading} onClick={this.openReviewReservationCancellationDialog}>
							Storniraj povpraševanje
						</Button>
					</ButtonGroup>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow' style={{ flexBasis: '0' }}>
						<Results
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={() => {}}
							tableItemsFilters={this.state.table_items_filters}
							originalTableItemsFilters={this.state.original_table_items_filters}
							handleFilterChange={this.handleFilterChange}
							table_items_checked_ids={this.state.table_items_checked_ids}
							rowCheckStateChanged={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							countries={this.props.countries}
							calculateDayCount={this.calculateDayCount}
							getAccommodationTitle={this.getAccommodationTitle}
							general_settings={this.props.general_settings}
							openQuickEditCheckInOutDialog={this.openQuickEditCheckInOutDialog}
							openQuickEditPeopleDialog={this.openQuickEditPeopleDialog}
							reservation_request_accommodations={this.props.reservation_request_accommodations} />
					</div>
				</div>
			</div>
		</>;
	}
}
ReservationRequestsList.propTypes = {
};

function mapStateToProps(state) {
	return {
		reservation_requests:               state.ReservationSlice.reservation_requests,
		reservation_request_accommodations: state.ReservationSlice.reservation_request_accommodations,
		reservations:                       state.ReservationSlice.reservations,
		countries:                          state.CodeTablesSlice.countries,
		accommodations:                     state.CodeTablesSlice.accommodations,
		accommodation_items:                state.CodeTablesSlice.accommodation_items,
		accommodation_item_places:          state.CodeTablesSlice.accommodation_item_places,
		channels:                           state.CodeTablesSlice.channels,
		general_settings:                   state.SettingsSlice.general,
		api_url:                            state.BusinessSlice.api_url,
		token:                              state.UserSlice.token,
	};
}

export default connect(mapStateToProps)(ReservationRequestsList);
