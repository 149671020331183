import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	Checkbox,
} from '@blueprintjs/core';

import TextWithTooltip from '../TextWithTooltip';
import { AppToaster }  from '../AppToaster';

class AddEditWarehouse extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_warehouse:    props.item === null ? -1    : props.item.id_warehouse,
			title:           props.item === null ? ''    : props.item.title,
			default_warehouse: props.item === null ? false : props.item.default_warehouse,
			validation_failed_fields: [],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-warehouse-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi skladišče</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='add-edit-warehouse-dialog--title'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('title') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Naziv'
								tooltip='Naziv' />
							<InputGroup
								id='add-edit-warehouse-dialog--title'
								value={this.state.title}
								onChange={event => {
									this.setState({
										title: event.target.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-warehouse-dialog--default_warehouse'
							className='flex-1'>
							<TextWithTooltip
								label='Privzeto skladišče'
								tooltip='Privzeto skladišče' />
							<Checkbox checked={this.state.default_warehouse} onChange={event => {
								this.setState({ default_warehouse: event.target.checked });
							}} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = {
									id_warehouse:    this.state.id_warehouse,
									title:           this.state.title,
									commission_sale: false,
									serial_internal_code: this.props.item === null ? null : this.props.item.serial_internal_code,
									default_warehouse: this.state.default_warehouse,
								};
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (new_item.title.length == 0) {
									validation_failed_fields.push('title');
									validation_failed_field_titles.push('naziv');
								}
								
								//TODO check default_warehouse and warn user if we're overriding another warehouse's default_warehouse
								//TODO also warn if we're trying to turn off default_warehouse
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
										</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								// save item
								this.props.closeAddEditItemDialog(new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditWarehouse.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
};

export default AddEditWarehouse;
