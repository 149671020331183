import React, { useFfect } from 'react';

import moment from 'moment';

import PrintHelper from '../../helpers/Print';
import T           from '../../helpers/Translation';

import {
	CompanyDetails,
	Logo,
	CustomerDetails,
	Footer,
} from './Common';

function formatNumber(val, decimal_digit_count) {
	decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
	
	return Math.properRound(parseFloat(val), decimal_digit_count)
		.toLocaleString(
			undefined,
			{
				minimumFractionDigits: decimal_digit_count,
				maximumFractionDigits: decimal_digit_count,
			}
		);
}

const typeTypeTitles = {
	'cash':          'Gotovina',
	'credit-card':   'Kartice',
	'bank-transfer': 'TRR',
	'gift-card':     'Bon',
	'other':         'Drugo',
};
const typeTypeValues = [
	'cash',
	'credit-card',
	'bank-transfer',
	'gift-card',
	'other',
];
const itemTypeTitles = {
	'item':        'Blago',
	'service':     'Storitev',
	'tourist_tax': 'Turistična taksa',
};
const itemTypeValues = [
	'item',
	'service',
	'tourist_tax',
];

function BasicData(props) {
	if (props.items.length == 1 && props.cash_register !== null) {
		return <div className='print-journal-basic-data'>
			<div className='print-journal-basic-data-1'>{props.cash_register.title}</div>
			<div className='print-journal-basic-data-2'>{'BLAGAJNIŠKI DNEVNIK - ' + moment(props.items[0].document_date).format('DD.MM.YYYY') + ' - št. ' + props.items[0].document_number}</div>
		</div>;
	}
	else if (props.items.length > 0) {
		const sorted_items = [...props.items].sort((a, b) => {
			if (a.document_date < b.document_date) return -1;
			if (a.document_date > b.document_date) return  1;
			return 0;
		});
		
		return <div className='print-journal-basic-data'>
			<div className='print-journal-basic-data-1'>{
				props.cash_registers[props.items[0].id_cash_register].title
			}</div>
			<div className='print-journal-basic-data-2'>
				BLAGAJNIŠKI DNEVNIK - ZBIRNIK
			</div>
			<div className='print-journal-basic-data-3'>{
				moment(props.items[0].document_date).format('DD.MM.YYYY')
				+ ' - ' +
				moment(props.items[props.items.length - 1].document_date).format('DD.MM.YYYY')
				+ ' • ' +
				props.items[0].document_number
				+ ' do ' +
				props.items[props.items.length - 1].document_number
			}</div>
		</div>;
	}
	return null;
}
function IncomeExpenseTable(props) {
	const { items, documents, payment_types } = props;
	
	const grouped       = {};
	const groupedTotals = {};
	const total   = {
		income:  0,
		expense: 0,
	};
	for (let i=0; i<documents.length; i++) {
		const doc = documents[i];
		if (doc.payment_types === null) continue;
		
		for (let j=0; j<doc.payment_types.length; j++) {
			const payment_type      = doc.payment_types[j];
			const payment_type_item = payment_types[payment_type.id_payment_type];
			
			if (groupedTotals[payment_type_item.type] === undefined) {
				groupedTotals[payment_type_item.type] = {
					income:  0,
					expense: 0,
				};
			}
			if (grouped[payment_type_item.type] === undefined) {
				grouped[payment_type_item.type] = {};
			}
			if (grouped[payment_type_item.type][payment_type.id_payment_type] === undefined) {
				grouped[payment_type_item.type][payment_type.id_payment_type] = {
					income:  0,
					expense: 0,
				};
			}
			
			grouped[payment_type_item.type][payment_type.id_payment_type][doc.type] += payment_type.amount;
			groupedTotals[payment_type_item.type]                        [doc.type] += payment_type.amount;
			total                                                        [doc.type] += payment_type.amount;
		}
	}
	
	return <div className='print-journal-income-expenses-table font-small'>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell emphasized-2'>Stari saldo (EUR)</div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell emphasized-2 text-right'>{ formatNumber(items[0].start_balance) }</div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell'></div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell'></div>
		
		<div className='print-journal-table-header-cell print-journal-income-expenses-table-cell print-journal-income-expenses-table-cell-span-2'>PREJEMKI</div>
		<div className='print-journal-table-header-cell print-journal-income-expenses-table-cell print-journal-income-expenses-table-cell-span-2'>IZDATKI</div>
		
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell'>NAČIN PLAČILA</div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell text-right'>Znesek (EUR)</div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell'>NAČIN PLAČILA</div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell text-right'>Znesek (EUR)</div>
		
		{typeTypeValues.map(type => {
			if (grouped[type] === undefined) return null;
			
			const display_group = Object.keys(grouped[type]).length > 1;
			
			return <React.Fragment key={'item-' + type}>
				{!display_group ? null :
					<>
						<div className={'print-journal-table-cell print-journal-income-expenses-table-cell emphasized-2'}>{
							typeTypeTitles[type]
						}</div>
						<div className={'print-journal-table-cell print-journal-income-expenses-table-cell emphasized-2 text-right'}>{
							formatNumber(groupedTotals[type].income)
						}</div>
						<div className={'print-journal-table-cell print-journal-income-expenses-table-cell emphasized-2'}>{
							typeTypeTitles[type]
						}</div>
						<div className={'print-journal-table-cell print-journal-income-expenses-table-cell emphasized-2 text-right'}>{
							formatNumber(groupedTotals[type].expense)
						}</div>
					</>
				}
				
				{Object.keys(grouped[type])
					.sort((id_payment_type_a, id_payment_type_b) => {
						return payment_types[id_payment_type_a].ord - payment_types[id_payment_type_b].ord;
					})
					.map(id_payment_type => {
						const payment_type = payment_types[id_payment_type];
						const cls          = display_group ? 'pl-2' : '';
						
						return <React.Fragment key={'item-' + type + '-' + id_payment_type}>
							<div className={'print-journal-table-cell print-journal-income-expenses-table-cell ' + cls}>{
								payment_type.title
							}</div>
							<div className={'print-journal-table-cell print-journal-income-expenses-table-cell text-right ' + cls}>{
								formatNumber(grouped[type][id_payment_type].income)
							}</div>
							<div className={'print-journal-table-cell print-journal-income-expenses-table-cell ' + cls}>{
								payment_type.title
							}</div>
							<div className={'print-journal-table-cell print-journal-income-expenses-table-cell text-right ' + cls}>{
								formatNumber(grouped[type][id_payment_type].expense)
							}</div>
						</React.Fragment>;
					})
				}
			</React.Fragment>;
		})}
		
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell top-border emphasized-2'>SKUPAJ</div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell top-border emphasized-2 text-right'>{ formatNumber(total.income) }</div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell top-border emphasized-2'>SKUPAJ</div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell top-border emphasized-2 text-right'>{ formatNumber(total.expense) }</div>
		
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell'></div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell'></div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell emphasized-2'>Novi saldo (EUR)</div>
		<div className='print-journal-table-cell print-journal-income-expenses-table-cell emphasized-2 text-right'>{ formatNumber(items[items.length - 1].end_balance) }</div>
	</div>;
}
function CashierTable(props) {
	const { item, documents, users } = props;
	
	const grouped = {};
	const total   = {
		income:  0,
		expense: 0,
	};
	for (let i=0; i<documents.length; i++) {
		const doc = documents[i];
		if (doc.payment_types === null) continue;
		
		if (grouped[doc.id_user] === undefined) {
			grouped[doc.id_user] = {
				income:  0,
				expense: 0,
			};
		}
		
		for (let j=0; j<doc.payment_types.length; j++) {
			const payment_type = doc.payment_types[j];
			
			grouped[doc.id_user][doc.type] += payment_type.amount;
			total               [doc.type] += payment_type.amount;
		}
	}
	
	return <div className='print-journal-cashier-table font-small'>
		<div className='print-journal-table-header-cell print-journal-cashier-table-cell'>Blagajnik</div>
		<div className='print-journal-table-header-cell print-journal-cashier-table-cell text-right'>PREJEMKI (EUR)</div>
		<div className='print-journal-table-header-cell print-journal-cashier-table-cell text-right'>IZDATKI (EUR)</div>
		
		{Object.keys(grouped).map(id_user => {
			const user = users[id_user];
			
			return <React.Fragment key={'item-' + id_user}>
				<div className='print-journal-table-cell print-journal-cashier-table-cell'>{ user.name + ' ' + user.surname }</div>
				<div className='print-journal-table-cell print-journal-cashier-table-cell text-right'>{ formatNumber(grouped[id_user].income) }</div>
				<div className='print-journal-table-cell print-journal-cashier-table-cell text-right'>{ formatNumber(grouped[id_user].expense) }</div>
			</React.Fragment>;
		})}
		
		{Object.keys(grouped).length <= 1 ? null :
			<>
				<div className='print-journal-table-cell print-journal-cashier-table-cell top-border emphasized-2'>SKUPAJ</div>
				<div className='print-journal-table-cell print-journal-cashier-table-cell top-border emphasized-2 text-right'>{ formatNumber(total.income) }</div>
				<div className='print-journal-table-cell print-journal-cashier-table-cell top-border emphasized-2 text-right'>{ formatNumber(total.expense) }</div>
			</>
		}
	</div>;
}
function SalesSpecificationTable(props) {
	const { documents, tax_rates } = props;
	
	const grouped       = {};
	const grouped_total = {};
	const total   = {
		amount:     0,
		tax_amount: 0,
	};
	for (let i=0; i<documents.length; i++) {
		const doc = documents[i];
		if (doc.payment_types === null) continue;
		if (doc.invoices === null || doc.invoices === undefined || doc.invoices.length == 0) continue;
		
		for (let j=0; j<doc.payment_types.length; j++) {
			const payment_type = doc.payment_types[j];
			
			if (payment_type.item_type   === null) continue;
			if (payment_type.id_tax_rate === null) continue;
			
			if (grouped[payment_type.item_type] === undefined) {
				grouped[payment_type.item_type] = {};
			}
			if (grouped_total[payment_type.item_type] === undefined) {
				grouped_total[payment_type.item_type] = {
					amount:     0,
					tax_amount: 0,
				};
			}
			
			if (grouped[payment_type.item_type][payment_type.id_tax_rate] === undefined) {
				grouped[payment_type.item_type][payment_type.id_tax_rate] = {
					amount:     0,
					tax_amount: 0,
					tax_rate:   payment_type.tax_rate,
				};
			}
			
			grouped[payment_type.item_type][payment_type.id_tax_rate].amount += parseFloat(payment_type.full_amount);
		}
	}
	
	for (let item_type in grouped) {
		for (let id_tax_rate in grouped[item_type]) {
			const rounded_amount     = Math.properRound(grouped[item_type][id_tax_rate].amount, 2);
			const rounded_tax_amount = Math.properRound(
				(rounded_amount - rounded_amount / (1 + parseFloat(grouped[item_type][id_tax_rate].tax_rate) / 100)),
				2
			);
			
			grouped[item_type][id_tax_rate].amount     = rounded_amount;
			grouped[item_type][id_tax_rate].tax_amount = rounded_tax_amount;
			
			grouped_total[item_type].amount     += rounded_amount;
			grouped_total[item_type].tax_amount += rounded_tax_amount;
			
			total.amount     += rounded_amount;
			total.tax_amount += rounded_tax_amount;
		}
	}
	
	return <div className='print-journal-sales-specification-table font-small'>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell'>Specifikacija prodaje</div>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell text-right'>Davčna stopnja</div>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell text-right'>Osnova (EUR)</div>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell text-right'>Davek (EUR)</div>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell text-right'>Skupaj (EUR)</div>
		
		{itemTypeValues.map(item_type => {
			if (grouped[item_type] === undefined) return null;
			
			let items = [];
			const keys = Object.keys(grouped[item_type])
				.sort((a, b) => {
					return tax_rates[a].value - tax_rates[b].value;
				});
			
			for (let i=0; i<keys.length; i++) {
				const id_tax_rate = keys[i];
				const item        = grouped[item_type][id_tax_rate];
				
				const tax_rate       = tax_rates[id_tax_rate];
				let   tax_rate_value = formatNumber(tax_rate.value) + ' %';
				if (tax_rate.taxable == 'nontaxable') {
					tax_rate_value = 'neobdavčeno';
				}
				else if (tax_rate.taxable == 'tax-exempt') {
					tax_rate_value = 'oproščeno';
				}
				
				items = [
					...items,
					<div key={'spec-item-1-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell ' + (i == 0 ? 'mt-05' : '')}>{
						i == 0 ? itemTypeTitles[item_type] : ''
					}</div>,
					<div key={'spec-item-2-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						tax_rate_value
					}</div>,
					<div key={'spec-item-3-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						formatNumber(item.amount - item.tax_amount)
					}</div>,
					<div key={'spec-item-4-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						formatNumber(item.tax_amount)
					}</div>,
					<div key={'spec-item-5-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						formatNumber(item.amount)
					}</div>,
				];
			}
			
			if (keys.length > 1) {
				items = [
					...items,
					<div key={'spec-item-6-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized'>{
						'Skupaj'
					}</div>,
					<div key={'spec-item-7-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized text-right'>{
						''
					}</div>,
					<div key={'spec-item-8-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized text-right'>{
						formatNumber(grouped_total[item_type].amount - grouped_total[item_type].tax_amount)
					}</div>,
					<div key={'spec-item-9-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized text-right'>{
						formatNumber(grouped_total[item_type].tax_amount)
					}</div>,
					<div key={'spec-item-10-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized text-right'>{
						formatNumber(grouped_total[item_type].amount)
					}</div>,
				];
			}
			
			return <React.Fragment key={'item-' + item_type}>
				{ items }
			</React.Fragment>;
		})}
		
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2'>
			SKUPAJ
		</div>
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2'></div>
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2 text-right'>
			{ formatNumber(total.amount - total.tax_amount) }
		</div>
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2 text-right'>
			{ formatNumber(total.tax_amount) }
		</div>
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2 text-right'>
			{ formatNumber(total.amount) }
		</div>
	</div>;
}
function UsedAdvancePaymentsSummaryTable(props) {
	const {
		documents,
		tax_rates,
		id_advance_invoice_consumption_by_id_consumer_invoices,
		advance_invoice_consumptions,
		invoices,
	} = props;
	
	const grouped       = {};
	const grouped_total = {};
	const total   = {
		amount:     0,
		tax_amount: 0,
	};
	for (let i=0; i<documents.length; i++) {
		const doc = documents[i];
		if (doc.payment_types === null) continue;
		if (doc.invoices === null || doc.invoices === undefined || doc.invoices.length == 0) continue;
		
		for (let j=0; j<doc.invoices.length; j++) {
			const invoice = doc.invoices[j];
			
			const id_advance_invoice_consumptions = id_advance_invoice_consumption_by_id_consumer_invoices[invoice.id_invoice] || [];
			for (let z=0; z<id_advance_invoice_consumptions.length; z++) {
				const consumption     = advance_invoice_consumptions[id_advance_invoice_consumptions[z]];
				const advance_invoice = invoices[consumption.advance_id_invoice];
				const invoice_items   = (advance_invoice.items ?? []);
				
				for (let x=0; x<invoice_items.length; x++) {
					const invoice_item = invoice_items[x];
					
					if (invoice_item.item_type   === null) continue;
					if (invoice_item.id_tax_rate === null) continue;
					
					if (grouped[invoice_item.item_type] === undefined) {
						grouped[invoice_item.item_type] = {};
					}
					if (grouped_total[invoice_item.item_type] === undefined) {
						grouped_total[invoice_item.item_type] = {
							amount:     0,
							tax_amount: 0,
						};
					}
					
					if (grouped[invoice_item.item_type][invoice_item.id_tax_rate] === undefined) {
						grouped[invoice_item.item_type][invoice_item.id_tax_rate] = {
							amount:     0,
							tax_amount: 0,
							tax_rate:   invoice_item.tax_rate,
						};
					}
					
					grouped[invoice_item.item_type][invoice_item.id_tax_rate].amount +=
						(parseFloat(consumption.amount) / parseFloat(advance_invoice.invoice_amount)) *
						parseFloat(invoice_item.price) * parseFloat(invoice_item.quantity) * -1;
				}
			}
		}
	}
	
	for (let item_type in grouped) {
		for (let id_tax_rate in grouped[item_type]) {
			const rounded_amount     = Math.properRound(grouped[item_type][id_tax_rate].amount, 2);
			const rounded_tax_amount = Math.properRound(
				(rounded_amount - rounded_amount / (1 + parseFloat(grouped[item_type][id_tax_rate].tax_rate) / 100)),
				2
			);
			
			grouped[item_type][id_tax_rate].amount     = rounded_amount;
			grouped[item_type][id_tax_rate].tax_amount = rounded_tax_amount;
			
			grouped_total[item_type].amount     += rounded_amount;
			grouped_total[item_type].tax_amount += rounded_tax_amount;
			
			total.amount     += rounded_amount;
			total.tax_amount += rounded_tax_amount;
		}
	}
	
	return <div className='print-journal-sales-specification-table font-small'>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell'>Koriščeni avansi</div>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell text-right'>Davčna stopnja</div>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell text-right'>Osnova (EUR)</div>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell text-right'>Davek (EUR)</div>
		<div className='print-journal-table-header-cell print-journal-sales-specification-table-cell text-right'>Skupaj (EUR)</div>
		
		{itemTypeValues.map(item_type => {
			if (grouped[item_type] === undefined) return null;
			
			let items = [];
			const keys = Object.keys(grouped[item_type])
				.sort((a, b) => {
					return tax_rates[a].value - tax_rates[b].value;
				});
			
			for (let i=0; i<keys.length; i++) {
				const id_tax_rate = keys[i];
				const item        = grouped[item_type][id_tax_rate];
				
				const tax_rate       = tax_rates[id_tax_rate];
				let   tax_rate_value = formatNumber(tax_rate.value) + ' %';
				if (tax_rate.taxable == 'nontaxable') {
					tax_rate_value = 'neobdavčeno';
				}
				else if (tax_rate.taxable == 'tax-exempt') {
					tax_rate_value = 'oproščeno';
				}
				
				items = [
					...items,
					<div key={'spec-item-1-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell ' + (i == 0 ? 'mt-05' : '')}>{
						i == 0 ? itemTypeTitles[item_type] : ''
					}</div>,
					<div key={'spec-item-2-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						tax_rate_value
					}</div>,
					<div key={'spec-item-3-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						formatNumber(item.amount - item.tax_amount)
					}</div>,
					<div key={'spec-item-4-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						formatNumber(item.tax_amount)
					}</div>,
					<div key={'spec-item-5-' + i} className={'print-journal-table-cell print-journal-sales-specification-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						formatNumber(item.amount)
					}</div>,
				];
			}
			
			if (keys.length > 1) {
				items = [
					...items,
					<div key={'spec-item-6-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized'>{
						'Skupaj'
					}</div>,
					<div key={'spec-item-7-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized text-right'>{
						''
					}</div>,
					<div key={'spec-item-8-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized text-right'>{
						formatNumber(grouped_total[item_type].amount - grouped_total[item_type].tax_amount)
					}</div>,
					<div key={'spec-item-9-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized text-right'>{
						formatNumber(grouped_total[item_type].tax_amount)
					}</div>,
					<div key={'spec-item-10-total'} className='print-journal-table-cell print-journal-sales-specification-table-cell emphasized text-right'>{
						formatNumber(grouped_total[item_type].amount)
					}</div>,
				];
			}
			
			return <React.Fragment key={'item-' + item_type}>
				{ items }
			</React.Fragment>;
		})}
		
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2'>
			SKUPAJ
		</div>
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2'></div>
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2 text-right'>
			{ formatNumber(total.amount - total.tax_amount) }
		</div>
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2 text-right'>
			{ formatNumber(total.tax_amount) }
		</div>
		<div className='print-journal-table-cell print-journal-sales-specification-table-cell top-border emphasized-2 text-right'>
			{ formatNumber(total.amount) }
		</div>
	</div>;
}
function UsedAdvancePaymentsTable(props) {
	const {
		documents,
		tax_rates,
		id_advance_invoice_consumption_by_id_consumer_invoices,
		advance_invoice_consumptions,
		invoices,
	} = props;
	
	const grouped       = {};
	const grouped_total = {};
	const total   = {
		amount:     0,
		tax_amount: 0,
	};
	const used_advance_invoices = [];
	
	for (let i=0; i<documents.length; i++) {
		const doc = documents[i];
		if (doc.payment_types === null) continue;
		if (doc.invoices === null || doc.invoices === undefined || doc.invoices.length == 0) continue;
		
		for (let j=0; j<doc.invoices.length; j++) {
			const invoice = doc.invoices[j];
			
			const id_advance_invoice_consumptions = id_advance_invoice_consumption_by_id_consumer_invoices[invoice.id_invoice] || [];
			for (let z=0; z<id_advance_invoice_consumptions.length; z++) {
				const consumption = advance_invoice_consumptions[id_advance_invoice_consumptions[z]];
				used_advance_invoices.push(consumption);
				
				if (grouped[consumption.advance_id_invoice] === undefined) {
					grouped[consumption.advance_id_invoice] = {};
				}
				if (grouped_total[consumption.advance_id_invoice] === undefined) {
					grouped_total[consumption.advance_id_invoice] = {
						amount:     0,
						tax_amount: 0,
					};
				}
				
				for (let x=0; x<consumption.taxes.length; x++) {
					const consumption_tax = consumption.taxes[x];
					
					if (grouped[consumption.advance_id_invoice][consumption_tax.id_tax_rate] === undefined) {
						grouped[consumption.advance_id_invoice][consumption_tax.id_tax_rate] = {
							amount:     0,
							tax_amount: 0,
						};
					}
					
					const amount     = parseFloat(consumption_tax.amount);
					const tax_amount = amount - amount / (1 + parseFloat(consumption_tax.tax_rate) / 100);
					
					grouped      [consumption.advance_id_invoice][consumption_tax.id_tax_rate].amount     += amount;
					grouped      [consumption.advance_id_invoice][consumption_tax.id_tax_rate].tax_amount += tax_amount
					grouped_total[consumption.advance_id_invoice].amount                                  += amount;
					grouped_total[consumption.advance_id_invoice].tax_amount                              += tax_amount
					total.amount                                                                          += amount;
					total.tax_amount                                                                      += tax_amount
				}
			}
		}
	}
	
	if (used_advance_invoices.length == 0) return null;
	
	const sorted_ids = Object.keys(grouped);
	sorted_ids.sort((id_invoice_a, id_invoice_b) => {
		const dir = 'DESC';
		
		const a = invoices[id_invoice_a].invoice_number_internal;
		const b = invoices[id_invoice_b].invoice_number_internal;
		
		return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
	});
	
	return <div className='print-journal-advance-payments-table font-small'>
		<div className='print-journal-table-header-cell print-journal-advance-payments-table-cell'>Koriščeni avansi</div>
		<div className='print-journal-table-header-cell print-journal-advance-payments-table-cell'>Datum</div>
		<div className='print-journal-table-header-cell print-journal-advance-payments-table-cell text-right'>Davčna stopnja</div>
		<div className='print-journal-table-header-cell print-journal-advance-payments-table-cell text-right'>Osnova (EUR)</div>
		<div className='print-journal-table-header-cell print-journal-advance-payments-table-cell text-right'>Davek (EUR)</div>
		<div className='print-journal-table-header-cell print-journal-advance-payments-table-cell text-right'>Skupaj (EUR)</div>
		
		{sorted_ids.map(advance_id_invoice => {
			let items = [];
			const keys = Object.keys(grouped[advance_id_invoice])
				.sort((a, b) => {
					return tax_rates[a].value - tax_rates[b].value;
				});
			
			for (let i=0; i<keys.length; i++) {
				const id_tax_rate     = keys[i];
				const item            = grouped[advance_id_invoice][id_tax_rate];
				const advance_invoice = invoices[advance_id_invoice];
				
				const tax_rate       = tax_rates[id_tax_rate];
				let   tax_rate_value = formatNumber(tax_rate.value) + ' %';
				if (tax_rate.taxable == 'nontaxable') {
					tax_rate_value = 'neobdavčeno';
				}
				else if (tax_rate.taxable == 'tax-exempt') {
					tax_rate_value = 'oproščeno';
				}
				
				items = [
					...items,
					<div key={'spec-item-1-' + i} className={'print-journal-table-cell print-journal-advance-payments-table-cell ' + (i == 0 ? 'mt-05' : '')}>{
						i == 0 ? advance_invoice.invoice_number : ''
					}</div>,
					<div key={'spec-item-2-' + i} className={'print-journal-table-cell print-journal-advance-payments-table-cell ' + (i == 0 ? 'mt-05' : '')}>{
						moment(advance_invoice.invoice_date).format('DD. MM. YYYY')
					}</div>,
					<div key={'spec-item-3-' + i} className={'print-journal-table-cell print-journal-advance-payments-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						tax_rate_value
					}</div>,
					<div key={'spec-item-4-' + i} className={'print-journal-table-cell print-journal-advance-payments-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						formatNumber((item.amount - item.tax_amount) * -1)
					}</div>,
					<div key={'spec-item-5-' + i} className={'print-journal-table-cell print-journal-advance-payments-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						formatNumber(item.tax_amount * -1)
					}</div>,
					<div key={'spec-item-6-' + i} className={'print-journal-table-cell print-journal-advance-payments-table-cell text-right ' + (i == 0 ? 'mt-05' : '')}>{
						formatNumber(item.amount * -1)
					}</div>,
				];
			}
			
			if (keys.length > 1) {
				items = [
					...items,
					<div key={'spec-item-7-total'} className='print-journal-table-cell print-journal-advance-payments-table-cell emphasized'>{
						'Skupaj'
					}</div>,
					<div key={'spec-item-8-total'} className='print-journal-table-cell print-journal-advance-payments-table-cell emphasized'>{
						''
					}</div>,
					<div key={'spec-item-9-total'} className='print-journal-table-cell print-journal-advance-payments-table-cell emphasized text-right'>{
						''
					}</div>,
					<div key={'spec-item-10-total'} className='print-journal-table-cell print-journal-advance-payments-table-cell emphasized text-right'>{
						formatNumber((grouped_total[advance_id_invoice].amount - grouped_total[advance_id_invoice].tax_amount) * -1)
					}</div>,
					<div key={'spec-item-11-total'} className='print-journal-table-cell print-journal-advance-payments-table-cell emphasized text-right'>{
						formatNumber(grouped_total[advance_id_invoice].tax_amount * -1)
					}</div>,
					<div key={'spec-item-12-total'} className='print-journal-table-cell print-journal-advance-payments-table-cell emphasized text-right'>{
						formatNumber(grouped_total[advance_id_invoice].amount * -1)
					}</div>,
				];
			}
			
			return <React.Fragment key={'item-' + advance_id_invoice}>
				{ items }
			</React.Fragment>;
		})}
		
		<div className='print-journal-table-cell print-journal-advance-payments-table-cell top-border emphasized-2'>SKUPAJ</div>
		<div className='print-journal-table-cell print-journal-advance-payments-table-cell top-border emphasized-2'></div>
		<div className='print-journal-table-cell print-journal-advance-payments-table-cell top-border emphasized-2'></div>
		<div className='print-journal-table-cell print-journal-advance-payments-table-cell top-border emphasized-2 text-right'>{ formatNumber((total.amount - total.tax_amount) * -1) }</div>
		<div className='print-journal-table-cell print-journal-advance-payments-table-cell top-border emphasized-2 text-right'>{ formatNumber(total.tax_amount * -1) }</div>
		<div className='print-journal-table-cell print-journal-advance-payments-table-cell top-border emphasized-2 text-right'>{ formatNumber(total.amount * -1) }</div>
	</div>;
}
function DetailedList(props) {
	const { documents, payment_types, users, customers } = props;
	
	const sorted_docs = documents
		.sort((a, b) => {
			if (a.type == 'expense' && b.type != 'expense') return -1;
			if (a.type == 'income'  && b.type != 'income' ) return  1;
			
			if      (a.reference > b.reference) return -1;
			else if (a.reference < b.reference) return  1;
			return 0;
		});
	
	const results = [];
	
	results.push(<React.Fragment key='document-group-header'>
		<div className='print-journal-documents-table font-smaller'>
			<div className='print-journal-table-header-cell print-journal-documents-table-cell'>Tip</div>
			<div className='print-journal-table-header-cell print-journal-documents-table-cell'>Številka</div>
			<div className='print-journal-table-header-cell print-journal-documents-table-cell'>Št. dokumenta</div>
			<div className='print-journal-table-header-cell print-journal-documents-table-cell'>Datum</div>
			<div className='print-journal-table-header-cell print-journal-documents-table-cell'>Šifra</div>
			<div className='print-journal-table-header-cell print-journal-documents-table-cell'>Naziv stranke</div>
			<div className='print-journal-table-header-cell print-journal-documents-table-cell'>Blagajnik</div>
			<div className='print-journal-table-header-cell print-journal-documents-table-cell'>Način plačila</div>
			<div className='print-journal-table-header-cell print-journal-documents-table-cell text-right'>Znesek</div>
		</div>
	</React.Fragment>);
	
	const group_count = 100;
	for (let part_idx=0; part_idx < Math.ceil(sorted_docs.length / group_count); part_idx++) {
		const result = [];
		
		for (let idx=part_idx * group_count; idx < Math.min((part_idx + 1) * group_count, sorted_docs.length); idx++) {
			const doc = sorted_docs[idx];
			
			const id_payment_types = [];
			let   amount           = 0;
			
			if (doc.payment_types !== null) {
				for (let i=0; i<doc.payment_types.length; i++) {
					const payment_type = doc.payment_types[i];
					
					if (id_payment_types.indexOf(payment_type.id_payment_type) == -1) {
						id_payment_types.push(payment_type.id_payment_type);
					}
					
					amount += payment_type.amount;
				}
			}
			
			const payment_type_titles = id_payment_types.map(id_payment_type => payment_types[id_payment_type].title);
			
			const customer = doc.id_customer === null ? null : (customers[doc.id_customer] || null);
			
			const user = users[doc.id_user];
			const user_name    = user.name.split(' ')[0];
			const user_surname = user.surname.length <= 1 ? user.surname : (user.surname.substring(0, 1) + '.');
			
			result.push(<React.Fragment key={'document-' + doc.id_cash_register_document}>
				<div className='print-journal-table-cell print-journal-documents-table-cell'>{ doc.type == 'income' ? 'Prejemek' : 'Izdatek' }</div>
				<div className='print-journal-table-cell print-journal-documents-table-cell'>{ doc.document_number }</div>
				<div className='print-journal-table-cell print-journal-documents-table-cell'>{ doc.reference }</div>
				<div className='print-journal-table-cell print-journal-documents-table-cell'>{ moment(doc.document_date).format('DD.MM.YYYY') }</div>
				<div className='print-journal-table-cell print-journal-documents-table-cell'>{ customer === null ? '' : customer.internal_code }</div>
				<div className='print-journal-table-cell print-journal-documents-table-cell'>{ customer === null ? '' : (customer.type == 'natural' ? (customer.surname + ' ' + customer.name) : customer.company_name) }</div>
				<div className='print-journal-table-cell print-journal-documents-table-cell'>{ user_name + ' ' + user_surname }</div>
				<div className='print-journal-table-cell print-journal-documents-table-cell'>{ payment_type_titles.join(', ') }</div>
				<div className='print-journal-table-cell print-journal-documents-table-cell text-right'>{ formatNumber(amount) }</div>
			</React.Fragment>);
		}
		
		results.push(<div className='print-journal-documents-table font-smaller' key={'document-group-' + part_idx}>
			{ result }
		</div>);
	}
	
	return <div className='print-journal-documents-table-container'>
		{ results }
	</div>;
}
function ManualIncomeList(props) {
	const { documents, payment_types, users, customers } = props;
	
	return <ManualList
		title        ='Prejemki, ki niso vezani na izdane račune'
		documents    ={documents.filter(doc => doc.manual && doc.type == 'income')}
		payment_types={payment_types}
		users        ={users}
		customers    ={customers} />;
}
function ManualOutcomeList(props) {
	const { documents, payment_types, users, customers } = props;
	
	return <ManualList
		title        ='Ročni izdatki iz blagajne'
		documents    ={documents.filter(doc => doc.manual && doc.type == 'expense')}
		payment_types={payment_types}
		users        ={users}
		customers    ={customers} />;
}
function ManualList(props) {
	const { title, documents, payment_types, users, customers } = props;
	
	if (documents.length == 0) return null;
	
	const sorted_docs = documents
		.sort((a, b) => {
			if      (a.created > b.created) return -1;
			else if (a.created < b.created) return  1;
			return 0;
		});
	
	const results = [];
	
	results.push(<React.Fragment key='document-group-header'>
		<div className='font-smaller emphasized-2 mb-05-line'>
			<div>{ title }</div>
		</div>
		<div className='print-journal-manual-documents-table font-smaller'>
			<div className='print-journal-table-header-cell print-journal-manual-documents-table-cell'>Številka</div>
			<div className='print-journal-table-header-cell print-journal-manual-documents-table-cell'>Št. dokumenta</div>
			<div className='print-journal-table-header-cell print-journal-manual-documents-table-cell'>Datum</div>
			<div className='print-journal-table-header-cell print-journal-manual-documents-table-cell'>Šifra</div>
			<div className='print-journal-table-header-cell print-journal-manual-documents-table-cell'>Naziv stranke</div>
			<div className='print-journal-table-header-cell print-journal-manual-documents-table-cell'>Blagajnik</div>
			<div className='print-journal-table-header-cell print-journal-manual-documents-table-cell'>Način plačila</div>
			<div className='print-journal-table-header-cell print-journal-manual-documents-table-cell text-right'>Znesek</div>
		</div>
	</React.Fragment>);
	
	const group_count = 100;
	let   sum_amount  =   0;
	
	for (let part_idx=0; part_idx < Math.ceil(sorted_docs.length / group_count); part_idx++) {
		const result = [];
		
		for (let idx=part_idx * group_count; idx < Math.min((part_idx + 1) * group_count, sorted_docs.length); idx++) {
			const doc = sorted_docs[idx];
			
			const id_payment_types = [];
			let   amount           = 0;
			
			if (doc.payment_types !== null) {
				for (let i=0; i<doc.payment_types.length; i++) {
					const payment_type = doc.payment_types[i];
					
					if (id_payment_types.indexOf(payment_type.id_payment_type) == -1) {
						id_payment_types.push(payment_type.id_payment_type);
					}
					
					amount += payment_type.amount;
				}
			}
			
			const payment_type_titles = id_payment_types.map(id_payment_type => payment_types[id_payment_type].title);
			
			const customer = doc.id_customer === null ? null : (customers[doc.id_customer] || null);
			
			const user = users[doc.id_user];
			const user_name    = user.name.split(' ')[0];
			const user_surname = user.surname.length <= 1 ? user.surname : (user.surname.substring(0, 1) + '.');
			
			sum_amount += amount;
			
			result.push(<React.Fragment key={'document-' + doc.id_cash_register_document}>
				<div className='print-journal-table-cell print-journal-manual-documents-table-cell'>{ doc.document_number }</div>
				<div className='print-journal-table-cell print-journal-manual-documents-table-cell'>{ doc.reference }</div>
				<div className='print-journal-table-cell print-journal-manual-documents-table-cell'>{ moment(doc.document_date).format('DD.MM.YYYY') }</div>
				<div className='print-journal-table-cell print-journal-manual-documents-table-cell'>{ customer === null ? '' : customer.internal_code }</div>
				<div className='print-journal-table-cell print-journal-manual-documents-table-cell'>{ customer === null ? '' : (customer.type == 'natural' ? (customer.surname + ' ' + customer.name) : customer.company_name) }</div>
				<div className='print-journal-table-cell print-journal-manual-documents-table-cell'>{ user_name + ' ' + user_surname }</div>
				<div className='print-journal-table-cell print-journal-manual-documents-table-cell'>{ payment_type_titles.join(', ') }</div>
				<div className='print-journal-table-cell print-journal-manual-documents-table-cell text-right'>{ formatNumber(amount) }</div>
			</React.Fragment>);
		}
		
		results.push(<div className='print-journal-manual-documents-table font-smaller' key={'document-group-' + part_idx}>
			{ result }
		</div>);
		
		results.push(<div key='sum' className='print-journal-manual-documents-table font-smaller'>
			<div className='print-journal-table-cell print-journal-manual-documents-table-cell top-border emphasized-2'>
				SKUPAJ
			</div>
			<div className='print-journal-table-cell print-journal-manual-documents-table-cell top-border emphasized-2'></div>
			<div className='print-journal-table-cell print-journal-manual-documents-table-cell top-border emphasized-2'></div>
			<div className='print-journal-table-cell print-journal-manual-documents-table-cell top-border emphasized-2'></div>
			<div className='print-journal-table-cell print-journal-manual-documents-table-cell top-border emphasized-2'></div>
			<div className='print-journal-table-cell print-journal-manual-documents-table-cell top-border emphasized-2'></div>
			<div className='print-journal-table-cell print-journal-manual-documents-table-cell top-border emphasized-2'></div>
			<div className='print-journal-table-cell print-journal-manual-documents-table-cell top-border emphasized-2 text-right'>
				{ formatNumber(sum_amount) }
			</div>
		</div>);
	}
	
	return <div className='print-journal-manual-documents-table-container'>
		{ results }
	</div>;
}

export default function Journal(props) {
	const {
		company_data,
		items,
		documents,
		documents_for_advance_payments,
		settings,
		lang,
		users,
		cash_register,
		payment_types,
		tax_rates,
		invoices,
		id_advance_invoice_consumption_by_id_consumer_invoices,
		advance_invoice_consumptions,
		customers,
		cash_registers,
		display_detailed_list,
	} = props;
	
	let cls = 'print-document print-journal';
	if (settings.logo_position == 'left') {
		cls += ' reverse-logo';
	}
	else if (settings.logo_position == 'hidden') {
		cls += ' hide-logo';
	}
	
	return <div className={cls}>
		<div className='print-journal-main'>
			<CompanyDetails          lang={lang} company_data={company_data} />
			<Logo                    lang={lang} company_data={company_data} />
			<BasicData items={items} cash_register={cash_register} cash_registers={cash_registers} />
			<IncomeExpenseTable      items={items} documents={documents} payment_types={payment_types} />
			<ManualIncomeList        documents={documents} payment_types={payment_types} users={users} customers={customers} />
			<ManualOutcomeList       documents={documents} payment_types={payment_types} users={users} customers={customers} />
			<CashierTable            documents={documents} users={users} />
			<SalesSpecificationTable documents={documents} tax_rates={tax_rates} />
			<UsedAdvancePaymentsSummaryTable
				documents={documents_for_advance_payments}
				tax_rates={tax_rates}
				id_advance_invoice_consumption_by_id_consumer_invoices={id_advance_invoice_consumption_by_id_consumer_invoices}
				advance_invoice_consumptions={advance_invoice_consumptions}
				invoices={invoices} />
			<UsedAdvancePaymentsTable
				documents={documents_for_advance_payments}
				tax_rates={tax_rates}
				id_advance_invoice_consumption_by_id_consumer_invoices={id_advance_invoice_consumption_by_id_consumer_invoices}
				advance_invoice_consumptions={advance_invoice_consumptions}
				invoices={invoices} />
			{!display_detailed_list ? null :
				<DetailedList documents={documents} payment_types={payment_types} users={users} customers={customers} />
			}
		</div>
		{true ? null : <Footer lang={lang} settings={settings} inline={true} />}
	</div>;
}
