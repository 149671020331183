import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	NumericInput,
	Checkbox,
	HTMLSelect,
} from '@blueprintjs/core';

import TextWithTooltip from '../TextWithTooltip';
import { AppToaster }  from '../AppToaster';

class AddEditPaymentType extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_payment_type:                props.item === null ? -1   : props.item.id_payment_type,
			type:                           props.item === null ? ''   : props.item.type,
			title:                          props.item === null ? ''   : props.item.title,
			visible:                        props.item === null ? true : props.item.visible,
			show_in_cash_register_document: props.item === null ? true : props.item.show_in_cash_register_document,
			ord:                            props.item === null ? 1    : props.item.ord,
			validation_failed_fields: [],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-payment-type-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi način plačila</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='add-edit-payment-type-dialog--type'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('type') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Tip plačila'
								tooltip='Tip plačila' />
							<HTMLSelect
								id='add-edit-payment-type-dialog--type'
								fill={true}
								options={
									[
										{ title: 'Gotovina', value: 'cash'          },
										{ title: 'Kartice',  value: 'credit-card'   },
										{ title: 'TRR',      value: 'bank-transfer' },
										{ title: 'Bon',      value: 'gift-card'     },
										{ title: 'Drugo',    value: 'other'         },
									].map(
										item => {
											return { label: item.title, value: item.value };
										}
									)
								}
								value={this.state.type}
								onChange={(e) => {
									this.setState({
										type: e.currentTarget.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-payment-type-dialog--title'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('title') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Naziv'
								tooltip='Naziv' />
							<InputGroup
								id='add-edit-payment-type-dialog--title'
								value={this.state.title}
								onChange={event => {
									this.setState({
										title: event.target.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-payment-type-dialog--visible'
							className='flex-1'>
							<TextWithTooltip
								label='Aktiven'
								tooltip='Aktiven' />
							<Checkbox checked={this.state.visible} onChange={event => {
								this.setState({ visible: event.target.checked });
							}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-payment-type-dialog--show_in_cash_register_document'
							className='flex-1'>
							<TextWithTooltip
								label='Prikaži na blagajniškem dokumentu'
								tooltip='Prikaži na blagajniškem dokumentu' />
							<Checkbox checked={this.state.show_in_cash_register_document} onChange={event => {
								this.setState({ show_in_cash_register_document: event.target.checked });
							}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-payment-type-dialog--ord'
							className='flex-1'>
							<TextWithTooltip
								label='Vrstni red'
								tooltip='Vrstni red' />
							<NumericInput
								id='add-edit-payment-type-dialog--ord'
								value={this.state.ord}
								min={0}
								minorStepSize={1}
								onValueChange={val => {
									this.setState({
										ord: val,
									});
								}} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = {
									id_payment_type:                this.state.id_payment_type,
									type:                           this.state.type,
									title:                          this.state.title,
									visible:                        this.state.visible,
									show_in_cash_register_document: this.state.show_in_cash_register_document,
									ord:                            this.state.ord,
									serial_internal_code:           this.props.item === null ? null : this.props.item.serial_internal_code,
								};
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (new_item.type.length == 0) {
									validation_failed_fields.push('type');
									validation_failed_field_titles.push('tip plačila');
								}
								if (new_item.title.length == 0) {
									validation_failed_fields.push('title');
									validation_failed_field_titles.push('naziv');
								}
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
										</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								// save item
								this.props.closeAddEditItemDialog(new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditPaymentType.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
};

export default AddEditPaymentType;
