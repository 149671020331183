import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	HTMLSelect,
	Icon,
	InputGroup,
	MenuItem,
	Navbar,
	Checkbox,
	Tabs,
	Tab,
	TextArea,
	Alert,
	Intent,
	Popover,
	Menu,
	Position,
} from '@blueprintjs/core';
import { DateInput, DateRangeInput } from '@blueprintjs/datetime';
import { Suggest } from '@blueprintjs/select';

import moment from 'moment';

import TextWithTooltip                from '../TextWithTooltip';
import CustomerSelect                 from '../CustomerSelect';
import SelectCustomerDialog           from '../reservations/SelectCustomerDialog';
import SelectPriceListDialog          from './SelectPriceListDialog';
import { parseFloatLocal }            from '../../numbers';
import Documents                      from '../Documents';
import InvoiceCreator                 from '../../helpers/InvoiceCreator';
import PriceHelper                    from '../../helpers/PriceHelper';
import SplitPaymentDialog             from './SplitPaymentDialog';
import PrepaymentDocumentSearchDialog from '../PrepaymentDocumentSearchDialog';
import CustomerInfo                   from './CustomerInfo';
import { AppToaster }                 from '../AppToaster';
import InvoiceHelper                  from '../../helpers/Invoice';

import { loadInvoiceFiscalVerification } from '../../api/Business';

class InvoiceItemViewModel {
	constructor(
		single_price_with_vat,
		price_without_vat,
		price_with_vat,
		invoice_item
	) {
		this.single_price_with_vat = single_price_with_vat;
		this.price_without_vat     = price_without_vat;
		this.price_with_vat        = price_with_vat;
		this.invoice_item          = invoice_item;
		
		if (this.invoice_item.approved_percent === undefined) {
			this.invoice_item.approved_percent = 100;
		}
		
		this.quantity_formatted                 = this.formatNumber(
			this.invoice_item.quantity,
			this.invoice_item.item_measuring_unit_decimal_digit_count
		);
		this.discount_formatted              = this.formatNumber(this.invoice_item.discount);
		this.single_price_with_vat_formatted = this.formatNumber(this.single_price_with_vat);
		this.price_without_vat_formatted     = this.formatNumber(this.price_without_vat);
		this.price_with_vat_formatted        = this.formatNumber(this.price_with_vat);
		this.approved_percent_formatted      = this.formatNumber(this.invoice_item.approved_percent);
		
		this.price           = this.price.bind(this);
		//this.priceWithoutVat = this.priceWithoutVat.bind(this);
	}
	
	static FromInvoiceItem(items, invoice_item, tax_rates) {
		const item             = items[invoice_item.id_item];
		const price_list_items = item === undefined ? {} : item.price_lists;
		const price_list       = invoice_item.id_item_price_list === null ? undefined : price_list_items[invoice_item.id_item_price_list];
		const price            = (invoice_item.price !== undefined ? invoice_item.price :
			(price_list === undefined ? 0 : price_list.price));
		
		if (invoice_item.approved_percent === undefined) {
			invoice_item.approved_percent = 100;
		}
		
		const quantity         = invoice_item.quantity;
		const vat              = (price_list === undefined ? 0 : tax_rates[price_list.id_tax_rate].value);
		const discount         = invoice_item.discount;
		const approved_percent = invoice_item.approved_percent;
		
		return new InvoiceItemViewModel(
			Math.properRound(price * (approved_percent / 100), 2),
			Math.properRound(price * quantity * (1 - discount / 100) / (1 + vat / 100) * (approved_percent / 100), 2),
			Math.properRound(price * quantity * (1 - discount / 100) * (approved_percent / 100), 2),
			invoice_item
		);
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	price(priceList) {
		if (this.invoice_item.id_item_price_list === null) return 0;
		return priceList[this.invoice_item.id_item_price_list].price;
	}
	/*priceWithoutVat(priceList, taxRates) {
		if (this.invoice_item.id_item_price_list === null) return 0;
		
		const price_list = priceList[this.invoice_item.id_item_price_list];
		const vat        = (price_list === undefined ? 0 : taxRates[price_list.id_tax_rate].value);
		return Math.properRound(price_list.price / (1 + vat / 100), 2);
	}*/
}

class ItemsList extends Component {
	constructor(props) {
		super(props);
		
		this.setMainTableItemsSort      = this.setMainTableItemsSort     .bind(this);
		this.formatNumber               = this.formatNumber              .bind(this);
		this.addEmptyItemIfNeeded       = this.addEmptyItemIfNeeded      .bind(this);
		this.changeInvoiceItemValue     = this.changeInvoiceItemValue    .bind(this);
		this.openSelectPriceListDialog  = this.openSelectPriceListDialog .bind(this);
		this.closeSelectPriceListDialog = this.closeSelectPriceListDialog.bind(this);
		this.findBestPriceListMatch     = this.findBestPriceListMatch    .bind(this);
		
		this.state = {
			main_table_items_sort_field: '',
			main_table_items_sort_direction: 'ASC',
			select_price_list_dialog_open: false,
			select_price_list_dialog_item: null,
			select_price_list_dialog_idx: -1,
		};
		
		this.main_table_refs = [];
	}
	
	setMainTableItemsSort(field) {}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	addEmptyItemIfNeeded(invoice_items) {
		if (this.props.read_only) return;
		
		if (invoice_items.length == 0 || invoice_items[invoice_items.length - 1].invoice_item.id_item != -1) {
			const item = InvoiceItemViewModel.FromInvoiceItem(this.props.items, this.getEmptyInvoiceItem(), this.props.tax_rates);
			item.quantity = 1;
			invoice_items.push(item);
			
			this.props.setInvoiceItems(invoice_items);
		}
	}
	
	getEmptyInvoiceItem() {
		return {
			id_invoice_item:    -1,
			id_invoice:         -1,
			id_item:            -1,
			id_item_price_list:  null,
			quantity:            1,
			discount:            0,
			approved_percent:    100,
			item_measuring_unit_decimal_digit_count: 0,
			description:         '',
		};
	}
	
	openSelectPriceListDialog(item, idx) {
		this.setState({
			select_price_list_dialog_open: true,
			select_price_list_dialog_item: item,
			select_price_list_dialog_idx:  idx,
		});
	}
	closeSelectPriceListDialog(id_item_price_list) {
		const invoice_items = this.props.invoice_items;
		
		if (id_item_price_list != -1) {
			this.state.select_price_list_dialog_item.id_item_price_list = id_item_price_list;
			
			if (this.state.select_price_list_dialog_idx != -1) {
				this.changeInvoiceItemValue(
					this.state.select_price_list_dialog_item,
					this.state.select_price_list_dialog_idx,
					[ 'invoice_item', 'id_item_price_list' ],
					id_item_price_list
				);
			}
		}
		
		this.setState({
			select_price_list_dialog_open: false,
			select_price_list_dialog_item: null,
			select_price_list_dialog_idx:  -1,
		});
		
		this.props.setInvoiceItems(invoice_items);
	}
	
	changeInvoiceItemValue(item, idx, field_name, val, invoice_items, formatted_field_name, formatted_field_value) {
		invoice_items = [...invoice_items || this.props.invoice_items];
		
		if (Array.isArray(field_name)) {
			invoice_items[idx][field_name[0]][field_name[1]] = val;
		}
		else {
			if (field_name != 'refresh') {
				invoice_items[idx][field_name] = val;
			}
		}
		
		if (field_name == 'refresh') {
			// round quantity to item_measuring_unit_decimal_digit_count
			const quantity            = parseFloat(invoice_items[idx].invoice_item.quantity);
			const decimal_digit_count = parseFloat(invoice_items[idx].invoice_item.item_measuring_unit_decimal_digit_count);
			
			let decimal_factor = 10 * decimal_digit_count;
			if (decimal_factor == 0) decimal_factor = 1;
			
			invoice_items[idx].invoice_item.quantity =
				Math.properRound(quantity, decimal_digit_count);
			
			invoice_items[idx].invoice_item.approved_percent =
				Math.properRound(invoice_items[idx].invoice_item.approved_percent, 2);
		}
		
		if (this.props.invoice_type == 'credit-note') {
			invoice_items[idx].invoice_item.quantity = Math.abs(invoice_items[idx].invoice_item.quantity) * -1;
		}
		
		let vat = 0;
		if (item.invoice_item !== undefined &&
			item.invoice_item.id_item_price_list !== null &&
			item.invoice_item.id_item != -1
		) {
			vat = this.props.tax_rates[
				this.props.items[item.invoice_item.id_item].price_lists[item.invoice_item.id_item_price_list].id_tax_rate
			].value;
		}
		
		invoice_items[idx].quantity_formatted              = invoice_items[idx].invoice_item.quantity;
		invoice_items[idx].discount_formatted              = invoice_items[idx].invoice_item.discount;
		invoice_items[idx].single_price_with_vat_formatted = invoice_items[idx].single_price_with_vat;
		invoice_items[idx].price_with_vat_formatted        = invoice_items[idx].price_with_vat;
		invoice_items[idx].price_without_vat_formatted     = invoice_items[idx].price_without_vat;
		invoice_items[idx].approved_percent_formatted      = invoice_items[idx].invoice_item.approved_percent;
		
		// set new price
		if (
			Array.isArray(field_name)             &&
			field_name.length == 2                &&
			field_name[0] == 'invoice_item'       &&
			field_name[1] == 'id_item_price_list'
		) {
			invoice_items[idx].single_price_with_vat =
				this.props.items[item.invoice_item.id_item].price_lists[item.invoice_item.id_item_price_list].price;
		}
		
		const price = invoice_items[idx].single_price_with_vat;
		
		// calculate discount, if needed
		if (field_name == 'price_without_vat') {
			invoice_items[idx].invoice_item.discount = Math.properRound((
				1 - (val * (1 + vat / 100) / (price * invoice_items[idx].invoice_item.quantity / (invoice_items[idx].invoice_item.approved_percent / 100)))
			) * 100, 6);
		}
		else if (field_name == 'price_with_vat') {
			invoice_items[idx].invoice_item.discount = Math.properRound((
				1 - (val / (price * invoice_items[idx].invoice_item.quantity / (invoice_items[idx].invoice_item.approved_percent / 100)))
			) * 100, 6);
		}
		
		// format discount
		if (formatted_field_name == 'discount_formatted') {
			invoice_items[idx].discount_formatted = formatted_field_value;
		}
		else if (!Array.isArray(field_name) || field_name[1] != 'discount') {
			invoice_items[idx].discount_formatted = this.formatNumber(invoice_items[idx].invoice_item.discount);
		}
		
		// format quantity
		if (formatted_field_name == 'quantity_formatted') {
			invoice_items[idx].quantity_formatted = formatted_field_value;
		}
		else if (!Array.isArray(field_name) || field_name[1] != 'quantity') {
			invoice_items[idx].quantity_formatted = this.formatNumber(
				invoice_items[idx].invoice_item.quantity,
				invoice_items[idx].invoice_item.item_measuring_unit_decimal_digit_count
			);
		}
		
		// calculate and format price with VAT
		if (formatted_field_name == 'price_with_vat_formatted') {
			invoice_items[idx].price_with_vat_formatted = formatted_field_value;
		}
		else if (field_name != 'price_with_vat') {
			invoice_items[idx].price_with_vat =  Math.properRound(
				price * 
				invoice_items[idx].invoice_item.quantity * 
				(1 - invoice_items[idx].invoice_item.discount / 100) *
				(invoice_items[idx].invoice_item.approved_percent / 100)
			, 2);
			invoice_items[idx].price_with_vat_formatted = this.formatNumber(invoice_items[idx].price_with_vat);
		}
		
		// calculate and format price without VAT
		if (formatted_field_name == 'price_without_vat_formatted') {
			invoice_items[idx].price_without_vat_formatted = formatted_field_value;
		}
		else if (field_name != 'price_without_vat') {
			invoice_items[idx].single_price_with_vat =
				price;
			
			invoice_items[idx].price_without_vat = Math.properRound(
				invoice_items[idx].single_price_with_vat /
				(1 + vat / 100) *
				(1 - invoice_items[idx].invoice_item.discount / 100) *
				(invoice_items[idx].invoice_item.approved_percent / 100) *
				invoice_items[idx].invoice_item.quantity
			, 2);
			invoice_items[idx].price_without_vat_formatted = this.formatNumber(invoice_items[idx].price_without_vat);
		}
		
		// format single price with VAT
		if (formatted_field_name == 'single_price_with_vat_formatted') {
			invoice_items[idx].single_price_with_vat_formatted = formatted_field_value;
		}
		else if (!Array.isArray(field_name) || field_name[1] != 'single_price_with_vat') {
			invoice_items[idx].single_price_with_vat_formatted = this.formatNumber(invoice_items[idx].single_price_with_vat);
		}
		
		// format approved percent
		if (formatted_field_name == 'approved_percent_formatted') {
			invoice_items[idx].approved_percent_formatted = formatted_field_value;
		}
		else if (!Array.isArray(field_name) || field_name[1] != 'approved_percent') {
			invoice_items[idx].approved_percent_formatted = this.formatNumber(
				invoice_items[idx].invoice_item.approved_percent
			);
		}
		
		this.props.setInvoiceItems(invoice_items);
	}
	
	findBestPriceListMatch(item) {
		if (item === undefined || item === null || this.props.invoice_date === null) return null;
		
		return PriceHelper.FindBestPriceListMatch(this.props.invoice_date, item.price_lists);
	}
	
	render() {
		let column_idx = 1;
		
		return <div className={
			'items-table ' +
			(this.props.read_only ? 'read-only' : '') +
			' ' +
			(this.props.invoice_type == 'credit-note' ? 'credit-note' : '')
		}>
			{!this.state.select_price_list_dialog_open ? null :
				<SelectPriceListDialog
					closeSelectPriceListDialog={this.closeSelectPriceListDialog}
					priceListItems={this.props.items[this.state.select_price_list_dialog_item.invoice_item.id_item].price_lists}
					item={this.state.select_price_list_dialog_item.invoice_item} />
			}
			
			{ this.props.read_only ?
				<>
					<div className='items-table-header-col col-1' style={{ gridColumn: column_idx++ }}>
						Urejanje
					</div>
					<div className='items-table-header-col col-2' style={{ gridColumn: column_idx++ }}>
						Storitev/blago
					</div>
					<div className='items-table-header-col col-3' style={{ gridColumn: column_idx++ }}>
						Opis
					</div>
					<div className='items-table-header-col col-4' style={{ gridColumn: column_idx++ }}>
						Količina
					</div>
					<div className='items-table-header-col col-5' style={{ gridColumn: column_idx++ }}>
						Cena z DDV
					</div>
					<div className='items-table-header-col col-6' style={{ gridColumn: column_idx++ }}>
						Popust (%)
					</div>
					<div className='items-table-header-col col-7' style={{ gridColumn: column_idx++ }}>
						Znesek brez DDV
					</div>
					<div className='items-table-header-col col-8' style={{ gridColumn: column_idx++ }}>
						DDV (%)
					</div>
					<div className='items-table-header-col col-9' style={{ gridColumn: column_idx++ }}>
						Znesek z DDV
					</div>
					<div className='items-table-header-col col-10' style={{ gridColumn: column_idx++ }}>
						Cenik
					</div>
				</>
				:
				<>
					<div
						className='items-table-header-col col-1'
						style={{ gridColumn: column_idx++ }}>
						Urejanje
					</div>
					<div
						className='items-table-header-col col-2'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('title'); }}>
						Storitev/blago
						{this.state.main_table_items_sort_field != 'title' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col col-3'
						style={{ gridColumn: column_idx++ }}>
						Opis
					</div>
					{this.props.invoice_type != 'credit-note' ? null :
						<div
							className='items-table-header-col col-4 text-center'
							style={{ gridColumn: column_idx++ }}>
							Priznan popust (%)
						</div>
					}
					<div
						className='items-table-header-col col-4 text-center'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('count'); }}>
						Količina
						{this.state.main_table_items_sort_field != 'count' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col col-5'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('single_price_with_vat'); }}>
						Cena z DDV
						{this.state.main_table_items_sort_field != 'single_price_with_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col col-6'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('discount'); }}>
						Popust (%)
						{this.state.main_table_items_sort_field != 'discount' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col col-7'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('price_without_vat'); }}>
						Znesek brez DDV
						{this.state.main_table_items_sort_field != 'price_without_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col col-8'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('vat'); }}>
						DDV (%)
						{this.state.main_table_items_sort_field != 'vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col col-9'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('price_with_vat'); }}>
						Znesek z DDV
						{this.state.main_table_items_sort_field != 'price_with_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col col-10'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('price_list'); }}>
						Cenik
						{this.state.main_table_items_sort_field != 'price_list' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
				</>
			}
			
			{this.props.invoice_items.map((item, idx) => {
				const self = this;
				
				let vat = 0;
				if (item.invoice_item.id_item_price_list !== null && item.invoice_item.id_item != -1) {
					vat = self.props.tax_rates[
						self.props.items[item.invoice_item.id_item].price_lists[item.invoice_item.id_item_price_list].id_tax_rate
					].value;
				}
				
				if (idx >= self.main_table_refs.length) {
					self.main_table_refs.push(React.createRef());
				}
				
				column_idx = 1;
				
				return <React.Fragment key={'invoice-item-' + idx}>
					{ this.props.read_only ?
						<>
							<div className='items-table-col col-1 text-center' style={{ gridColumn: column_idx++ }}>
								<Button
									icon='trash'
									intent='danger'
									minimal={true}
									small={true}
									disabled={this.props.read_only}
									onClick={() => {}} />
							</div>
							<div className='items-table-col col-2' style={{ gridColumn: column_idx++ }}>
								{
									this.props.items[item.invoice_item.id_item].internal_code +
									' • ' +
									this.props.items[item.invoice_item.id_item].title
								}
							</div>
							<div className='items-table-col col-3 description' style={{ gridColumn: column_idx++ }}>
								{ item.invoice_item.description || '' }
							</div>
							{self.props.invoice_type != 'credit-note' ? null :
								<div className='items-table-col col-4' style={{ gridColumn: column_idx++ }}>
									{ item.approved_percent_formatted }
								</div>
							}
							<div className='items-table-col col-4' style={{ gridColumn: column_idx++ }}>
								{ item.quantity_formatted }
							</div>
							<div className='items-table-col col-5' style={{ gridColumn: column_idx++ }}>
								{ item.single_price_with_vat_formatted }
							</div>
							<div className='items-table-col col-6' style={{ gridColumn: column_idx++ }}>
								{ item.discount_formatted }
							</div>
							<div className='items-table-col col-7' style={{ gridColumn: column_idx++ }}>
								{ item.price_without_vat_formatted }
							</div>
							<div className='items-table-col col-8' style={{ gridColumn: column_idx++ }}>
								{ this.formatNumber(vat) }
							</div>
							<div className='items-table-col col-9' style={{ gridColumn: column_idx++ }}>
								{ item.price_with_vat_formatted }
							</div>
							<div className='items-table-col col-10' style={{ gridColumn: column_idx++ }}>
								{
									this.props.items[
										item.invoice_item.id_item
									].price_lists[
										item.invoice_item.id_item_price_list
									].price
								}
							</div>
						</>
						:
						<>
							<div className='items-table-col col-1 text-center' style={{ gridColumn: column_idx++ }}>
								{ item.invoice_item.id_item == -1 ? null :
									<Button
										icon='trash'
										intent='danger'
										minimal={true}
										small={true}
										onClick={() => {
											const invoice_items = [...this.props.invoice_items];
											invoice_items.splice(idx, 1);
											this.props.setInvoiceItems(invoice_items);
										}} />
								}
							</div>
							<div className='items-table-col col-2 tight service-suggest' style={{ gridColumn: column_idx++ }}>
								<Suggest
									itemListPredicate={(query, items) => {
										const trimmed_query = query.trim().toLowerCase();
										if (trimmed_query.length < 2) return [];
										
										const found_items = [];
										const found_ids   = []; // to prevent duplicate items
										
										const ITEM_LIMIT = 20;
										
										// find exact matches
										for (let i=0; i<items.length; i++) {
											const item = items[i];
											
											if (
												found_ids.indexOf(item.id_item) == -1    &&
												Object.keys(item.price_lists).length > 0 &&
												item.internal_code.toLowerCase() == trimmed_query
											) {
												found_items.push(item);
												found_ids.push(item.id_item);
											}
											
											if (found_items.length >= ITEM_LIMIT) {
												break;
											}
										}
										
										// find matches that start with query
										if (found_items.length < ITEM_LIMIT) {
											for (let i=0; i<items.length; i++) {
												const item = items[i];
												
												if (
													found_ids.indexOf(item.id_item) == -1 &&
													Object.keys(item.price_lists).length > 0 &&
													(item.internal_code.toLowerCase().indexOf(trimmed_query) == 0)
												) {
													found_items.push(item);
													found_ids.push(item.id_item);
												}
												
												if (found_items.length >= ITEM_LIMIT) {
													break;
												}
											}
										}
										
										// find inexact matches
										if (found_items.length < ITEM_LIMIT) {
											for (let i=0; i<items.length; i++) {
												const item = items[i];
												
												if (
													found_ids.indexOf(item.id_item) == -1    &&
													Object.keys(item.price_lists).length > 0 &&
													(
														item.title.toLowerCase().indexOf(trimmed_query) > -1 ||
														item.internal_code.toLowerCase().indexOf(trimmed_query) > -1 ||
														(item.ean !== null && item.ean.length > 0 && item.ean.toLowerCase() == trimmed_query)
													)
												) {
													found_items.push(item);
													found_ids.push(item.id_item);
												}
												
												if (found_items.length >= ITEM_LIMIT) {
													break;
												}
											}
										}
										
										return found_items;
									}}
									itemRenderer={(item, { handleClick, modifiers }) => {
										return <MenuItem
											active={modifiers.active}
											key={item.id_item}
											onClick={handleClick}
											text={item.internal_code + ' • ' + item.title}
											label={item.ean} />;
									}}
									items={Object.values(this.props.items)}
									onItemSelect={item => {
										const item_item = self.props.items[item.id_item];
										
										const invoice_items = [...this.props.invoice_items];
										invoice_items[idx].invoice_item.id_item            = item.id_item;
										//TODO change findBestPriceListMatch to base search on proper dates!
										invoice_items[idx].invoice_item.id_item_price_list = self.findBestPriceListMatch(item_item);
										invoice_items[idx].invoice_item.item_measuring_unit_decimal_digit_count =
											self.props.measuring_units[item_item.id_measuring_unit].decimal_digit_count;
										
										invoice_items[idx].invoice_item.description = item.description;
										
										if (invoice_items[idx].invoice_item.id_item_price_list !== null) {
											const price_list = item_item.price_lists[invoice_items[idx].invoice_item.id_item_price_list];
											
											const price            = price_list.price;
											const quantity         = invoice_items[idx].invoice_item.quantity;
											const vat              = self.props.tax_rates[price_list.id_tax_rate].value;
											const discount         = invoice_items[idx].invoice_item.discount;
											const approved_percent = invoice_items[idx].invoice_item.approved_percent;
											
											if (invoice_items[idx].invoice_item.approved_percent === undefined) {
												invoice_items[idx].invoice_item.approved_percent = 100;
											}
											
											invoice_items[idx].single_price_with_vat = 
												Math.properRound(price * (approved_percent / 100), 2);
											invoice_items[idx].price_without_vat = 
												Math.properRound(price * quantity * (1 - discount / 100) / (1 + vat / 100) * (approved_percent / 100), 2);
											invoice_items[idx].price_with_vat = 
												Math.properRound(price * quantity * (1 - discount / 100) * (approved_percent / 100), 2);
											
											invoice_items[idx].discount_formatted =
												this.formatNumber(invoice_items[idx].invoice_item.discount);
											invoice_items[idx].single_price_with_vat_formatted =
												this.formatNumber(invoice_items[idx].single_price_with_vat);
											invoice_items[idx].price_without_vat_formatted =
												this.formatNumber(invoice_items[idx].price_without_vat);
											invoice_items[idx].price_with_vat_formatted =
												this.formatNumber(invoice_items[idx].price_with_vat);
											invoice_items[idx].approved_percent_formatted =
												this.formatNumber(invoice_items[idx].invoice_item.approved_percent);
										}
										
										this.props.setInvoiceItems(invoice_items);
										this.addEmptyItemIfNeeded(invoice_items);
										
										// refresh values
										self.changeInvoiceItemValue(
											invoice_items[idx],
											idx,
											'refresh',
											null,
											invoice_items
										);
										
										if (idx < self.main_table_refs.length &&
											self.main_table_refs[idx] !== undefined &&
											self.main_table_refs[idx].current !== null)
										{
											self.main_table_refs[idx].current.focus();
											setTimeout(() => {
												self.main_table_refs[idx].current.select();
											}, 200);
										}
									}}
									openOnKeyDown={true}
									popoverProps={{
										targetProps: { style: { display: 'block' } },
										minimal: true,
									}}
									fill={true}
									selectedItem={item.invoice_item.id_item == -1 ? null : this.props.items[item.invoice_item.id_item]}
									inputProps={{
										placeholder: 'Išči',
									}}
									inputValueRenderer={item => item.internal_code + ' • ' + item.title}
									resetOnSelect={true} />
							</div>
							<div className='items-table-col col-3 description tight' style={{ gridColumn: column_idx++ }}>
								{item.invoice_item.description || ''}
							</div>
							{self.props.invoice_type != 'credit-note' ? null :
								<div className='items-table-col col-4 tight' style={{ gridColumn: column_idx++ }}>
									<input
										type='text'
										className='text-center'
										value={item.approved_percent_formatted}
										ref={self.main_table_refs[idx]}
										onChange={event => {
											let val = parseFloatLocal(event.target.value);
											if (Object.is(val, NaN)) {
												val = item.invoice_item.approved_percent;
											}
											
											self.changeInvoiceItemValue(
												item,
												idx,
												[ 'invoice_item', 'approved_percent' ],
												val,
												this.props.invoice_items,
												'approved_percent_formatted',
												event.target.value
											);
										}}
										onBlur={event => self.changeInvoiceItemValue(item, idx, 'refresh', null)} />
								</div>
							}
							<div className='items-table-col col-4 tight' style={{ gridColumn: column_idx++ }}>
								<input
									type='text'
									className='text-center'
									value={item.quantity_formatted}
									ref={self.main_table_refs[idx]}
									onChange={event => {
										let val = parseFloatLocal(event.target.value);
										if (Object.is(val, NaN)) {
											val = item.invoice_item.quantity;
										}
										
										self.changeInvoiceItemValue(
											item,
											idx,
											[ 'invoice_item', 'quantity' ],
											val,
											this.props.invoice_items,
											'quantity_formatted',
											event.target.value
										);
									}}
									onBlur={event => self.changeInvoiceItemValue(item, idx, 'refresh', null)} />
							</div>
							<div className='items-table-col col-5 text-right' style={{ gridColumn: column_idx++ }}>
								{this.props.items[item.invoice_item.id_item] === undefined || this.props.items[item.invoice_item.id_item].allow_changing_price !== true ? item.single_price_with_vat_formatted :
									<input
										type='text'
										className='text-right'
										value={item.single_price_with_vat_formatted}
										onChange={event => {
											let val = parseFloatLocal(event.target.value);
											if (Object.is(val, NaN)) {
												val = item.single_price_with_vat;
											}
											
											self.changeInvoiceItemValue(
												item,
												idx,
												'single_price_with_vat',
												val,
												this.props.invoice_items,
												'single_price_with_vat_formatted',
												event.target.value
											);
										}}
										onBlur={event => self.changeInvoiceItemValue(item, idx, 'refresh', null)} />
								}
							</div>
							<div className='items-table-col col-6 tight text-right' style={{ gridColumn: column_idx++ }}>
								<input
									type='text'
									className='text-right'
									value={item.discount_formatted}
									onChange={event => {
										let val = parseFloatLocal(event.target.value);
										if (Object.is(val, NaN)) {
											val = item.invoice_item.discount;
										}
										
										self.changeInvoiceItemValue(
											item,
											idx,
											[ 'invoice_item', 'discount' ],
											val,
											this.props.invoice_items,
											'discount_formatted',
											event.target.value
										);
									}}
									onBlur={event => self.changeInvoiceItemValue(item, idx, 'refresh', null)} />
							</div>
							<div className='items-table-col col-7 tight text-right' style={{ gridColumn: column_idx++ }}>
								<input
									type='text'
									className='text-right'
									value={item.price_without_vat_formatted}
									onChange={event => {
										let val = parseFloatLocal(event.target.value);
										if (Object.is(val, NaN)) {
											val = item.price_without_vat;
										}
										
										self.changeInvoiceItemValue(
											item,
											idx,
											'price_without_vat',
											val,
											this.props.invoice_items,
											'price_without_vat_formatted',
											event.target.value
										);
									}}
									onBlur={event => self.changeInvoiceItemValue(item, idx, 'refresh', null)} />
							</div>
							<div className='items-table-col col-8 text-right' style={{ gridColumn: column_idx++ }}>
								{this.formatNumber(vat)}
							</div>
							<div className='items-table-col col-9 tight text-right' style={{ gridColumn: column_idx++ }}>
								<input
									type='text'
									className='text-right'
									value={item.price_with_vat_formatted}
									onChange={event => {
										let val = parseFloatLocal(event.target.value);
										if (Object.is(val, NaN)) {
											val = item.price_with_vat;
										}
										
										self.changeInvoiceItemValue(
											item,
											idx,
											'price_with_vat',
											val,
											this.props.invoice_items,
											'price_with_vat_formatted',
											event.target.value
										);
									}}
									onBlur={event => self.changeInvoiceItemValue(item, idx, 'refresh', null)} />
							</div>
							<div className='items-table-col col-10' style={{ gridColumn: column_idx++ }}>
								{ item.invoice_item.id_item == -1 ? null :
									<Button
										className='cell-button'
										small={true}
										onClick={() => { this.openSelectPriceListDialog(item, idx); }}>
										{item.invoice_item.id_item_price_list === null ? 'Izberi' :
											this.formatNumber(
												this.props.items[
													item.invoice_item.id_item
												].price_lists[
													item.invoice_item.id_item_price_list
												].price
											)
										}
									</Button>
								}
							</div>
						</>
					}
				</React.Fragment>;
			})}
		</div>;
	}
}

function AdvanceInvoiceList(props) {
	const {
		prepayments,
		customers,
		invoices,
		openDocumentSearchDialog,
		removePrepayment,
	} = props;
	return <div className={'advance-invoice-list ' + (openDocumentSearchDialog === null && removePrepayment === null ? 'without-add-and-remove' : '')}>
		<div className='advance-invoice-list-table'>
			{openDocumentSearchDialog === null && removePrepayment === null ? null :
				<div className='advance-invoice-list-table-header-col col-1'>
					{openDocumentSearchDialog === null ? null :
						<Button
							small={true}
							minimal={true}
							intent='primary'
							className='add-document-button'
							onClick={openDocumentSearchDialog}
							icon='add' />
					}
				</div>
			}
			<div className='advance-invoice-list-table-header-col col-2'>
				Datum
			</div>
			<div className='advance-invoice-list-table-header-col col-3'>
				Št. dokumenta
			</div>
			<div className='advance-invoice-list-table-header-col col-4'>
				Stranka
			</div>
			<div className='advance-invoice-list-table-header-col col-5 text-right'>
				Znesek
			</div>
		</div>
		<div className='advance-invoice-list-table'>
			{prepayments.map(item => {
				const invoice                  = invoices[item.advance_id_invoice];
				const document_date            = invoice.invoice_date;
				const document_document_number = invoice.invoice_number_internal;
				
				let document_amount = item.amount;
				
				switch (invoice.invoice_type) {
					case 'prepayment-invoice':
					case 'cash-prepayment-invoice':
						document_amount = invoice.advance_payment_amount;
						break;
				}
				
				const document_customer = invoice.id_customer === null ? null :
					customers[invoice.id_customer];
				
				return <React.Fragment key={'prepayment-' + item.id_advance_invoice_consumption}>
					{openDocumentSearchDialog === null && removePrepayment === null ? null :
						<div className='advance-invoice-list-table-col col-1'>
							{removePrepayment === null ? null :
								<Button
									small={true}
									minimal={true}
									className='cell-button'
									onClick={() => removePrepayment(invoice.id_invoice)}
									icon='remove' />
							}
						</div>
					}
					<div className='advance-invoice-list-table-col col-2'>
						{document_date === null ? null : moment(document_date).format('DD.MM.YYYY')}
					</div>
					<div className='advance-invoice-list-table-col col-3'>
						{document_document_number}
					</div>
					<div className='advance-invoice-list-table-col col-4'>
						{document_customer === null ? null :
							(document_customer.type == 'natural' ?
								document_customer.surname + ' ' + document_customer.name
								:
								document_customer.company_name
							)
						}
					</div>
					<div className='advance-invoice-list-table-col col-5 text-right'>
						{document_amount === null ? null :
							parseFloat(document_amount)
								.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) +
								' EUR'
						}
					</div>
				</React.Fragment>;
			})}
		</div>
	</div>;
}

class EditInvoiceDialog extends Component {
	constructor(props) {
		super(props);
		
		this.openSelectCustomerDialog   = this.openSelectCustomerDialog  .bind(this);
		this.closeSelectCustomerDialog  = this.closeSelectCustomerDialog .bind(this);
		this.calculateInvoiceSum        = this.calculateInvoiceSum       .bind(this);
		this.calculateRemainingAmount   = this.calculateRemainingAmount  .bind(this);
		this.getItemFromState           = this.getItemFromState          .bind(this);
		this.changeAdvancePaymentValue  = this.changeAdvancePaymentValue .bind(this);
		this.closeAndSaveAsDraft        = this.closeAndSaveAsDraft       .bind(this);
		this.closeAndSave               = this.closeAndSave              .bind(this);
		this.closeWithoutSave           = this.closeWithoutSave          .bind(this);
		this.closeDialog                = this.closeDialog               .bind(this);
		this.convertInvoiceItems        = this.convertInvoiceItems       .bind(this);
		this.closeAndCreateNewInvoice   = this.closeAndCreateNewInvoice  .bind(this);
		this.openSplitPaymentDialog     = this.openSplitPaymentDialog    .bind(this);
		this.closeSplitPaymentDialog    = this.closeSplitPaymentDialog   .bind(this);
		this.hasChanged                 = this.hasChanged                .bind(this);
		this.isDraft                    = this.isDraft                   .bind(this);
		this.canBeSavedAsFinal          = this.canBeSavedAsFinal         .bind(this);
		this.toLocalStringWithTime      = this.toLocalStringWithTime     .bind(this);
		this.openDocumentSearchDialog   = this.openDocumentSearchDialog  .bind(this);
		this.closeDocumentSearchDialog  = this.closeDocumentSearchDialog .bind(this);
		this.removePrepayment           = this.removePrepayment          .bind(this);
		this.updateAdvancePaymentAmountFromPrepayments = this.updateAdvancePaymentAmountFromPrepayments.bind(this);
		this.calculateAdvancePaymentsFromPrepayments   = this.calculateAdvancePaymentsFromPrepayments  .bind(this);
		
		this.texts = {
			title:                '',
			invoice_number_title: '',
			invoice_date_title:   '',
			payment_date_title:   '',
			credit_note_type_title: '',
		};
		
		let first_payment = null;
		let split_payment_types = [];
		if (props.item !== null && props.item.payments !== undefined && props.item.payments !== null) {
			if (props.item.payments.length == 1) {
				first_payment = props.item.payments[0];
			}
			split_payment_types = props.item.payments;
		}
		
		const invoice_number_internal = props.item === null ? '' : props.item.invoice_number_internal || '';
		
		let draft = props.item === null ? true : props.item.draft;
		if (draft === undefined) draft = true;
		
		const invoice_type = props.item === null ? props.type : props.item.invoice_type || props.type;
		
		let read_only = false;
		if (!draft && (invoice_type == 'cash-invoice' || invoice_type == 'cash-prepayment-invoice')) {
			read_only = true;
		}
		
		const prepayments = [];
		if (
			props.selected_advance_invoices_amounts !== undefined &&
			props.selected_advance_invoices_amounts !== null &&
			Object.keys(props.selected_advance_invoices_amounts).length > 0
		) {
			for (let id_invoice in props.selected_advance_invoices_amounts) {
				prepayments.push({
					id_advance_invoice_consumption: uuid.v4(),
					created:                        null,
					updated:                        null,
					advance_id_invoice:             id_invoice,
					consumer_id_invoice:            null,
					amount:                         props.selected_advance_invoices_amounts[id_invoice],
					enabled:                        true,
				});
			}
		}
		else if (props.item !== null) {
			const id_advance_invoice_consumptions = props.id_advance_invoice_consumption_by_id_consumer_invoices[props.item.id_invoice] || [];
			for (let i=0; i<id_advance_invoice_consumptions.length; i++) {
				prepayments.push(props.advance_invoice_consumptions[id_advance_invoice_consumptions[i]]);
			}
		}
		
		this.state = {
			invoice_number:     (props.item === null || props.item.invoice_number === null ? '' : props.item.invoice_number),
			invoice_number_internal,
			id_customer:        (props.item === null ? null       : props.item.id_customer || null),
			invoice_date:       (props.item === null ? new Date() : props.item.invoice_date === null ? null : new Date(props.item.invoice_date)),
			payment_date:       (props.item === null ? null      : props.item.payment_date === null ? null : new Date(props.item.payment_date)),
			paid:               (props.item === null ? false     : props.item.paid),
			service_date_start: (props.item === null ? new Date() : props.item.service_date_start === null ? null : new Date(props.item.service_date_start)),
			service_date_end:   (props.item === null ? new Date() : props.item.service_date_end === null ? null : new Date(props.item.service_date_end)),
			reference:          (props.item === null ? ''        : props.item.reference || ''),
			reservation:        (props.item === null ? ''        : props.item.reservation || ''),
			id_payment_type:    (first_payment === null ? (split_payment_types.length > 0 ? -2 : null) : first_payment.id_payment_type),
			split_payment_types,
			invoice_type,
			vat_type:           '',
			note:               (props.item === null ? ''        : props.item.note || ''),
			credit_note_type:   (props.item === null ? null      : props.item.credit_note_type),
			documents: [],
			invoice_items: this.convertInvoiceItems(props.item, read_only),
			select_customer_dialog_open: false,
			select_customer_dialog_surname: '',
			split_payment_data: [
				{ title: 'Gotovina',   value: 0 },
				{ title: 'Maestro',    value: 0 },
				{ title: 'MasterCard', value: 0 },
				{ title: 'Visa',       value: 0 },
			],
			advance_payment_value:             props.item === null ? 0    : (props.item.advance_payment_amount === undefined ? 0 : props.item.advance_payment_amount),
			advance_payment_value_formatted:   props.item === null ? 0    : this.formatNumber(props.item.advance_payment_amount || 0),
			advance_payment_percent:           props.item !== null ? null : ((props.type == 'prepayment-invoice' || props.type == 'cash-prepayment-invoice') ? 100 : 0),
			advance_payment_percent_formatted: props.item !== null ? 0    : this.formatNumber((props.type == 'prepayment-invoice' || props.type == 'cash-prepayment-invoice') ? 100 : 0),
			close_dialog_save_item: false,
			close_dialog_save_as_draft: false,
			close_dialog_confirm_open: false,
			payment_date_changed: false,
			payment_date_changed_ignore_requested: false,
			payment_date_changed_ignore: false,
			draft,
			reversed: props.item === null ? false : props.item.reversed,
			reverse_invoice: props.item === null ? false : (props.item.reverse_invoice === undefined ? false : props.item.reverse_invoice),
			reverse_invoice_reference: props.item === null ? null : props.item.reverse_invoice_reference,
			original_id_invoice: props.item === null ? '' : (props.item.original_id_invoice === undefined ? '' : props.item.original_id_invoice),
			original_invoice_type: props.item === null ? '' : (props.item.original_invoice_type === undefined ? '' : props.item.original_invoice_type),
			read_only,
			split_payment_dialog_open: false,
			prepayments,
			document_search_dialog_open: false,
		};
		
		if (this.state.invoice_type == 'credit-note' && props.item === null) {
			this.state.payment_date = this.state.payment_date === null ? this.state.invoice_date : props.item.payment_date;
		}
		
		if (this.state.reversed === undefined) {
			this.state.reversed = false;
		}
		
		if (props.item === null || props.item.id_invoice === null || props.item.id_invoice === undefined || this.state.payment_date === null) {
			if (this.state.payment_date === null) {
				if (this.state.invoice_type == 'invoice' || this.state.invoice_type == 'offer') {
					const due_day_count = this.state.id_customer !== null ?
						props.customers[this.state.id_customer].due_day_count
						:
						props.settings.due_day_count;
					this.state.payment_date = moment(this.state.invoice_date).add(due_day_count, 'days').toDate();
				}
				else if (this.state.invoice_type == 'prepayment-invoice' || this.state.invoice_type == 'cash-prepayment-invoice') {
					this.state.payment_date = new Date();
				}
			}
		}
		
		if (this.state.id_payment_type === null) {
			const payment_types = Object.values(props.payment_types);
			if (payment_types.length > 0) {
				this.state.id_payment_type = payment_types[0].id_payment_type;
			}
		}
		
		this.texts.service_date_title = 'Datum storitve';
		this.texts.payment_date_title = 'Datum valute';
		
		if (this.state.invoice_type == 'cash-invoice') {
			this.texts.title                = 'Vnos blagajniškega računa';
			this.texts.invoice_number_title = 'Blagajniški račun št.:';
			this.texts.invoice_date_title   = 'Datum računa';
		}
		else if (this.state.invoice_type == 'invoice') {
			this.texts.title                = 'Vnos računa';
			this.texts.invoice_number_title = 'Račun št.:';
			this.texts.invoice_date_title   = 'Datum fakture';
		}
		else if (this.state.invoice_type == 'prepayment-invoice') {
			this.texts.title                = 'Vnos avansnega računa';
			this.texts.invoice_number_title = 'Avans št.:';
			this.texts.invoice_date_title   = 'Datum avansa';
		}
		else if (this.state.invoice_type == 'credit-note') {
			this.texts.title                = 'Vnos dobropisa';
			this.texts.invoice_number_title = 'Dobropis št.:';
			this.texts.invoice_date_title   = 'Datum dobropisa';
			this.texts.payment_date_title   = 'Datum DDV';
			this.texts.credit_note_type_title = 'Vrsta dobropisa';
		}
		else if (this.state.invoice_type == 'cash-prepayment-invoice') {
			this.texts.title                = 'Vnos računa za predplačilo';
			this.texts.invoice_number_title = 'Račun za predplačilo št.:';
			this.texts.invoice_date_title   = 'Datum računa';
		}
		else if (this.state.invoice_type == 'offer') {
			this.texts.title                = 'Vnos ponudbe';
			this.texts.invoice_number_title = 'Ponudba št.:';
			this.texts.invoice_date_title   = 'Datum ponudbe';
			this.texts.payment_date_title   = 'Datum veljavnosti';
			this.texts.service_date_title   = 'Datum storitve/termin';
		}
		
		if (
			props.item !== null &&
			props.item.id_invoice_fiscal_verification !== null &&
			props.item.id_invoice_fiscal_verification !== undefined &&
			props.invoice_fiscal_verifications[props.item.id_invoice_fiscal_verification] === undefined
		) {
			loadInvoiceFiscalVerification(
				props.api_url,
				props.item.id_invoice_fiscal_verification,
				props.dispatch,
				props.token,
			);
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.invoice_items != prevState.invoice_items) {
			// if an item was deleted, focus new/empty item
			if (this.state.invoice_items.length < prevState.invoice_items.length) {
				const service_suggest_inputs = document.querySelectorAll('.service-suggest input');
				if (service_suggest_inputs.length > 0) {
					service_suggest_inputs[service_suggest_inputs.length - 1].focus();
				}
			}
		}
		
		this.updateAdvancePaymentAmountFromPrepayments();
	}
	
	componentDidMount() {
		if (this.state.invoice_type == 'cash-invoice') {
			setTimeout(() => {
				const service_suggest_inputs = document.querySelectorAll('.service-suggest input');
				if (service_suggest_inputs.length > 0) {
					service_suggest_inputs[0].focus();
				}
			}, 1000);
		}
		
		this.updateAdvancePaymentAmountFromPrepayments();
	}
	
	hasChanged() {
		if (this.props.item === null || this.props.item === undefined) {
			return true;
		}
		
		if (this.props.item.id_customer != this.state.id_customer) return true;
		
		let first_payment = null;
		let split_payment_types = [];
		if (this.props.item !== null && this.props.item.payments !== undefined && this.props.item.payments !== null) {
			if (this.props.item.payments.length == 1) {
				first_payment = this.props.item.payments[0];
			}
			split_payment_types = this.props.item.payments;
		}
		
		if (this.state.id_payment_type == -2) {
			if (first_payment !== null) return true;
			
			if (split_payment_types.length != this.state.split_payment_types.length) return true;
			
			let found_diff = false;
			for (let i=0; i<split_payment_types.length; i++) {
				let found = false;
				
				for (let j=0; j<this.state.split_payment_types.length; j++) {
					if (
						this.state.split_payment_types[j].id_payment_type == split_payment_types[i].id_payment_type &&
						this.state.split_payment_types[j].amount          == split_payment_types[i].amount
					) {
						found = true;
						break;
					}
				}
				
				if (!found) {
					found_diff = true;
					break;
				}
			}
			if (found_diff) return true;
		}
		else if (
			first_payment === null ||
			(first_payment !== null && this.state.id_payment_type != first_payment.id_payment_type)
		) {
			return true;
		}
		
		return false;
	}
	isDraft() {
		return this.state.invoice_number.length == 0;
	}
	isCashAndCanBeSaved() {
		// must have cash register selected to be able to save cash invoice and cash prepayment invoice
		if ([ 'cash-invoice', 'cash-prepayment-invoice' ].indexOf(this.state.invoice_type) != -1) {
			if (this.props.current_id_cash_register === null || this.props.current_id_cash_register === undefined || this.props.current_id_cash_register === 0) {
				return false;
			}
		}
		return true;
	}
	canBeSavedAsFinal() {
		return this.state.invoice_number.length == 0 || [ 'cash-invoice', 'cash-prepayment-invoice' ].indexOf(this.state.invoice_type) == -1;
	}
	
	getEmptyInvoiceItem() {
		return {
			id_invoice_item:    -1,
			id_invoice:         -1,
			id_item:            -1,
			id_item_price_list:  null,
			quantity:            1,
			discount:            0,
			approved_percent:    100,
			item_measuring_unit_decimal_digit_count: 0,
			description:         '',
		};
	}
	
	convertInvoiceItems(item, read_only) {
		const items = [];
		
		if (item !== null) {
			for (let i=0; i<item.items.length; i++) {
				items.push(InvoiceItemViewModel.FromInvoiceItem(this.props.items, {...item.items[i]}, this.props.tax_rates));
			}
		}
		
		if (!read_only) {
			const empty_invoice_item_view = InvoiceItemViewModel.FromInvoiceItem(this.props.items, this.getEmptyInvoiceItem(), this.props.tax_rates);
			empty_invoice_item_view.invoice_item.quantity = 1;
			
			items.push(empty_invoice_item_view);
		}
		
		return items;
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
			parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
		}
	}
	
	getMaxDate() {
		const date = new Date();
		date.setFullYear(date.getFullYear() + 10);
		return date;
	}
	
	openSelectCustomerDialog(query, item) {
		item = item || null;
		
		this.setState({
			select_customer_dialog_open: true,
			select_customer_dialog_surname: query,
			select_customer_dialog_item: item,
		});
	}
	closeSelectCustomerDialog(id_customer, new_customer) {
		const state = {
			select_customer_dialog_open: false,
		};
		if (id_customer !== undefined) {
			state.id_customer = id_customer;
		}
		
		this.setState(state);
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	calculateInvoiceSum(round) {
		round = round === undefined ? true : false;
		
		const sum = this.state.invoice_items.reduce(
			(acc, item) => acc +
				parseFloat(item.single_price_with_vat) *
				parseFloat(item.invoice_item.quantity) *
				(1 - parseFloat(item.invoice_item.discount) / 100),
			0);
		if (round) {
			return Math.properRound(sum, 2);
		}
		return sum;
	}
	calculateRemainingAmount() {
		const total_sum   = this.calculateInvoiceSum(false);
		const advance_sum = (
			this.state.advance_payment_value !== null ?
				this.state.advance_payment_value
				:
				total_sum * (this.state.advance_payment_percent / 100)
		);
		
		return total_sum - advance_sum;
	}
	calculateRemainingAmountForPayment() {
		const total_sum   = this.calculateInvoiceSum();
		const advance_sum = (
			this.state.advance_payment_value !== null ?
				this.state.advance_payment_value
				:
				total_sum * (this.state.advance_payment_percent / 100)
		);
		
		if (this.state.invoice_type == 'prepayment-invoice' || this.state.invoice_type == 'cash-prepayment-invoice') {
			return advance_sum;
		}
		return total_sum - advance_sum;
	}
	
	getItemFromState(save_as_draft) {
		const invoice_amount = this.calculateInvoiceSum();
		const advance_payment_amount =
			this.state.advance_payment_value !== null ?
				this.state.advance_payment_value
				:
				invoice_amount * (this.state.advance_payment_percent / 100);
		
		const selected_advance_invoices_amounts = {};
		for (let i=0; i<this.state.prepayments.length; i++) {
			const prepayment = this.state.prepayments[i];
			if (
				(this.state.reverse_invoice === true  && prepayment.amount < 0) ||
				(this.state.reverse_invoice === false && prepayment.amount > 0)
			) {
				selected_advance_invoices_amounts[prepayment.advance_id_invoice] = prepayment.amount;
			}
		}
		
		if (
			[ 'prepayment-invoice', 'cash-prepayment-invoice' ].indexOf(this.state.invoice_type) != -1 &&
			this.state.original_id_invoice != '' &&
			[ 'prepayment-invoice', 'cash-prepayment-invoice' ].indexOf(this.state.original_invoice_type) != -1
		) {
			selected_advance_invoices_amounts[this.state.original_id_invoice] = -advance_payment_amount;
		}
		
		let original_id_invoice   = this.state.original_id_invoice;
		let original_invoice_type = this.state.original_invoice_type;
		
		// add references to prepayment invoices
		if (Array.isArray(original_id_invoice) && Array.isArray(original_invoice_type)) {
			for (let id_invoice in selected_advance_invoices_amounts) {
				if (original_id_invoice.indexOf(id_invoice) == -1) {
					original_id_invoice  .push(id_invoice);
					original_invoice_type.push(this.props.invoices[id_invoice].invoice_type);
				}
			}
		}
		
		let id_cash_register = (
			this.props.item === null || this.props.item.id_cash_register === null || this.props.item.id_cash_register === undefined
				?
				this.props.current_id_cash_register
				:
				this.props.item.id_cash_register
		);
		
		// override id cash register with currently selected one for draft
		if (this.props.item !== null && this.props.item.draft) {
			id_cash_register = this.props.current_id_cash_register;
		}
		
		const item = InvoiceCreator.CreateInvoiceFromInitialData({
			id_invoice:         this.props.item === null ? uuid.v4() : this.props.item.id_invoice,
			invoice_number:     this.props.item === null ? null : this.props.item.invoice_number,
			invoice_number_internal: this.props.item === null ? null : this.props.item.invoice_number_internal,
			invoice_type:       this.props.item === null ? this.props.type : this.props.item.invoice_type,
			id_customer:        this.state.id_customer,
			invoice_date:       this.state.invoice_date === null ? null : this.toIsoString(this.state.invoice_date),
			payment_date:       this.state.payment_date === null ? null : this.toIsoString(this.state.payment_date),
			service_date_start: this.state.service_date_start === null ? null : this.toIsoString(this.state.service_date_start),
			service_date_end:   this.state.service_date_end === null   ? null : this.toIsoString(this.state.service_date_end),
			reference:          this.state.reference,
			reservation:        this.state.reservation,
			paid:               this.state.paid,
			items: this.state.invoice_items.filter(item => item.invoice_item.id_item != -1).map((item, item_idx) => {
				const item_item       = this.props.items[item.invoice_item.id_item];
				const item_price_list = item_item.price_lists[item.invoice_item.id_item_price_list];
				const tax_rate        = this.props.tax_rates[item_price_list.id_tax_rate];
				const measuring_unit  = this.props.measuring_units[item_item.id_measuring_unit];
				
				const id_invoice_item = item.invoice_item.id_invoice_item == -1 ?
					uuid.v4() : item.invoice_item.id_invoice_item;
				
				return InvoiceCreator.CreateInvoiceItemFromInitialData({
					id_invoice_item:                         id_invoice_item,
					id_item:                                 item.invoice_item.id_item,
					id_item_price_list:                      item.invoice_item.id_item_price_list,
					quantity:                                item.invoice_item.quantity,
					discount:                                item.invoice_item.discount,
					item_type:                               item_item.type,
					item_measuring_unit_code:                measuring_unit.title,
					item_measuring_unit_decimal_digit_count: measuring_unit.decimal_digit_count,
					item_ean:                                item_item.ean,
					item_title:                              item_item.title,
					price:                                   item.single_price_with_vat,
					id_tax_rate:                             item_price_list.id_tax_rate,
					tax_rate:                                tax_rate.value,
					description:                             item.invoice_item.description,
					approved_percent:                        item.invoice_item.approved_percent,
					ord:                                     item_idx,
					taxable:                                 tax_rate.taxable,
					item_internal_code:                      item_item.internal_code,
					id_warehouse: item_item.type != 'item' ? null : InvoiceHelper.findCurrentIdWarehouse(
						id_cash_register === undefined || id_cash_register === null ? null :
							this.props.cash_registers[id_cash_register],
						this.props.warehouses
					),
				});
			}),
			eor:            this.props.item === null ? '' : this.props.item.eor,
			zoi:            this.props.item === null ? '' : this.props.item.zoi,
			//documents:      [],
			note: 			this.state.note,
			invoice_amount,
			advance_payment_amount,
			username:       this.props.user.username,
			draft:          save_as_draft,
			reversed:       this.state.reversed,
			reverse_invoice: this.state.reverse_invoice,
			reverse_invoice_reference: this.state.reverse_invoice_reference,
			original_id_invoice,
			original_invoice_type,
			id_cash_register,
			selected_advance_invoices_amounts,
			credit_note_type: this.state.credit_note_type == '' ? null : this.state.credit_note_type,
		});
		
		if (item.id_invoice === null || item.id_invoice === undefined) {
			item.id_invoice = uuid.v4();
		}
		
		if (this.state.id_payment_type == -2) { // split payment
			for (let i=0; i<this.state.split_payment_types.length; i++) {
				item.payments.push({
					id_invoice_payment: this.state.split_payment_types[i].id_invoice_payment,
					id_payment_type:    this.state.split_payment_types[i].id_payment_type,
					amount:             this.state.split_payment_types[i].amount,
					
					//TODO should we overwrite payment date on existing payment with new one?
					payment_date:       this.toIsoString(new Date()), // today
				});
			}
		}
		else {
			let existing_id_invoice_payment = null;
			for (let i=0; i<this.state.split_payment_types.length; i++) {
				if (this.state.split_payment_types[i].id_payment_type == this.state.id_payment_type) {
					existing_id_invoice_payment = this.state.split_payment_types[i].id_invoice_payment;
					break;
				}
			}
			
			item.payments.push({
				id_invoice_payment: existing_id_invoice_payment === null ? uuid.v4() : existing_id_invoice_payment,
				id_payment_type:    this.state.id_payment_type,
				amount:             this.calculateRemainingAmountForPayment(),
				
				//TODO should we overwrite payment date on existing payment with new one?
				payment_date:       this.toIsoString(new Date()), // today
			});
		}
		
		const customer = item.id_customer === null ? null : this.props.customers[item.id_customer];
		
		item.customer_id_country        = customer === null ? null : customer.id_country;
		item.customer_type              = customer === null ? null : customer.type;
		item.customer_address           = customer === null ? null : customer.address;
		item.customer_birth_date        = customer === null ? null : customer.birth_date;
		item.customer_company_long_name = customer === null ? null : customer.company_long_name;
		item.customer_company_name      = customer === null ? null : customer.company_name;
		item.customer_email             = customer === null ? null : customer.email;
		item.customer_name              = customer === null ? null : customer.name;
		item.customer_surname           = customer === null ? null : customer.surname;
		item.customer_phone             = customer === null ? null : customer.phone;
		item.customer_post_code         = customer === null ? null : customer.post_code;
		item.customer_post_office       = customer === null ? null : customer.post_office;
		item.customer_sex               = customer === null ? null : customer.sex;
		item.customer_tax_number        = customer === null ? null : customer.tax_number;
		item.customer_vat_prefix        = customer === null ? null : customer.vat_prefix;
		item.customer_vat_registered    = customer === null ? null : customer.vat_registered;
		item.customer_display_company_long_name_on_invoices = customer === null ? null : customer.display_company_long_name_on_invoices;
		item.customer_identification_number = customer === null ? null : customer.identification_number;
		item.customer_internal_code   = customer === null ? null : customer.internal_code;
		
		return item;
	}
	
	changeAdvancePaymentValue(field_name, value, formatted_value) {
		if (field_name == 'refresh') {
			const should_cap_at_max =
				(this.state.invoice_type == 'prepayment-invoice' || this.state.invoice_type == 'cash-prepayment-invoice') &&
				this.props.item !== null && this.props.item !== undefined &&
				this.props.item.max_advance_payment_amount !== null && this.props.item.max_advance_payment_amount !== undefined;
			
			if (this.state.advance_payment_value !== null) {
				let val = Math.properRound(this.state.advance_payment_value, 2);
				if (should_cap_at_max && val < -this.props.item.max_advance_payment_amount) {
					val = -this.props.item.max_advance_payment_amount;
				}
				
				this.setState({
					advance_payment_value:           val,
					advance_payment_value_formatted: this.formatNumber(val),
				});
			}
			else {
				let   val         = Math.properRound(this.state.advance_payment_percent, 2);
				const sum         = this.calculateInvoiceSum(false);
				const max_percent = (this.props.item === null || this.props.item.undefined ? 0 :
					-this.props.item.max_advance_payment_amount / sum * 100);
				
				if (should_cap_at_max && val > max_percent) {
					val = Math.properRound(max_percent, 2);
				}
				
				this.setState({
					advance_payment_percent:           val,
					advance_payment_percent_formatted: this.formatNumber(val),
				});
			}
		}
		else if (field_name == 'advance_payment_value') {
			this.setState({
				advance_payment_value:             value,
				advance_payment_value_formatted:   formatted_value,
				advance_payment_percent:           null,
				advance_payment_percent_formatted: 0,
			});
		}
		else if (field_name == 'advance_payment_percent') {
			this.setState({
				advance_payment_value:             null,
				advance_payment_value_formatted:   0,
				advance_payment_percent:           value,
				advance_payment_percent_formatted: formatted_value,
			});
		}
	}
	
	recalculateTable(invoice_items) {
		invoice_items = invoice_items || this.state.invoice_items;
		
		
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	closeAndSaveAsDraft() {
		this.closeDialog(true, true);
	}
	closeAndSave() {
		this.closeDialog(true, false);
	}
	closeWithoutSave(confirm) {
		this.closeDialog(false, false, confirm);
	}
	closeAndCreateNewInvoice(invoice_type) {
		this.closeDialog(true, false, false, true, invoice_type);
	}
	closeDialog(save, save_as_draft, confirm, payment_date_ignore_changed, open_create_invoice_type) {
		if (confirm) {
			this.setState({
				close_dialog_save_item:     save,
				close_dialog_save_as_draft: save_as_draft,
				close_dialog_confirm_open:  true,
			});
			return;
		}
		
		if (!save) {
			this.props.closeEditInvoiceDialog(undefined, open_create_invoice_type, !save_as_draft);
			return;
		}
		
		if ([
			'invoice',
			'prepayment-invoice',
			'credit-note',
			'offer',
		].indexOf(this.state.invoice_type) != -1) {
			if (!save_as_draft && (this.state.id_customer === null || this.state.id_customer === -1)) {
				AppToaster.show({
					message: <div>
						Napake na dokumentu:
						<br />
						Stranka ni izbrana
					</div>,
					intent: 'danger',
					icon:   'issue',
				});
				return;
			}
		}
		
		if (this.state.invoice_type == 'prepayment-invoice') {
			//TODO only do this on new invoices?
			if (!this.state.payment_date_changed  && !this.state.payment_date_changed_ignore && !payment_date_ignore_changed) {
				this.setState({
					close_dialog_save_item:                save,
					close_dialog_save_as_draft:            save_as_draft,
					payment_date_changed_ignore_requested: true,
				});
				return;
			}
		}
		
		const item = this.getItemFromState(save_as_draft);
		//TODO validation
		
		if (item.items.length == 0) {
			AppToaster.show({
				message: <div>
					Napake na dokumentu:
					<br />
					Dokument ne sme biti prazen
				</div>,
				intent: 'danger',
				icon:   'issue',
			});
			return;
		}
		
		if (
			(this.state.invoice_type == 'prepayment-invoice' || this.state.invoice_type == 'cash-prepayment-invoice') &&
			item.advance_payment_amount == 0
		) {
			AppToaster.show({
				message: <div>
					Napake na dokumentu:
					<br />
					Znesek predplačila ne sme biti prazen
				</div>,
				intent: 'danger',
				icon:   'issue',
			});
			return;
		}
		
		if (this.state.invoice_type == 'credit-note' && (item.credit_note_type === null || item.credit_note_type == '')) {
			AppToaster.show({
				message: <div>
					Napake na dokumentu:
					<br />
					Vrsta dobropisa ne sme biti prazna
				</div>,
				intent: 'danger',
				icon:   'issue',
			});
			return;
		}
		
		this.props.closeEditInvoiceDialog(item, open_create_invoice_type, !save_as_draft);
	}
	
	openSplitPaymentDialog() {
		this.setState({
			split_payment_dialog_open: true,
		});
	}
	closeSplitPaymentDialog(payments) {
		this.setState({
			split_payment_dialog_open: false,
		});
		
		if (payments !== undefined) {
			for (let i=0; i<payments.length; i++) {
				if (payments[i].id_invoice_payment === null) {
					payments[i].id_invoice_payment = uuid.v4();
				}
				if (payments[i].payment_date === null) {
					payments[i].payment_date = this.toIsoString(new Date()); // today
				}
			}
			
			this.setState({ split_payment_types: payments });
		}
	}
	
	toLocalStringWithTime(date) {
		if (date === null) return '';
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear() + ' ' +
			date.getHours().toString().padStart(2, '0') + ':' +
			date.getMinutes().toString().padStart(2, '0') + ':' +
			date.getSeconds().toString().padStart(2, '0');
	}
	
	updateAdvancePaymentAmountFromPrepayments() {
		// only update if this is a draft
		if (!this.canBeSavedAsFinal()) return;
		
		// try to match advance payment amount to invoice sum, if possible (e.g. there are enough prepayments)
		const invoice_sum = this.calculateInvoiceSum(false);
		if (
			this.state.invoice_type          != 'prepayment-invoice'      && // manual advance payment amount
			this.state.invoice_type          != 'cash-prepayment-invoice' && // manual advance payment amount
			this.state.advance_payment_value !== null                     &&
			this.state.advance_payment_value != invoice_sum
		) {
			const { advance_amount, prepayments } = this.calculateAdvancePaymentsFromPrepayments(invoice_sum);
			
			if (this.state.advance_payment_value != advance_amount) {
				this.setState({
					advance_payment_value: advance_amount,
					prepayments,
				});
			}
		}
	}
	calculateAdvancePaymentsFromPrepayments(invoice_amount) {
		const prepayments = [];
		
		const prepayment_invoices_sorted_by_date = this.state.prepayments
			.map(x => { return {...this.props.invoices[x.advance_id_invoice], advance_payment_amount: x.amount }; })
			.sort((a, b) => {
				if      (a.created < b.created) return -1;
				else if (a.created > b.created) return  1;
				return 0;
			});
		
		let advance_amount = 0;
		for (let i=0; i<prepayment_invoices_sorted_by_date.length; i++) {
			const invoice = prepayment_invoices_sorted_by_date[i];
			let amount = 0;
			if (this.state.reverse_invoice === true) {
				amount = Math.min(0, parseFloat(invoice.advance_payment_amount));
				
				// clamp to invoice amount
				amount = Math.max(advance_amount + amount, invoice_amount) - advance_amount;
			}
			else {
				amount = Math.max(0, parseFloat(invoice.advance_payment_amount));
				
				// clamp to invoice amount
				amount = Math.min(advance_amount + amount, invoice_amount) - advance_amount;
			}
			
			prepayments.push({
				id_advance_invoice_consumption: uuid.v4(), //TODO combine with existing one, if it exists
				created:                        null,
				updated:                        null,
				advance_id_invoice:             invoice.id_invoice,
				consumer_id_invoice:            null,
				amount,
				enabled:                        true,
			});
			advance_amount += amount;
		}
		
		return { advance_amount, prepayments };
	}
	
	openDocumentSearchDialog() {
		this.setState({
			document_search_dialog_open: true,
		});
	}
	closeDocumentSearchDialog(id_document, document_type) {
		this.setState({
			document_search_dialog_open: false,
		});
		
		if (id_document !== undefined && document_type !== undefined) {
			const prepayments = this.state.prepayments;
			
			let found = false;
			for (let i=0; i<prepayments.length; i++) {
				if (prepayments[i].advance_id_invoice == id_document) {
					found = true;
					break;
				}
			}
			
			if (!found) {
				const item = this.props.invoices[id_document];
				
				prepayments.push({
					id_advance_invoice_consumption: uuid.v4(),
					created:                        null,
					updated:                        null,
					advance_id_invoice:             id_document,
					consumer_id_invoice:            null,
					amount:                         Math.max(0, parseFloat(item.advance_payment_amount) - parseFloat(item.used_advance_payment_amount)),
					enabled:                        true,
				});
				
				this.setState({ prepayments });
				this.updateAdvancePaymentAmountFromPrepayments();
			}
		}
	}
	removePrepayment(id_invoice) {
		const prepayments = this.state.prepayments;
		
		let found_idx = -1;
		for (let i=0; i<prepayments.length; i++) {
			if (prepayments[i].advance_id_invoice == id_invoice) {
				found_idx = i;
				break;
			}
		}
		
		if (found_idx > -1) {
			prepayments.splice(found_idx, 1);
			
			this.setState({ prepayments });
			this.updateAdvancePaymentAmountFromPrepayments();
		}
	}
	
	render() {
		const customer = (this.state.id_customer === null ? null : (this.props.customers[this.state.id_customer]) || null);
		
		const invoice_fiscal_verification =
			this.props.item === null ||
			this.props.item.id_invoice_fiscal_verification === null ||
			this.props.item.id_invoice_fiscal_verification === undefined
			? null
			: this.props.invoice_fiscal_verifications[this.props.item.id_invoice_fiscal_verification];
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={!this.state.select_customer_dialog_open}
			onClose={() => this.closeWithoutSave(true)}
			className='edit-invoice-dialog'>
			<div className='flex flex-col flex-grow'>
				{!this.state.select_customer_dialog_open ? null :
					<SelectCustomerDialog
						closeSelectCustomerDialog={this.closeSelectCustomerDialog}
						surname={this.state.select_customer_dialog_surname}
						item={null} />
				}
				
				{!this.state.close_dialog_confirm_open ? null :
					<Alert
						cancelButtonText='Prekliči'
						confirmButtonText='Zapri'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='help'
						intent={Intent.PRIMARY}
						isOpen={true}
						onConfirm={() => {
							this.closeDialog(
								this.state.close_dialog_save_item,
								this.state.close_dialog_save_as_draft,
								false
							);
							this.setState({
								close_dialog_save_item:     false,
								close_dialog_save_as_draft: false,
								close_dialog_confirm_open:  false,
							});
						}}
						onCancel={() => {
							this.setState({
								close_dialog_save_item:     false,
								close_dialog_save_as_draft: false,
								close_dialog_confirm_open:  false,
							});
						}}>
						Res želite zapreti brez shranjevanja?
					</Alert>
				}
				
				{!this.state.payment_date_changed_ignore_requested ? null :
					<Alert
						cancelButtonText='Prekliči'
						confirmButtonText='Da'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='help'
						intent={Intent.PRIMARY}
						isOpen={true}
						onConfirm={() => {
							this.closeDialog(
								this.state.close_dialog_save_item,
								this.state.close_dialog_save_as_draft,
								false,
								true
							);
							this.setState({
								payment_date_changed_ignore_requested: false,
								payment_date_changed_ignore: true,
							});
						}}
						onCancel={() => {
							this.setState({
								payment_date_changed_ignore_requested: false,
								payment_date_changed_ignore: false,
							});
						}}>
						Datum plačila ni bil spremenjen. Vseeno nadaljujem?
					</Alert>
				}
				
				{!this.state.split_payment_dialog_open ? null :
					<SplitPaymentDialog
						payments={this.state.split_payment_types}
						total_value={this.calculateRemainingAmountForPayment()}
						closeDialog={this.closeSplitPaymentDialog} />
				}
				
				{!this.state.select_customer_dialog_open ? null :
					<SelectCustomerDialog
						closeSelectCustomerDialog={this.closeSelectCustomerDialog}
						surname={this.state.select_customer_dialog_surname}
						item={this.state.select_customer_dialog_item} />
				}
				
				{!this.state.document_search_dialog_open ? null :
					<PrepaymentDocumentSearchDialog
						closeDialog={this.closeDocumentSearchDialog} />
				}
				
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>{ this.texts.title }</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						{
							[ 'offer', 'prepayment-invoice' ].indexOf(this.state.invoice_type) == -1 ||
							this.props.item === null ||
							this.props.item.invoice_number == '' ?
							null
							:
							(this.state.draft ?
								<Button
									intent='success'
									icon='inheritance'
									className='w-32 mr-2'
									disabled={true}
									text='Izdelaj ...' />
								:
								<Popover content={
									this.state.invoice_type == 'offer' ?
										<Menu>
											<MenuItem onClick={() => this.closeAndCreateNewInvoice('prepayment-invoice')}      text="Avansni račun" />
											<MenuItem onClick={() => this.closeAndCreateNewInvoice('cash-invoice')}            text="Blagajniški račun" disabled={this.props.current_id_cash_register == 0} />
											<MenuItem onClick={() => this.closeAndCreateNewInvoice('cash-prepayment-invoice')} text="Račun za predplačilo" disabled={true || this.props.current_id_cash_register == 0} />
											<MenuItem onClick={() => this.closeAndCreateNewInvoice('invoice')}                 text="Fakturo" />
										</Menu>
										:
										<Menu>
											<MenuItem onClick={() => this.closeAndCreateNewInvoice('cash-invoice')}            text="Blagajniški račun" disabled={this.props.current_id_cash_register == 0} />
											<MenuItem onClick={() => this.closeAndCreateNewInvoice('invoice')}                 text="Fakturo" />
										</Menu>
								} position={Position.BOTTOM}>
									<Button
										intent='success'
										icon='inheritance'
										className='w-32 mr-2'
										text='Izdelaj ...' />
								</Popover>
							)
						}
						
						<Button intent='primary' icon='floppy-disk' onClick={() => this.closeAndSaveAsDraft()} className='w-48' disabled={!this.isDraft()}>
							Shrani kot osnutek
						</Button>
						<Button intent='success' icon='tick' onClick={() => this.closeAndSave()} className='w-48 ml-2' disabled={!this.isCashAndCanBeSaved() || (!this.canBeSavedAsFinal() && !this.hasChanged())}>
							Zapri in zaključi
						</Button>
						<Button intent='danger' icon='cross' onClick={() => this.closeWithoutSave(true)} className='w-48 ml-10'>
							Zapri brez shranjevanja
						</Button>
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-col'>
						<div className='invoice-header'>
							<div className='invoice-number'>
								<h3 className='bp3-heading'>
									<span className='font-thin'>{this.texts.invoice_number_title} </span>
									{this.state.draft ? 'osnutek' : this.state.invoice_number_internal}
								</h3>
							</div>
							<div className='customer'>
								<CustomerSelect
									itemSelected={item => {
										this.setState({
											id_customer: item.id_customer,
										});
									}}
									addPersonClick={query => { this.openSelectCustomerDialog(query); }}
									fieldTitle='Stranka'
									formGroupCls='mb-0'
									focus={this.state.invoice_type != 'cash-invoice'} />
								
								<CustomerInfo
									customer={customer}
									countries={this.props.countries}
									editClick={() => {
										this.openSelectCustomerDialog('', customer);
									}}
									clearClick={() => {
										this.setState({ id_customer: null });
									}} />
							</div>
							<div className='vat-type'>
								<FormGroup
									labelFor='edit-invoice--vat-type'>
									<TextWithTooltip
										label='Vrsta obračuna DDV'
										tooltip='Vrsta obračuna DDV' />
									<HTMLSelect
										id='edit-invoice--vat-type'
										fill={true}
										options={
											[
												{ title: 'DDV obračun', value: 'ddv'    },
												{ title: 'Ni DDV-ja',   value: 'no-ddv' },
											].map(
												item => {
													return { label: item.title, value: item.value };
												}
											)
										}
										value={this.state.vat_type}
										onChange={(e) => {
											this.setState({
												vat_type: e.currentTarget.value,
											});
										}} />
								</FormGroup>
							</div>
							<div className={'invoice-date ' + (this.state.invoice_type == 'cash-invoice' ? 'wide' : '')}>
								<FormGroup
									labelFor='edit-invoice--invoice-date'>
									<TextWithTooltip
										label={this.texts.invoice_date_title}
										tooltip={this.texts.invoice_date_title} />
									{this.state.invoice_type == 'cash-invoice' || this.state.invoice_type == 'cash-prepayment-invoice' ?
										<div className='leading-loose'>{
											this.props.item === null || this.props.item.issued === null ?
												'ni datuma računa' :
												moment(this.props.item.issued).format('DD. MM. YYYY HH:mm:ss')
										}</div>
										:
										<DateInput
											id='edit-invoice--invoice-date'
											locale='sl'
											{...this.getMomentFormatter('DD. MM. YYYY')}
											inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
											selectAllOnFocus={true}
											minDate={new Date('1900-01-01')}
											maxDate={moment().add('10', 'years').toDate()}
											disabled={this.state.read_only}
											onChange={(selectedDate, isUserChange) => {
												if (isUserChange) this.setState({ invoice_date: selectedDate });
											}}
											value={this.state.invoice_date} />
									}
								</FormGroup>
							</div>
							{this.state.invoice_type != 'invoice' ? null :
								<div className='payment-date-paid flex flex-row'>
									<FormGroup
										labelFor='edit-invoice--paid'>
										<TextWithTooltip
											label='Plačana'
											tooltip='Plačana' />
										<Checkbox
											id='edit-invoice--paid'
											checked={this.state.paid}
											onChange={event => {
												this.setState({ paid: event.target.checked });
											}} />
									</FormGroup>
								</div>
							}
							{this.state.invoice_type != 'credit-note' ? null :
								<div className='credit-note-type'>
									<FormGroup
										labelFor='edit-invoice--credit-note-type'>
										<TextWithTooltip
											label={this.texts.credit_note_type_title}
											tooltip={this.texts.credit_note_type_title} />
										<HTMLSelect
											id='edit-invoice--credit-note-type'
											fill={true}
											options={
												[
													{ title: '-- izberi --', value: ''          },
													{ title: 'Finančni',     value: 'financial' },
													{ title: 'Blagovni',     value: 'material'  },
												].map(
													item => {
														return { label: item.title, value: item.value };
													}
												)
											}
											value={this.state.credit_note_type || ''}
											onChange={(e) => {
												this.setState({
													credit_note_type: e.currentTarget.value,
												});
											}} />
									</FormGroup>
								</div>
							}
							{this.state.invoice_type != 'invoice' && this.state.invoice_type != 'credit-note' && this.state.invoice_type != 'offer' ? null :
								<div className='payment-date flex flex-row'>
									<FormGroup
										labelFor='edit-invoice--payment-date'
										className='ml-2'>
										<TextWithTooltip
											label={this.texts.payment_date_title}
											tooltip={this.texts.payment_date_title} />
										<DateInput
											id='edit-invoice--payment-date'
											locale='sl'
											{...this.getMomentFormatter('DD. MM. YYYY')}
											inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
											selectAllOnFocus={true}
											minDate={new Date('1900-01-01')}
											maxDate={moment().add('10', 'years').toDate()}
											onChange={(selectedDate, isUserChange) => {
												if (isUserChange) {
													this.setState({
														payment_date:         selectedDate,
														payment_date_changed: true,
													});
												}
											}}
											value={this.state.payment_date} />
									</FormGroup>
								</div>
							}
							{this.state.invoice_type != 'prepayment-invoice' && this.state.invoice_type != 'cash-prepayment-invoice' ? null :
								<div className='payment-date flex flex-row'>
									<FormGroup
										labelFor='edit-invoice--payment-date'
										className='ml-2'>
										<TextWithTooltip
											label='Datum plačila'
											tooltip='Datum plačila' />
										<DateInput
											id='edit-invoice--payment-date'
											locale='sl'
											{...this.getMomentFormatter('DD. MM. YYYY')}
											inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
											selectAllOnFocus={true}
											minDate={new Date('1900-01-01')}
											maxDate={moment().add('10', 'years').toDate()}
											onChange={(selectedDate, isUserChange) => {
												if (isUserChange) {
													this.setState({
														payment_date:         selectedDate,
														payment_date_changed: true,
													});
												}
											}}
											value={this.state.payment_date} />
									</FormGroup>
								</div>
							}
							<div className='service-date'>
								<FormGroup
									labelFor='edit-invoice--service-date'>
									<TextWithTooltip
										label={this.texts.service_date_title}
										tooltip={this.texts.service_date_title} />
									<DateRangeInput
										id='edit-invoice--service-date'
										locale='sl'
										{...this.getMomentFormatter('DD. MM. YYYY')}
										allowSingleDayRange={true}
										contiguousCalendarMonths={false}
										shortcuts={false}
										startInputProps={{ placeholder: 'Od', className: 'from-until' }}
										endInputProps={{ placeholder: 'Do', className: 'from-until' }}
										minDate={new Date('1900-01-01')}
										maxDate={ this.getMaxDate() }
										selectAllOnFocus={true}
										disabled={this.state.read_only}
										onChange={(selectedRange) => {
											this.setState({
												service_date_start: selectedRange[0],
												service_date_end:   selectedRange[1],
											});
										}}
										value={[
											this.state.service_date_start,
											this.state.service_date_end,  
										]} />
								</FormGroup>
							</div>
							<div className='reference'>
								<FormGroup
									labelFor='edit-invoice--reference'>
									<TextWithTooltip
										label='Referenca'
										tooltip='Referenca' />
									<InputGroup
										id='edit-invoice--reference'
										value={this.state.reference}
										onChange={event => {
											this.setState({ reference: event.target.value });
										}} />
								</FormGroup>
							</div>
							<div className='reservation'>
								<FormGroup
									labelFor='edit-invoice--reservation'>
									<TextWithTooltip
										label='Rezervacija'
										tooltip='Rezervacija' />
									<InputGroup
										id='edit-invoice--reservation'
										value={this.state.reservation}
										onChange={event => {
											this.setState({ reservation: event.target.value });
										}} />
								</FormGroup>
							</div>
							{this.state.invoice_type != 'cash-invoice' && this.state.invoice_type != 'cash-prepayment-invoice' ? null :
								<div className='payment-type'>
									<FormGroup
										labelFor='edit-invoice--payment-type'>
										<TextWithTooltip
											label='Način plačila'
											tooltip='Način plačila' />
										<HTMLSelect
											id='edit-invoice--payment-type'
											fill={true}
											options={
												Object.values(this.props.payment_types)
													.filter(x => x.visible || x.id_payment_type == this.state.id_payment_type)
													.concat([
														{
															id_payment_type: -2,
															type:            'split-payment',
															title:           'Deljeno plačilo',
															visible:         true,
															ord:             99999,
														},
													])
													.sort((a, b) => a.ord - b.ord)
													.map(
														payment_type => {
															return { label: payment_type.title, value: payment_type.id_payment_type };
														}
													)
											}
											value={this.state.id_payment_type || ''}
											onChange={(e) => {
												this.setState({
													id_payment_type: e.currentTarget.value,
												});
											}} />
									</FormGroup>
								</div>
							}
							<div className='split-payment-form'>
								{this.state.id_payment_type != -2 ? null :
									<>
										<div className='split-payment-title flex flex-row items-center justify-between pb-2'>
											<div className='font-bold'>Deljeno plačilo</div>
											<Button
												className='ml-2'
												onClick={this.openSplitPaymentDialog}>
												Uredi
											</Button>
										</div>
										<div>
											<div className='split-payment-form-table'>
												{this.state.split_payment_types.map((invoice_payment, idx) =>
													<React.Fragment key={invoice_payment.id_invoice_payment}>
														<div className='col-1 pl-2' style={{ gridRow: idx }}>
															{this.props.payment_types[invoice_payment.id_payment_type].title}
														</div>
														<div className='col-2' style={{ gridRow: idx }}>
															{this.formatNumber(invoice_payment.amount)} EUR
														</div>
													</React.Fragment>
												)}
											</div>
										</div>
									</>
								}
							</div>
							<div className='documents flex flex-row'>
								<Tabs className='flex flex-col flex-1' vertical={false}>
									<Tab id='comment' title='Opomba' panelClassName='flex-grow' panel={
										<TextArea
											className='table-full-h'
											fill={true}
											onChange={(e) => {
												this.setState({
													note: e.target.value,
												});
											}}
											value={this.state.note} />
									} />
									
									<Tab id='documents' title='Dokumenti' panelClassName='flex-grow h-full' panel={
										this.props.item === null || this.props.item.id_invoice === null ? null :
											<Documents id_document={this.props.item.id_invoice} deep={true} document_type={this.state.invoice_type} />
									} />
									
									{[ 'invoice', 'cash-invoice' ].indexOf(this.state.invoice_type) === -1 ? null :
										<Tab id='prepayments' title='Predplačila' panelClassName='flex-grow h-full' panel={
											<AdvanceInvoiceList
												prepayments={this.state.prepayments}
												customers={this.props.customers}
												invoices={this.props.invoices}
												openDocumentSearchDialog={this.canBeSavedAsFinal() ? this.openDocumentSearchDialog : null}
												removePrepayment={this.canBeSavedAsFinal() ? this.removePrepayment : null} />
										} />
									}
								</Tabs>
							</div>
						</div>
						
						<div className='items-list-wrapper flex-1'>
							<div className='items-list-scroll-container'>
								<div className='items-list'>
									<ItemsList
										read_only={this.state.read_only}
										invoice_type={this.state.invoice_type}
										invoice_items={this.state.invoice_items}
										items={this.props.items}
										tax_rates={this.props.tax_rates}
										invoice_date={this.state.invoice_date}
										measuring_units={this.props.measuring_units}
										setInvoiceItems={invoice_items => {
											this.setState({ invoice_items });
										}} />
								</div>
							</div>
						</div>
						
						<div className='items-summary font-semibold text-base'>
							{this.state.invoice_type != 'prepayment-invoice' && this.state.invoice_type != 'cash-prepayment-invoice' ? null :
								<>
									<div className='col-1'></div>
									<div className='col-2'></div>
									<div className='col-3'></div>
									<div className='col-4 pt-1'>
										Predplačilo:
									</div>
									<div className='col-5 flex flex-row items-center'>
										<div className='w-16'>
											<InputGroup
												intent={this.state.advance_payment_percent !== null ? 'success' : null}
												value={(() => {
													if (this.state.advance_payment_percent !== null) {
														return this.state.advance_payment_percent_formatted;
													}
													
													let val = 0;
													
													const sum = this.calculateInvoiceSum(false);
													if (sum != 0) {
														val = this.state.advance_payment_value / sum * 100;
													}
													val = this.formatNumber(val);
													
													return val;
												})()}
												onChange={event => {
													let val = parseFloatLocal(event.target.value);
													if (Object.is(val, NaN)) {
														val = this.state.advance_payment_percent;
													}
													
													let source_val = event.target.value;
													if (val < 0) {
														val        = 0;
														source_val = 0;
													}
													else if (val > 100) {
														val        = 100;
														source_val = 100;
													}
													
													this.changeAdvancePaymentValue(
														'advance_payment_percent',
														val,
														source_val
													);
												}}
												onBlur={event => this.changeAdvancePaymentValue('refresh')} />
										</div>
										<div className='pl-1 pr-3 text-right'>
											%
										</div>
										<div className='flex-1'>
											<InputGroup
												className='text-right'
												intent={this.state.advance_payment_value !== null ? 'success' : null}
												value={(() => {
													if (this.state.advance_payment_value !== null) {
														return this.state.advance_payment_value_formatted;
													}
													
													const sum = this.calculateInvoiceSum(false);
													let val   = sum * (this.state.advance_payment_percent / 100);
													val       = this.formatNumber(val);
													
													return val;
												})()}
												onChange={event => {
													let val = parseFloatLocal(event.target.value);
													if (Object.is(val, NaN)) {
														val = this.state.advance_payment_value;
													}
													
													this.changeAdvancePaymentValue(
														'advance_payment_value',
														val,
														event.target.value
													);
												}}
												onBlur={event => this.changeAdvancePaymentValue('refresh')} />
										</div>
									</div>
									
									<div className='col-1 pb-1'></div>
									<div className='col-2 pb-1'></div>
									<div className='col-3 pb-1'></div>
									<div className='col-4 pb-1'></div>
									<div className='col-5 pb-1'></div>
								</>
							}
							
							<div className='col-1 font-normal'>
								{
									invoice_fiscal_verification === undefined || invoice_fiscal_verification === null ?
										null : 'EOR:'
								}
							</div>
							<div className='col-2 font-normal select-text'>
								{
									invoice_fiscal_verification === undefined || invoice_fiscal_verification === null ?
										null : invoice_fiscal_verification.eor
								}
							</div>
							<div className='col-3'></div>
							<div className='col-4'>{
								this.state.invoice_type == 'credit-note' ?
									'Skupaj za vračilo:'
									:
									'Znesek za plačilo:'
							}</div>
							<div className='col-5 text-right select-text'>
								{this.formatNumber(
									this.calculateInvoiceSum(false)
								)}
							</div>
							
							{this.state.invoice_type == 'offer' || this.state.invoice_type == 'credit-note' ? null :
								<>
									<div className='col-1 font-normal'>
										{
											invoice_fiscal_verification === undefined || invoice_fiscal_verification === null ?
												null : 'ZOI:'
										}
									</div>
									<div className='col-2 font-normal select-text'>
										{
											invoice_fiscal_verification === undefined || invoice_fiscal_verification === null ?
												null : invoice_fiscal_verification.signed_zoi
										}
									</div>
									<div className='col-3'></div>
									<div className='col-4'>Plačano z avansom:</div>
									<div className='col-5 text-right select-text'>{
										this.formatNumber(
											this.state.advance_payment_value !== null ?
												(this.state.advance_payment_value * -1)
												:
												(this.calculateInvoiceSum(false) * (this.state.advance_payment_percent / 100) * -1)
										)
									}</div>
								</>
							}
							
							{this.state.invoice_type == 'offer' || this.state.invoice_type == 'credit-note' ? null :
								<>
									<div className='col-1 font-normal'>
										{
											invoice_fiscal_verification === undefined || invoice_fiscal_verification === null ?
												null : 'Stanje:'
										}
									</div>
									<div className='col-2 font-normal select-text'>
										{
											invoice_fiscal_verification === undefined || invoice_fiscal_verification === null ? null : (
												invoice_fiscal_verification.status === null ? 'pošiljanje v teku' : (() => {
													let verified = JSON.parse(invoice_fiscal_verification.response)?.InvoiceResponse?.Header?.DateTime;
													let verified_date = verified === undefined ? 'neznano' :
														this.toLocalStringWithTime(new Date(verified));
													
													return invoice_fiscal_verification.status === true ? 
														'poslano in potrjeno (' + verified_date + ')' : (
															'poslano in nepotrjeno (' + verified_date + ')'
														);
												})()
											)
										}
									</div>
									<div className='col-3'></div>
									<div className='col-4'>Ostane za plačilo:</div>
									<div className='col-5 text-right select-text'>
										{ this.formatNumber(this.calculateRemainingAmount()) }
									</div>
								</>
							}
						</div>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
EditInvoiceDialog.propTypes = {
	closeEditInvoiceDialog: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		customers:                                              state.CodeTablesSlice.customers,
		payment_types:                                          state.CodeTablesSlice.payment_types,
		items:                                                  state.CodeTablesSlice.items,
		countries:                                              state.CodeTablesSlice.countries,
		tax_rates:                                              state.CodeTablesSlice.tax_rates,
		measuring_units:                                        state.CodeTablesSlice.measuring_units,
		api_url:                                                state.CodeTablesSlice.api_url,
		token:                                                  state.UserSlice.token,
		user:                                                   state.UserSlice.user,
		settings:                                               state.SettingsSlice,
		document_types:                                         state.DocumentSlice.document_types,
		current_id_cash_register:                               state.SettingsSlice.current_id_cash_register,
		invoice_fiscal_verifications:                           state.BusinessSlice.invoice_fiscal_verifications,
		invoices:                                               state.BusinessSlice.invoices,
		advance_invoice_consumptions:                           state.BusinessSlice.advance_invoice_consumptions,
		id_advance_invoice_consumption_by_id_consumer_invoices: state.BusinessSlice.id_advance_invoice_consumption_by_id_consumer_invoices,
		current_id_cash_register:                               state.SettingsSlice.current_id_cash_register,
		cash_registers:                                         state.CashRegisterSlice.cash_registers,
		warehouses:                                             state.CodeTablesSlice.warehouses,
	};
}

export default connect(mapStateToProps)(EditInvoiceDialog);
