import React from 'react';

import moment from 'moment';

import {
	Icon,
	Popover,
	Menu,
	MenuItem,
	Button,
	InputGroup,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';

function ResultHeader(props) {
	const {
		cls,
		title,
		columnIdx,
		columnSpan,
		rowIdx,
	} = props;
	
	let style = {};
	let css   = 'results-table-header-col ' + (cls || '');
	
	if (columnIdx !== undefined) {
		style.gridColumn = columnIdx + ' / ' + (columnIdx + (columnSpan || 1));
		css += ' col-' + columnIdx;
	}
	style.gridRow = (rowIdx || 1);
	
	return <div
		className={css}
		style={style}>
		{ title }
	</div>;
}

function ResultHeaderWithSort(props) {
	const {
		cls,
		title,
		columnIdx,
		columnSpan,
		rowIdx,
		fieldName,
		setTableItemsSort,
		tableItemsSortField,
		tableItemsSortDirection,
	} = props;
	
	let style = {};
	let css   = 'results-table-header-col sortable ' + (cls || '');
	
	if (columnIdx !== undefined) {
		style.gridColumn = columnIdx + ' / ' + (columnIdx + (columnSpan || 1));
		css += ' col-' + columnIdx;
	}
	if (rowIdx !== undefined) {
		style.gridRow = rowIdx;
	}
	
	return <div
		className={css}
		style={style}
		onClick={() => { setTableItemsSort(fieldName); }}>
		{ title }
		{tableItemsSortField != fieldName ? null :
			<Icon icon={'caret-' + (tableItemsSortDirection == 'ASC' ? 'up' : 'down')} color='#888888' />
		}
	</div>;
}

function Result(props) {
	const {
		columnIdx,
		rowIdx,
		child,
		cls,
		span,
	} = props;
	
	let style = {};
	let css   = 'results-table-col ' + (cls || '');
	
	if (columnIdx !== undefined) {
		style.gridColumn = columnIdx + ' / ' + (columnIdx + (span || 1));
		css += ' col-' + columnIdx;
	}
	if (rowIdx !== undefined) {
		style.gridRow = rowIdx;
	}
	
	return <div className={css} style={style}>
		{ child === undefined ? null : child }
	</div>;
}

class MultiSelectFilter extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			popover_open: false,
		};
	}
	
	render() {
		let display_items = Object.values(this.props.items);
		if (this.props.displayFilter !== null && this.props.displayFilter !== undefined) {
			display_items = display_items.filter(this.props.displayFilter);
		}
		if (this.props.displaySort !== null && this.props.displaySort !== undefined) {
			display_items = display_items.sort(this.props.displaySort);
		}
		
		return <>
			<Popover
				onClose={e => {
					if (
						e !== undefined &&
						e.currentTarget !== undefined &&
						e.currentTarget.className !== undefined &&
						e.currentTarget.className.indexOf('bp3-popover') != -1
					) {
						return;
					}
					this.setState({ popover_open: false });
				}}
				isOpen={this.state.popover_open}
				position='bottom'
				minimal={true}
				className='multiselect-button-wrapper'
				popoverClassName='limit-height'
				content={<Menu>
					{display_items.map(x =>
						<MenuItem
							className='bp3-menu-item-horizontal'
							icon={this.props.filtered_keys.indexOf(x[this.props.value_field]) != -1 ? 'tick' : 'square'}
							key={x[this.props.value_field]}
							text={x[this.props.title_field]}
							onClick={() => {
								const new_keys = [...this.props.filtered_keys];
								
								const idx = new_keys.indexOf(x[this.props.value_field]);
								if (idx == -1) { new_keys.push(x[this.props.value_field]);    }
								else           { new_keys.splice(idx, 1); }
								
								this.props.onFilterChange(new_keys);
							}} />
					)}
				</Menu>}>
				<Button
					fill={true}
					small={true}
					onClick={() => this.setState({ popover_open: true })}
					disabled={this.props.disabled || false}>
					<div className='multiselect-button'>
						{this.props.filtered_keys.length == 0 ? this.props.empty_title :
							this.props.filtered_keys.map(x => this.props.items[x] === undefined ? '' : this.props.items[x][this.props.title_field]).join(', ')}
					</div>
				</Button>
			</Popover>
			<Button icon='cross' small={true} disabled={this.props.disabled || false} onClick={() => {
				this.props.onFilterChange([]);
			}} />
		</>;
	}
}

function DateRangeFilter(props) {
	const {
		filter_values,
		short_year,
		original_start_date,
		original_end_date,
		onFilterChange,
		hideReset,
	} = props;
	
	const disableFrom  = (props.disabled || false) || (props.disable_from  || false);
	const disableUntil = (props.disabled || false) || (props.disable_until || false);
	
	const formatDatePattern = short_year === true ? 'DD. MM. YY' : 'DD. MM. YYYY';
	
	let placeholderFrom  = '01. 01. ' + new Date().getFullYear();
	let placeholderUntil = '31. 12. ' + new Date().getFullYear();
	if (short_year) {
		placeholderFrom  = placeholderFrom.substring(0, placeholderFrom.length - 2);
		placeholderUntil = placeholderUntil.substring(0, placeholderUntil.length - 2);
	}
	
	return <div className='flex flex-row filter-date-range'>
		<DateInput
			className='flex-1'
			locale='sl'
			formatDate={(date, locale) => moment(date).locale(locale).format(formatDatePattern)}
			parseDate={(str, locale) => moment(str, 'DD. MM. YYYY').locale(locale).toDate()}
			inputProps={{ placeholder: placeholderFrom }}
			popoverProps={{ position: 'left' }}
			selectAllOnFocus={true}
			minDate={new Date('1900-01-01')}
			maxDate={moment().add('10', 'years').toDate()}
			onChange={(selectedDate, isUserChange) => {
				if (isUserChange) onFilterChange([ selectedDate, filter_values[1] ]);
			}}
			value={filter_values[0]}
			disabled={disableFrom} />
		<DateInput
			className='flex-1'
			locale='sl'
			formatDate={(date, locale) => moment(date).locale(locale).format(formatDatePattern)}
			parseDate={(str, locale) => moment(str, 'DD. MM. YYYY').locale(locale).toDate()}
			inputProps={{ placeholder: placeholderUntil }}
			popoverProps={{ position: 'right' }}
			selectAllOnFocus={true}
			minDate={new Date('1900-01-01')}
			maxDate={moment().add('10', 'years').toDate()}
			onChange={(selectedDate, isUserChange) => {
				if (isUserChange) onFilterChange([ filter_values[0], selectedDate ]);
			}}
			value={filter_values[1]}
			disabled={disableUntil} />
		{(hideReset || false) ? null :
			<Button icon='cross' small={true} disabled={props.disabled || false} onClick={() => {
				onFilterChange([
					original_start_date === undefined ? null : original_start_date,
					original_end_date   === undefined ? null : original_end_date,
				]);
			}} />
		}
	</div>;
}

function DateFilter(props) {
	const {
		filter_value,
		short_year,
		original_date,
		onFilterChange,
	} = props;
	
	const formatDatePattern = short_year === true ? 'DD. MM. YY' : 'DD. MM. YYYY';
	
	let placeholderFrom = '01. 01. ' + new Date().getFullYear();
	if (short_year) {
		placeholderFrom = placeholderFrom.substring(0, placeholderFrom.length - 2);
	}
	
	return <div className='flex flex-row filter-date-range'>
		<DateInput
			className='flex-1'
			locale='sl'
			formatDate={(date, locale) => moment(date).locale(locale).format(formatDatePattern)}
			parseDate={(str, locale) => moment(str, 'DD. MM. YYYY').locale(locale).toDate()}
			inputProps={{ placeholder: placeholderFrom }}
			popoverProps={{ position: 'left' }}
			selectAllOnFocus={true}
			minDate={new Date('1900-01-01')}
			maxDate={moment().add('10', 'years').toDate()}
			onChange={(selectedDate, isUserChange) => {
				if (isUserChange) onFilterChange(selectedDate);
			}}
			value={filter_value}
			disabled={props.disabled || false} />
		<Button icon='cross' small={true} disabled={props.disabled || false} onClick={() => {
			onFilterChange(
				original_date === undefined ? null : original_date
			);
		}} />
	</div>;
}

function TextFilter(props) {
	const {
		value,
		onChange,
	} = props;
	
	return <InputGroup
		value={value}
		onChange={onChange}
		fill={true}
		rightElement={<Button icon='cross' minimal={true} onClick={() => { onChange(''); }} />}
		disabled={props.disabled || false} />;
}

export { ResultHeader, ResultHeaderWithSort, Result, MultiSelectFilter, DateRangeFilter, DateFilter, TextFilter };
