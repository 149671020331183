import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Intent,
	Navbar,
	HTMLSelect,
	Checkbox,
} from '@blueprintjs/core';

import moment from 'moment';

import { AppToaster } from '../AppToaster';

import { Result, ResultHeader } from '../code_tables/ResultComponents';

const eTourismReportStatuses = {
	'11': 'ODPRT - TURISTI SO PRIHAJALI',
	'12': 'ODPRT - TURISTOV NI BILO',
	'50': 'ZAČASNO ZAPRT - DRUGI RAZLOGI',
	'51': 'ZAČASNO ZAPRT - ADAPTACIJA',
	'54': 'ZAČASNO ZAPRT - SEZONSKA ZAPRTOST',
	'61': 'PRENEHANJE ODDAJANJA NASTANITVENIH KAPACITET TURISTOM',
	'62': 'ZAČASNO ZAPRT - NASTANITVENE KAPACITETE ŠE V PRIPRAVI',
};

class AddMonthlyGuestBookReportDialog extends Component {
	constructor(props) {
		super(props);
		
		this.calculateUsedRooms = this.calculateUsedRooms.bind(this);
		
		const prev_month = moment().subtract(1, 'month');
		const year       = prev_month.year();
		const month      = prev_month.month() + 1;
		const day_count  = new Date(year, month, 0).getDate();
		
		const used_count_by_id_accommodations = this.calculateUsedRooms(
			year + '-' + (month < 10 ? '0' + month : month) + '-01',
			year + '-' + (month < 10 ? '0' + month : month) + '-' + (day_count < 10 ? '0' + day_count : day_count)
		);
		
		this.calculateUsedRooms('2021-01-01', '2021-09-15');
		
		const data_by_id_nos = {};
		for (let id_accommodation in props.accommodations) {
			const id_no = props.accommodations[id_accommodation].etourism_id_rno;
			
			const accommodation = props.accommodations[id_accommodation];
			if (accommodation === undefined || accommodation.etourism_reporting !== true) continue;
			
			if (
				id_no === null ||
				id_no == 0     ||
				id_no === undefined
			) continue;
			
			data_by_id_nos[id_no] = {
				id_accommodation,
				checked:                       true,
				etourism_report_status:        '11',
				extra_bed_count:               0,
				sold_accommodation_unit_count: used_count_by_id_accommodations[id_accommodation] || 0,
				active_day_count:              day_count,
			};
		}
		
		this.state = {
			year,
			month,
			data_by_id_nos,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.year != prevState.year || this.state.month != prevState.month) {
			const day_count = new Date(this.state.year, this.state.month, 0).getDate();
			
			const new_items = {...this.state.data_by_id_nos};
			for (let id_no in new_items) {
				new_items[id_no].active_day_count              = day_count;
				new_items[id_no].sold_accommodation_unit_count = 0; // reset, so that we can set them again below
			}
			
			const used_count_by_id_accommodations = this.calculateUsedRooms(
				this.state.year + '-' + (this.state.month < 10 ? '0' + this.state.month : this.state.month) + '-01',
				this.state.year + '-' + (this.state.month < 10 ? '0' + this.state.month : this.state.month) + '-' + (day_count < 10 ? '0' + day_count : day_count)
			);
			for (let id_accommodation in used_count_by_id_accommodations) {
				const id_no = this.props.accommodations[id_accommodation].etourism_id_rno;
				
				const accommodation = this.props.accommodations[id_accommodation];
				if (accommodation === undefined || accommodation.etourism_reporting !== true) continue;
				
				if (
					id_no === null ||
					id_no == 0     ||
					id_no === undefined
				) continue;
				
				new_items[id_no].sold_accommodation_unit_count = used_count_by_id_accommodations[id_accommodation] || 0;
			}
			
			this.setState({ data_by_id_nos: new_items });
		}
	}
	
	calculateUsedRooms(from_iso, until_iso) {
		const used_days_by_id_accommodations = {};
		
		for (let id_reservation in this.props.reservations) {
			const reservation = this.props.reservations[id_reservation];
			if (reservation.deleted === true || reservation.status == 'not-for-rent') continue;
			
			const reservation_check_in_date_iso  = moment(reservation.check_in ).format('YYYY-MM-DD');
			const reservation_check_out_date_iso = moment(reservation.check_out).format('YYYY-MM-DD');
			
			if (reservation_check_out_date_iso <  from_iso) continue;
			if (reservation_check_in_date_iso  > until_iso) continue;
			
			const accommodation_item = this.props.accommodation_items[
				this.props.accommodation_item_places[
					reservation.id_accommodation_item_place
				].id_accommodation_item
			];
			if (accommodation_item === undefined) continue;
			
			const customers = this.props.reservation_customers_by_id_reservations[id_reservation];
			if (customers === undefined || customers.length == 0) continue;
			
			let reservation_matches = false;
			for (let id_reservation_customer in customers) {
				const rc = customers[id_reservation_customer];
				if (rc.id_guest_book === null || rc.id_guest_book === undefined) continue;
				
				const guest_book = this.props.guest_book_items[rc.id_guest_book];
				if (
					guest_book !== undefined &&
					guest_book.status == 1 /* check in */
				) {
					reservation_matches = true;
					break;
				}
			}
			
			if (!reservation_matches) continue;
			
			if (used_days_by_id_accommodations[reservation.id_accommodation_item_place] === undefined) {
				used_days_by_id_accommodations[reservation.id_accommodation_item_place] = {};
			}
			
			const reservation_length =
				Math.max(
					1, // reservations are at least 1 day in length
					moment(reservation_check_out_date_iso).diff(moment(reservation_check_in_date_iso), 'days')
				);
			let date = moment(reservation.check_in);
			for (let i=0; i<reservation_length; i++) {
				const date_iso = date.format('YYYY-MM-DD');
				if (date_iso >= from_iso && date_iso <= until_iso) {
					if (used_days_by_id_accommodations[reservation.id_accommodation_item_place][date_iso] === undefined) {
						used_days_by_id_accommodations[reservation.id_accommodation_item_place][date_iso] = 0;
					}
					used_days_by_id_accommodations[reservation.id_accommodation_item_place][date_iso]++;
				}
				
				date.add(1, 'day');
			}
		}
		
		const used_count_by_id_accommodations      = {};
		const used_count_by_id_accommodation_items = {};
		for (let id_accommodation_item_place in used_days_by_id_accommodations) {
			const accommodation_item_place = this.props.accommodation_item_places[
				id_accommodation_item_place
			];
			const id_accommodation_item = accommodation_item_place.id_accommodation_item;
			const accommodation_item = this.props.accommodation_items[
				id_accommodation_item
			];
			const id_accommodation = accommodation_item.id_accommodation;
			
			if (used_count_by_id_accommodation_items[id_accommodation_item] == undefined) {
				used_count_by_id_accommodation_items[id_accommodation_item] = 0;
			}
			if (used_count_by_id_accommodations[id_accommodation] === undefined) {
				used_count_by_id_accommodations[id_accommodation] = 0;
			}
			
			for (let date in used_days_by_id_accommodations[id_accommodation_item_place]) {
				if (used_days_by_id_accommodations[id_accommodation_item_place][date] > 0) {
					used_count_by_id_accommodation_items[id_accommodation_item]++;
					used_count_by_id_accommodations     [id_accommodation     ]++;
				}
			}
		}
		
		const used_count_by_id_accommodation_items_with_titles = {};
		for (let id_accommodation_item in used_count_by_id_accommodation_items) {
			used_count_by_id_accommodation_items_with_titles[id_accommodation_item] = {
				count: used_count_by_id_accommodation_items[id_accommodation_item],
				title: this.props.accommodation_items[id_accommodation_item].title,
			};
		}
		console.log([
			from_iso,
			until_iso,
		]);
		console.log(used_count_by_id_accommodation_items_with_titles);
		
		return used_count_by_id_accommodations;
	}
	
	render() {
		const id_nos      = Object.keys(this.state.data_by_id_nos);
		const check_count = id_nos.filter(id_no => this.state.data_by_id_nos[id_no].checked === true).length;
		const all_checked = id_nos.length == check_count;
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			onClose={() => this.props.closeDialog()}
			className='add-monthly-guest-book-report-dialog'>
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Mesečno poročilo</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY + ' flex flex-col'}>
					<div className='flex flex-col flex-1'>
						<div className='flex flex-row'>
							<div className='flex flex-col'>
								<div className='font-bold'>Leto</div>
								<div>
									<HTMLSelect
										options={Object.keys(new Array(80).fill(1)).map(x => {
											return {
												value: 2000 + parseInt(x, 10),
												label: 2000 + parseInt(x, 10),
											};
										})}
										value={this.state.year}
										onChange={event => {
											this.setState({
												year: event.currentTarget.value,
											});
										}} />
								</div>
							</div>
							<div className='flex flex-col'>
								<div className='font-bold'>Mesec</div>
								<div>
									<HTMLSelect
										options={[
											{ value:  1, label: 'januar'    },
											{ value:  2, label: 'februar'   },
											{ value:  3, label: 'marec'     },
											{ value:  4, label: 'april'     },
											{ value:  5, label: 'maj'       },
											{ value:  6, label: 'junij'     },
											{ value:  7, label: 'julij'     },
											{ value:  8, label: 'avgust'    },
											{ value:  9, label: 'september' },
											{ value: 10, label: 'oktober'   },
											{ value: 11, label: 'november'  },
											{ value: 12, label: 'december'  },
										]}
										value={this.state.month}
										onChange={event => {
											this.setState({
												month: event.currentTarget.value,
											});
										}} />
								</div>
							</div>
						</div>
						
						<div className='mt-4 flex flex-col flex-1 self-stretch'>
							<div className='base-list add-monthly-guest-book-report-dialog-documents-list flex-1 relative'>
								<div className='results h-full relative'>
									<div className='results-table overflow-y-auto sticky-header absolute max-h-full inset-x-0 top-0'>
										<ResultHeader columnIdx={1} title={
											<Checkbox
												checked={check_count > 0}
												indeterminate={!all_checked && check_count > 0}
												onChange={event => {
													const new_items = {...this.state.data_by_id_nos};
													
													for (const id_no in new_items) {
														new_items[id_no].checked = event.target.checked;
													}
													
													this.setState({
														data_by_id_nos: new_items,
													});
												}} />
										} />
										<ResultHeader columnIdx={2} title='ID RNO' />
										<ResultHeader columnIdx={3} title='Leto / mesec' />
										<ResultHeader columnIdx={4} title='Status' />
										<ResultHeader columnIdx={5} title='Dod. ležišča' />
										<ResultHeader columnIdx={6} title='Prodane NE' />
										<ResultHeader columnIdx={7} title='Odprto št. dni' />
										
										{Object.keys(this.state.data_by_id_nos)
											.map(id_no => {
												const item = this.state.data_by_id_nos[id_no];
												const cls  = item.checked ? '' : 'text-gray-500';
												
												return <React.Fragment key={'id-no-' + id_no}>
													<Result columnIdx={1} cls={cls} child={
														<Checkbox
															checked={item.checked}
															onChange={event => {
																const new_items = {...this.state.data_by_id_nos};
																new_items[id_no].checked = event.target.checked;
																
																this.setState({
																	data_by_id_nos: new_items,
																});
															}} />
													} />
													<Result columnIdx={2} cls={cls} child={
														id_no +
														' • ' +
														this.props.accommodations[item.id_accommodation].title
													} />
													<Result columnIdx={3} cls={cls} child={
														this.state.year + ' / ' + this.state.month
													} />
													<Result columnIdx={4} cls={cls} child={
														<HTMLSelect
															options={
																Object.keys(eTourismReportStatuses)
																	.map(status => {
																		return {
																			value: status,
																			label: status + ' • ' + eTourismReportStatuses[status],
																		};
																	})
															}
															value={item.etourism_report_status}
															disabled={!item.checked}
															onChange={event => {
																const new_items = {...this.state.data_by_id_nos};
																new_items[id_no].etourism_report_status = event.currentTarget.value;
																
																this.setState({
																	data_by_id_nos: new_items,
																});
															}} />
													} />
													<Result columnIdx={5} cls={cls} child={
														<input
															type='text'
															value={ item.extra_bed_count }
															disabled={!item.checked}
															onChange={event => {
																const value = event.target.value;
																
																const new_items = {...this.state.data_by_id_nos};
																new_items[id_no].extra_bed_count = value;
																
																this.setState({
																	data_by_id_nos: new_items,
																});
															}} />
													} />
													<Result columnIdx={6} cls={cls} child={
														<input
															type='text'
															value={ item.sold_accommodation_unit_count }
															disabled={!item.checked}
															onChange={event => {
																const value = event.target.value;
																
																const new_items = {...this.state.data_by_id_nos};
																new_items[id_no].sold_accommodation_unit_count = value;
																
																this.setState({
																	data_by_id_nos: new_items,
																});
															}} />
													} />
													<Result columnIdx={7} cls={cls} child={
														<input
															type='text'
															value={ item.active_day_count }
															disabled={!item.checked}
															onChange={event => {
																const value = event.target.value;
																
																const new_items = {...this.state.data_by_id_nos};
																new_items[id_no].active_day_count = value;
																
																this.setState({
																	data_by_id_nos: new_items,
																});
															}} />
													} />
												</React.Fragment>;
											})
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						{this.props.item !== null ?
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.props.closeDialog()}>
								Zapri
							</Button>
							:
							<>
								<Button
									minimal={true}
									onClick={() => this.props.closeDialog()}>
									Prekliči
								</Button>
								<Button
									intent={Intent.PRIMARY}
									onClick={() => {
										const invalid_values_length = Object.keys(this.state.data_by_id_nos)
											.filter(id_no => this.state.data_by_id_nos[id_no].checked)
											.filter(id_no => {
												if (isNaN(this.state.data_by_id_nos[id_no].extra_bed_count)) {
													return true;
												}
												if (isNaN(this.state.data_by_id_nos[id_no].sold_accommodation_unit_count)) {
													return true;
												}
												if (isNaN(this.state.data_by_id_nos[id_no].active_day_count)) {
													return true;
												}
												return false;
											})
											.length;
										
										if (invalid_values_length > 0) {
											AppToaster.show({
												message: <div>
													<div>Vsaj ena od vpisanih vrednosti ni veljavna</div>
												</div>,
												intent: 'danger',
												icon:   'issue',
											});
											return;
										}
										
										const items = Object.keys(this.state.data_by_id_nos)
											.filter(id_no => this.state.data_by_id_nos[id_no].checked)
											.map(id_no => {
												const item = this.state.data_by_id_nos[id_no];
												
												return {
													id_monthly_guest_book_report:  uuid.v4(),
													year:                          this.state.year,
													month:                         this.state.month,
													etourism_status:               'waiting',
													etourism_report_status:        item.etourism_report_status,
													id_no:                         id_no,
													extra_bed_count:               item.extra_bed_count,
													sold_accommodation_unit_count: item.sold_accommodation_unit_count,
													active_day_count:              item.active_day_count,
												};
											});
										
										if (items.length == 0) {
											AppToaster.show({
												message: <div>
													<div>Označite vsaj en objekt</div>
												</div>,
												intent: 'danger',
												icon:   'issue',
											});
											return;
										}
										
										this.props.closeDialog(
											items
										);
									}}>
									Pošlji poročilo
								</Button>
							</>
						}
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddMonthlyGuestBookReportDialog.propTypes = {
	closeDialog: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		accommodations:            state.CodeTablesSlice.accommodations,
		accommodation_items:       state.CodeTablesSlice.accommodation_items,
		accommodation_item_places: state.CodeTablesSlice.accommodation_item_places,
		guest_book_items:          state.GuestSlice.guest_book_items,
		reservations:              state.ReservationSlice.reservations,
		reservation_customers:     state.ReservationSlice.reservation_customers,
		reservation_customers_by_id_reservations: state.ReservationSlice.reservation_customers_by_id_reservations,
	};
}

export default connect(mapStateToProps)(AddMonthlyGuestBookReportDialog);
