import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Intent,
	Navbar,
	FormGroup,
	InputGroup,
	Checkbox,
	Tag,
} from '@blueprintjs/core';

import TextWithTooltip     from '../TextWithTooltip';
import { parseFloatLocal } from '../../numbers';

class SplitPaymentDialog extends Component {
	constructor(props) {
		super(props);
		
		this.changeValue     = this.changeValue    .bind(this);
		this.preparePayments = this.preparePayments.bind(this);
		this.reformat        = this.reformat       .bind(this);
		
		const payments = {};
		for (let i=0; i<this.props.payments.length; i++) {
			payments[this.props.payments[i].id_payment_type] = this.props.payments[i];
		}
		
		const values = {};
		for (const id_payment_type in props.payment_types) {
			let value = 0;
			if (payments[id_payment_type] !== undefined) {
				value = parseFloat(payments[id_payment_type].amount);
			}
			
			values[id_payment_type] = { value, formatted: this.formatNumber(value) };
		}
		
		this.state = {
			payments,
			values,
			values_as_percents: false,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.values_as_percents != prevState.values_as_percents) {
			const values = this.state.values;
			
			if (this.state.values_as_percents) {
				for (const id_payment_type in values) {
					values[id_payment_type].value = this.props.total_value == 0 ? 0 :
						(values[id_payment_type].value / this.props.total_value * 100);
					
					values[id_payment_type].formatted = this.formatNumber(values[id_payment_type].value);
				}
			}
			else {
				for (const id_payment_type in values) {
					values[id_payment_type].value     = values[id_payment_type].value / 100 * this.props.total_value;
					values[id_payment_type].formatted = this.formatNumber(values[id_payment_type].value);
				}
			}
			
			this.setState({ values });
		}
	}
	
	reformat() {
		const values = this.state.values;
		
		for (const id_payment_type in values) {
			values[id_payment_type].formatted = this.formatNumber(values[id_payment_type].value);
		}
		
		this.setState({ values });
	}
	
	changeValue(val, field_id_payment_type, type) {
		const values = this.state.values;
		
		if (type == 'formatted') {
			let parsed_val = parseFloatLocal(val);
			if (!Object.is(parsed_val, NaN)) {
				values[field_id_payment_type].value = parsed_val;
			}
			
			values[field_id_payment_type].formatted = val;
		}
		else if (type == 'value') {
			values[field_id_payment_type].value     = val;
			values[field_id_payment_type].formatted = this.formatNumber(val);
		}
		
		for (const id_payment_type in values) {
			if (id_payment_type != field_id_payment_type) {
				values[id_payment_type].formatted = this.formatNumber(values[id_payment_type].value);
			}
		}
		
		this.setState({ values });
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	preparePayments() {
		const payments = [];
		
		for (const id_payment_type in this.state.values) {
			let value = this.state.values[id_payment_type].value;
			if (value == 0) continue;
			
			if (this.state.values_as_percents) {
				value = (value / 100) * this.props.total_value;
			}
			
			const id_invoice_payment = this.state.payments[id_payment_type] === undefined ? null :
				this.state.payments[id_payment_type].id_invoice_payment;
			
			payments.push({
				id_invoice_payment,
				id_payment_type,
				amount:       value,
				payment_date: null,
			});
		}
		
		return payments;
	}
	
	render() {
		const number_of_columns = 3;
		
		const sum = Math.properRound(
			Object.values(this.state.values).map(x => x.value).reduce((acc, curr) => acc + curr),
			2
		);
		
		let total_value = 0;
		if (this.state.values_as_percents) {
			total_value = 100;
		}
		else {
			total_value = this.props.total_value;
		}
		total_value = Math.properRound(total_value, 2);
		
		const valid = sum == total_value;
		
		const payment_types = Object.values(this.props.payment_types)
			.filter(x => x.visible)
			.sort((a, b) => {
				return a.ord - b.ord;
			});
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => { this.props.closeDialog(); }}
			className='select-price-list-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Deljeno plačilo</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => { this.props.closeDialog(); }} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className={'grid grid-cols-' + number_of_columns + ' gap-2'}>
						<div>
							<Checkbox
								label='Vnos v procentih'
								checked={this.state.values_as_percents}
								onChange={event => {
									this.setState({ values_as_percents: event.target.checked });
								}} />
						</div>
						
						<div className={
							'col-span-' + (number_of_columns-1) + ' ' +
							'text-right ' +
							(valid ? 'text-green-600' : 'text-red-600')
						}>
							{
								this.formatNumber(sum)
								+ ' / ' +
								this.formatNumber(total_value)
								+ ' ' +
								(this.state.values_as_percents ? '%' : 'EUR')
								+ ' (' +
								this.formatNumber(Math.properRound(total_value - sum, 2))
								+ ')'
							}
						</div>
						
						{payment_types.map(payment_type =>
							<FormGroup
								key={'key-split-payment-dialog--' + payment_type.id_payment_type}
								labelFor={'split-payment-dialog--' + payment_type.id_payment_type}
								className={
									'flex-1 ' /*+
									(this.state.validation_failed_fields.indexOf('internal_code') == -1 ?
										'' : 'validation-failed')*/
								}>
								<span className={this.state.values[payment_type.id_payment_type].value != 0 ? 'font-bold' : ''}>
									<TextWithTooltip
										label={payment_type.title} />
								</span>
								<InputGroup
									id={'split-payment-dialog--' + payment_type.id_payment_type}
									value={this.state.values[payment_type.id_payment_type].formatted}
									onChange={event => {
										this.changeValue(event.target.value, payment_type.id_payment_type, 'formatted');
									}}
									onBlur={this.reformat}
									rightElement={
										<Tag minimal={true}>{this.state.values_as_percents ? '％' : 'EUR'}</Tag>
									} />
							</FormGroup>
						)}
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => { this.props.closeDialog(); }}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => { this.props.closeDialog(this.preparePayments()); }}
							disabled={!valid}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}

function mapStateToProps(state) {
	return {
		payment_types: state.CodeTablesSlice.payment_types,
	};
}

export default connect(mapStateToProps)(SplitPaymentDialog);
