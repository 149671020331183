import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Button,
	ButtonGroup,
	FormGroup,
	Checkbox,
	Navbar,
	Dialog,
	Alignment,
	Classes,
	Intent,
	Divider,
	HTMLSelect,
	Tooltip,
	Icon,
} from '@blueprintjs/core';

class InvoiceDialog extends Component {
	constructor(props) {
		super(props);
		
		this.closeWithType = this.closeWithType.bind(this);
		this.formatNumber  = this.formatNumber .bind(this);
		
		let id_customer = props.idCustomer;
		if ((id_customer === null || id_customer === undefined || id_customer == -1) && props.guestIdCustomers.length > 0) {
			id_customer = props.guestIdCustomers[0];
		}
		console.log({
			calculatedState: props.calculatedState,
		});
		
		this.state = {
			id_customer,
			selected_id_customer: id_customer,
			items: [
				/*{
					'id_user': 1,
					'user': 'Bože Jože',
					'sum': 20,
					'items': [
						{ 'title': 'kava', 'count': 4, 'sum': 4, 'checked': false },
						{ 'title': 'masaža', 'count': 1, 'sum': 16, 'checked': false },
					],
				},
				{
					'id_user': 2,
					'user': 'Bože Matjaž',
					'sum': 10,
					'items': [
						{ 'title': 'hamburger', 'count': 1, 'sum': 10, 'checked': false },
					],
				},*/
				{
					'id_user': -1,
					'user': 'Storitve na rezervaciji',
					'sum': 0,
					'items': props.calculatedState.items.filter(item => item.type == 'reservation-item').map(item => {
						let price = item.price !== undefined ? item.price : 0;
						if (item.price === undefined && item.id_item_price_list !== undefined) {
							price = props.items[item.id_item].price_lists[item.id_item_price_list].price * item.quantity;
						}
						
						return {
							title:   props.items[item.id_item].title,
							count:   item.quantity,
							sum:     price,
							checked: false,
							item,
						};
					}),
					/*[
						{ 'title': 'NKV2A Nočitve - KV 1', 'count': 1, 'sum': 45, 'checked': true },
						{ 'title': 'NKV2A Nočitve - KV 5', 'count': 1, 'sum': 45, 'checked': true },
					],*/
				},
				{
					'id_user': -3,
					'user': 'Dodatne storitve/artikli gosta',
					'sum': 0,
					'items': props.calculatedState.items.filter(item => item.type == 'customer-item').map(item => {
						let price = item.price !== undefined ? item.price : 0;
						if (item.price === undefined && item.id_item_price_list !== undefined) {
							price = props.items[item.id_item].price_lists[item.id_item_price_list].price * item.quantity;
						}
						
						return {
							title:   props.items[item.id_item].title,
							count:   item.quantity,
							sum:     price,
							checked: false,
							item,
						};
					}),
				},
				{
					'id_user': -2,
					'user': 'Turistična taksa',
					'sum': 0,
					'items': props.calculatedState.items.filter(item => item.type == 'tourist-tax' && item.id_tourist_tax !== undefined).map(item => {
						let price = item.price !== undefined ? item.price : 0;
						if (item.price === undefined && item.id_item_price_list !== undefined) {
							price = props.items[item.id_item].price_lists[item.id_item_price_list].price * item.quantity;
						}
						
						return {
							title:    props.items[item.id_item].title,
							subtitle: props.tourist_taxes[item.id_tourist_tax].title,
							count:    item.quantity,
							sum:      price,
							checked:  false,
							item,
						};
					}),
					/*[
						{ 'title': 'NKV2A Nočitve - KV 1', 'count': 1, 'sum': 45, 'checked': true },
						{ 'title': 'NKV2A Nočitve - KV 5', 'count': 1, 'sum': 45, 'checked': true },
					],*/
				},
			],
			selected_advance_invoices: [],
		};
		
		for (let i=0; i<this.state.items.length; i++) {
			let sum = 0;
			for (let j=0; j<this.state.items[i].items.length; j++) {
				sum += this.state.items[i].items[j].sum;
			}
			
			this.state.items[i].sum = sum;
		}
		
		//if (this.props.preselect) {
		//	for (let i=0; i<this.state.items[0].items.length; i++) {
		//		this.state.items[0].items[i].checked = true;
		//	}
		//}
		
		this.selectNone                              = this.selectNone                             .bind(this);
		this.selectAll                               = this.selectAll                              .bind(this);
		this.calculateTotalAmounts                   = this.calculateTotalAmounts                  .bind(this);
		this.calculateAdvanceInvoicesSelectedAmounts = this.calculateAdvanceInvoicesSelectedAmounts.bind(this);
		this.getAdvanceInvoicesForAmount             = this.getAdvanceInvoicesForAmount            .bind(this);
		
		if (this.props.preselect) this.setCheckedToAll(true, true);
		
		const total_amounts = this.calculateTotalAmounts();
		this.state.selected_advance_invoices = this.getAdvanceInvoicesForAmount(total_amounts.total);
	}
	
	selectNone() {
		this.setCheckedToAll(false);
	}
	
	selectAll() {
		this.setCheckedToAll(true);
	}
	
	setCheckedToAll(value, set_state_directly) {
		set_state_directly = set_state_directly === undefined ? false : set_state_directly;
		let items = this.state.items;
		
		for (let i=0; i<items.length; i++) {
			for (let j=0; j<items[i].items.length; j++) {
				items[i].items[j].checked = value;
			}
		}
		
		if (set_state_directly) {
			this.state.items = items;
		}
		else {
			this.setState({ items });
		}
	}
	
	closeWithType(type) {
		const selected_items = [];
		for (let i=0; i<this.state.items.length; i++) {
			for (let j=0; j<this.state.items[i].items.length; j++) {
				if (this.state.items[i].items[j].checked) {
					selected_items.push(this.state.items[i].items[j].item);
				}
			}
		}
		
		if (selected_items.length == 0) {
			//TODO validation error ..
			return;
		}
		
		this.props.closeInvoiceDialog(
			type,
			selected_items,
			this.state.selected_id_customer,
			this.calculateAdvanceInvoicesSelectedAmounts(),
		);
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	calculateTotalAmounts() {
		return {
			without_tourist_tax: this.state.items.filter(item => isNaN(item.id_user) || item.id_user == -1).reduce((curr_value, item) => 
				curr_value +
				item.items.reduce((curr_subvalue, subitem) =>
					curr_subvalue + (subitem.checked ? subitem.sum : 0),
				0), 
			0),
			customer_services: this.state.items.filter(item => item.id_user == -3).reduce((curr_value, item) => 
				curr_value +
				item.items.reduce((curr_subvalue, subitem) =>
					curr_subvalue + (subitem.checked ? subitem.sum : 0),
				0), 
			0),
			tourist_tax: this.state.items.filter(item => item.id_user == -2).reduce((curr_value, item) => 
				curr_value +
				item.items.reduce((curr_subvalue, subitem) =>
					curr_subvalue + (subitem.checked ? subitem.sum : 0),
				0), 
			0),
			total: this.state.items.reduce((curr_value, item) => 
				curr_value +
				item.items.reduce((curr_subvalue, subitem) =>
					curr_subvalue + (subitem.checked ? subitem.sum : 0),
				0), 
			0),
		};
	}
	
	calculateAdvanceInvoicesSelectedAmounts() {
		const amounts = {};
		
		const total_amounts = this.calculateTotalAmounts();
		let remaining_amount = total_amounts.total;
		
		const sorted_advance_invoices = this.props.advance_invoices
			.sort((a, b) => {
				if      (a.invoice_date < b.invoice_date) return -1;
				else if (a.invoice_date > b.invoice_date) return  1;
				return 0;
			});
		
		for (let i=0; i<sorted_advance_invoices.length; i++) {
			const invoice = sorted_advance_invoices[i];
			if (this.state.selected_advance_invoices.indexOf(invoice.id_invoice) == -1) {
				continue;
			}
			
			const amount = Math.min(invoice.advance_payment_amount - invoice.used_advance_payment_amount, remaining_amount);
			if (amount > 0) {
				remaining_amount -= amount;
				amounts[invoice.id_invoice] = amount;
			}
		}
		
		return amounts;
	}
	getAdvanceInvoicesForAmount(target_amount) {
		const ids = [];
		if (target_amount <= 0) {
			return ids;
		}
		
		let remaining_amount = target_amount;
		
		const sorted_advance_invoices = this.props.advance_invoices
			.sort((a, b) => {
				if      (a.invoice_date < b.invoice_date) return -1;
				else if (a.invoice_date > b.invoice_date) return  1;
				return 0;
			});
		
		for (let i=0; i<sorted_advance_invoices.length; i++) {
			const invoice = sorted_advance_invoices[i];
			
			const amount = Math.min(invoice.advance_payment_amount - invoice.used_advance_payment_amount, remaining_amount);
			if (amount > 0) {
				remaining_amount -= amount;
				ids.push(invoice.id_invoice);
				if (remaining_amount <= 0) {
					break;
				}
			}
		}
		
		return ids;
	}
	
	render() {
		const selected_advance_invoices_amounts = this.calculateAdvanceInvoicesSelectedAmounts();
		const advance_payment_amount = Object.values(selected_advance_invoices_amounts)
			.reduce((curr_value, item_val) => curr_value + item_val, 0);
		
		const total_amounts = this.calculateTotalAmounts();
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeInvoiceDialog()}
			className='invoice-dialog'>
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Priprava računa</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeInvoiceDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-col'>
						<h6 className='bp3-heading'>Nosilec računa</h6>
						
						<div className='mb-4'>
							<HTMLSelect
								options={
									[
										this.state.id_customer,
										...this.props.guestIdCustomers.filter(id => id != this.state.id_customer),
									].map(id => {
										const customer = this.props.customers[id];
										if (customer === undefined) return null;
										
										return {
											label: customer.type == 'natural' ? customer.surname + ' ' + customer.name : customer.company_name,
											value: id,
										};
									})
								}
								value={this.state.selected_id_customer}
								onChange={(e) => {
									this.setState({
										selected_id_customer: e.currentTarget.value,
									});
								}} />
						</div>
						
						<h6 className='bp3-heading'>Neplačane obveznosti</h6>
						
						{this.state.items.map((item, item_idx) => {
							const count_checked = item.items.reduce((curr_value, item_val) => curr_value + (item_val.checked ? 1 : 0), 0);
							
							return <FormGroup key={'invoice-item-' + item.user}>
								<Checkbox
									className='flex flex-row'
									checked={count_checked == item.items.length}
									indeterminate={count_checked > 0 && count_checked != item.items.length}
									onChange={event => {
										var items = this.state.items;
										
										for (let i=0; i<items[item_idx].items.length; i++) {
											items[item_idx].items[i].checked = event.target.checked;
										}
										
										this.setState({
											items,
										});
									}}
								>
									<div className='flex-grow flex-row'>
										<span className={ item.user == this.state.invoice_holder ? 'font-bold' : '' }>
											{ item.user }
										</span>
										{item.id_user == -1 || item.id_user == -2 || item.id_user == -3 ? null :
											<Button
												className='ml-2'
												icon='follower'
												disabled={ item.user == this.state.invoice_holder }
												minimal={true}
												small={true}
												onClick={() => {
													this.setState({
														invoice_holder: item.user,
													});
												}} />
										}
									</div>
									<div className='font-bold'>{ this.formatNumber(item.sum) } EUR</div>
								</Checkbox>
								{item.items.map((subitem, subitem_idx) => {
									return <Checkbox
										key={'invoice-item-' + item.user + '-subitem-' + subitem_idx}
										className='ml-8 flex flex-row'
										checked={ subitem.checked }
										onChange={event => {
											var items = this.state.items;
											items[item_idx].items[subitem_idx].checked = event.target.checked;
											
											this.setState({
												items,
											});
										}}
									>
										<div className='flex-grow flex flex-col'>
											<div>{ subitem.count }x { subitem.title }</div>
											{subitem.subtitle === undefined || subitem.subtitle.length == 0 ? null :
												<div className='text-gray-500'>{ subitem.subtitle }</div>
											}
										</div>
										<div>{ this.formatNumber(subitem.sum) } EUR</div>
									</Checkbox>;
								})}
							</FormGroup>;
						})}
						
						<div className='flex justify-between'>
							<div>
								<ButtonGroup>
									<Button onClick={this.selectNone}>
										Izberi nobenega
									</Button>
									<Button onClick={this.selectAll}>
										Izberi vse
									</Button>
								</ButtonGroup>
							</div>
						</div>
						
						<Divider className='mt-2 mb-2' />
						
						<div className='flex flex-row justify-between'>
							<div className='flex flex-row flex-1 justify-start'>
								{this.props.advance_invoices.length == 0 ? null :
									<div className='mb-4 mr-4 flex-1'>
										<div className='font-bold mb-2'>Avansi</div>
										<div className='flex flex-col'>
											{this.props.advance_invoices
												.sort((a, b) => {
													if      (a.invoice_date < b.invoice_date) return -1;
													else if (a.invoice_date > b.invoice_date) return  1;
													return 0;
												}).map(item => {
													const advance_invoice_consumption_ids = this.props.id_advance_invoice_consumption_by_id_advance_invoices[
														item.id_invoice
													] || [];
													
													let tooltip_content = null;
													if (advance_invoice_consumption_ids.length > 0) {
														tooltip_content = <div>
															<div className='font-bold'>Že uporabljeno</div>
															<div className='grid grid-cols-2 col-gap-4 row-gap-1'>
																{advance_invoice_consumption_ids.map(id_consumer_invoice => {
																	const advance_invoice_consumption =
																		this.props.advance_invoice_consumptions[
																			id_consumer_invoice
																		];
																	if (advance_invoice_consumption === undefined) return null;
																	
																	const invoice = this.props.invoices[
																		advance_invoice_consumption.consumer_id_invoice
																	];
																	
																	return <React.Fragment key={id_consumer_invoice}>
																		<div>{
																			invoice.invoice_number_internal
																		}</div>
																		<div className='text-right'>{
																			this.formatNumber(
																				advance_invoice_consumption.amount
																			) + ' EUR'
																		}</div>
																	</React.Fragment>;
																})}
															</div>
														</div>;
													}
													
													const used_amount = selected_advance_invoices_amounts[item.id_invoice] || 0;
													
													return <div key={'prepayment--' + item.id_invoice}>
														<div>
															<Checkbox
																checked={this.state.selected_advance_invoices.indexOf(item.id_invoice) != -1}
																onChange={event => {
																	const selected_advance_invoices = [...this.state.selected_advance_invoices];
																	const idx = selected_advance_invoices.indexOf(item.id_invoice);
																	
																	if (event.target.checked) {
																		if (idx == -1) {
																			selected_advance_invoices.push(item.id_invoice);
																		}
																	}
																	else {
																		if (idx != -1) {
																			selected_advance_invoices.splice(idx, 1);
																		}
																	}
																	
																	this.setState({ selected_advance_invoices });
																}}
																className='flex flex-row'
															>
																<div className='flex-1 mr-4 leading-3'>{item.invoice_number}</div>
																<div className='w-20 text-right leading-3'>{
																	this.formatNumber(
																		parseFloat(item.advance_payment_amount)
																	)
																} EUR</div>
																<div className='text-right leading-3' style={{ width: '9rem' }}>(ostane še {
																	this.formatNumber(
																		parseFloat(item.advance_payment_amount) -
																		parseFloat(item.used_advance_payment_amount) -
																		used_amount
																	)
																} EUR)</div>
																<div className='w-6 ml-2' style={{ marginTop: -2 }}>
																	{tooltip_content === null ? null :
																		<Tooltip content={tooltip_content}>
																			<Icon
																				icon='info-sign'
																				color='#cccccc' />
																		</Tooltip>
																	}
																</div>
															</Checkbox>
														</div>
													</div>;
												})
											}
										</div>
									</div>
								}
							</div>
							
							<div className='text-right' style={{ width: 260 }}>
								<div className='flex flex-row mt-1 mb-1'>
									<div className='inline-block mr-2' style={{ width: 180 }}>Storitve na rezervaciji:</div>
									<div className='flex-1 inline-block'>{this.formatNumber(
										total_amounts.without_tourist_tax
									)} EUR</div>
								</div>
								<div className='flex flex-row mt-1 mb-1'>
									<div className='inline-block mr-2' style={{ width: 180 }}>Dodatne storitve/artikli gosta:</div>
									<div className='flex-1 inline-block'>{this.formatNumber(
										total_amounts.customer_services
									)} EUR</div>
								</div>
								<div className='flex flex-row mt-1 mb-1'>
									<div className='inline-block mr-2' style={{ width: 180 }}>Turistična taksa:</div>
									<div className='flex-1 inline-block'>{this.formatNumber(
										total_amounts.tourist_tax
									)} EUR</div>
								</div>
								
								{this.props.advance_invoices.length == 0 ? null :
									<div className='flex flex-row mt-1 mb-1'>
										<div className='inline-block mr-2' style={{ width: 180 }}>Znesek avansov:</div>
										<div className='flex-1 inline-block'>{this.formatNumber(-advance_payment_amount)} EUR</div>
									</div>
								}
								
								<div className='flex flex-row mt-3 mb-1 font-bold'>
									<div className='inline-block mr-2' style={{ width: 180 }}>Skupaj za plačilo:</div>
									<div className='flex-1 inline-block'>{this.formatNumber(
										total_amounts.total - advance_payment_amount
									)} EUR</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeInvoiceDialog()}>
							Prekliči
						</Button>
						<Button
							onClick={() => this.closeWithType('invoice')}>
							Pripravi fakturo
						</Button>
						<Button
							intent={Intent.PRIMARY}
							disabled={this.props.current_id_cash_register == 0}
							onClick={() => this.closeWithType('cash-invoice')}>
							Pripravi račun
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
InvoiceDialog.propTypes = {
	closeInvoiceDialog: PropTypes.func,
	preselect: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
		items:         state.CodeTablesSlice.items,
		customers:     state.CodeTablesSlice.customers,
		tourist_taxes: state.CodeTablesSlice.tourist_taxes,
		invoices:                                              state.BusinessSlice.invoices,
		advance_invoice_consumptions:                          state.BusinessSlice.advance_invoice_consumptions,
		id_advance_invoice_consumption_by_id_advance_invoices: state.BusinessSlice.id_advance_invoice_consumption_by_id_advance_invoices,
		current_id_cash_register:                              state.SettingsSlice.current_id_cash_register,
	};
}

export default connect(mapStateToProps)(InvoiceDialog);
