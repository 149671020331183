import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	Intent,
	Navbar,
	TextArea,
	Checkbox,
} from '@blueprintjs/core';

import TextWithTooltip from '../TextWithTooltip';

class EditSettingsItemDocumentDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			logo_position:        props.item.logo_position,
			print_final_text:     props.item.print_final_text,
			print_final_text_de:  props.item.print_final_text_de,
			print_final_text_en:  props.item.print_final_text_en,
			print_final_text_pos: props.item.print_final_text_pos,
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeDialog()}
			className='edit-settings-item-dialog document'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>{'Uredi nastavitev | ' + this.props.title}</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY + ' flex flex-col'}>
					<div className='flex flex-1 flex-col items-stretch'>
						<div className='flex flex-row'>
							<div className='mr-2'>Logo poravnan na levo:</div>
							<Checkbox
								checked={this.state.logo_position == 'left'}
								onChange={event => {
									this.setState({
										logo_position: event.target.checked ? 'left' : 'right',
									});
								}} />
						</div>
						
						<div className='flex flex-row items-stretch'>
							<FormGroup
								className='edit-settings-item-dialog-document-value flex-1 mr-2'>
								<TextWithTooltip
									label='Zaključni tekst (slovenščina)'
									tooltip='Zaključni tekst (slovenščina)' />
								<TextArea
									growVertically={false}
									large={true}
									fill={true}
									intent={Intent.PRIMARY}
									onChange={event => {
										this.setState({
											print_final_text: event.target.value,
										});
									}}
									value={this.state.print_final_text} />
							</FormGroup>
							<FormGroup
								className='edit-settings-item-dialog-document-value flex-1'>
								<TextWithTooltip
									label='Zaključni tekst (angleščina)'
									tooltip='Zaključni tekst (angleščina)' />
								<TextArea
									growVertically={false}
									large={true}
									fill={true}
									intent={Intent.PRIMARY}
									onChange={event => {
										this.setState({
											print_final_text_en: event.target.value,
										});
									}}
									value={this.state.print_final_text_en} />
							</FormGroup>
						</div>
						<div className='flex flex-row items-stretch'>
							<FormGroup
								className='edit-settings-item-dialog-document-value flex-1 mr-2'>
								<TextWithTooltip
									label='Zaključni tekst (nemščina)'
									tooltip='Zaključni tekst (nemščina)' />
								<TextArea
									growVertically={false}
									large={true}
									fill={true}
									intent={Intent.PRIMARY}
									onChange={event => {
										this.setState({
											print_final_text_de: event.target.value,
										});
									}}
									value={this.state.print_final_text_de} />
							</FormGroup>
							{!this.props.display_pos ? null :
								<FormGroup
									className='edit-settings-item-dialog-document-value flex-1'>
									<TextWithTooltip
										label='Zaključni tekst (POS)'
										tooltip='Zaključni tekst (POS)' />
									<TextArea
										growVertically={false}
										large={true}
										fill={true}
										intent={Intent.PRIMARY}
										onChange={event => {
											this.setState({
												print_final_text_pos: event.target.value,
											});
										}}
										value={this.state.print_final_text_pos} />
								</FormGroup>
							}
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								this.props.closeDialog({
									...this.props.item,
									logo_position:        this.state.logo_position,
									print_final_text:     this.state.print_final_text,
									print_final_text_de:  this.state.print_final_text_de,
									print_final_text_en:  this.state.print_final_text_en,
									print_final_text_pos: this.state.print_final_text_pos,
								});
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
EditSettingsItemDocumentDialog.propTypes = {
	closeDialog: PropTypes.func,
	item: PropTypes.object,
};

export default EditSettingsItemDocumentDialog;
