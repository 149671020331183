import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	HTMLSelect,
} from '@blueprintjs/core';

import TextWithTooltip from '../TextWithTooltip';
import { AppToaster }  from '../AppToaster';

class AddEditCashRegisters extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_cash_register:    props.item === null ? uuid.v4() : props.item.id_cash_register,
			internal_code:       props.item === null ? '' : props.item.internal_code,
			furs_code:           props.item === null ? '' : props.item.furs_code,
			title:               props.item === null ? '' : props.item.title,
			expense:             props.item === null ? 0  : props.item.expense,
			income:              props.item === null ? 0  : props.item.income,
			log:                 props.item === null ? '' : props.item.log,
			id_business_premise: props.item === null ? -1 : props.item.id_business_premise,
			business_premise:    props.item === null ? '' : props.item.business_premise,
			id_warehouse:        props.item === null ? -1 : props.item.id_warehouse,
			validation_failed_fields: [],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-cash-register-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi blagajno</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='add-edit-cash-register-dialog--internal_code'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('internal_code') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Interna šifra'
								tooltip='Interna šifra' />
							<InputGroup
								id='add-edit-cash-register-dialog--internal_code'
								value={this.state.internal_code}
								onChange={event => {
									if (event.target.value.length > 3) return;
									this.setState({
										internal_code: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-cash-register-dialog--furs_code'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('furs_code') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Identifikator blagajne (FURS)'
								tooltip='Identifikator blagajne (FURS)' />
							<InputGroup
								id='add-edit-cash-register-dialog--furs_code'
								value={this.state.furs_code}
								onChange={event => {
									this.setState({
										furs_code: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-cash-register-dialog--title'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('title') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Naziv'
								tooltip='Naziv' />
							<InputGroup
								id='add-edit-cash-register-dialog--title'
								value={this.state.title}
								onChange={event => {
									this.setState({
										title: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-cash-register-dialog--id_business_premise'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('id_business_premise') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Poslovni prostor'
								tooltip='Poslovni prostor' />
							<HTMLSelect
								fill={true}
								options={
									[ { label: 'Izberi poslovni prostor', value: -1 } ].concat(
										Object.values(this.props.business_premises).sort((a, b) => a.ord - b.ord).map(function(item) {
											return { label: item.furs_code, value: item.id_business_premise };
										})
									)
								}
								onChange={(e) => {
									this.setState({
										id_business_premise: e.currentTarget.value,
									});
								}}
								value={this.state.id_business_premise} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-cash-register-dialog--id_warehouse'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('id_warehouse') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Skladišče'
								tooltip='Skladišče' />
							<HTMLSelect
								fill={true}
								options={
									[ { label: 'Izberi skladišče', value: -1 } ].concat(
										Object.values(this.props.warehouses).sort((a, b) => a.ord - b.ord).map(function(item) {
											return { label: item.title, value: item.id_warehouse };
										})
									)
								}
								onChange={(e) => {
									this.setState({
										id_warehouse: e.currentTarget.value,
									});
								}}
								value={this.state.id_warehouse} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = {
									id_cash_register:    this.state.id_cash_register,
									internal_code:       this.state.internal_code,
									furs_code:           this.state.furs_code,
									title:               this.state.title,
									expense:             this.state.expense,
									income:              this.state.income,
									log:                 this.state.log,
									id_business_premise: this.state.id_business_premise,
									business_premise:    this.state.business_premise,
									id_warehouse:        this.state.id_warehouse,
								};
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (new_item.internal_code.length == 0) {
									validation_failed_fields.push('internal_code');
									validation_failed_field_titles.push('Interna šifra');
								}
								if (new_item.furs_code.length == 0) {
									validation_failed_fields.push('furs_code');
									validation_failed_field_titles.push('identifikator blagajne (FURS)');
								}
								if (new_item.title.length == 0) {
									validation_failed_fields.push('title');
									validation_failed_field_titles.push('naziv');
								}
								if (new_item.id_business_premise === null || new_item.id_business_premise == -1) {
									validation_failed_fields.push('id_business_premise');
									validation_failed_field_titles.push('poslovni prostor');
								}
								if (new_item.id_warehouse === null || new_item.id_warehouse == -1) {
									validation_failed_fields.push('id_warehouse');
									validation_failed_field_titles.push('skladišče');
								}
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
										</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								// save item
								this.props.closeAddEditItemDialog(new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditCashRegisters.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		business_premises: state.CodeTablesSlice.business_premises,
		warehouses:        state.CodeTablesSlice.warehouses,
	};
}

export default connect(mapStateToProps)(AddEditCashRegisters);
