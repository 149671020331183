import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Intent,
	Navbar,
	FormGroup,
	InputGroup,
	Checkbox,
	Tag,
	NumericInput,
	Icon,
} from '@blueprintjs/core';

import TextWithTooltip     from './TextWithTooltip';
import { parseFloatLocal } from '../numbers';

class ItemLabelPrintDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			items_with_counts: props.items.map(item => {
				return { item, count: props.counts === null || props.counts === undefined || props.counts[item.item.id_item] === undefined ? 1 : props.counts[item.item.id_item] };
			}),
		};
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={false}
			onClose={() => { this.props.closeDialog(null); }}
			className='item-label-print-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Tiskanje nalepk</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => { this.props.closeDialog(null); }} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className={'grid gap-4 list'}>
						{this.state.items_with_counts.map((item_with_count, idx) =>
							<React.Fragment key={item_with_count.item.item.id_item}>
								<div>
									<div>{item_with_count.item.item.internal_code}</div>
									<div className={'text-gray-500'}>{item_with_count.item.item.title}</div>
								</div>
								<div className='text-right'>{item_with_count.item.price.toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								)}</div>
								<div>
									<NumericInput
										value={item_with_count.count}
										min={0}
										fill={true}
										buttonPosition='none'
										onValueChange={val => {
											const items_with_counts = [...this.state.items_with_counts];
											items_with_counts[idx].count = val;
											
											this.setState({
												items_with_counts,
											});
										}}
										rightElement={
											<Button icon={<Icon tabIndex={-1} icon='trash' />} disabled={item_with_count.count <= 0} tabIndex={-1} onClick={() => {
												const items_with_counts = [...this.state.items_with_counts];
												items_with_counts[idx].count = 0;
												
												this.setState({
													items_with_counts,
												});
											}} />
										} />
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => { this.props.closeDialog(null); }}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => { this.props.closeDialog(this.state.items_with_counts); }}>
							Zapri in natisni
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps)(ItemLabelPrintDialog);
