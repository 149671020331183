import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';
import uuid   from 'uuid';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	HTMLSelect,
	Intent,
	Navbar,
	NumericInput,
	TextArea,
	ControlGroup,
	Alert,
} from '@blueprintjs/core';

import TextWithTooltip                                               from '../TextWithTooltip';
import CustomerSelect                                                from '../CustomerSelect';
import SelectCustomerDialog                                          from './SelectCustomerDialog';
import SimpleSuggest                                                 from '../SimpleSuggest';
import { AppToaster }                                                from '../AppToaster';
import Documents                                                     from '../Documents';
import EditInvoiceDialog                                             from '../business/EditInvoiceDialog';
import WaitFiscalVerificationDialog                                  from '../business/WaitFiscalVerificationDialog';
import { addInvoice, addInvoices, addAdvanceInvoiceConsumptions }    from '../../slices/BusinessSlice';
import { saveInvoice, loadInvoices, loadAdvanceInvoiceConsumptions } from '../../api/Business';
import { addDocumentAssociations }                                   from '../../slices/DocumentSlice';
import InvoiceCreator                                                from '../../helpers/InvoiceCreator';
import { addActivityReservation  }                                   from '../../slices/ReservationSlice';
import { saveActivityReservation }                                   from '../../api/Reservations';
import DocumentsHelper                                               from '../../helpers/DocumentsHelper';
import InvoiceHelper                                                 from '../../helpers/Invoice';
import PriceHelper                                                   from '../../helpers/PriceHelper';
import UserTag                                                       from '../UserTag';

import { enqueueInvoiceOnA4PrinterQueue, enqueueInvoiceOnPosPrinterQueue } from '../../slices/AppSlice';

class EditActivityItemDialog extends Component {
	constructor(props) {
		super(props);
		
		this.openSelectCustomerDialog  = this.openSelectCustomerDialog .bind(this);
		this.closeSelectCustomerDialog = this.closeSelectCustomerDialog.bind(this);
		this.openOfferDialog           = this.openOfferDialog          .bind(this);
		this.openEditInvoiceDialog     = this.openEditInvoiceDialog    .bind(this);
		this.closeEditInvoiceDialog    = this.closeEditInvoiceDialog   .bind(this);
		this.saveItem                  = this.saveItem                 .bind(this);
		this.getItem                   = this.getItem                  .bind(this);
		this.validate                  = this.validate                 .bind(this);
		this.getUserByUsername         = this.getUserByUsername        .bind(this);
		
		this.state = {
			item:                           this.props.item,
			internal_code:                  this.props.item === null ? null       : this.props.item.internal_code,
			id_customer:                    this.props.item === null ? -1         : this.props.item.id_customer,
			guest_count:                    this.props.item === null ? 1          : this.props.item.guest_count,
			guest_child_count:              this.props.item === null ? 0          : this.props.item.guest_child_count,
			note:                           this.props.item === null ? ''         : this.props.item.note,
			id_channel:                     this.props.item === null ? null       : this.props.item.id_channel,
			phone:                          this.props.item === null ? ''         : this.props.item.phone,
			email:                          this.props.item === null ? ''         : this.props.item.email,
			reservation_date:               this.props.item === null ? this.props.reservation_date : new Date(this.props.item.reservation_date),
			status:                         this.props.item === null ? 'new'      : this.props.item.status,
			status_2:                       this.props.item === null ? null       : this.props.item.status_2,
			deleted:                        this.props.item === null ? false      : this.props.item.deleted,
			username:                       this.props.item === null ? this.props.user.username : this.props.item.username,
			select_customer_dialog_open:    false,
			select_customer_dialog_surname: '',
			select_customer_dialog_item:    null,
			validation_failed_fields:       [],
			create_invoice_dialog_open: false,
			create_invoice_dialog_item: null,
			create_invoice_dialog_type: '',
			create_invoice_dialog_selected_advance_invoices_amounts: {},
			wait_fiscal_verification_dialog_open:       false,
			wait_fiscal_verification_dialog_id_invoice: null,
			delete_requested: false,
		};
	}
	
	openSelectCustomerDialog(query, item) {
		item = item || null;
		
		this.setState({
			select_customer_dialog_open: true,
			select_customer_dialog_surname: query,
			select_customer_dialog_item: item,
		});
	}
	closeSelectCustomerDialog(id_customer) {
		const state = {
			select_customer_dialog_open: false,
		};
		if (id_customer !== undefined) {
			state.id_customer = id_customer;
		}
		
		this.setState(state);
	}
	
	openOfferDialog() {
		const starts_at_arr = this.props.time_slot.starts_at.split(':');
		const time_label    = starts_at_arr.length != 3 ? '00:00' : starts_at_arr[0] + ':' + starts_at_arr[1];
		
		let reservation_title =
			this.props.activity.title +
			' • ' +
			time_label +
			' • ' +
			moment(this.props.reservation_date).format('DD. MM. YYYY');
		
		const people_counts = [];
		if (this.props.activity.id_item !== null) {
			if (this.state.guest_count > 0) {
				people_counts.push({ count: this.state.guest_count, type: 'adult' });
			}
			if (this.state.guest_child_count > 0) {
				people_counts.push({ count: this.state.guest_child_count, type: 'child' });
			}
		}
		
		const new_item = InvoiceCreator.CreateInvoiceFromInitialData({
			id_invoice:            uuid.v4(),
			reverse_invoice:       false,
			id_customer:           this.state.id_customer,
			invoice_date:          moment().toISOString(true),
			invoice_type:          'offer',
			reservation:           reservation_title,
			service_date_end:      this.state.reservation_date === null ? null : moment(this.state.reservation_date).toISOString(true),
			service_date_start:    this.state.reservation_date === null ? null : moment(this.state.reservation_date).toISOString(true),
			original_id_invoice:   this.state.item === null ? undefined : this.state.item.id_activity_reservation,
			original_invoice_type: 'activity-reservation',
			items:                 people_counts.map((people_count, idx) => {
				const item            = this.props.items[this.props.activity.id_item];
				const id_price_list   = PriceHelper.FindBestPriceListMatch(new Date(), item.price_lists);
				const item_price_list = id_price_list === null ? null : item.price_lists[id_price_list];
				
				const measuring_unit  = this.props.measuring_units[item.id_measuring_unit];
				const tax_rate        = item_price_list === undefined ? null : this.props.tax_rates[item_price_list.id_tax_rate];
				
				return InvoiceCreator.CreateInvoiceItemFromInitialData({
					id_invoice_item:                         uuid.v4(),
					description:                             '',
					id_item:                                 item.id_item,
					id_item_price_list:                      id_price_list,
					id_tax_rate:                             item_price_list === undefined ? null : item_price_list.id_tax_rate,
					item_measuring_unit_code:                measuring_unit.title,
					item_measuring_unit_decimal_digit_count: measuring_unit.decimal_digit_count,
					price:                                   item_price_list.price,
					quantity:                                people_count.count,
					discount:                                people_count.type == 'adult' ? 0 : this.props.activity.item_child_discount,
					tax_rate,
					item_type:                               item.type,
					item_ean:                                item.ean,
					item_title:                              item.title,
					ord:                                     idx,
					id_warehouse: item.item_type != 'item' ? null : InvoiceHelper.findCurrentIdWarehouse(
						this.props.current_id_cash_register === undefined || this.props.current_id_cash_register === null ? null :
							this.props.cash_registers[this.props.current_id_cash_register],
						this.props.warehouses
					),
				});
			}),
		});
		
		this.openEditInvoiceDialog('offer', new_item, {});
	}
	openEditInvoiceDialog(type, item, selected_advance_invoices_amounts) {
		this.setState({
			create_invoice_dialog_open: true,
			create_invoice_dialog_type: type,
			create_invoice_dialog_item: item,
			create_invoice_dialog_selected_advance_invoices_amounts: selected_advance_invoices_amounts,
		});
	}
	async closeEditInvoiceDialog(item, open_create_invoice_type, finish_invoice) {
		this.setState({
			create_invoice_dialog_open: false,
			create_invoice_dialog_item: null,
		});
		
		if (item !== undefined) {
			this.props.dispatch(addInvoice({
				item,
				token: this.props.token,
			}));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			//self.increaseLoading();
			const new_items = await saveInvoice(this.props.api_url, item, token, dispatch);
			if (new_items !== null) {
				//self.decreaseLoading();
				
				if (new_items.invoices !== undefined) {
					dispatch(addInvoices(new_items.invoices));
				}
				if (new_items.document_associations !== undefined) {
					dispatch(addDocumentAssociations(new_items.document_associations));
				}
				if (new_items.advance_invoice_consumptions !== undefined) {
					dispatch(addAdvanceInvoiceConsumptions(new_items.advance_invoice_consumptions));
				}
				
				/*for (let i=0; i<new_items.invoices.length; i++) {
					self.addAndSaveDocumentAssociation(
						new_items.invoices[i].id_invoice,
						new_items.invoices[i].invoice_type,
						self.state.reservation.id_reservation,
						'reservation'
					);
				}*/
				
				if (finish_invoice && this.props.document_types[item.invoice_type].fiscal_verification_enabled) {
					this.setState({
						wait_fiscal_verification_dialog_open:       true,
						wait_fiscal_verification_dialog_id_invoice: item.id_invoice,
					});
				}
			}
		}
	}
	
	async saveItem() {
		const item = this.getItem();
		if (!this.validate(item)) return;
		
		let new_item = {};
		if (this.state.item !== null) { // existing item
			new_item = {...this.state.item};
			new_item.guest_count       = item.guest_count;
			new_item.guest_child_count = item.guest_child_count;
			new_item.note              = item.note;
			new_item.id_channel        = item.id_channel;
			new_item.id_customer       = item.id_customer;
			new_item.phone             = '';
			new_item.email             = '';
			new_item.reservation_date  = item.reservation_date;
			new_item.status            = item.status;
			new_item.status_2          = item.status_2 == '' ? null : item.status_2;
			new_item.deleted           = item.deleted;
		}
		else { // new item
			new_item = {
				id_activity_reservation: -1,
				guest_count:             item.guest_count,
				guest_child_count:       item.guest_child_count,
				note:                    item.note,
				id_channel:              item.id_channel,
				id_customer:             item.id_customer,
				phone:                   '',
				email:                   '',
				status:                  item.status,
				status_2:                item.status_2 == '' ? null : item.status_2,
				reservation_date:        item.reservation_date,
				id_accommodation_item_schedule_time_slot_activity:
					this.props.accommodation_item_schedule_time_slot_activity.id_accommodation_item_schedule_time_slot_activity,
				username:                this.state.username,
				deleted:                 item.deleted,
			};
		}
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		dispatch(addActivityReservation({
			item: new_item,
			token,
		}));
		new_item = await saveActivityReservation(this.props.api_url, new_item, token);
		if (new_item !== null) {
			this.setState({ item: new_item });
			
			dispatch(addActivityReservation({
				item: new_item,
				token,
			}));
		}
	}
	
	getItem() {
		return {
			id_customer:       this.state.id_customer,
			guest_count:       this.state.guest_count,
			guest_child_count: this.state.guest_child_count,
			note:              this.state.note,
			id_channel:        this.state.id_channel,
			phone:             '',
			email:             '',
			reservation_date:  moment(this.state.reservation_date) .format('YYYY-MM-DD'),
			status:            this.state.status,
			status_2:          this.state.status_2 == '' ? null : this.state.status_2,
			deleted:           this.state.status != 'reversed' && this.state.status != 'no-show' && this.state.deleted,
		};
	}
	
	validate(item) {
		// validate missing/empty fields
		const validation_failed_fields       = [];
		const validation_failed_field_titles = [];
		
		if (item.id_customer == -1) {
			validation_failed_fields.push('id_customer');
			validation_failed_field_titles.push('nosilec');
		}
		if (item.id_channel === null) {
			validation_failed_fields.push('id_channel');
			validation_failed_field_titles.push('prodajni kanal');
		}
		
		this.setState({ validation_failed_fields });
		
		if (validation_failed_fields.length > 0) {
			AppToaster.show({
				message: <div>
					Za shranjevanje, izpolnite vsaj polja:
					<br />
					{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
				</div>,
				intent: 'danger',
				icon: 'issue'
			});
			return false;
		}
		
		return true;
	}
	
	getUserByUsername(username) {
		if (this.props.users === null) return null;
		
		for (let id_user in this.props.users) {
			const user = this.props.users[id_user];
			if (user !== undefined && user.username == username) {
				return user;
			}
		}
		
		return null;
	}
	
	render() {
		const customer = this.state.id_customer == -1 ? null : this.props.customers[this.state.id_customer];
		
		const starts_at_arr = this.props.time_slot.starts_at.split(':');
		const time_label    = starts_at_arr.length != 3 ? '00:00' : starts_at_arr[0] + ':' + starts_at_arr[1];
		
		const weekdays = [
			'nedelja',
			'ponedeljek',
			'torek',
			'sreda',
			'četrtek',
			'petek',
			'sobota',
		];
		
		// get connected documents, but ignore offers
		const documents = this.state.item === null ? [] : Object.values(DocumentsHelper.GetDocuments(
			this.props.document_associations,
			this.state.item.id_activity_reservation,
			1
		)).filter(x => x.source_type != 'offer' && x.target_type != 'offer');
		const consider_deleted = this.state.deleted || this.state.status == 'reversed' || this.state.status == 'no-show';
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeEditActivityItemDialog()}
			className={'edit-activity-item-dialog ' + (this.state.item === null ? '' : 'wide')}>
			
			{!this.state.select_customer_dialog_open ? null :
				<SelectCustomerDialog
					closeSelectCustomerDialog={this.closeSelectCustomerDialog}
					surname={this.state.select_customer_dialog_surname}
					item={this.state.select_customer_dialog_item} />
			}
			
			{!this.state.create_invoice_dialog_open ? null :
				<EditInvoiceDialog
					closeEditInvoiceDialog={this.closeEditInvoiceDialog}
					item={this.state.create_invoice_dialog_item}
					type={this.state.create_invoice_dialog_type}
					selected_advance_invoices_amounts={this.state.create_invoice_dialog_selected_advance_invoices_amounts} />
			}
			
			{!this.state.wait_fiscal_verification_dialog_open ? null :
				<WaitFiscalVerificationDialog
					closeWaitFiscalVerificationDialog={(print) => {
						this.setState({ wait_fiscal_verification_dialog_open: false });
						
						if (print) {
							const invoice = this.props.invoices[this.state.wait_fiscal_verification_dialog_id_invoice];
							if (this.props.general_settings.auto_print_type == 'a4') {
								this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'invoice', lang: 'sl', invoice }));
							}
							else if (this.props.general_settings.auto_print_type == 'pos') {
								this.props.dispatch(enqueueInvoiceOnPosPrinterQueue({ type: 'invoice', invoice }));
							}
						}
					}}
					id_invoice={this.state.wait_fiscal_verification_dialog_id_invoice} />
			}
			
			{!this.state.delete_requested ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Da'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={async () => {
						this.setState({ deleted: true, delete_requested: false });
						
						const new_item = this.getItem();
						if (!this.validate(new_item)) return;
						
						new_item.deleted = true;
						
						this.props.closeEditActivityItemDialog(this.state.item, new_item);
					}}
					onCancel={() => {
						this.setState({ delete_requested: false });
					}}>
					<div>
						<div className='mb-3'><strong>Brisanje rezervacije?</strong></div>
						
						<div>
							Rezervacija:
							<span> </span>
							<div>{this.props.activity.title}</div>
							<div>{
								weekdays[moment(this.props.reservation_date).day()] +
								', ' +
								moment(this.props.reservation_date).format('DD. MM. YYYY') +
								' ob ' + time_label
							}</div>
						</div>
						<div className='mb-3'>Nosilec: {
							this.state.id_customer === null || this.state.id_customer === undefined || this.state.id_customer == -1 || this.props.customers[this.state.id_customer] === undefined ? '' :
							(
								this.props.customers[this.state.id_customer].type == 'natural' ?
									this.props.customers[this.state.id_customer].surname + ' ' + this.props.customers[this.state.id_customer].name
									:
									this.props.customers[this.state.id_customer].company_name
							)
						}</div>
						
						<div className='mt-4 mb-4'>
							Izbrisana rezervacija se bo evidentirala v seznamu rezervacij.
						</div>
					</div>
				</Alert>
			}
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>{
							'Rezervacija aktivnosti' + (
								this.state.internal_code === null ? '' : ' • ' + this.state.internal_code
							)
						}</Navbar.Heading>
						<HTMLSelect
							style={{ backgroundColor: '#2c85c1' }}
							options={
								[
									{ label: 'Nova',           value: 'new',                      disabled: consider_deleted },
									{ label: 'V potrjevanju',  value: 'waiting-for-confirmation', disabled: consider_deleted },
									{ label: 'Izdana ponudba', value: 'offer-sent',               disabled: consider_deleted },
									{ label: 'Potrjena',       value: 'confirmed',                disabled: consider_deleted },
									{ label: 'Izdan avans',    value: 'advance-invoice-sent',     disabled: consider_deleted },
									{ label: 'Plačana',        value: 'closed',                   disabled: consider_deleted },
									{ label: 'Stornirana',     value: 'reversed',                 disabled: false },
									{ label: 'Neprihod',       value: 'no-show',                  disabled: false },
								]
							}
							value={this.state.status}
							onChange={(e) => {
								const val = e.currentTarget.value;
								
								this.setState({
									status: val,
								});
							}} />
							<HTMLSelect
								style={{ backgroundColor: '#2c85c1' }}
								className='mr-6'
								options={
									[
										{ label: 'Izberi status po meri', value: '' },
										{ label: this.props.general_settings.title_custom_status_1, value: 'custom-status-1' },
										{ label: this.props.general_settings.title_custom_status_2, value: 'custom-status-2' },
										{ label: this.props.general_settings.title_custom_status_3, value: 'custom-status-3' },
										{ label: this.props.general_settings.title_custom_status_4, value: 'custom-status-4' },
										{ label: this.props.general_settings.title_custom_status_5, value: 'custom-status-5' },
										{ label: this.props.general_settings.title_custom_status_6, value: 'custom-status-6' },
										{ label: this.props.general_settings.title_custom_status_7, value: 'custom-status-7' },
									]
								}
								value={this.state.status_2 ?? ''}
								onChange={(e) => {
									this.setState({
										status_2: e.currentTarget.value,
									});
								}} />
						{this.state.item === null ? null :
							<Button
								minimal={false}
								disabled={consider_deleted}
								icon='trash'
								text='Izbriši'
								intent='danger'
								className='ml-6'
								onClick={() => {
									this.setState({ delete_requested: true });
								}} />
						}
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<UserTag user={this.getUserByUsername(this.state.username)} reservation={this.state.item} className='mr-2 mt-1' />
						
						<Button minimal={true} icon='cross' onClick={() => this.props.closeEditActivityItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-row'>
						<div className={'flex pr-2 flex-col ' + (this.state.item === null ? 'flex-1' : 'w-2/5')}>
							<div className='mb-6 flex flex-row justify-center text-sm'>
								<div>{this.props.activity.title}</div>
								<div className='ml-2 mr-2'>•</div>
								<div>{time_label}</div>
								<div className='ml-2 mr-2'>•</div>
								<div>{
									weekdays[moment(this.props.reservation_date).day()] +
									', ' +
									moment(this.props.reservation_date).format('DD. MM. YYYY')
								}</div>
							</div>
							<FormGroup
								className={
									(this.state.validation_failed_fields.indexOf('id_customer') == -1 ?
										'' : 'validation-failed')
								}>
								<CustomerSelect
									itemSelected={item => {
										this.setState({
											id_customer: item.id_customer,
										});
									}}
									addPersonClick={query => { this.openSelectCustomerDialog(query); }}
									fieldTitle='Nosilec' />
							</FormGroup>
							{customer === null || customer === undefined ? null :
								<div className='mb-4'>
									<div className='flex flex-row justify-between items-start'>
										<div className='flex-1 flex flex-row'>
											<div className='w-2/4'>
												<div className='mb-2'>
													<div className='opacity-50'>Naziv:</div>
													<div>{
														customer.type == 'natural' ?
															customer.surname + ' ' + customer.name :
															customer.company_name
													}</div>
												</div>
												<div className='mb-2'>
													<div className='opacity-50'>Telefon:</div>
													<div>{ customer.phone }</div>
												</div>
												<div className='mb-2'>
													<div className='opacity-50'>E-pošta:</div>
													<div>{ customer.email }</div>
												</div>
											</div>
											<div className='w-2/4'>
												<div className='mb-2'>
													<div className='opacity-50'>Številka stranke:</div>
													<div>{ customer.internal_code }</div>
												</div>
												<div className='mb-2'>
													<div className='opacity-50'>Država:</div>
													<div>{ this.props.countries[customer.id_country].official_name_local }</div>
												</div>
											</div>
										</div>
										<Button
											icon='edit'
											minimal={true}
											onClick={() => {
												this.openSelectCustomerDialog('', customer);
											}} />
									</div>
									{customer.note === null || customer.note.length == 0 ? null :
										<div className='mb-2'>
											<div className='opacity-50'>Opomba:</div>
											<div>{ customer.note }</div>
										</div>
									}
								</div>
							}
							
							<div className='flex flex-row'>
								<FormGroup
									labelFor='edit-activity-item--guest-count'>
									<TextWithTooltip
										label='Št. odraslih'
										tooltip='Št. odraslih' />
									<ControlGroup>
										<Button icon='minus' tabIndex='-1' minimal={false} small={true} onClick={() => {
											if (this.state.guest_count >= 1) {
												this.setState({ guest_count: this.state.guest_count - 1 });
											}
										}} />
										<div className='w-8'>
											<NumericInput
												id='edit-activity-item--guest-count'
												className='centered-numeric-input'
												fill={true}
												buttonPosition='none'
												value={this.state.guest_count}
												min={0}
												onValueChange={val => {
													this.setState({ guest_count: val });
												}} />
										</div>
										<Button icon='plus' tabIndex='-1' minimal={false} small={true} onClick={() => {
											this.setState({ guest_count: this.state.guest_count + 1 });
										}} />
									</ControlGroup>
								</FormGroup>
								
								<FormGroup
									labelFor='edit-activity-item--guest-child-count'
									className='ml-2'>
									<TextWithTooltip
										label='Št. otrok'
										tooltip='Št. otrok' />
									<ControlGroup>
										<Button icon='minus' tabIndex='-1' minimal={false} small={true} onClick={() => {
											if (this.state.guest_child_count >= 1) {
												this.setState({ guest_child_count: this.state.guest_child_count - 1 });
											}
										}} />
										<div className='w-8'>
											<NumericInput
												id='edit-activity-item--guest-child-count'
												className='centered-numeric-input'
												fill={true}
												buttonPosition='none'
												value={this.state.guest_child_count}
												min={0}
												onValueChange={val => {
													this.setState({ guest_child_count: val });
												}} />
										</div>
										<Button icon='plus' tabIndex='-1' minimal={false} small={true} onClick={() => {
											this.setState({ guest_child_count: this.state.guest_child_count + 1 });
										}} />
									</ControlGroup>
								</FormGroup>
								
								<FormGroup
									labelFor='edit-activity-item--channel-input'
									className={
										'ml-4 flex-1 ' +
										(this.state.validation_failed_fields.indexOf('id_channel') == -1 ?
											'' : 'validation-failed')
									}>
									<TextWithTooltip
										label='Prodajni kanal'
										tooltip='Prodajni kanal' />
									<SimpleSuggest
										matchKeyName='title'
										valueKeyName='id_channel'
										items={Object.values(this.props.channels).sort((a, b) => a.ord - b.ord)}
										onItemSelect={item => {
											this.setState({
												id_channel: item.id_channel,
											});
										}}
										selectedItem={this.props.channels[this.state.id_channel]}
										placeholder='Išči' />
								</FormGroup>
							</div>
							
							<FormGroup
								labelFor='edit-activity-item--note'>
								<TextWithTooltip
									label='Opomba'
									tooltip='Opomba' />
								<TextArea
									id='edit-activity-item--note'
									growVertically={true}
									fill={true}
									style={{ minHeight: '6rem' }}
									onChange={(e) => {
										this.setState({
											note: e.target.value,
										});
									}}
									value={this.state.note} />
							</FormGroup>
						</div>
						{this.state.item === null ? null :
							<div className='flex w-3/5 pl-2 flex-col'>
								<div className='overflow-y-auto activity-item-dialog-documents'>
									<Documents id_document={this.state.item.id_activity_reservation} deep={true} document_type='activity-reservation' />
								</div>
							</div>
						}
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						{this.props.setCurrentDate === null ? null :
							<Button
								style={{ marginLeft: 0 }}
								onClick={() => this.props.setCurrentDate(this.state.reservation_date)}>
								Prikaži termin
							</Button>
						}
						<Button
							disabled={this.state.item === null}
							style={ this.props.setCurrentDate === null ? { marginLeft: 0 } : {} }
							onClick={() => this.openOfferDialog()}>
							Izdelaj ponudbo
						</Button>
						<div className='flex-1'></div>
						<Button
							minimal={true}
							onClick={() => this.props.closeEditActivityItemDialog()}>
							Prekliči
						</Button>
						<Button
							onClick={() => this.saveItem()}>
							Shrani
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = this.getItem();
								if (!this.validate(new_item)) return;
								
								this.props.closeEditActivityItemDialog(this.state.item, new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
EditActivityItemDialog.propTypes = {
	closeEditActivityItemDialog: PropTypes.func,
	surname: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		countries:                state.CodeTablesSlice.countries,
		channels:                 state.CodeTablesSlice.channels,
		customers:                state.CodeTablesSlice.customers,
		items:                    state.CodeTablesSlice.items,
		measuring_units:          state.CodeTablesSlice.measuring_units,
		tax_rates:                state.CodeTablesSlice.tax_rates,
		warehouses:               state.CodeTablesSlice.warehouses,
		token:                    state.UserSlice.token,
		user:                     state.UserSlice.user,
		users:                    state.UserSlice.users,
		api_url:                  state.ConstantsSlice.api_url,
		document_types:           state.DocumentSlice.document_types,
		document_associations:    state.DocumentSlice.document_associations_id_documents,
		invoices:                 state.BusinessSlice.invoices,
		general_settings:         state.SettingsSlice.general,
		current_id_cash_register: state.SettingsSlice.current_id_cash_register,
		cash_registers:           state.CashRegisterSlice.cash_registers,
	};
}

export default connect(mapStateToProps)(EditActivityItemDialog);
