import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Navbar,
	Dialog,
	Classes,
	Intent,
} from '@blueprintjs/core';
import { TimePrecision, DatePicker } from '@blueprintjs/datetime';

import moment from 'moment';

class DateTimePickerDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			selectedDate: this.props.value,
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={false}
			className='date-picker-dialog'>
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Izbira datuma in časa</Navbar.Heading>
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-col'>
						<div className='self-center'>
							<DatePicker
								timePickerProps={{
									showArrowButtons: true,
								}}
								timePrecision={TimePrecision.MINUTE}
								onChange={(selectedDate, isUserChange) => {
									this.setState({
										selectedDate,
									});
								}}
								value={this.state.selectedDate}
								maxDate={moment().add('10', 'years').toDate()} />
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => { this.props.closeDateTimePickerDialog(false) }}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => { this.props.closeDateTimePickerDialog(this.state.selectedDate) }}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
DateTimePickerDialog.propTypes = {
	closeDateTimePickerDialog: PropTypes.func,
	value: PropTypes.object,
};

export default DateTimePickerDialog;
