import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';

import { connect } from 'react-redux';

import moment from 'moment';

import {
	setA4PrinterConnectedStatus,
	enqueueInvoiceOnA4PrinterQueue,
	requeueInvoiceOnA4PrinterQueue,
	removeInvoiceFromA4PrinterQueue,
	setPrinters,
} from '../slices/AppSlice';

import {
	loadInvoice,
} from '../api/Business';

import InvoiceA4              from '../printing/a4/Invoice';
import JournalA4              from '../printing/a4/Journal';
import CashRegisterDocumentA4 from '../printing/a4/CashRegisterDocument';
import Acquisition            from '../printing/a4/Acquisition';
import LocalStorageHelper     from '../helpers/LocalStorageHelper';

class A4PrinterWatcher extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			current_item: null,
			wait_for_remote_confirmation: false,
		};
		
		this.init                      = this.init.bind(this);
		this.cleanup                   = this.cleanup.bind(this);
		this.processQueue              = this.processQueue.bind(this);
		this.printInvoice              = this.printInvoice.bind(this);
		this.printJournal              = this.printJournal.bind(this);
		this.printCashRegisterDocument = this.printCashRegisterDocument.bind(this);
	}
	
	componentWillUnmount() {
		this.cleanup();
	}
	
	init() {
		console.log('init a4 print ws');
		
		const ws = new WebSocket(this.props.ws_print_url);
		ws.onmessage = event => {
			console.log({ event });
			
			const data = JSON.parse(event.data);
			if (data.Action == 'version') {
				const version = parseInt(data.Data, 10);
				if (version >= 1002) {
					this.setState({
						wait_for_remote_confirmation: true,
					});
				}
			}
			else if (data.Action == 'completed-convert-to-pdf-and-open') {
				this.props.removeInvoiceFromA4PrinterQueue();
				
				/*ErrorReportingHelper.ReportA4PrintInfo(
					JSON.stringify({ op: 'A4PrinterWatcher.processQueue: step finished', ts: (new Date()).toISOString(), id_invoice: item.id_invoice }),
					{ tags: 'a4_print, step_finished, item_' + item.id_invoice }
				);*/
				
				this.setState({ current_item: null });
				
				this.processQueue();
			}
		};
		ws.onopen = event => {
			this.props.setA4PrinterConnectedStatus(true);
			
			if (ws.readyState == WebSocket.OPEN) {
				ws.send(JSON.stringify({
					Action: 'get-printers',
					Data:   '',
				}));
				ws.send(JSON.stringify({
					Action: 'get-version',
					Data:   '',
				}));
			}
		};
		ws.onclose = event => {
			this.props.setA4PrinterConnectedStatus(false);
			setTimeout(() => {
				this.ws = this.init();
			}, 5000);
		};
		
		return ws;
	}
	
	cleanup() {
		if (this.ws !== undefined && this.ws !== null) {
			// prevent auto reconnect
			this.ws.onclose = event => {};
			this.ws.close();
			
			this.props.setA4PrinterConnectedStatus(false);
		}
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.ws_print_url != prevProps.ws_print_url) {
			this.cleanup();
			this.ws = this.init();
		}
		
		console.log(JSON.stringify(this.props.a4_printer_queue));
		if (this.props.a4_printer_queue != prevProps.a4_printer_queue) {
			this.processQueue();
		}
	}
	
	componentDidMount() {
		if (this.props.ws_print_url !== null) {
			this.ws = this.init();
		}
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		let rounded_val = Math.properRound(parseFloat(val), decimal_digit_count);
		
		return rounded_val
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	async processQueue() {
		console.log('processQueue: queue length is ' + this.props.a4_printer_queue.length);
		if (this.state.current_item !== null || this.props.a4_printer_queue.length == 0) {
			return;
		}
		
		let item_data = this.props.a4_printer_queue[0];
		if (item_data.type == 'invoice') {
			await this.printInvoice(item_data);
		}
		else if (item_data.type == 'journal') {
			await this.printJournal(item_data);
		}
		else if (item_data.type == 'journal-summary') {
			await this.printJournalSummary(item_data);
		}
		else if (item_data.type == 'cash-register-document') {
			await this.printCashRegisterDocument(item_data);
		}
		else if (item_data.type == 'acquisition') {
			await this.printAcquisition(item_data);
		}
	
		if (false) {
			//this.props.requeueInvoiceOnA4PrinterQueue(invoice);
		}
		
		if (!this.state.wait_for_remote_confirmation) {
			this.props.removeInvoiceFromA4PrinterQueue();
			this.setState({ current_item: null });
			
			//this.processQueue();
		}
	}
	
	async getPrintHTML(type, html) {
		let result;
		
		result = await fetch(location.origin + '/print/print_template_a4.html');
		result = await result.text();
		
		result = result.replaceAll('%CLASS%', type);
		result = result.replaceAll('%HTML%', html);
		result = result.replaceAll('"/print', '"' + window.location.protocol + '//' + window.location.host + '/print');
		
		return result;
	}
	
	async printInvoice(item_data) {
		let invoice_lang = item_data.lang;
		let invoice      = item_data.invoice;
		invoice          = await loadInvoice(this.props.api_url, invoice.id_invoice, this.props.dispatch, this.props.token);
		
		this.setState({ current_item: invoice });
		
		/*ErrorReportingHelper.ReportA4PrintInfo(
			JSON.stringify({ op: 'A4PrinterWatcher.processQueue: step 2', ts: (new Date()).toISOString(), id_invoice: invoice.id_invoice }),
			{ tags: 'a4_print, step_2, item_' + invoice.id_invoice }
		);*/
		
		// print
		console.log('printing');
		const general_settings = this.props.general_settings;
		
		const cash_register    = this.props.cash_registers[invoice.id_cash_register] || null;
		const business_premise = cash_register === null ? null : (this.props.business_premises[cash_register.id_business_premise] || null);
		
		const document_type = this.props.document_types[invoice.invoice_type];
		
		const invoice_element = <InvoiceA4
			company_data={{
				subject_type:                general_settings.subject_type,
				subject_short_name:          general_settings.subject_short_name,
				subject_long_name:           general_settings.subject_long_name,
				subject_address:             general_settings.subject_address,
				subject_post_code:           general_settings.subject_post_code,
				subject_city:                general_settings.subject_city,
				subject_email:               general_settings.subject_email,
				subject_website:             general_settings.subject_website,
				subject_phone_1:             general_settings.subject_phone_1,
				subject_phone_2:             general_settings.subject_phone_2,
				subject_trr_1:               general_settings.subject_trr_1,
				subject_trr_2:               general_settings.subject_trr_2,
				subject_tax_number:          general_settings.subject_tax_number,
				subject_vat_registered:      general_settings.subject_vat_registered,
				subject_vat_number:          general_settings.subject_vat_number,
				subject_registration_number: general_settings.subject_registration_number,
				logo:                        general_settings.logo,
			}}
			item=                                                  {invoice}
			business_premise=                                      {business_premise}
			payment_types=                                         {this.props.payment_types}
			id_advance_invoice_consumption_by_id_consumer_invoices={this.props.id_advance_invoice_consumption_by_id_consumer_invoices}
			invoices=                                              {this.props.invoices}
			advance_invoice_consumptions=                          {this.props.advance_invoice_consumptions}
			users=                                                 {this.props.users}
			invoice_fiscal_verifications=                          {this.props.invoice_fiscal_verifications}
			customer_country=                                      {invoice.customer_id_country === null ? null : this.props.countries[invoice.customer_id_country]}
			settings={{
				footer:                        general_settings.footer,
				tourist_tax_text:              general_settings.tourist_tax_text,
				subject_vat_registered:        general_settings.subject_vat_registered,
				subject_vat_registered_reason: general_settings.subject_vat_registered_reason,
				print_footer_a4:               general_settings.print_footer_a4,
				print_final_text:    (document_type === undefined ? ''      : document_type.print_final_text),
				print_final_text_en: (document_type === undefined ? ''      : document_type.print_final_text_en),
				print_final_text_de: (document_type === undefined ? ''      : document_type.print_final_text_de),
				logo_position:       (document_type === undefined ? 'right' : document_type.logo_position),
			}}
			lang={invoice_lang} />;
		let html = ReactDOMServer.renderToStaticMarkup(invoice_element);
		
		html = await this.getPrintHTML('invoice', html);
		
		/*ErrorReportingHelper.ReportA4PrintInfo(
			JSON.stringify({ op: 'A4PrinterWatcher.processQueue: step 3', ts: (new Date()).toISOString(), id_invoice: invoice.id_invoice }),
			{ tags: 'a4_print, step_3, item_' + invoice.id_invoice }
		);*/
		
		const print_preview = false;
		if (print_preview) {
			//const win = window.open('/print/print_template_a4.html?v=3', 'Title');
			const win = window.open('', 'Title');
			if (win === null || win === undefined) {
				//TODO display a message saying to allow popups
			}
			else {
				win.document.body.innerHTML = html;
				/*win.document.body.innerHTML = response;
				
				const message_callback = event => {
					if (event.data != 'printing-a4-template-loaded') return;
					
					const root_el = win === null || win.document === null ? null : win.document.getElementById('root');
					if (root_el === null) return;
					
					root_el.innerHTML = html;
					win.init();
					
					window.removeEventListener('message', message_callback);
				};
				
				window.addEventListener('message', message_callback, false);*/
			}
			
			this.props.removeInvoiceFromA4PrinterQueue();
			this.setState({ current_item: null });
			
			//this.processQueue();
		}
		
		//console.log(print_items.filter(x => x !== null).join('\n'));
		if (!print_preview && this.ws.readyState == WebSocket.OPEN) {
			const type_names = {
				'cash-invoice':            'Racun',
				'cash-prepayment-invoice': 'Racun za predplacilo',
				'offer':                   'Ponudba',
				'invoice':                 'Faktura',
				'prepayment-invoice':      'Avansni racun',
			};
			const name_prefix = type_names[invoice.invoice_type] || '';
			
			const name_postfix = invoice_lang == 'sl' ? '' : invoice_lang.toUpperCase();
			
			/*ErrorReportingHelper.ReportA4PrintInfo(
				JSON.stringify({ op: 'A4PrinterWatcher.processQueue: step 4', ts: (new Date()).toISOString(), id_invoice: invoice.id_invoice }),
				{ tags: 'a4_print, step_4, item_' + invoice.id_invoice }
			);*/
			
			this.ws.send(JSON.stringify({
				Action: 'convert-to-pdf-and-open',
				Data: JSON.stringify({
					Html:          html,
					Path:          LocalStorageHelper.GetValue(this.props.client + '__save_path'),
					InvoiceNumber: (name_prefix + ' ' + invoice.invoice_number + ' ' + name_postfix).trim() + (invoice.copy_count < 1 ? '' : ' kopija ' + invoice.copy_count),
				}),
			}));
		}
	}
	async printJournal(item_data) {
		let item_lang = item_data.lang;
		let item      = item_data.item;
		
		this.setState({ current_item: item });
		
		// print
		console.log('printing journal');
		const general_settings = this.props.general_settings;
		
		const cash_register = this.props.cash_registers[item.id_cash_register] || null;
		
		const documents = Object.values(this.props.cash_register_documents).filter(x => x.id_cash_register_daily_state === item.id_cash_register_daily_state);
		
		let documents_for_advance_payments = Object.values(this.props.cash_register_documents).filter(x => x.id_cash_register_daily_state === item.id_cash_register_daily_state);
		
		// filter out reverse invoices and their references
		let reverse_invoice_ids = [];
		let reversed_invoice_ids = [];
		for (let i=0; i<documents_for_advance_payments.length; i++) {
			const doc_invoices = documents_for_advance_payments[i].invoices ?? [];
			for (let j=0; j<doc_invoices.length; j++) {
				const invoice = this.props.invoices[doc_invoices[j].id_invoice];
				if (invoice.reverse_invoice === true) {
					reverse_invoice_ids.push(invoice.id_invoice);
					reversed_invoice_ids.push(invoice.reverse_invoice_reference);
				}
			}
		}
		
		documents_for_advance_payments = documents_for_advance_payments.map(doc => {
			return {
				...doc,
				invoices: (doc.invoices ?? [])
					.filter(x => reverse_invoice_ids.indexOf(x.id_invoice) === -1 && reversed_invoice_ids.indexOf(x.id_invoice) === -1),
			};
		});
		
		const item_element = <JournalA4
			company_data={{
				subject_type:                general_settings.subject_type,
				subject_short_name:          general_settings.subject_short_name,
				subject_long_name:           general_settings.subject_long_name,
				subject_address:             general_settings.subject_address,
				subject_post_code:           general_settings.subject_post_code,
				subject_city:                general_settings.subject_city,
				subject_email:               general_settings.subject_email,
				subject_website:             general_settings.subject_website,
				subject_phone_1:             general_settings.subject_phone_1,
				subject_phone_2:             general_settings.subject_phone_2,
				subject_trr_1:               general_settings.subject_trr_1,
				subject_trr_2:               general_settings.subject_trr_2,
				subject_tax_number:          general_settings.subject_tax_number,
				subject_vat_registered:      general_settings.subject_vat_registered,
				subject_vat_number:          general_settings.subject_vat_number,
				subject_registration_number: general_settings.subject_registration_number,
				logo:                        general_settings.logo,
			}}
			items={[ item ]}
			documents={documents}
			documents_for_advance_payments={documents_for_advance_payments}
			settings={{
				footer:        general_settings.footer,
				logo_position: 'right',
			}}
			lang={item_lang}
			users={this.props.users}
			cash_register={cash_register}
			payment_types={this.props.payment_types}
			tax_rates={this.props.tax_rates}
			invoices={this.props.invoices}
			id_advance_invoice_consumption_by_id_consumer_invoices={this.props.id_advance_invoice_consumption_by_id_consumer_invoices}
			advance_invoice_consumptions={this.props.advance_invoice_consumptions}
			customers={this.props.customers}
			cash_registers={this.props.cash_registers}
			display_detailed_list={true} />;
		let html = ReactDOMServer.renderToStaticMarkup(item_element);
		
		html = await this.getPrintHTML('journal', html)
		
		const print_preview = false;
		if (print_preview) {
			//const win = window.open('/print/print_template_a4.html?v=3', 'Title');
			const win = window.open('', 'Title');
			if (win === null || win === undefined) {
				//TODO display a message saying to allow popups
			}
			else {
				win.document.body.innerHTML = html;
				/*win.document.body.innerHTML = response;
				
				const message_callback = event => {
					if (event.data != 'printing-a4-template-loaded') return;
					
					const root_el = win === null || win.document === null ? null : win.document.getElementById('root');
					if (root_el === null) return;
					
					root_el.innerHTML = html;
					win.init();
					
					window.removeEventListener('message', message_callback);
				};
				
				window.addEventListener('message', message_callback, false);*/
			}
			
			console.log('removing journal from queue');
			this.props.removeInvoiceFromA4PrinterQueue();
			this.setState({ current_item: null });
			
			//this.processQueue();
		}
		
		//console.log(print_items.filter(x => x !== null).join('\n'));
		if (!print_preview && this.ws.readyState == WebSocket.OPEN) {
			this.ws.send(JSON.stringify({
				Action: 'convert-to-pdf-and-open',
				Data: JSON.stringify({
					Html:          html,
					Path:          LocalStorageHelper.GetValue(this.props.client + '__save_path'),
					InvoiceNumber: 'Blagajniski dnevnik ' + item.document_number,
				}),
			}));
		}
		
		console.log('finished printing journal');
	}
	async printJournalSummary(item_data) {
		let item_lang             = item_data.lang;
		let items                 = [ ...item_data.items ].sort((a, b) => a.document_date < b.document_date ? -1 : (a.document_date > b.document_date ? 1 : 0));
		let display_detailed_list = item_data.display_detailed_list;
		
		if (items.length == 0) {
			this.props.removeInvoiceFromA4PrinterQueue();
			return;
		}
		
		this.setState({ current_item: items });
		
		// print
		console.log('printing journal summary');
		const general_settings = this.props.general_settings;
		
		const ids = items.map(item => item.id_cash_register_daily_state);
		
		const documents = Object.values(this.props.cash_register_documents).filter(x => ids.indexOf(x.id_cash_register_daily_state) != -1);
		
		let documents_for_advance_payments = Object.values(this.props.cash_register_documents).filter(x => ids.indexOf(x.id_cash_register_daily_state) != -1);
		
		// filter out reverse invoices and their references
		let reverse_invoice_ids = [];
		let reversed_invoice_ids = [];
		for (let i=0; i<documents_for_advance_payments.length; i++) {
			const doc_invoices = documents_for_advance_payments[i].invoices ?? [];
			for (let j=0; j<doc_invoices.length; j++) {
				const invoice = this.props.invoices[doc_invoices[j].id_invoice];
				if (invoice.reverse_invoice === true) {
					reverse_invoice_ids.push(invoice.id_invoice);
					reversed_invoice_ids.push(invoice.reverse_invoice_reference);
				}
			}
		}
		
		documents_for_advance_payments = documents_for_advance_payments.map(doc => {
			return {
				...doc,
				invoices: (doc.invoices ?? [])
					.filter(x => reverse_invoice_ids.indexOf(x.id_invoice) === -1 && reversed_invoice_ids.indexOf(x.id_invoice) === -1),
			};
		});
		
		const item_element = <JournalA4
			company_data={{
				subject_type:                general_settings.subject_type,
				subject_short_name:          general_settings.subject_short_name,
				subject_long_name:           general_settings.subject_long_name,
				subject_address:             general_settings.subject_address,
				subject_post_code:           general_settings.subject_post_code,
				subject_city:                general_settings.subject_city,
				subject_email:               general_settings.subject_email,
				subject_website:             general_settings.subject_website,
				subject_phone_1:             general_settings.subject_phone_1,
				subject_phone_2:             general_settings.subject_phone_2,
				subject_trr_1:               general_settings.subject_trr_1,
				subject_trr_2:               general_settings.subject_trr_2,
				subject_tax_number:          general_settings.subject_tax_number,
				subject_vat_registered:      general_settings.subject_vat_registered,
				subject_vat_number:          general_settings.subject_vat_number,
				subject_registration_number: general_settings.subject_registration_number,
				logo:                        general_settings.logo,
			}}
			items={items}
			documents={documents}
			documents_for_advance_payments={documents_for_advance_payments}
			settings={{
				footer:        general_settings.footer,
				logo_position: 'right',
			}}
			lang={item_lang}
			users={this.props.users}
			cash_register={null}
			payment_types={this.props.payment_types}
			tax_rates={this.props.tax_rates}
			invoices={this.props.invoices}
			id_advance_invoice_consumption_by_id_consumer_invoices={this.props.id_advance_invoice_consumption_by_id_consumer_invoices}
			advance_invoice_consumptions={this.props.advance_invoice_consumptions}
			customers={this.props.customers}
			cash_registers={this.props.cash_registers}
			display_detailed_list={display_detailed_list} />;
		let html = ReactDOMServer.renderToStaticMarkup(item_element);
		
		html = await this.getPrintHTML('journal', html)
		
		const print_preview = false;
		if (print_preview) {
			//const win = window.open('/print/print_template_a4.html?v=3', 'Title');
			const win = window.open('', 'Title');
			if (win === null || win === undefined) {
				//TODO display a message saying to allow popups
			}
			else {
				win.document.body.innerHTML = html;
				/*win.document.body.innerHTML = response;
				
				const message_callback = event => {
					if (event.data != 'printing-a4-template-loaded') return;
					
					const root_el = win === null || win.document === null ? null : win.document.getElementById('root');
					if (root_el === null) return;
					
					root_el.innerHTML = html;
					win.init();
					
					window.removeEventListener('message', message_callback);
				};
				
				window.addEventListener('message', message_callback, false);*/
			}
			
			console.log('removing journal from queue');
			this.props.removeInvoiceFromA4PrinterQueue();
			this.setState({ current_item: null });
			
			//this.processQueue();
		}
		
		//console.log(print_items.filter(x => x !== null).join('\n'));
		if (!print_preview && this.ws.readyState == WebSocket.OPEN) {
			this.ws.send(JSON.stringify({
				Action: 'convert-to-pdf-and-open',
				Data: JSON.stringify({
					Html:          html,
					Path:          LocalStorageHelper.GetValue(this.props.client + '__save_path'),
					InvoiceNumber:
						'Zbirnik ' +
						this.props.cash_registers[items[0].id_cash_register].title +
						' ' +
						moment(items[0].document_date).format('DD.MM.YYYY') +
						'-' +
						moment(items[items.length - 1].document_date).format('DD.MM.YYYY') +
						(display_detailed_list ? ' - podrobno' : ''),
				}),
			}));
		}
		
		console.log('finished printing journal summary');
	}
	async printCashRegisterDocument(item_data) {
		let item = item_data.item;
		
		this.setState({ current_item: item });
		
		// print
		console.log('printing cash register document');
		const general_settings = this.props.general_settings;
		
		const invoice_element = <CashRegisterDocumentA4
			company_data={{
				subject_type:                general_settings.subject_type,
				subject_short_name:          general_settings.subject_short_name,
				subject_long_name:           general_settings.subject_long_name,
				subject_address:             general_settings.subject_address,
				subject_post_code:           general_settings.subject_post_code,
				subject_city:                general_settings.subject_city,
				subject_email:               general_settings.subject_email,
				subject_website:             general_settings.subject_website,
				subject_phone_1:             general_settings.subject_phone_1,
				subject_phone_2:             general_settings.subject_phone_2,
				subject_trr_1:               general_settings.subject_trr_1,
				subject_trr_2:               general_settings.subject_trr_2,
				subject_tax_number:          general_settings.subject_tax_number,
				subject_vat_registered:      general_settings.subject_vat_registered,
				subject_vat_number:          general_settings.subject_vat_number,
				subject_registration_number: general_settings.subject_registration_number,
				logo:                        general_settings.logo,
			}}
			item=            {item}
			payment_types=   {this.props.payment_types}
			cash_registers=  {this.props.cash_registers}
			users=           {this.props.users}
			customer_country={item.customer_id_country === null ? null : this.props.countries[item.customer_id_country]}
			settings={{
				footer:        general_settings.footer,
				logo_position: 'right',
			}}
			lang={'sl'} />;
		let html = ReactDOMServer.renderToStaticMarkup(invoice_element);
		
		html = await this.getPrintHTML('invoice cash-register-document', html);
		
		const print_preview = false;
		if (print_preview) {
			//const win = window.open('/print/print_template_a4.html?v=3', 'Title');
			const win = window.open('', 'Title');
			if (win === null || win === undefined) {
				//TODO display a message saying to allow popups
			}
			else {
				win.document.body.innerHTML = html;
				/*win.document.body.innerHTML = response;
				
				const message_callback = event => {
					if (event.data != 'printing-a4-template-loaded') return;
					
					const root_el = win === null || win.document === null ? null : win.document.getElementById('root');
					if (root_el === null) return;
					
					root_el.innerHTML = html;
					win.init();
					
					window.removeEventListener('message', message_callback);
				};
				
				window.addEventListener('message', message_callback, false);*/
			}
			
			this.props.removeInvoiceFromA4PrinterQueue();
			this.setState({ current_item: null });
			
			//this.processQueue();
		}
		
		//console.log(print_items.filter(x => x !== null).join('\n'));
		if (!print_preview && this.ws.readyState == WebSocket.OPEN) {
			const name_prefix  = item.type == 'income' ? 'Blagajniski prejemek' : 'Blagajniski izdatek';
			const name_postfix = '';
			
			this.ws.send(JSON.stringify({
				Action: 'convert-to-pdf-and-open',
				Data: JSON.stringify({
					Html:          html,
					Path:          LocalStorageHelper.GetValue(this.props.client + '__save_path'),
					InvoiceNumber: (name_prefix + ' ' + item.document_number + ' ' + name_postfix).trim(),
				}),
			}));
		}
	}
	async printAcquisition(item_data) {
		let item = item_data.item;
		
		this.setState({ current_item: item });
		
		// print
		console.log('printing acquisition');
		const general_settings = this.props.general_settings;
		
		const item_element = <Acquisition
			item        ={item}
			items       ={this.props.items}
			tax_rates   ={this.props.tax_rates}
			warehouses  ={this.props.warehouses}
			customers   ={this.props.customers}
			countries   ={this.props.countries}
			company_data={{
				subject_type:                general_settings.subject_type,
				subject_short_name:          general_settings.subject_short_name,
				subject_long_name:           general_settings.subject_long_name,
				subject_address:             general_settings.subject_address,
				subject_post_code:           general_settings.subject_post_code,
				subject_city:                general_settings.subject_city,
				subject_email:               general_settings.subject_email,
				subject_website:             general_settings.subject_website,
				subject_phone_1:             general_settings.subject_phone_1,
				subject_phone_2:             general_settings.subject_phone_2,
				subject_trr_1:               general_settings.subject_trr_1,
				subject_trr_2:               general_settings.subject_trr_2,
				subject_tax_number:          general_settings.subject_tax_number,
				subject_vat_registered:      general_settings.subject_vat_registered,
				subject_vat_number:          general_settings.subject_vat_number,
				subject_registration_number: general_settings.subject_registration_number,
				logo:                        general_settings.logo,
			}} />;
		let html = ReactDOMServer.renderToStaticMarkup(item_element);
		
		html = await this.getPrintHTML('acquisition', html);
		
		const print_preview = false;
		if (print_preview) {
			//const win = window.open('/print/print_template_a4.html?v=3', 'Title');
			const win = window.open('', 'Title');
			if (win === null || win === undefined) {
				//TODO display a message saying to allow popups
			}
			else {
				win.document.body.innerHTML = html;
				/*win.document.body.innerHTML = response;
				
				const message_callback = event => {
					if (event.data != 'printing-a4-template-loaded') return;
					
					const root_el = win === null || win.document === null ? null : win.document.getElementById('root');
					if (root_el === null) return;
					
					root_el.innerHTML = html;
					win.init();
					
					window.removeEventListener('message', message_callback);
				};
				
				window.addEventListener('message', message_callback, false);*/
			}
			
			this.props.removeInvoiceFromA4PrinterQueue();
			this.setState({ current_item: null });
			
			//this.processQueue();
		}
		
		//console.log(print_items.filter(x => x !== null).join('\n'));
		if (!print_preview && this.ws.readyState == WebSocket.OPEN) {
			this.ws.send(JSON.stringify({
				Action: 'convert-to-pdf-and-open',
				Data: JSON.stringify({
					Html:          html,
					Path:          LocalStorageHelper.GetValue(this.props.client + '__save_path'),
					InvoiceNumber: 'Prevzem ' + item.document_number,
				}),
			}));
		}
	}
	
	render() {
		return null;
	}
}
export default connect(
	state => {
		return {
			api_url:                                                state.ConstantsSlice.api_url,
			ws_print_url:                                           state.ConstantsSlice.ws_print_url,
			client:                                                 state.ConstantsSlice.client,
			a4_printer_queue:                                       state.AppSlice.a4_printer_queue,
			general_settings:                                       state.SettingsSlice.general,
			users:                                                  state.UserSlice.users,
			token:                                                  state.UserSlice.token,
			cash_registers:                                         state.CashRegisterSlice.cash_registers,
			business_premises:                                      state.CodeTablesSlice.business_premises,
			payment_types:                                          state.CodeTablesSlice.payment_types,
			countries:                                              state.CodeTablesSlice.countries,
			tax_rates:                                              state.CodeTablesSlice.tax_rates,
			customers:                                              state.CodeTablesSlice.customers,
			items:                                                  state.CodeTablesSlice.items,
			warehouses:                                             state.CodeTablesSlice.warehouses,
			document_types:                                         state.DocumentSlice.document_types,
			invoices:                                               state.BusinessSlice.invoices,
			invoice_fiscal_verifications:                           state.BusinessSlice.invoice_fiscal_verifications,
			advance_invoice_consumptions:                           state.BusinessSlice.advance_invoice_consumptions,
			id_advance_invoice_consumption_by_id_consumer_invoices: state.BusinessSlice.id_advance_invoice_consumption_by_id_consumer_invoices,
			cash_register_documents:                                state.CashRegisterSlice.cash_register_documents,
		};
	},
	dispatch => {
		return {
			dispatch,
			setA4PrinterConnectedStatus:     connected => dispatch(setA4PrinterConnectedStatus({ connected })),
			enqueueInvoiceOnA4PrinterQueue:  data      => dispatch(enqueueInvoiceOnA4PrinterQueue(data)),
			requeueInvoiceOnA4PrinterQueue:  data      => dispatch(requeueInvoiceOnA4PrinterQueue(data)),
			removeInvoiceFromA4PrinterQueue: data      => dispatch(removeInvoiceFromA4PrinterQueue(data)),
			setPrinters:                     data      => dispatch(setPrinters({ printers: data })),
		};
	},
)(A4PrinterWatcher);
