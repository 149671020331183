import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppContainer from './AppContainer';
import * as serviceWorker from './serviceWorker';

import { ENVIRONMENT, HONEY_BADGER_API_KEY, HONEY_BADGER_REVISION } from './constants';

import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';

const pos = document.location.host.indexOf('.');
const client = (pos == -1 ? 'test' : document.location.host.substr(0, pos));

Honeybadger.configure({
	apiKey:      HONEY_BADGER_API_KEY,
	environment: ENVIRONMENT,
	disabled:    ENVIRONMENT != 'production',
	revision:    HONEY_BADGER_REVISION,
});
Honeybadger.setContext({
	client,
})

ReactDOM.render(
	<HoneybadgerErrorBoundary honeybadger={Honeybadger}>
		<AppContainer client={client} />
	</HoneybadgerErrorBoundary>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
