import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	NumericInput,
	HTMLSelect,
	Checkbox,
} from '@blueprintjs/core';
import { DateInput, IDateFormatProps } from '@blueprintjs/datetime';

import moment from 'moment';

import TextWithTooltip      from '../TextWithTooltip';
import DateTimePickerDialog from '../DateTimePickerDialog';
import { AppToaster }       from '../AppToaster';

function FormTextInput(props) {
	const {
		fieldName,
		title,
		tooltip,
		value,
		changeValue,
		className,
		disabled,
	} = props;
	
	return <FormGroup labelFor={'add-edit-item-dialog--' + fieldName} className={'flex-1 ' + className}>
		<TextWithTooltip label={title} tooltip={tooltip} />
		<InputGroup
			id={'add-edit-item-dialog--' + fieldName}
			value={value}
			disabled={disabled}
			onChange={event => changeValue(event.target.value)} />
	</FormGroup>;
}

function FormDateInput(props) {
	const {
		fieldName,
		title,
		tooltip,
		value,
		changeValue,
		className,
		disabled,
	} = props;
	
	const moment_formatter = (format) => {
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
			parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
		};
	};
	
	return <FormGroup labelFor={'add-edit-item-dialog--' + fieldName} className={'flex-1 ' + className}>
		<TextWithTooltip label={title} tooltip={tooltip} />
		<DateInput
			id={'add-edit-item-dialog--' + fieldName}
			locale='sl'
			{...moment_formatter('DD. MM. YYYY')}
			inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
			selectAllOnFocus={true}
			minDate={new Date('1900-01-01')}
			maxDate={moment().add('10', 'years').toDate()}
			onChange={(selectedDate, isUserChange) => { if (isUserChange) changeValue(selectedDate) }}
			disabled={disabled}
			value={value} />
	</FormGroup>;
}

const BusinessPremiseTypes = {
	'1':  'Nepremični prostor',
	'2A': 'Premični prostor - premični objekt',
	'2B': 'Premični prostor - objekt na stalni lokaciji',
	'2C': 'Premični prostor - posamezna elektronska naprava',
}

class AddEditBusinessPremise extends Component {
	constructor(props) {
		super(props);
		
		this.hasValidationFailed       = this.hasValidationFailed      .bind(this);
		this.getValidationClass        = this.getValidationClass       .bind(this);
		this.openDateTimePickerDialog  = this.openDateTimePickerDialog .bind(this);
		this.closeDateTimePickerDialog = this.closeDateTimePickerDialog.bind(this);
		this.saveAndClose              = this.saveAndClose             .bind(this);
		
		this.state = {
			id_business_premise:       props.item === null ? -1   : props.item.id_business_premise,
			furs_code:                 props.item === null ? ''   : props.item.furs_code,
			cadastral_municipality:    props.item === null ? ''   : props.item.cadastral_municipality,
			building_number:           props.item === null ? ''   : props.item.building_number,
			building_part_number:      props.item === null ? ''   : props.item.building_part_number,
			street:                    props.item === null ? ''   : props.item.street,
			house_number:              props.item === null ? ''   : props.item.house_number,
			house_number_appendix:     props.item === null ? ''   : props.item.house_number_appendix,
			town:                      props.item === null ? ''   : props.item.town,
			post_code:                 props.item === null ? ''   : props.item.post_code,
			post_office:               props.item === null ? ''   : props.item.post_office,
			city:                      props.item === null ? ''   : props.item.city,
			software_maker_tax_number: props.item === null ? ''   : props.item.software_maker_tax_number,
			valid_from:                props.item === null ? null : (props.item.valid_from === null ? null : new Date(props.item.valid_from)),
			registered_at_furs:        props.item === null ? null : (props.item.registered_at_furs === null ? null : new Date(props.item.registered_at_furs + '00')),
			special_notes:             props.item === null ? ''   : props.item.special_notes,
			type:                      props.item === null ? '1'  : props.item.type,
			closed:                    props.item === null ? false : props.item.closed,
			validation_failed_fields: [],
			date_picker_dialog_open:       false,
			date_picker_dialog_state_name: null,
			date_picker_dialog_value:      null,
		};
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
			parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
		}
	}
	
	hasValidationFailed(field_name) {
		return this.state.validation_failed_fields.indexOf(field_name) != -1;
	}
	getValidationClass(field_name) {
		return this.hasValidationFailed(field_name) ? 'validation-failed' : '';
	}
	
	openDateTimePickerDialog(state_name) {
		let value = this.state[state_name];
		
		this.setState({
			date_picker_dialog_open: true,
			date_picker_dialog_state_name: state_name,
			date_picker_dialog_value: value,
		});
	}
	closeDateTimePickerDialog(newValue) {
		this.setState({ date_picker_dialog_open: false });
		
		if (newValue !== false) {
			const obj = {};
			obj[this.state.date_picker_dialog_state_name] = newValue;
			this.setState(obj);
		}
	}
	
	saveAndClose(submit_to_furs) {
		const movable = this.state.type != '1';
		
		const new_item = {
			id_business_premise:       this.state.id_business_premise,
			furs_code:                 this.state.furs_code,
			cadastral_municipality:    this.state.cadastral_municipality,
			building_number:           this.state.building_number,
			building_part_number:      this.state.building_part_number,
			street:                    this.state.street,
			house_number:              this.state.house_number,
			house_number_appendix:     this.state.house_number_appendix,
			town:                      this.state.town,
			post_code:                 this.state.post_code,
			post_office:               this.state.post_office,
			city:                      this.state.city,
			software_maker_tax_number: this.state.software_maker_tax_number,
			valid_from:                this.state.valid_from === null ? null : this.state.valid_from.toISOString(true),
			serial_internal_code: this.props.item === null ? null : this.props.item.serial_internal_code,
			special_notes:             this.state.special_notes,
			type:                      this.state.type,
			closed:                    this.state.closed,
			submit_to_furs:            submit_to_furs ? 'true' : 'false',
		};
		
		// validate missing/empty fields
		const validation_failed_fields       = [];
		const validation_failed_field_titles = [];
		
		if (new_item.furs_code.length == 0) {
			validation_failed_fields.push('furs_code');
			validation_failed_field_titles.push('oznaka prostora (FURS)');
		}
		
		if (new_item.software_maker_tax_number.length == 0) {
			validation_failed_fields.push('software_maker_tax_number');
			validation_failed_field_titles.push('davčna št. izdajatelja programske opreme');
		}
		
		if (!movable) {
			if (new_item.cadastral_municipality.length == 0) {
				validation_failed_fields.push('cadastral_municipality');
				validation_failed_field_titles.push('številka katastrske občine');
			}
			if (new_item.building_number.length == 0) {
				validation_failed_fields.push('building_number');
				validation_failed_field_titles.push('številka stavbe');
			}
			if (new_item.building_part_number.length == 0) {
				validation_failed_fields.push('building_part_number');
				validation_failed_field_titles.push('številka dela stavbe');
			}
			if (new_item.street.length == 0) {
				validation_failed_fields.push('street');
				validation_failed_field_titles.push('ulica');
			}
			if (new_item.house_number.length == 0) {
				validation_failed_fields.push('house_number');
				validation_failed_field_titles.push('hišna številka');
			}
			if (new_item.post_code.length == 0) {
				validation_failed_fields.push('post_code');
				validation_failed_field_titles.push('poštna številka');
			}
			if (new_item.post_office.length == 0) {
				validation_failed_fields.push('post_office');
				validation_failed_field_titles.push('pošta');
			}
			if (new_item.town.length == 0) {
				validation_failed_fields.push('town');
				validation_failed_field_titles.push('naselje');
			}
		}
		this.setState({ validation_failed_fields });
		
		if (validation_failed_fields.length > 0) {
			AppToaster.show({
				message: <div>
					Za shranjevanje, izpolnite vsaj polja:
					<br />
					{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
				</div>,
				intent: 'danger',
				icon: 'issue'
			});
			return;
		}
		
		this.props.closeAddEditItemDialog(new_item);
	}
	
	render() {
		const movable = this.state.type != '1';
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={
				!this.state.date_picker_dialog_open
			}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-business-premise-dialog'>
			
			{!this.state.date_picker_dialog_open ? null :
				<DateTimePickerDialog
					closeDateTimePickerDialog={this.closeDateTimePickerDialog}
					value={this.state.date_picker_dialog_value} />
			}
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi poslovni prostor</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-col'>
						<div className='flex flex-1 flex-row'>
							<FormGroup labelFor='add-edit-item-dialog--type' className='flex-1'>
								<TextWithTooltip label='Tip poslovnega prostora' tooltip='Tip poslovnega prostora' />
								<HTMLSelect
									id='add-edit-item-dialog--type'
									fill={true}
									options={
										Object.entries(BusinessPremiseTypes).map(([key, value]) => {
											return { label: value, value: key };
										})
									}
									value={this.state.type}
									onChange={(e) => {
										this.setState({
											type: e.currentTarget.value,
										});
									}} />
							</FormGroup>
						</div>
						<div className='flex flex-1 flex-row'>
							<FormTextInput
								disabled=   {false}
								className=  {'m-1 ' + this.getValidationClass('furs_code')}
								fieldName=  'furs_code'
								title=      'Oznaka prostora (FURS)'
								tooltip=    'Oznaka prostora (FURS)'
								value=      {this.state.furs_code}
								changeValue={val => this.setState({ furs_code: val })} />
							<FormTextInput
								disabled=   {movable}
								className=  {'m-1 ' + this.getValidationClass('cadastral_municipality')}
								fieldName=  'cadastral_municipality'
								title=      'Številka katastrske občine'
								tooltip=    'Številka katastrske občine'
								value=      {this.state.cadastral_municipality}
								changeValue={val => this.setState({ cadastral_municipality: val })} />
						</div>
						<div className='flex flex-1 flex-row'>
							<FormTextInput
								disabled=   {movable}
								className=  {'m-1 ' + this.getValidationClass('building_number')}
								fieldName=  'building_number'
								title=      'Številka stavbe'
								tooltip=    'Številka stavbe'
								value=      {this.state.building_number}
								changeValue={val => this.setState({ building_number: val })} />
							<FormTextInput
								disabled=   {movable}
								className=  {'m-1 ' + this.getValidationClass('building_part_number')}
								fieldName=  'building_part_number'
								title=      'Številka dela stavbe'
								tooltip=    'Številka dela stavbe'
								value=      {this.state.building_part_number}
								changeValue={val => this.setState({ building_part_number: val })} />
						</div>
						<div className='flex flex-1 flex-row'>
							<FormTextInput
								disabled=   {movable}
								className=  {'m-1 ' + this.getValidationClass('street')}
								fieldName=  'street'
								title=      'Ulica'
								tooltip=    'Ulica'
								value=      {this.state.street}
								changeValue={val => this.setState({ street: val })} />
							<FormTextInput
								disabled=   {movable}
								className=  {'m-1 ' + this.getValidationClass('house_number')}
								fieldName=  'house_number'
								title=      'Hišna številka'
								tooltip=    'Hišna številka'
								value=      {this.state.house_number}
								changeValue={val => this.setState({ house_number: val })} />
						</div>
						<div className='flex flex-1 flex-row'>
							<FormTextInput
								disabled=   {movable}
								className=  {'m-1 ' + this.getValidationClass('house_number_appendix')}
								fieldName=  'house_number_appendix'
								title=      'Dodatek k hišni številki'
								tooltip=    'Dodatek k hišni številki'
								value=      {this.state.house_number_appendix}
								changeValue={val => this.setState({ house_number_appendix: val })} />
							<FormTextInput
								disabled=   {movable}
								className=  {'m-1 ' + this.getValidationClass('town')}
								fieldName=  'town'
								title=      'Naselje'
								tooltip=    'Naselje'
								value=      {this.state.town}
								changeValue={val => this.setState({ town: val })} />
						</div>
						<div className='flex flex-1 flex-row'>
							<FormTextInput
								disabled=   {movable}
								className=  {'m-1 ' + this.getValidationClass('post_code')}
								fieldName=  'post_code'
								title=      'Poštna številka'
								tooltip=    'Poštna številka'
								value=      {this.state.post_code}
								changeValue={val => this.setState({ post_code: val })} />
							<FormTextInput
								disabled=   {movable}
								className=  {'m-1 ' + this.getValidationClass('post_office')}
								fieldName=  'post_office'
								title=      'Pošta'
								tooltip=    'Pošta'
								value=      {this.state.post_office}
								changeValue={val => this.setState({ post_office: val })} />
						</div>
						<div className='flex flex-1 flex-row'>
							<FormTextInput
								disabled=   {false}
								className=  {'m-1'}
								fieldName=  'special_notes'
								title=      'Opis'
								tooltip=    'Opis'
								value=      {this.state.special_notes}
								changeValue={val => this.setState({ special_notes: val })} />
							<FormTextInput
								disabled=   {false}
								className=  {'m-1 ' + this.getValidationClass('software_maker_tax_number')}
								fieldName=  'software-maker-tax_number'
								title=      'Davčna št. izdajatelja programske opreme'
								tooltip=    'Davčna št. izdajatelja programske opreme'
								value=      {this.state.software_maker_tax_number}
								changeValue={val => this.setState({ software_maker_tax_number: val })} />
						</div>
						<div className='flex flex-1 flex-row'>
							<FormGroup labelFor={'add-edit-item-dialog--registered_at_furs'} className={'flex-1 m-1'}>
								<TextWithTooltip label='Prijavljen na FURS od' tooltip='Prijavljen na FURS od' />
								<div className='mt-1'>{
									this.state.registered_at_furs === null || this.state.registered_at_furs === false ?
										'' :
										this.getMomentFormatter('DD. MM. YYYY HH:mm').formatDate(this.state.registered_at_furs, 'sl')
									}
								</div>
							</FormGroup>
							<FormDateInput
								disabled=   {false}
								className=  'm-1'
								fieldName=  'valid_from'
								title=      'Veljaven od'
								tooltip=    'Veljaven od'
								value=      {this.state.valid_from}
								changeValue={val => this.setState({ valid_from: val })} />
							<FormGroup labelFor='add-edit-item-dialog--closed' className='flex-1 pt-1'>
								<TextWithTooltip label='Zaprtje poslovnega prostora' tooltip='Zaprtje poslovnega prostora' />
								<Checkbox
									id='add-edit-item-dialog--closed'
									disabled={this.props.item !== null && this.props.item.closed}
									checked={this.state.closed}
									onChange={event => {
										this.setState({ closed: event.target.checked });
									}} />
							</FormGroup>
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => this.saveAndClose(false)}>
							Shrani in zapri
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => this.saveAndClose(true)}>
							Shrani in pošlji na FURS
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditBusinessPremise.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
};

export default AddEditBusinessPremise;
