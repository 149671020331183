import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	Checkbox,
	NumericInput,
} from '@blueprintjs/core';

import TextWithTooltip from '../TextWithTooltip';
import { AppToaster }  from '../AppToaster';

class AddEditUser extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_user:             props.item === null ? -1    : props.item.id_user,
			username:            props.item === null ? ''    : props.item.username,
			name:                props.item === null ? ''    : props.item.name,
			surname:             props.item === null ? ''    : props.item.surname,
			is_worker:           props.item === null ? false : props.item.is_worker,
			is_active:           props.item === null ? true  : props.item.is_active,
			password:            props.item === null ? ''    : (props.item.password ?? ''),
			operator_tax_number: props.item === null ? ''    : props.item.operator_tax_number,
			access_roles:        props.item === null ? []    : props.item.access_roles,
			is_foreigner:        props.item === null ? false : props.item.is_foreigner,
			validation_failed_fields: [],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-channel-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi uporabnika</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='add-edit-channel-dialog--username'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('username') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Uporabniško ime'
								tooltip='Uporabniško ime' />
							<InputGroup
								id='add-edit-channel-dialog--username'
								value={this.state.username}
								onChange={event => {
									this.setState({
										username: event.target.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-channel-dialog--password'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('password') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Geslo (če ostane prazno, se geslo ne spremeni)'
								tooltip='Geslo' />
							<InputGroup
								id='add-edit-channel-dialog--password'
								value={this.state.password}
								onChange={event => {
									this.setState({
										password: event.target.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-channel-dialog--name'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('name') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Ime'
								tooltip='Ime' />
							<InputGroup
								id='add-edit-channel-dialog--name'
								value={this.state.name}
								onChange={event => {
									this.setState({
										name: event.target.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-channel-dialog--surname'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('surname') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Priimek'
								tooltip='Priimek' />
							<InputGroup
								id='add-edit-channel-dialog--surname'
								value={this.state.surname}
								onChange={event => {
									this.setState({
										surname: event.target.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-channel-dialog--operator_tax_number'
							className='flex-1'>
							<TextWithTooltip
								label='Davčna številka'
								tooltip='Davčna številka' />
							<InputGroup
								id='add-edit-channel-dialog--operator_tax_number'
								value={this.state.operator_tax_number}
								onChange={event => {
									this.setState({
										operator_tax_number: event.target.value,
									});
								}} />
						</FormGroup>
						<div className='flex flex-row items-start'>
							<div className='flex flex-col' style={{ flex: 30 }}>
								<FormGroup
									labelFor='add-edit-channel-dialog--is_worker'
									className='flex-1'>
									<TextWithTooltip
										label='Delavec'
										tooltip='Delavec' />
									<Checkbox checked={this.state.is_worker} onChange={event => {
										this.setState({ is_worker: event.target.checked });
									}} />
								</FormGroup>
								<FormGroup
									labelFor='add-edit-user-dialog--is_active'>
									<TextWithTooltip
										label='Aktiven'
										tooltip='Aktiven' />
									<Checkbox checked={this.state.is_active} onChange={event => {
										this.setState({ is_active: event.target.checked });
									}} />
								</FormGroup>
								<FormGroup
									labelFor='add-edit-user-dialog--is_foreigner'>
									<TextWithTooltip
										label='Tujec'
										tooltip='Tujec' />
									<Checkbox checked={this.state.is_foreigner} disabled={true} onChange={event => {
										this.setState({ is_foreigner: event.target.checked });
									}} />
								</FormGroup>
							</div>
							<FormGroup
								labelFor='add-edit-user-dialog--access_roles'
								className='flex flex-col' style={{ flex: 50 }}>
								<TextWithTooltip
									label='Pravice'
									tooltip='Pravice' />
								<Checkbox
									className='pb-4'
									checked={this.state.access_roles.indexOf('main') !== -1}
									onChange={event => {
										const access_roles = this.state.access_roles.filter(x => x != 'main');
										if (event.target.checked) access_roles.push('main');
										this.setState({ access_roles });
									}}>
									Dostop - AssistPRO
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-entry') !== -1}
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-entry');
										if (event.target.checked) {
											access_roles.push('doc-entry');
											
											// remove admin role
											access_roles = access_roles.filter(x => x != 'doc-admin');
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (vpis)
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-entry-edit-bonus-1') !== -1}
									className='ml-2'
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-entry-edit-bonus-1');
										if (event.target.checked) {
											access_roles.push('doc-entry-edit-bonus-1');
											
											// remove admin role
											access_roles = access_roles.filter(x => x != 'doc-admin');
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (vpis, dodatek I.)
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-entry-edit-bonus-2') !== -1}
									className='ml-2'
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-entry-edit-bonus-2');
										if (event.target.checked) {
											access_roles.push('doc-entry-edit-bonus-2');
											
											// remove admin role
											access_roles = access_roles.filter(x => x != 'doc-admin');
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (vpis, dodatek II.)
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-entry-edit-bonus-3') !== -1}
									className='ml-2'
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-entry-edit-bonus-3');
										if (event.target.checked) {
											access_roles.push('doc-entry-edit-bonus-3');
											
											// remove admin role
											access_roles = access_roles.filter(x => x != 'doc-admin');
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (vpis, dodatek III.)
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-entry-edit-bonus-extra') !== -1}
									className='ml-2'
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-entry-edit-bonus-extra');
										if (event.target.checked) {
											access_roles.push('doc-entry-edit-bonus-extra');
											
											// remove admin role
											access_roles = access_roles.filter(x => x != 'doc-admin');
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (vpis, dodatek Extra)
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-statistics') !== -1}
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-statistics');
										if (event.target.checked) {
											access_roles.push('doc-statistics');
											
											// remove admin role
											access_roles = access_roles.filter(x => x != 'doc-admin');
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (statistika)
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-user-statistics') !== -1}
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-user-statistics');
										if (event.target.checked) {
											access_roles.push('doc-user-statistics');
											
											// remove admin role
											access_roles = access_roles.filter(x => x != 'doc-admin');
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (obračun)
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-analytics') !== -1}
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-analytics');
										if (event.target.checked) {
											access_roles.push('doc-analytics');
											
											// remove admin role
											access_roles = access_roles.filter(x => x != 'doc-admin');
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (analitika)
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-settings') !== -1}
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-settings');
										if (event.target.checked) {
											access_roles.push('doc-settings');
											
											// remove admin role
											access_roles = access_roles.filter(x => x != 'doc-admin');
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (nastavitve)
								</Checkbox>
								<Checkbox
									checked={this.state.access_roles.indexOf('doc-admin') !== -1}
									onChange={event => {
										let access_roles = this.state.access_roles.filter(x => x != 'doc-admin');
										if (event.target.checked) {
											access_roles.push('doc-admin');
											
											// remove roles superseded by this one
											access_roles = access_roles.filter(x => {
												return [
													'doc',
													'doc-entry',
													'doc-entry-edit-bonus-1',
													'doc-entry-edit-bonus-2',
													'doc-entry-edit-bonus-3',
													'doc-entry-edit-bonus-extra',
													'doc-statistics',
													'doc-user-statistics',
													'doc-analytics',
													'doc-settings',
												].indexOf(x) === -1;
											});
										}
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (polni dostop)
								</Checkbox>
								<Checkbox
									className='pt-4'
									checked={this.state.access_roles.indexOf('doc-worker') !== -1}
									onChange={event => {
										const access_roles = this.state.access_roles.filter(x => x != 'doc-worker');
										if (event.target.checked) access_roles.push('doc-worker');
										this.setState({ access_roles });
									}}>
									Dostop - evidenca (mobilna)
								</Checkbox>
							</FormGroup>
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = {
									id_user:             this.state.id_user,
									username:            this.state.username,
									name:                this.state.name,
									surname:             this.state.surname,
									is_worker:           this.state.is_worker,
									is_active:           this.state.is_active,
									password:            this.state.password,
									operator_tax_number: this.state.operator_tax_number,
									access_roles:        this.state.access_roles,
									is_foreigner:        this.state.is_foreigner,
								};
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (new_item.username.length == 0) {
									validation_failed_fields.push('username');
									validation_failed_field_titles.push('oznaka');
								}
								if (new_item.name.length == 0) {
									validation_failed_fields.push('name');
									validation_failed_field_titles.push('ime');
								}
								if (new_item.surname.length == 0) {
									validation_failed_fields.push('surname');
									validation_failed_field_titles.push('priimek');
								}
								if (new_item.password.length == 0 && new_item.id_user === -1) {
									validation_failed_fields.push('password');
									validation_failed_field_titles.push('geslo');
								}
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
										</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								// save item
								if (new_item.id_user === -1) {
									new_item.id_user = uuid.v4();
								}
								this.props.closeAddEditItemDialog(new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditUser.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
};

export default AddEditUser;
