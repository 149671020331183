import React from 'react';
import mitt from 'mitt';

var BusContext = React.createContext(null);
var P = BusContext.Provider;

function useBus () {
  return React.useContext(BusContext)
}

function useListener (name, fn) {
  var bus = useBus();
  React.useEffect(function () {
    bus.on(name, fn);
    return function () {
      bus.off(name, fn);
    }
  }, [bus, name, fn]);
}

function Provider (ref) {
  var children = ref.children;

  var ref$1 = React.useState(function () { return mitt(); });
  var bus = ref$1[0];
  return React.createElement( P, { value: bus }, children)
}

export { BusContext, Provider, useBus, useListener };
