import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	NumericInput,
	TextArea,
} from '@blueprintjs/core';
import { DateInput, IDateFormatProps } from '@blueprintjs/datetime';

import moment from 'moment';

import TextWithTooltip      from '../TextWithTooltip';
import { AppToaster }       from '../AppToaster';
import { parseFloatLocal }  from '../../numbers';
import CustomerSelect       from '../CustomerSelect';
import SelectCustomerDialog from '../reservations/SelectCustomerDialog';
import CustomerInfo         from '../business/CustomerInfo';

import { ResultHeader, Result } from '../code_tables/ResultComponents';

function FormTextInput(props) {
	const {
		fieldName,
		title,
		tooltip,
		value,
		changeValue,
		className,
		disabled,
	} = props;
	
	return <FormGroup labelFor={'add-edit-item-dialog--' + fieldName} className={className}>
		<TextWithTooltip label={title} tooltip={tooltip} className='font-bold' />
		<InputGroup
			id={'add-edit-item-dialog--' + fieldName}
			className='w-full'
			value={value}
			disabled={disabled}
			onChange={event => changeValue(event.target.value)} />
	</FormGroup>;
}

function FormTextArea(props) {
	const {
		fieldName,
		title,
		tooltip,
		value,
		changeValue,
		className,
		disabled,
		growVertically,
		large,
	} = props;
	
	return <FormGroup labelFor={'add-edit-item-dialog--' + fieldName} className={className}>
		<TextWithTooltip label={title} tooltip={tooltip} className='font-bold' />
		<TextArea
			id={'add-edit-item-dialog--' + fieldName}
			className='w-full'
			growVertically={growVertically}
			large={large}
			value={value}
			disabled={disabled}
			onChange={event => changeValue(event.target.value)} />
	</FormGroup>;
}

function FormDateInput(props) {
	const {
		fieldName,
		title,
		tooltip,
		value,
		changeValue,
		className,
		disabled,
	} = props;
	
	const moment_formatter = (format) => {
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
			parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
		};
	};
	
	return <FormGroup labelFor={'add-edit-item-dialog--' + fieldName} className={className}>
		<TextWithTooltip label={title} tooltip={tooltip} className='font-bold' />
		<DateInput
			id={'add-edit-item-dialog--' + fieldName}
			className='w-full'
			locale='sl'
			{...moment_formatter('DD. MM. YYYY')}
			inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
			selectAllOnFocus={true}
			minDate={new Date('1900-01-01')}
			maxDate={moment().add('10', 'years').toDate()}
			onChange={(selectedDate, isUserChange) => { if (isUserChange) changeValue(selectedDate) }}
			disabled={disabled}
			value={value} />
	</FormGroup>;
}

const typeTitles = {
	income:  'Prejemek',
	expense: 'Izdatek',
};
const typeTypeTitles = {
	'cash':          'Gotovina',
	'credit-card':   'Kartice',
	'bank-transfer': 'TRR',
	'gift-card':     'Bon',
	'other':         'Drugo',
};

class AddCashRegisterDocumentDialog extends Component {
	constructor(props) {
		super(props);
		
		this.openSelectCustomerDialog  = this.openSelectCustomerDialog .bind(this);
		this.closeSelectCustomerDialog = this.closeSelectCustomerDialog.bind(this);
		
		const table_items       = {};
		const payment_type_keys = Object.keys(props.payment_types);
		for (let i=0; i<payment_type_keys.length; i++) {
			table_items[payment_type_keys[i]] = {
				value:           0.0,
				formatted_value: (0.0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
				note:            '',
			};
		}
		
		this.state = {
			table_items,
			document_date:                  new Date(),
			reference:                      '',
			note:                           '',
			id_customer:                    props.item === null ? null : this.props.item.id_customer,
			select_customer_dialog_open:    false,
			select_customer_dialog_surname: '',
			select_customer_dialog_item:    null,
		};
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	openSelectCustomerDialog(query, item) {
		item = item || null;
		
		this.setState({
			select_customer_dialog_open:    true,
			select_customer_dialog_surname: query,
			select_customer_dialog_item:    item,
		});
	}
	closeSelectCustomerDialog(id_customer, new_customer) {
		const state = {
			select_customer_dialog_open: false,
		};
		/*if (id_customer !== undefined) {
			state.id_customer = id_customer;
			
			state.phone = new_customer.phone;
			state.email = new_customer.email;
		}*/
		
		this.setState(state);
	}
	
	render() {
		const customer = (this.state.id_customer === null ? null : (this.props.customers[this.state.id_customer]) || null);
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			onClose={() => this.props.closeDialog()}
			className='add-cash-register-document-dialog'>
			
			{!this.state.select_customer_dialog_open ? null :
				<SelectCustomerDialog
					closeSelectCustomerDialog={this.closeSelectCustomerDialog}
					surname={this.state.select_customer_dialog_surname}
					item={this.state.select_customer_dialog_item} />
			}
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>{ typeTitles[
							this.props.item !== null ? this.props.item.type : this.props.type
						] }</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY + ' flex flex-col'}>
					<div className='flex flex-row flex-1'>
						<div className='flex flex-col flex-1 mr-2' style={{ flexBasis: '45%' }}>
							<div className='flex flex-row'>
								<div className='flex-1 flex-col' style={{ flexBasis: '40%' }}>
									<div className='mt-1'>
										<div className='font-bold'>Številka dokumenta</div>
										<div>{ this.props.item === null ? 'nov dokument' : this.props.item.document_number }</div>
									</div>
									
									<div className='flex-1 mt-3'>
										<div className='font-bold'>Blagajna</div>
										<div>{ this.props.cash_registers[this.props.id_cash_register].title }</div>
									</div>
									
									<div className='flex-1 mt-3'>
										<div className='font-bold'>Blagajnik</div>
										<div>{ this.props.user.name + ' ' + this.props.user.surname }</div>
									</div>
								</div>
								<div className='flex-1' style={{ flexBasis: '60%' }}>
									<FormGroup className='flex mt-1 mb-0'>
										{this.props.item !== null ?
											<>
												<div className='font-bold'>Stranka</div>
												<CustomerInfo
													customer={customer}
													countries={this.props.countries} />
											</>
											:
											<>
												<CustomerSelect
													itemSelected={item => {
														this.setState({
															id_customer: item.id_customer,
														});
													}}
													addPersonClick={query => { this.openSelectCustomerDialog(query); }}
													fieldTitle='Stranka'
													titleClassName='font-bold' />
												<CustomerInfo
													customer={customer}
													countries={this.props.countries}
													editClick={() => {
														this.openSelectCustomerDialog('', customer);
													}}
													clearClick={() => {
														this.setState({ id_customer: null });
													}} />
											</>
										}
									</FormGroup>
								</div>
							</div>
							<div className='flex flex-row'>
								<div className='flex-1 mb-1 mt-3' style={{ flexBasis: '40%' }}>
									{this.props.item !== null ?
										<>
											<div className='font-bold'>Datum dokumenta</div>
											<div>{ moment(this.props.item.document_date).format('DD. MM. YYYY') }</div>
										</>
										:
										<FormDateInput
											fieldName=  'document_date'
											title=      'Datum dokumenta'
											tooltip=    ''
											value=      {this.state.document_date}
											changeValue={value => {
												this.setState({ document_date: value });
											}}
											disabled=   {false} />
									}
								</div>
								
								<div className='flex-1 mb-1 mt-3' style={{ flexBasis: '60%' }}>
									{this.props.item !== null ?
										<>
											<div className='font-bold'>Referenca</div>
											<div>{ this.props.item.reference }</div>
										</>
										:
										<FormTextInput
											className=  'vibrant'
											fieldName=  'cash'
											title=      'Veza'
											tooltip=    ''
											value=      {this.state.reference}
											changeValue={value => {
												this.setState({ reference: value });
											}} />
									}
								</div>
							</div>
							
							{this.props.item !== null ?
								<div className='mt-1 mb-1'>
									<div className='font-bold'>Opomba</div>
									<div className='whitespace-pre'>{ this.props.item.note }</div>
								</div>
								:
								<FormTextArea
									className=  'mt-1 mb-1 vibrant'
									fieldName=  'cash'
									growVertically={true}
									large=      {true}
									title=      'Opomba'
									tooltip=    ''
									value=      {this.state.note}
									changeValue={value => {
										this.setState({ note: value });
									}} />
							}
						</div>
						<div className='flex flex-col flex-1' style={{ flexBasis: '55%' }}>
							<div className='base-list add-cash-register-document-dialog-payment-type-list flex-1 relative'>
								<div className='results'>
									<div className='results-table'>
										<ResultHeader columnIdx={1} title='Način plačila' />
										<ResultHeader columnIdx={2} title='Tip plačila' />
										<ResultHeader columnIdx={3} cls='text-right' title='Znesek' />
										<ResultHeader columnIdx={4} title='Opis' />
										
										{this.props.item !== null ?
											<>
												{this.props.item.payment_types.map(payment_type_item => {
													const payment_type = this.props.payment_types[payment_type_item.id_payment_type];
													
													return <React.Fragment key={'payment-type-' + payment_type.id_payment_type}>
														<Result columnIdx={1} child={
															payment_type.title
														} />
														<Result columnIdx={2} child={
															typeTypeTitles[payment_type.type]
														} />
														<Result columnIdx={3} child={
															parseFloat(payment_type_item.amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
														} />
														<Result columnIdx={4} cls='text-right' child={
															payment_type_item.note
														} />
													</React.Fragment>;
												})}
											</>
											:
											Object.values(this.props.payment_types)
												.filter(x => x.visible && x.show_in_cash_register_document)
												.sort((a, b) => a.ord - b.ord)
												.map(payment_type => {
													const filled = this.state.table_items[payment_type.id_payment_type].value > 0;
													
													return <React.Fragment key={'payment-type-' + payment_type.id_payment_type}>
														<Result columnIdx={1} cls={ filled ? 'font-bold' : '' } child={
															payment_type.title
														} />
														<Result columnIdx={2} cls={ filled ? 'font-bold' : '' } child={
															typeTypeTitles[payment_type.type]
														} />
														<Result columnIdx={3} child={
															<input
																type='text'
																value={ this.state.table_items[payment_type.id_payment_type].formatted_value }
																onChange={event => {
																	const value = event.target.value;
																	let val     = parseFloatLocal(value);
																	if (Object.is(val, NaN)) {
																		val = 0;
																	}
																	
																	const new_items = {...this.state.table_items};
																	new_items[payment_type.id_payment_type].value           = val;
																	new_items[payment_type.id_payment_type].formatted_value = value;
																	
																	this.setState({
																		table_items: new_items,
																	});
																}}
																onBlur={event => {
																	const new_items = {...this.state.table_items};
																	new_items[payment_type.id_payment_type].formatted_value =
																		new_items[payment_type.id_payment_type].value
																			.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
																	
																	this.setState({
																		table_items: new_items,
																	});
																}} />
														} />
														<Result columnIdx={4} child={
															<input
																type='text'
																value={ this.state.table_items[payment_type.id_payment_type].note }
																onChange={event => {
																	const value = event.target.value;
																	
																	const new_items = {...this.state.table_items};
																	new_items[payment_type.id_payment_type].note = value;
																	
																	this.setState({
																		table_items: new_items,
																	});
																}} />
														} />
													</React.Fragment>;
												})
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						{this.props.item !== null ?
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.props.closeDialog()}>
								Zapri
							</Button>
							:
							<>
								<Button
									minimal={true}
									onClick={() => this.props.closeDialog()}>
									Prekliči
								</Button>
								<Button
									intent={Intent.PRIMARY}
									onClick={() => {
										const valid_payment_count = Object.values(this.state.table_items)
											.filter(x => x.value > 0)
											.filter(x => x.note.length > 0)
											.length;
										
										if (valid_payment_count == 0) {
											AppToaster.show({
												message: <div>
													Vpisan mora biti vsaj en znesek, vsak vpisan znesek pa mora imeti vpisan tudi opis
												</div>,
												intent: 'danger',
												icon:   'issue',
											});
											return;
										}
										
										const item = this.props.item !== null ? {...this.props.item} : {
											id_cash_register_document:    uuid.v4(),
											id_user:                      this.props.user.id_user,
											id_customer:                  customer === null ? null : customer.id_customer,
											customer_id_country:          customer === null ? null : customer.id_country,
											customer_type:                customer === null ? null : customer.type,
											customer_address:             customer === null ? null : customer.address,
											customer_birth_date:          customer === null ? null : customer.birth_date,
											customer_company_long_name:   customer === null ? null : customer.company_long_name,
											customer_company_name:        customer === null ? null : customer.company_name,
											customer_email:               customer === null ? null : customer.email,
											customer_name:                customer === null ? null : customer.name,
											customer_surname:             customer === null ? null : customer.surname,
											customer_phone:               customer === null ? null : customer.phone,
											customer_post_code:           customer === null ? null : customer.post_code,
											customer_post_office:         customer === null ? null : customer.post_office,
											customer_seX:                 customer === null ? null : customer.seX,
											customer_tax_number:          customer === null ? null : customer.tax_number,
											customer_vat_prefix:          customer === null ? null : customer.vat_prefix,
											customer_vat_registered:      customer === null ? null : customer.vat_registered,
											type:                         this.props.type,
											document_number:              null,
											status:                       'new',
											id_cash_register_daily_state: null,
											id_cash_register:             this.props.id_cash_register,
											item_type:                    null,
											manual:                       true,
										};
										
										item.document_date = moment(this.state.document_date).format('YYYY-MM-DD');
										item.reference     = this.state.reference;
										item.note          = this.state.note;
										
										item.payment_types = Object.keys(this.state.table_items)
											.filter(id_payment_type => this.state.table_items[id_payment_type].value > 0)
											.map(id_payment_type => {
												const table_item = this.state.table_items[id_payment_type];
												
												return {
													id_cash_register_document_payment_type: uuid.v4(),
													id_cash_register_document:              item.id_cash_register_document,
													amount:                                 table_item.value,
													full_amount:                            table_item.value,
													id_payment_type,
													note:                                   table_item.note,
													item_type:                              null,
													id_tax_rate:                            null, //'b69ac39a-64bd-4e7b-bc7d-0e82a87e9e1f',
													tax_rate:                               null, //0,
												};
											});
										
										this.props.closeDialog(
											item,
										);
									}}>
									Zaključi
								</Button>
							</>
						}
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddCashRegisterDocumentDialog.propTypes = {
	closeDialog:      PropTypes.func,
	id_cash_register: PropTypes.string,
	date:             PropTypes.string,
	documents:        PropTypes.array,
};

function mapStateToProps(state) {
	return {
		payment_types: state.CodeTablesSlice.payment_types,
		customers:     state.CodeTablesSlice.customers,
		countries:     state.CodeTablesSlice.countries,
		users:         state.UserSlice.users,
		cash_registers: state.CashRegisterSlice.cash_registers,
	};
}

export default connect(mapStateToProps)(AddCashRegisterDocumentDialog);
