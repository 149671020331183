import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	NumericInput,
	MenuItem,
} from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';

import TextWithTooltip from '../TextWithTooltip';
import { AppToaster }  from '../AppToaster';

class AddEditTouristTax extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_tourist_tax:    props.item === null ? -1 : props.item.id_tourist_tax,
			internal_code:     props.item === null ? '' : props.item.internal_code,
			title:             props.item === null ? '' : props.item.title,
			discount:          props.item === null ? 0  : parseFloat(props.item.discount),
			ord:               props.item === null ? 1  : props.item.ord,
			id_accommodations: props.item === null ? [] : (props.item.id_accommodations || []),
			validation_failed_fields: [],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-tourist-tax-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi turistično takso</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='add-edit-tourist-tax-dialog--internal_code'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('internal_code') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Oznaka'
								tooltip='Oznaka' />
							<InputGroup
								id='add-edit-tourist-tax-dialog--internal_code'
								value={this.state.internal_code}
								onChange={event => {
									this.setState({
										internal_code: event.target.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-tourist-tax-dialog--title'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('title') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Naziv'
								tooltip='Naziv' />
							<InputGroup
								id='add-edit-tourist-tax-dialog--title'
								value={this.state.title}
								onChange={event => {
									this.setState({
										title: event.target.value,
									});
								}} />
						</FormGroup>
						<div className='flex flex-row justify-between'>
							<FormGroup
								labelFor='add-edit-tourist-tax-dialog--discount'
								className={
									(this.state.validation_failed_fields.indexOf('discount') == -1 ?
										'' : 'validation-failed')
								}>
								<TextWithTooltip
									label='Popust (%)'
									tooltip='Popust (%)' />
								<NumericInput
									id='add-edit-tourist-tax-dialog--discount'
									value={this.state.discount}
									min={0}
									max={100}
									minorStepSize={1}
									onValueChange={(val, val_str) => {
										this.setState({
											discount: val_str,
										});
									}} />
							</FormGroup>
							<FormGroup
								labelFor='add-edit-tourist-tax-dialog--ord'>
								<TextWithTooltip
									label='Vrstni red'
									tooltip='Vrstni red' />
								<NumericInput
									id='add-edit-tourist-tax-dialog--ord'
									value={this.state.ord}
									min={0}
									minorStepSize={1}
									onValueChange={val => {
										this.setState({
											ord: val,
										});
									}} />
							</FormGroup>
						</div>
						<FormGroup>
							<TextWithTooltip
								label='Rezervacijska mesta'
								tooltip='Rezervacijska mesta' />
							<MultiSelect
								items={Object.values(this.props.accommodations).filter(item => item.type == 'regular')}
								itemRenderer={(item, { handleClick, modifiers }) => {
									return <MenuItem
										className='bp3-menu-item-horizontal'
										active={modifiers.active}
										icon={this.state.id_accommodations.indexOf(item.id_accommodation) != -1 ? 'tick' : 'blank'}
										key={item.id_accommodation}
										onClick={handleClick}
										text={item.title} />;
								}}
								onItemSelect={(item, e) => {
									const id_accommodations = [...this.state.id_accommodations];
									
									const idx = id_accommodations.indexOf(item.id_accommodation);
									if (idx == -1) {
										id_accommodations.push(item.id_accommodation);
									}
									else {
										id_accommodations.splice(idx, 1);
									}
									
									this.setState({ id_accommodations });
								}}
								popoverProps={{
									targetTagName: 'div',
								}}
								tagRenderer={item => item.title}
								tagInputProps={{ onRemove: (tag, idx) => {
									const id_accommodations = [...this.state.id_accommodations];
									id_accommodations.splice(idx, 1);
									this.setState({ id_accommodations });
								} }}
								itemsEqual='id_accommodation'
								selectedItems={this.state.id_accommodations.map(id_accommodation => this.props.accommodations[id_accommodation])} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = {
									id_tourist_tax: this.state.id_tourist_tax,
									internal_code:  this.state.internal_code,
									title:          this.state.title,
									discount:       this.state.discount,
									ord:            this.state.ord,
									id_accommodations: this.state.id_accommodations.length == 0 ? null : this.state.id_accommodations,
									serial_internal_code: this.props.item === null ? null : this.props.item.serial_internal_code,
								};
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (new_item.internal_code.length == 0) {
									validation_failed_fields.push('internal_code');
									validation_failed_field_titles.push('oznaka');
								}
								if (new_item.title.length == 0) {
									validation_failed_fields.push('title');
									validation_failed_field_titles.push('naziv');
								}
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
										</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								// save item
								this.props.closeAddEditItemDialog(new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditTouristTax.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		accommodations: state.CodeTablesSlice.accommodations,
	};
}

export default connect(mapStateToProps)(AddEditTouristTax);
