import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	MenuItem,
	Navbar,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import TextWithTooltip from '../TextWithTooltip';
import { AppToaster }  from '../AppToaster';

class AddEditPostOffice extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_post_office: props.item === null ? -1   : props.item.id_post_office,
			post_code:      props.item === null ? ''   : props.item.post_code,
			title:          props.item === null ? ''   : props.item.title,
			id_country:     props.item === null ? null : props.item.id_country,
			validation_failed_fields: [],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-post-office-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi pošto</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='add-edit-post-office-dialog--id_country'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('id_country') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Država'
								tooltip='Država' />
							<Select
								id='add-edit-post-office-dialog--id_country'
								items={Object.values(this.props.countries)}
								itemPredicate={(query, item, idx, exactMatch) => {
									return item.official_name_local.toUpperCase().indexOf(query.toUpperCase()) > -1;
								}}
								itemRenderer={(item, { handleClick, modifiers }) => {
									return <MenuItem
										active={modifiers.active}
										key={item.id_country}
										onClick={handleClick}
										text={item.official_name_local} />;
								}}
								onItemSelect={item => {
									this.setState({ id_country: item.id_country });
								}}
								popoverProps={{
									targetTagName: 'div',
								}}>
								<Button
									text={
										this.state.id_country == null ?
											'Izberi državo'
											:
											this.props.countries[this.state.id_country].official_name_local
									}
									rightIcon='double-caret-vertical'
									fill={true}
									alignText={Alignment.LEFT} />
							</Select>
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-post-office-dialog--post_code'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('post_code') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Poštna številka'
								tooltip='Poštna številka' />
							<InputGroup
								id='add-edit-post-office-dialog--post_code'
								value={this.state.post_code}
								onChange={event => {
									this.setState({
										post_code: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-post-office-dialog--title'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('title') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Naziv'
								tooltip='Naziv' />
							<InputGroup
								id='add-edit-post-office-dialog--title'
								value={this.state.title}
								onChange={event => {
									this.setState({
										title: event.target.value,
									});
								}} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = {
									id_post_office: this.state.id_post_office,
									id_country:     this.state.id_country,
									post_code:      this.state.post_code,
									title:          this.state.title,
									serial_internal_code: this.props.item === null ? null : this.props.item.serial_internal_code,
								};
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (new_item.id_country === null) {
									validation_failed_fields.push('id_country');
									validation_failed_field_titles.push('država');
								}
								if (new_item.post_code.length == 0) {
									validation_failed_fields.push('post_code');
									validation_failed_field_titles.push('koda');
								}
								if (new_item.title.length == 0) {
									validation_failed_fields.push('title');
									validation_failed_field_titles.push('naziv');
								}
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
										</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								// save item
								this.props.closeAddEditItemDialog(new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditPostOffice.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item:                   PropTypes.object,
	countries:              PropTypes.object,
};

export default AddEditPostOffice;
