import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Button,
	Intent,
	Icon,
	Dialog,
	Classes,
	Navbar,
	Alignment,
	ControlGroup,
	NumericInput,
	TextArea,
	Checkbox,
	Tooltip,
} from '@blueprintjs/core';

import moment from 'moment';
import uuid   from 'uuid';
import Sortable from 'react-sortablejs';

import EditInvoiceDialog                                             from '../business/EditInvoiceDialog';
import WaitFiscalVerificationDialog                                  from '../business/WaitFiscalVerificationDialog';
import { addActivityReservation  }                                   from '../../slices/ReservationSlice';
import { saveActivityReservation }                                   from '../../api/Reservations';
import { addInvoice, addInvoices, addAdvanceInvoiceConsumptions }    from '../../slices/BusinessSlice';
import { saveInvoice, loadInvoices, loadAdvanceInvoiceConsumptions } from '../../api/Business';
import { addDocumentAssociations }                                   from '../../slices/DocumentSlice';
import InvoiceCreator                                                from '../../helpers/InvoiceCreator';
import InvoiceHelper                                                 from '../../helpers/Invoice';
import PriceHelper                                                   from '../../helpers/PriceHelper';
import UserTag                                                       from '../UserTag';

import { enqueueInvoiceOnA4PrinterQueue, enqueueInvoiceOnPosPrinterQueue } from '../../slices/AppSlice';

class TimelineActivities extends Component {
	constructor(props) {
		super(props);
		
		this.isActivitySelected                            = this.isActivitySelected                           .bind(this);
		this.updateFilteredItems                           = this.updateFilteredItems                          .bind(this);
		this.openGuestCount                                = this.openGuestCount                               .bind(this);
		this.closeGuestCount                               = this.closeGuestCount                              .bind(this);
		this.openNotePopup                                 = this.openNotePopup                                .bind(this);
		this.closeNotePopup                                = this.closeNotePopup                               .bind(this);
		this.handleSortableChange                          = this.handleSortableChange                         .bind(this);
		this.moveReservationFromState                      = this.moveReservationFromState                     .bind(this);
		this.groupActivityReservationsByTimeSlotActivities = this.groupActivityReservationsByTimeSlotActivities.bind(this);
		this.openCreateInvoiceDialog                       = this.openCreateInvoiceDialog                      .bind(this);
		this.openEditInvoiceDialog                         = this.openEditInvoiceDialog                        .bind(this);
		this.closeEditInvoiceDialog                        = this.closeEditInvoiceDialog                       .bind(this);
		this.setCompact                                    = this.setCompact                                   .bind(this);
		this.openPrintPOSDialog                            = this.openPrintPOSDialog                           .bind(this);
		this.closePrintPOSDialog                           = this.closePrintPOSDialog                          .bind(this);
		this.getUserByUsername                             = this.getUserByUsername                            .bind(this);
		
		this.state = {
			items: this.updateFilteredItems(props, true),
			note_popup_id_activity_reservation: null,
			note_popup_note: '',
			guest_count_popup_id_activity_reservation: null,
			guest_count_popup_count: -1,
			guest_child_count_popup_count: -1,
			activity_reservations: this.groupActivityReservationsByTimeSlotActivities(props, true),
			create_invoice_dialog_open: false,
			create_invoice_dialog_item: null,
			create_invoice_dialog_type: '',
			compact: false,
			wait_fiscal_verification_dialog_open:       false,
			wait_fiscal_verification_dialog_id_invoice: null,
			print_pos_dialog_open: false,
			print_pos_dialog_item: null,
			print_pos_dialog_id_activities_checked: {},
			request_move_reservation_open:                   false,
			request_move_reservation_from_id:                -1,
			request_move_reservation_activity_item_key_from: -1,
			request_move_reservation_activity_item_key_to:   -1,
			request_move_reservation_day_count:              -1,
		};
		
		this.skip_next_sortable_event = false;
	}
	
	updateFilteredItems(props, only_return) {
		only_return = only_return || false;
		
		let filtered_items = [];
		const all_items    = [];
		
		if (props.accommodation_item_schedules !== undefined &&
			props.accommodation_item_schedule_time_slots !== undefined &&
			props.accommodation_item_schedule_time_slot_activities !== undefined) {
			
			const item_schedules = Object.values(props.accommodation_item_schedules)
				.filter(item => item.id_accommodation_item == props.idAccommodationItem)
				.sort((a, b) => {
					if (a.valid_from > b.valid_from) return -1;
					if (a.valid_from < b.valid_from) return  1;
					
					if (a.valid_until === null) return -1;
					if (b.valid_until === null) return  1;
					
					return 0;
				});
			
			const weekdays = [
				'sunday',   'monday', 'tuesday', 'wednesday',
				'thursday', 'friday', 'saturday'
			];
			
			const current_date = moment(props.currentDate);
			for (let i=0; i<props.scaleDays; i++) {
				// find the most appropriate schedule
				const current_date_iso = current_date.format('YYYY-MM-DD');
				let accommodation_item_schedule = null;
				for (let j=0; j<item_schedules.length; j++) {
					const item = item_schedules[j];
					if (
						item.valid_from <= current_date_iso &&
						(item.valid_until === null || item.valid_until >= current_date_iso) &&
						(accommodation_item_schedule === null || item.valid_from > accommodation_item_schedule.valid_from)
					) {
						accommodation_item_schedule = item;
					}
				}
				
				if (accommodation_item_schedule === null) continue;
				
				const time_slots = Object.values(props.accommodation_item_schedule_time_slots).filter(item =>
					item.id_accommodation_item_schedule == accommodation_item_schedule.id_accommodation_item_schedule);
				
				let time_slots_for_current_date = [];
				for (let i=0; i<time_slots.length; i++) {
					if (time_slots[i].day_of_week == weekdays[current_date.day()]) {
						time_slots_for_current_date.push(time_slots[i]);
					}
				}
				
				time_slots_for_current_date.sort((a, b) => {
					if      (a.starts_at < b.starts_at) return -1;
					else if (a.starts_at > b.starts_at) return  1;
					                                    return  0;
				});
				
				const items = [];
				const activities_values = Object.values(props.accommodation_item_schedule_time_slot_activities);
				for (let i=0; i<time_slots_for_current_date.length; i++) {
					const time_slot  = time_slots_for_current_date[i];
					const activities = activities_values.filter(item =>
						item.id_accommodation_item_schedule_time_slot == time_slot.id_accommodation_item_schedule_time_slot &&
						this.isActivitySelected(item.id_activity));
					
					if (activities.length > 0 && Object.keys(props.activities).length > 0) {
						const starts_at_arr = time_slot.starts_at.split(':');
						
						const time_label = starts_at_arr.length != 3 ? '00:00' : starts_at_arr[0] + ':' + starts_at_arr[1];
						
						const item = {
							id_time_slot: time_slot.id_accommodation_item_schedule_time_slot,
							time_label,
							activities: activities.sort((a, b) => {
								return props.activities[a.id_activity].ord - props.activities[b.id_activity].ord;
							}).map(activity => {
								return {
									key:             activity.id_accommodation_item_schedule_time_slot_activity,
									title:           props.activities[activity.id_activity].title,
									max_guest_count: activity.max_guest_count,
									guests:          [],
								};
							}),
						};
						
						items.push(item);
					}
				}
				
				all_items.push(items);
				
				current_date.add(1, 'days');
			}
		}
		
		filtered_items = all_items;
		
		if (!only_return) {
			this.setState({
				items: filtered_items,
			});
		}
		
		return filtered_items;
	}
	
	groupActivityReservationsByTimeSlotActivities(props, only_return) {
		only_return = only_return || false;
		
		const activity_reservations = {};
		if (props.activity_reservations !== undefined) {
			for (let key in props.activity_reservations) {
				const activity_reservation = props.activity_reservations[key];
				if (
					activity_reservation.status == 'reversed' ||
					activity_reservation.status == 'no-show'  ||
					activity_reservation.deleted === true
				) continue;
				
				if (activity_reservations[activity_reservation.id_accommodation_item_schedule_time_slot_activity] === undefined) {
					activity_reservations[activity_reservation.id_accommodation_item_schedule_time_slot_activity] = [];
				}
				
				activity_reservations[activity_reservation.id_accommodation_item_schedule_time_slot_activity].push(activity_reservation);
			}
		}
		
		if (!only_return) {
			this.setState({
				activity_reservations,
			});
		}
		
		return activity_reservations;
	}
	
	isActivitySelected(activity) {
		return this.props.selectedActivities.indexOf(activity) != -1;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.currentDate != this.props.currentDate) {
		}
		
		if (
			!this.compareArrays(prevProps.selectedActivities, this.props.selectedActivities) ||
			prevProps.accommodation_item_schedule != this.props.accommodation_item_schedule ||
			prevProps.accommodation_item_schedule_time_slots != this.props.accommodation_item_schedule_time_slots ||
			prevProps.accommodation_item_schedule_time_slot_activities != this.props.accommodation_item_schedule_time_slot_activities ||
			prevProps.scaleDays != this.props.scaleDays ||
			prevProps.currentDate != this.props.currentDate
		) {
			this.updateFilteredItems(this.props);
		}
		
		if (prevProps.activity_reservations != this.props.activity_reservations) {
			this.setState({
				activity_reservations: this.groupActivityReservationsByTimeSlotActivities(this.props, true),
				items: this.updateFilteredItems(this.props, true),
			});
		}
	}
	
	compareArrays(arr1, arr2) {
		if (arr1.length != arr2.length)
		{
			return false;
		}

		for (var i = 0, l=arr1.length; i < l; i++) {
			// Check if we have nested arrays
			if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
				// recurse into the nested arrays
				if (!arr1[i].equals(arr2[i])) {
					return false;
				}
			}
			else if (arr1[i] != arr2[i]) {
				// Warning - two different object instances will never be equal: {x:20} != {x:20}
				return false;
			}
		}       
		return true;
	}
	
	openGuestCount(reservation) {
		this.setState({
			guest_count_popup_id_activity_reservation: reservation.id_activity_reservation,
			guest_count_popup_count:       reservation.guest_count,
			guest_child_count_popup_count: reservation.guest_child_count,
		});
	}
	async closeGuestCount(save) {
		if (save) {
			const item = this.props.activity_reservations[this.state.guest_count_popup_id_activity_reservation];
			if (item === null || item === undefined) return;
			
			if (item.guest_count != this.state.guest_count_popup_count || item.guest_child_count != this.state.guest_child_count_popup_count) {
				let new_item = {...item};
				new_item.guest_count       = this.state.guest_count_popup_count;
				new_item.guest_child_count = this.state.guest_child_count_popup_count;
				
				const dispatch = this.props.dispatch;
				const token    = this.props.token;
				
				dispatch(addActivityReservation({
					item: new_item,
					token,
				}));
				new_item = await saveActivityReservation(this.props.api_url, new_item, token);
				if (new_item !== null) {
					dispatch(addActivityReservation({
						item: new_item,
						token,
					}));
				}
			}
		}
		
		this.setState({
			guest_count_popup_id_activity_reservation: null,
		});
	}
	
	openNotePopup(reservation) {
		this.setState({
			note_popup_id_activity_reservation: reservation.id_activity_reservation,
			note_popup_note:                    reservation.note,
		});
	}
	async closeNotePopup(save) {
		if (save) {
			const item = this.props.activity_reservations[this.state.note_popup_id_activity_reservation];
			if (item === null || item === undefined) return;
			
			if (item.note != this.state.note_popup_note) {
				let new_item = {...item};
				new_item.note = this.state.note_popup_note;
				
				const dispatch = this.props.dispatch;
				const token    = this.props.token;
				
				dispatch(addActivityReservation({
					item: new_item,
					token,
				}));
				new_item = await saveActivityReservation(this.props.api_url, new_item, token);
				if (new_item !== null) {
					dispatch(addActivityReservation({
						item: new_item,
						token,
					}));
				}
			}
		}
		
		this.setState({
			note_popup_id_activity_reservation: null,
			note_popup_note:                    '',
		});
	}
	
	handleSortableChange(evt) {
		if (this.skip_next_sortable_event) {
			this.skip_next_sortable_event = false;
			return;
		}
		
		if (evt.from != evt.to) {
			this.skip_next_sortable_event = true;
		}
		
		const path_from = evt.from.dataset.path.split('--');
		const path_to   = evt.to  .dataset.path.split('--');
		
		const activity_item_key_from = path_from[0];
		const activity_item_key_to   = path_to  [0];
		
		const id_arr = evt.item.dataset.id.split('--');
		
		this.setState({
			request_move_reservation_open:                   true,
			request_move_reservation_from_id:                id_arr[1],
			request_move_reservation_activity_item_key_from: activity_item_key_from,
			request_move_reservation_activity_item_key_to:   activity_item_key_to,
			request_move_reservation_day_count:              path_to[1],
		});
	}
	async moveReservationFromState() {
		let new_item = {...this.props.activity_reservations[this.state.request_move_reservation_from_id]};
		
		new_item.id_accommodation_item_schedule_time_slot_activity = this.state.request_move_reservation_activity_item_key_to;
		new_item.reservation_date = moment(this.props.currentDate).add(this.state.request_move_reservation_day_count, 'days').format('YYYY-MM-DD');
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		dispatch(addActivityReservation({
			item: new_item,
			token,
		}));
		new_item = await saveActivityReservation(this.props.api_url, new_item, token);
		if (new_item !== null) {
			dispatch(addActivityReservation({
				item: new_item,
				token,
			}));
		}
		
		this.setState({
			request_move_reservation_open:                 false,
			request_move_reservation_from_id:              -1,
			request_move_reservation_activity_item_key_to: -1,
			request_move_reservation_day_count:            -1,
		});
	}
	
	openCreateInvoiceDialog(type, reservation) {
		const accommodation_item_schedule_time_slot_activity = this.props.accommodation_item_schedule_time_slot_activities[
			reservation.id_accommodation_item_schedule_time_slot_activity
		];
		const activity = this.props.activities[accommodation_item_schedule_time_slot_activity.id_activity];
		
		const people_counts = [];
		if (activity.id_item !== null) {
			if (reservation.guest_count > 0) {
				people_counts.push({ count: reservation.guest_count, type: 'adult' });
			}
			if (reservation.guest_child_count > 0) {
				people_counts.push({ count: reservation.guest_child_count, type: 'child' });
			}
		}
		
		this.openEditInvoiceDialog(type, {
			invoice_number_internal: '',
			invoice_type: type,
			note: '',
			
			invoice_number:     '',
			id_customer:        reservation.id_customer,
			invoice_date:       moment().toISOString(true),
			payment_date:       null,
			service_date_start: moment().toISOString(true),
			service_date_end:   moment().toISOString(true),
			reference:          '',
			reservation:        '',
			id_payment_type:    null,
			original_id_invoice:   reservation.id_activity_reservation,
			original_invoice_type: 'activity-reservation',
			items:                 people_counts.map((people_count, idx) => {
				const item            = this.props.items[activity.id_item];
				const id_price_list   = PriceHelper.FindBestPriceListMatch(new Date(), item.price_lists);
				const item_price_list = id_price_list === null ? null : item.price_lists[id_price_list];
				
				const measuring_unit  = this.props.measuring_units[item.id_measuring_unit];
				const tax_rate        = item_price_list === undefined ? null : this.props.tax_rates[item_price_list.id_tax_rate];
				
				return InvoiceCreator.CreateInvoiceItemFromInitialData({
					id_invoice_item:                         uuid.v4(),
					description:                             '',
					id_item:                                 item.id_item,
					id_item_price_list:                      id_price_list,
					id_tax_rate:                             item_price_list === undefined ? null : item_price_list.id_tax_rate,
					item_measuring_unit_code:                measuring_unit.title,
					item_measuring_unit_decimal_digit_count: measuring_unit.decimal_digit_count,
					price:                                   item_price_list.price,
					quantity:                                people_count.count,
					discount:                                people_count.type == 'adult' ? 0 : activity.item_child_discount,
					tax_rate,
					item_type:                               item.type,
					item_ean:                                item.ean,
					item_title:                              item.title,
					ord:                                     idx,
					id_warehouse: item.item_type != 'item' ? null : InvoiceHelper.findCurrentIdWarehouse(
						this.props.current_id_cash_register === undefined || this.props.current_id_cash_register === null ? null :
							this.props.cash_registers[this.props.current_id_cash_register],
						this.props.warehouses
					),
				});
			}),
		});
	}
	openEditInvoiceDialog(type, item) {
		this.setState({
			create_invoice_dialog_open: true,
			create_invoice_dialog_type: type,
			create_invoice_dialog_item: item,
		});
	}
	async closeEditInvoiceDialog(item, open_create_invoice_type, finish_invoice) {
		this.setState({
			create_invoice_dialog_open: false,
			create_invoice_dialog_item: null,
		});
		
		if (item !== undefined) {
			this.props.dispatch(addInvoice({
				item,
				token: this.props.token,
			}));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			const new_items = await saveInvoice(this.props.api_url, item, token, dispatch);
			if (new_items !== null) {
				if (new_items.invoices !== undefined) {
					dispatch(addInvoices(new_items.invoices));
				}
				if (new_items.document_associations !== undefined) {
					dispatch(addDocumentAssociations(new_items.document_associations));
				}
				if (new_items.advance_invoice_consumptions !== undefined) {
					dispatch(addAdvanceInvoiceConsumptions(new_items.advance_invoice_consumptions));
				}
				
				if (finish_invoice && this.props.document_types[item.invoice_type].fiscal_verification_enabled) {
					this.setState({
						wait_fiscal_verification_dialog_open:       true,
						wait_fiscal_verification_dialog_id_invoice: item.id_invoice,
					});
				}
				
				/*console.log({ item });
				for (let i=0; i<new_items.invoices.length; i++) {
					self.addAndSaveDocumentAssociation(
						new_items.invoices[i].id_invoice,
						new_items.invoices[i].invoice_type,
						item.id_activity_reservation,
						'activity-reservation'
					);
				}*/
			}
		}
	}
	
	setCompact(enabled) {
		this.setState({
			compact: enabled,
		});
	}
	
	openPrintPOSDialog(item, date, time_slot) {
		const activities_checked = {};
		for (let i=0; i<item.activities.length; i++) {
			activities_checked[item.activities[i].key] = false;
		}
		
		this.setState({
			print_pos_dialog_open: true,
			print_pos_dialog_item: item,
			print_pos_dialog_date: date,
			print_pos_dialog_time_slot: time_slot,
			print_pos_dialog_id_activities_checked: activities_checked,
		});
	}
	closePrintPOSDialog(print, id_activities) {
		this.setState({ print_pos_dialog_open: false });
		
		if (print) {
			const activity_reservations = {};
			for (let i=0; i<this.state.print_pos_dialog_item.activities.length; i++) {
				if (this.state.print_pos_dialog_id_activities_checked[this.state.print_pos_dialog_item.activities[i].key] !== true) continue;
				
				activity_reservations[this.state.print_pos_dialog_item.activities[i].key] =
					(this.state.activity_reservations[this.state.print_pos_dialog_item.activities[i].key] || [])
						.filter(x => x.reservation_date == this.state.print_pos_dialog_date);
			}
			
			this.props.dispatch(enqueueInvoiceOnPosPrinterQueue({
				type:      'activities',
				item:      this.state.print_pos_dialog_item,
				date:      this.state.print_pos_dialog_date,
				time_slot: this.state.print_pos_dialog_time_slot,
				activity_reservations,
			}));
		}
	}
	
	getUserByUsername(username) {
		if (this.props.users === null) return null;
		
		for (let id_user in this.props.users) {
			const user = this.props.users[id_user];
			if (user !== undefined && user.username == username) {
				return user;
			}
		}
		
		return null;
	}
	
	render() {
		const weekdays = [
			'nedelja',
			'ponedeljek',
			'torek',
			'sreda',
			'četrtek',
			'petek',
			'sobota',
		];
		const dbKeysToWeekdays = {
			sunday:    'nedelja',
			monday:    'ponedeljek',
			tuesday:   'torek',
			wednesday: 'sreda',
			thursday:  'četrtek',
			friday:    'petek',
			saturday:  'sobota',
		};
		
		const items = this.state.items;
		
		return <>
			{!this.state.create_invoice_dialog_open ? null :
				<EditInvoiceDialog
					closeEditInvoiceDialog={this.closeEditInvoiceDialog}
					item={this.state.create_invoice_dialog_item}
					type={this.state.create_invoice_dialog_type} />
			}
			
			<Dialog
				isOpen={this.state.note_popup_id_activity_reservation !== null}
				onClose={() => this.closeNotePopup(false)}
				className='dialog--note-popup-data'>
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp3-dark'>
						<Navbar.Group>
							<Navbar.Heading>Opomba</Navbar.Heading>
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button minimal={true} icon='cross' onClick={() => this.closeNotePopup(false)} />
						</Navbar.Group>
					</Navbar>
					<div className={Classes.DIALOG_BODY}>
						<TextArea
							growVertically={true}
							large={true}
							fill={true}
							intent={Intent.PRIMARY}
							onChange={(e) => {
								this.setState({
									note_popup_note: e.target.value,
								});
							}}
							value={this.state.note_popup_note} />
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button
								minimal={true}
								onClick={() => this.closeNotePopup(false)}>
								Prekliči
							</Button>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.closeNotePopup(true)}>
								Shrani
							</Button>
						</div>
					</div>
				</div>
			</Dialog>
			
			<Dialog
				isOpen={this.state.guest_count_popup_id_activity_reservation !== null}
				onClose={() => this.closeGuestCount(false)}
				style={{ width: 160 }}>
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp3-dark'>
						<Navbar.Group>
							<Navbar.Heading>Št. oseb</Navbar.Heading>
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button
								minimal={true}
								icon='cross'
								onClick={() => this.closeGuestCount(false)} />
						</Navbar.Group>
					</Navbar>
					<div className={Classes.DIALOG_BODY + ' p-2'}>
						<div>Odrasli:</div>
						<ControlGroup>
							<Button icon='minus' tabIndex='-1' minimal={false} small={true} onClick={() => {
								this.setState({
									guest_count_popup_count: Math.max(0, this.state.guest_count_popup_count - 1),
								});
							}} />
							<NumericInput
								id='guest-count--count'
								className='centered-numeric-input'
								buttonPosition='none'
								fill={true}
								value={this.state.guest_count_popup_count}
								min={0}
								onValueChange={val => {
									this.setState({ guest_count_popup_count: val });
								}} />
							<Button icon='plus' tabIndex='-1' minimal={false} small={true} onClick={() => {
								this.setState({
									guest_count_popup_count: (this.state.guest_count_popup_count + 1),
								});
							}} />
						</ControlGroup>
						
						<div>Otroci:</div>
						<ControlGroup>
							<Button icon='minus' tabIndex='-1' minimal={false} small={true} onClick={() => {
								this.setState({
									guest_child_count_popup_count: Math.max(0, this.state.guest_child_count_popup_count - 1),
								});
							}} />
							<NumericInput
								id='guest-child-count--count'
								className='centered-numeric-input'
								buttonPosition='none'
								fill={true}
								value={this.state.guest_child_count_popup_count}
								min={0}
								onValueChange={val => {
									this.setState({ guest_child_count_popup_count: val });
								}} />
							<Button icon='plus' tabIndex='-1' minimal={false} small={true} onClick={() => {
								this.setState({
									guest_child_count_popup_count: (this.state.guest_child_count_popup_count + 1),
								});
							}} />
						</ControlGroup>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button
								minimal={true}
								onClick={() => this.closeGuestCount(false)}>
								Prekliči
							</Button>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.closeGuestCount(true)}>
								Shrani
							</Button>
						</div>
					</div>
				</div>
			</Dialog>
			
			{!this.state.wait_fiscal_verification_dialog_open ? null :
				<WaitFiscalVerificationDialog
					closeWaitFiscalVerificationDialog={(print) => {
						this.setState({ wait_fiscal_verification_dialog_open: false });
						
						if (print) {
							const invoice = this.props.invoices[this.state.wait_fiscal_verification_dialog_id_invoice];
							if (this.props.general_settings.auto_print_type == 'a4') {
								this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'invoice', lang: 'sl', invoice }));
							}
							else if (this.props.general_settings.auto_print_type == 'pos') {
								this.props.dispatch(enqueueInvoiceOnPosPrinterQueue({ type: 'invoice', invoice }));
							}
						}
					}}
					id_invoice={this.state.wait_fiscal_verification_dialog_id_invoice} />
			}
			
			{!this.state.print_pos_dialog_open ? null :
				<Dialog
					onClose={() => this.closePrintPOSDialog(false)}
					isOpen={true}
					style={{ width: 260 }}>
					<div className='flex flex-col flex-grow'>
						<Navbar fixedToTop={false} className='bp3-dark'>
							<Navbar.Group>
								<Navbar.Heading>Izberi aktivnosti</Navbar.Heading>
							</Navbar.Group>
							<Navbar.Group align={Alignment.RIGHT}>
								<Button
									minimal={true}
									icon='cross'
									onClick={() => this.closePrintPOSDialog(false)} />
							</Navbar.Group>
						</Navbar>
						<div className={Classes.DIALOG_BODY + ' p-2'}>
							<Checkbox
								checked={Object.values(this.state.print_pos_dialog_id_activities_checked).every(x => x)}
								label='Vse'
								onChange={event => {
									const print_pos_dialog_id_activities_checked = {...this.state.print_pos_dialog_id_activities_checked};
									for (let id_activity in print_pos_dialog_id_activities_checked) {
										print_pos_dialog_id_activities_checked[id_activity] = event.target.checked;
									}
									
									this.setState({ print_pos_dialog_id_activities_checked });
								}} />
							{this.state.print_pos_dialog_item.activities.map(activity =>
								<Checkbox
									key={'print-pos-dialog-activity' + activity.key}
									checked={this.state.print_pos_dialog_id_activities_checked[activity.key]}
									label={activity.title}
									onChange={event => {
										const print_pos_dialog_id_activities_checked = {...this.state.print_pos_dialog_id_activities_checked};
										print_pos_dialog_id_activities_checked[activity.key] = event.target.checked;
										
										this.setState({ print_pos_dialog_id_activities_checked });
									}} />
							)}
						</div>
						<div className={Classes.DIALOG_FOOTER}>
							<div className={Classes.DIALOG_FOOTER_ACTIONS}>
								<Button
									minimal={true}
									onClick={() => this.closePrintPOSDialog(false)}>
									Prekliči
								</Button>
								<Button
									intent={Intent.PRIMARY}
									onClick={() => this.closePrintPOSDialog(true)}>
									Natisni
								</Button>
							</div>
						</div>
					</div>
				</Dialog>
			}
			
			{!this.state.request_move_reservation_open ? null :
				<Dialog
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					isOpen={true}
					style={{ minWidth: 580 }}>
					<div className={Classes.DIALOG_BODY}>
						{(() => {
							const reservation   = this.props.activity_reservations[this.state.request_move_reservation_from_id];
							const customer      = this.props.customers[reservation.id_customer];
							const customer_name = customer.type == 'natural' ?
								customer.surname + ' ' + customer.name
								:
								customer.company_name;
							
							const time_slot_from = this.props.accommodation_item_schedule_time_slots[
								this.props.accommodation_item_schedule_time_slot_activities[this.state.request_move_reservation_activity_item_key_from].id_accommodation_item_schedule_time_slot
							];
							const activity_from = this.props.activities[
								this.props.accommodation_item_schedule_time_slot_activities[this.state.request_move_reservation_activity_item_key_from].id_activity
							];
							
							const day_of_week_from   = dbKeysToWeekdays[time_slot_from.day_of_week]
							const starts_at_arr_from = time_slot_from.starts_at.split(':');
							const time_label_from    = starts_at_arr_from.length != 3 ? '00:00' : starts_at_arr_from[0] + ':' + starts_at_arr_from[1];
							
							const time_slot_to = this.props.accommodation_item_schedule_time_slots[
								this.props.accommodation_item_schedule_time_slot_activities[this.state.request_move_reservation_activity_item_key_to].id_accommodation_item_schedule_time_slot
							];
							const activity_to = this.props.activities[
								this.props.accommodation_item_schedule_time_slot_activities[this.state.request_move_reservation_activity_item_key_to].id_activity
							];
							
							const day_of_week_to   = dbKeysToWeekdays[time_slot_to.day_of_week]
							const starts_at_arr_to = time_slot_to.starts_at.split(':');
							const time_label_to    = starts_at_arr_to.length != 3 ? '00:00' : starts_at_arr_to[0] + ':' + starts_at_arr_to[1];
							
							return <div>
								<div className='mb-3'><strong>Sprememba rezervacije?</strong></div>
								
								<div>
									<span>Rezervacija: </span>
									<span>{reservation.internal_code}</span>
								</div>
								<div className='mb-3'>Nosilec: {customer_name}</div>
								
								<div><strong>Sprememba:</strong></div>
								<div>Iz: {activity_from.title}, {day_of_week_from}, {time_label_from}</div>
								<div>Na: {activity_to.title}, {day_of_week_to}, {time_label_to}</div>
							</div>
						})()}
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button onClick={() => {
								this.setState({
									request_move_reservation_open:                   false,
									request_move_reservation_from_id:                -1,
									request_move_reservation_activity_item_key_from: -1,
									request_move_reservation_activity_item_key_to:   -1,
									request_move_reservation_day_count:              -1,
								});
							}}>Prekliči</Button>
							<Button intent='primary' onClick={this.moveReservationFromState}>Da</Button>
						</div>
					</div>
				</Dialog>
			}
			
			<div style={{ left: 0, right: 0, top: 0, bottom: 0, position: 'absolute' }} className='timeline-activities overflow-auto mt-2'>
				<div style={{ display: 'grid', gridTemplateColumns: [...Array(this.props.scaleDays).keys()].map(x => '1fr').join(' ') }}>
					{[...Array(this.props.scaleDays).keys()].map(day_idx => {
						const curr_date = moment(this.props.currentDate).add(day_idx, 'days');
						const curr_date_iso = curr_date.format('YYYY-MM-DD');
						
						return <div key={'activities-guests-table-day-' + day_idx}>
							<div className={'activities-guests-table activities-guests-table-header ' + (this.props.scaleDays > 1 ? 'narrow' : '')}>
								<div className='activities-guests-table-header-col col-1'>
									{day_idx > 0 ? null :
										<Button
											icon={this.state.compact ? 'minimize' : 'maximize'}
											minimal={true}
											onClick={() => {
												this.setCompact(!this.state.compact);
											}}
											intent={Intent.SUCCESS}
											style={{
												position: 'absolute',
												left:     '8px',
												top:      '5px',
												color:    '#ffffff',
											}} />
									}
								</div>
								{this.props.scaleDays > 1 ? null :
									<div className='activities-guests-table-header-col'>
										#
									</div>
								}
								<div className='activities-guests-table-header-col'>
									Naziv
								</div>
								{this.props.scaleDays > 1 ? null :
									<div className='activities-guests-table-header-col'>
										Telefon
									</div>
								}
								{this.props.scaleDays > 1 ? null :
									<div className='activities-guests-table-header-col'>
										E-pošta
									</div>
								}
								<div className='activities-guests-table-header-col'>
									Osebe
								</div>
								<div className='activities-guests-table-header-col'>
									Opomba
								</div>
								{this.props.scaleDays > 1 ? null :
									<div className='activities-guests-table-header-col'>
										Kanal
									</div>
								}
								{this.props.scaleDays > 1 ? null :
									<div className='activities-guests-table-header-col'>
										Država
									</div>
								}
								{this.props.scaleDays > 1 ? null :
									<div className='activities-guests-table-header-col'>
										Račun
									</div>
								}
							</div>
							
							{items[day_idx] === undefined ? null : items[day_idx].map((item, item_idx) => {
								const counts = item.activities.reduce(
									(acc, val) => {
										const reservations = (this.state.activity_reservations[val.key] || [])
											.filter(x => x.reservation_date == curr_date_iso);
										
										return [
											acc[0] + reservations.reduce((accx, x) => accx + x.guest_count + x.guest_child_count, 0),
											acc[1] + val.max_guest_count
										];
									},
									[ 0, 0 ]
								);
								
								return <React.Fragment key={'activities-guests-table-day-' + day_idx + '-item-' + item.id_time_slot}>
									<div className={'activities-guests-table ' + (this.props.scaleDays > 1 ? 'narrow' : '')}>
										<div
											className='activities-guests-table-col activities-guests-table-time-section col-1'
											style={{ gridColumn: '1 / 1' }}>
												<span className='text-base'>{ item.time_label }</span>
										</div>
										<div
											className='activities-guests-table-col activities-guests-table-time-section col-2'
											style={{ gridColumn: '2 / 6' }}>
												<span>št. oseb: </span>
												<span>{ counts[0] + ' / ' + counts[1] }</span>
										</div>
										<div
											className='activities-guests-table-col activities-guests-table-time-section col-2 text-right'
											style={{ gridColumn: '6 / ' + (this.props.scaleDays == 1 ? '11' : '6') }}>
												<span>{
													weekdays[curr_date.day()].toUpperCase() +
													', ' +
													curr_date.date() + '.' +
													(curr_date.month()+1) + '.'
												}</span>
												
												<Button className='ml-2' icon={<Icon icon='print' color='white' />} minimal={true} onClick={() => this.openPrintPOSDialog(
													item,
													curr_date_iso,
													this.props.accommodation_item_schedule_time_slots[item.id_time_slot]
												)} />
										</div>
									</div>
									
									{item.activities.map((activity_item, activity_item_idx) => {
										const reservations = (this.state.activity_reservations[activity_item.key] || [])
											.filter(x => x.reservation_date == curr_date_iso)
											.sort((a, b) => {
												if (a.created > b.created) return  1;
												if (a.created < b.created) return -1;
												return 0;
											});
										const guest_count = reservations.reduce(
											(acc, val) => acc + val.guest_count + val.guest_child_count,
											0
										);
										const near_or_over_capacity = (guest_count / activity_item.max_guest_count >= 0.8);
										
										return <React.Fragment key={'activities-guests-table-day-' + day_idx + '-item-' + item.id_time_slot + '-activity-' + activity_item.key}>
											<div className={
												'activities-guests-table ' +
												(this.props.scaleDays > 1 ? 'narrow' : '') +
												' ' +
												(this.state.compact ? 'compact' : '')
											}>
												<div
													className={
														'activities-guests-table-col ' +
														'activities-guests-table-activity-section ' +
														'col-1 ' +
														'flex flex-row items-center ' +
														(
															near_or_over_capacity ?
																'warning' : ''
														)
													}
													style={{
														gridColumn: '1 / ' + (this.props.scaleDays == 1 ? '11' : '6'),
													}}>
													<Button
														icon='plus'
														small={true}
														intent={Intent.PRIMARY}
														className='mr-2'
														onClick={() => {
															this.props.openEditActivityItemDialog(
																null,
																this.state.items[day_idx][item_idx].activities[activity_item_idx].key,
																curr_date_iso
															);
														}} />
													{
														activity_item.title + 
														', št. oseb: ' + 
														guest_count + ' / ' + activity_item.max_guest_count
													}
													{
														near_or_over_capacity ?
															<Icon
																icon='warning-sign'
																intent={Intent.WARNING}
																className='ml-2'
																htmlTitle='Blizu ali čez kapaciteto' />
															:
															null
													}
													{this.props.reservationInClipboard === null ? null :
														<>
															<div className='flex-1'></div>
															<Tooltip content={'Prestavi rezervacijo iz odložišča na ta termin'}>
																<Button
																	className='mt-1 ml-1'
																	small={true}
																	icon={<Icon color='#5c7080' icon='bring-data' />}
																	onClick={() => {
																		this.props.pasteReservation(
																			this.state.items[day_idx][item_idx].activities[activity_item_idx].key,
																			curr_date_iso
																		);
																	}} />
															</Tooltip>
														</>
													}
												</div>
											</div>
											
											<Sortable
												options={{
													group: 'shared',
												}}
												onChange={(order, sortable, evt) => {
													this.handleSortableChange(evt);
												}}
												data-path={[ activity_item.key, day_idx ].join('--')}
												className='activities-guests-table-guests'
												style={{ gridColumn: (day_idx + 1) }}>
												{reservations === undefined ? null : reservations.map((reservation, reservation_idx) => {
													const cls = 'activities-guests-table-col activities-guests-table-guest';
													
													let pattern_cls = '';
													if (reservation.status == 'offer-sent') {
														pattern_cls = 'pattern-polka-dots';
													}
													else if (
														reservation.status == 'advance-invoice-sent' ||
														reservation.status == 'not-for-rent' /*with red background*/
													) {
														pattern_cls = 'pattern-diagonal-stripes';
													}
													
													const customer = this.props.customers[reservation.id_customer] ?? null;
													const channel  = this.props.channels [reservation.id_channel];
													const country  = customer === null ? null : this.props.countries[customer.id_country];
													
													return <div
														key={'activities-guests--' + reservation.id_activity_reservation}
														data-id={item.id_time_slot + '--' + reservation.id_activity_reservation}
														className={
															'activities-guests-table ' +
															(this.props.scaleDays > 1 ? 'narrow' : '') +
															' ' + pattern_cls + ' ' +
															'status-' + reservation.status +
															' ' +
															(reservation.status_2 === null ? '' : reservation.status_2) +
															' ' +
															(this.state.compact ? 'compact' : '')
														}>
														<svg version='1.1'
															baseProfile='full'
															width='16' height='16'
															className='custom-status'
															xmlns='http://www.w3.org/2000/svg'>
															<path
																d='M 0,0 L 16,0 L 0,16 L 0,0 z'
																fill='#ff0000' />
														</svg>
														<div className={cls + ' col-1'}>
															<Button
																icon='edit'
																minimal={true}
																small={true}
																className='mr-3'
																onClick={() => {
																	this.props.openEditActivityItemDialog(
																		reservation,
																		this.state.items[day_idx][item_idx].activities[activity_item_idx].key,
																		curr_date_iso
																	);
																}} />
														</div>
														{this.props.scaleDays > 1 ? null :
															<div className={cls}>
																{ customer === null ? '' : customer.internal_code }
															</div>
														}
														<div className={cls}>
															{
																customer === null ? '' : 
																	(
																		customer.type == 'company' ?
																			customer.company_name
																			:
																			customer.surname + ' ' + customer.name
																	)
															}
														</div>
														{this.props.scaleDays > 1 ? null :
															<div className={cls}>
																{ customer === null ? '' : customer.phone }
															</div>
														}
														{this.props.scaleDays > 1 ? null :
															<div className={cls}>
																{ customer === null ? '' : customer.email }
															</div>
														}
														<div className={cls + ' guest-count text-center'} onClick={() => {
															this.openGuestCount(reservation);
														}}>
															{ reservation.guest_count } / { reservation.guest_child_count }
														</div>
														<div className={cls + ' note'} onClick={() => {
															this.openNotePopup(reservation);
														}}>
															{ reservation.note }
														</div>
														{this.props.scaleDays > 1 ? null :
															<div className={cls + ' text-center'}>
																{ channel === undefined ? '' :
																	channel.internal_code }
															</div>
														}
														{this.props.scaleDays > 1 ? null :
															<div className={cls}>
																{ country === undefined ? '' :
																	country.official_name_local }
															</div>
														}
														{this.props.scaleDays > 1 ? null :
															<div className={cls}>
																<div style={{ height: 32, display: 'flex' }} className='flex flex-row justify-between items-center'>
																	{
																	reservation.status == 'paid' ?
																		'' :
																			<>
																				<Button
																					className='mt-1'
																					small={true}
																					disabled={this.props.current_id_cash_register == 0}
																					onClick={() => {
																						this.openCreateInvoiceDialog(
																							'cash-invoice',
																							reservation
																						);
																					}}>
																					Račun
																				</Button>
																				<Button
																					className='mt-1 ml-1'
																					small={true}
																					onClick={() => {
																						this.openCreateInvoiceDialog(
																							'invoice',
																							reservation
																						);
																					}}>
																					Faktura
																				</Button>
																			</>
																	}
																	<UserTag user={this.getUserByUsername(reservation.username)} reservation={reservation} className='ml-2' />
																	<Tooltip content={'Prestavi rezervacijo na drug termin'}>
																		<Button
																			className='ml-1'
																			small={true}
																			icon={<Icon color='#5c7080' icon='inheritance' />}
																			disabled={
																				this.props.reservationInClipboard !== null &&
																				this.props.reservationInClipboard.id_activity_reservation ==
																					reservation.id_activity_reservation
																			}
																			onClick={() => {
																				this.props.cutReservation(reservation);
																			}} />
																</Tooltip>
																</div>
															</div>
														}
													</div>;
												})}
											</Sortable>
										</React.Fragment>;
									})}
								</React.Fragment>;
							})}
						</div>;
					})}
				</div>
			</div>
		</>;
	}
}
TimelineActivities.propTypes = {
	openReservation: PropTypes.func,
	openExistingReservation: PropTypes.func,
	currentDate: PropTypes.object,
	scaleDays: PropTypes.number,
	selectedActivities: PropTypes.array,
};

function mapStateToProps(state) {
	return {
		accommodation_item_schedules:                     state.CodeTablesSlice.accommodation_item_schedules,
		accommodation_item_schedule_time_slots:           state.CodeTablesSlice.accommodation_item_schedule_time_slots,
		accommodation_item_schedule_time_slot_activities: state.CodeTablesSlice.accommodation_item_schedule_time_slot_activities,
		activities:                                       state.CodeTablesSlice.activities,
		customers:                                        state.CodeTablesSlice.customers,
		countries:                                        state.CodeTablesSlice.countries,
		channels:                                         state.CodeTablesSlice.channels,
		items:                                            state.CodeTablesSlice.items,
		measuring_units:                                  state.CodeTablesSlice.measuring_units,
		tax_rates:                                        state.CodeTablesSlice.tax_rates,
		warehouses:                                       state.CodeTablesSlice.warehouses,
		activity_reservations:                            state.ReservationSlice.activity_reservations,
		token:                                            state.UserSlice.token,
		users:                                            state.UserSlice.users,
		api_url:                                          state.ConstantsSlice.api_url,
		document_associations:                            state.DocumentSlice.document_associations_id_documents,
		document_types:                                   state.DocumentSlice.document_types,
		invoices:                                         state.BusinessSlice.invoices,
		general_settings:                                 state.SettingsSlice.general,
		current_id_cash_register:                         state.SettingsSlice.current_id_cash_register,
		cash_registers:                                   state.CashRegisterSlice.cash_registers,
	};
}
export default connect(mapStateToProps)(TimelineActivities);
