import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
	Button,
	InputGroup,
	FormGroup,
	TextArea,
	Checkbox,
	Navbar,
	Dialog,
	Alignment,
	HTMLSelect,
	RadioGroup,
	Radio,
	Classes,
	Intent,
	NumericInput,
	ControlGroup,
	Alert,
	Icon,
} from '@blueprintjs/core';
import { DateInput, IDateFormatProps } from '@blueprintjs/datetime';

import moment from 'moment';

import TextWithTooltip       from '../TextWithTooltip';
import CustomerSelect        from '../CustomerSelect';
import { addCustomer }       from '../../slices/CodeTablesSlice';
import { saveCodeTableItem } from '../../api/CodeTables';
import SimpleSuggest         from '../SimpleSuggest';
import { AppToaster }        from '../AppToaster';

class SelectCustomerDialog extends Component {
	constructor(props) {
		super(props);
		
		this.closeDialog          = this.closeDialog.bind(this);
		this.findSimilarCustomers = this.findSimilarCustomers.bind(this);
		
		this.state = {
			id_customer:                           props.item === null ? -1   : props.item.id_customer,
			customer_type:                         props.item === null ? 'natural' : props.item.type,
			vat_registered:                        props.item === null ? false : props.item.vat_registered,
			vat_prefix:                            props.item === null ? '' : props.item.vat_prefix,
			tax_number:                            props.item === null ? '' : props.item.tax_number,
			name:                                  props.item === null ? (this.props.name ?? '') : props.item.name,
			surname:                               props.item === null ? this.props.surname : props.item.surname,
			company_name:                          props.item === null ? '' : props.item.company_name,
			company_long_name:                     props.item === null ? '' : props.item.company_long_name,
			id_country:                            props.item === null || props.item.id_country === null ? (this.props.id_country ?? null) : props.item.id_country,
			phone:                                 props.item === null ? (this.props.phone ?? '') : props.item.phone,
			email:                                 props.item === null ? (this.props.email ?? '') : props.item.email,
			address:                               props.item === null ? (this.props.address ?? '') : props.item.address,
			post_code:                             props.item === null ? (this.props.post_code ?? '') : props.item.post_code,
			post_office:                           props.item === null ? (this.props.post_office ?? '') : props.item.post_office,
			supplier:                              props.item === null ? false : props.item.supplier,
			due_day_count:                         props.item === null ? 8 : props.item.due_day_count,
			display_company_long_name_on_invoices: props.item === null ? false : props.item.display_company_long_name_on_invoices,
			note:                                  props.item === null ? '' : (props.item.note || ''),
			birth_date:                            props.item === null ? null : (props.item.birth_date === null ? null : new Date(props.item.birth_date)),
			validation_failed_fields:              [],
			close_dialog_save_id_item:             false,
			close_dialog_save_item:                false,
			close_dialog_confirm_open:             false,
			similar_customers:                     [],
		};
		this.state.similar_customers = this.findSimilarCustomers(true);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.customer_type != this.state.customer_type) {
			if (this.state.customer_type == 'company') {
				this.setState({
					company_name: this.state.company_name.length > 0 ? this.state.company_name : (this.state.surname + ' ' + this.state.name).trim(),
					name:         '',
					surname:      '',
				});
			}
			else {
				this.setState({
					company_name: '',
					name:         this.state.name.length    > 0 ? this.state.name    : '',
					surname:      this.state.surname.length > 0 ? this.state.surname : this.state.company_name,
				});
			}
		}
		
		if (prevState.name != this.state.name || prevState.surname != this.state.surname || prevState.id_country != this.state.id_country) {
			this.findSimilarCustomers(false);
		}
	}
	
	findSimilarCustomers(only_return) {
		if (this.state.name.length >= 2 && this.state.surname.length >= 2) {
			const name    = this.state.name   .toLowerCase();
			const surname = this.state.surname.toLowerCase();
			
			const found_customers = [];
			for (let id_customer in this.props.customers) {
				// skip customer we're currently editing
				if (this.props.item !== null && this.props.item.id_customer === id_customer) continue;
				
				const customer = this.props.customers[id_customer];
				if (customer.name.toLowerCase().indexOf(name) != -1 && customer.surname.toLowerCase().indexOf(surname) != -1) {
					if (
						this.state.id_country === null ||
						this.state.id_country == '' ||
						customer.id_country === null ||
						customer.id_country == this.state.id_country
					) {
						found_customers.push(customer);
					}
				}
			}
			
			if (!only_return) this.setState({ similar_customers: found_customers });
			return found_customers;
		}
		else {
			if (!only_return) this.setState({ similar_customers: [] });
			return [];
		}
	}
	
	closeDialog(confirm, id_item, new_item) {
		if (confirm) {
			this.setState({
				close_dialog_save_id_item: id_item,
				close_dialog_save_item:    new_item,
				close_dialog_confirm_open: true,
			});
			return;
		}
		
		this.props.closeSelectCustomerDialog(id_item, new_item);
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
			parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
		}
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.closeDialog(true)}
			className='select-customer-dialog'>
			
			{!this.state.close_dialog_confirm_open ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Zapri'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='help'
					intent={Intent.PRIMARY}
					isOpen={true}
					onConfirm={() => {
						this.closeDialog(false, this.state.close_dialog_save_id_item, this.state.close_dialog_save_item);
						this.setState({
							close_dialog_save_id_item: false,
							close_dialog_save_item:    false,
							close_dialog_confirm_open: false,
						});
					}}
					onCancel={() => {
						this.setState({
							close_dialog_save_id_item: false,
							close_dialog_save_item:    false,
							close_dialog_confirm_open: false,
						});
					}}>
					Res želite zapreti brez shranjevanja?
				</Alert>
			}
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Vpis stranke</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.closeDialog(true)} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-row'>
						<div className='flex flex-1 pr-2 flex-col'>
							<div>
								<CustomerSelect
									itemSelected={item => {
										this.setState({
											id_customer:                           item.id_customer,
											customer_type:                         item.type,
											vat_registered:                        item.vat_registered,
											vat_prefix:                            item.vat_prefix,
											tax_number:                            item.tax_number,
											name:                                  item.name,
											surname:                               item.surname,
											company_name:                          item.company_name,
											company_long_name:                     item.company_long_name,
											id_country:                            item.id_country,
											phone:                                 item.phone,
											email:                                 item.email,
											address:                               item.address,
											post_code:                             item.post_code,
											post_office:                           item.post_office,
											supplier:                              item.supplier,
											due_day_count:                         item.due_day_count,
											display_company_long_name_on_invoices: item.display_company_long_name_on_invoices,
											note:                                  (item.note || ''),
											birth_date:                            item.birth_date === null ? null : new Date(item.birth_date),
										});
									}}
									fieldTitle='Oseba' />
							</div>
							<div className='flex flex-row justify-between'>
								<div>Tip stranke</div>
								
								<RadioGroup
									onChange={event => {
										this.setState({
											customer_type: event.target.value,
										});
									}}
									selectedValue={this.state.customer_type}
									inline={true}>
									<Radio label='Fizična' value='natural' />
									<Radio label='Pravna' value='company' />
								</RadioGroup>
							</div>
							
							{this.state.customer_type == 'natural' ?
								<div className='flex flex-row justify-between'>
									<FormGroup
										labelFor='select-customer--surname'
										className={
											'flex-1 mr-1 ' +
											(this.state.validation_failed_fields.indexOf('surname') == -1 ?
												'' : 'validation-failed')
										}>
										<TextWithTooltip
											label='Priimek'
											tooltip='Priimek' />
										<InputGroup
											id='select-customer--surname'
											value={this.state.surname}
											onChange={event => {
												this.setState({
													surname: event.target.value,
												});
											}} />
									</FormGroup>
									<FormGroup
										labelFor='select-customer--name'
										className={
											'flex-1 ' +
											(this.state.validation_failed_fields.indexOf('name') == -1 ?
												'' : 'validation-failed')
										}>
										<TextWithTooltip
											label='Ime'
											tooltip='Ime' />
										<InputGroup id='select-customer--name'
											value={this.state.name}
											onChange={event => {
												this.setState({
													name: event.target.value,
												});
											}} />
									</FormGroup>
								</div>
								:
								<>
									<div className='flex flex-row justify-between'>
										<FormGroup
											labelFor='select-customer--company-name'
											className={
												'flex-1 ' +
												(this.state.validation_failed_fields.indexOf('company_name') == -1 ?
													'' : 'validation-failed')
											}>
											<TextWithTooltip
												label='Naziv'
												tooltip='Naziv' />
											<InputGroup id='select-customer--company-name'
												value={this.state.company_name}
												onChange={event => {
													this.setState({
														company_name: event.target.value,
													});
												}} />
										</FormGroup>
									</div>
									<div className='flex flex-row justify-between'>
										<FormGroup
											labelFor='select-customer--company-long-name'
											className='flex-1'>
											<TextWithTooltip
												label='Daljši naziv'
												tooltip='Daljši naziv' />
											<InputGroup id='select-customer--company-long-name'
												value={this.state.company_long_name}
												onChange={event => {
													this.setState({
														company_long_name: event.target.value,
													});
												}} />
										</FormGroup>
									</div>
								</>
							}
							
							<Checkbox checked={this.state.vat_registered} onChange={event => {
								this.setState({ vat_registered: event.target.checked });
							}}>
								Zavezanec za DDV
							</Checkbox>
							
							<div className='flex flex-row justify-between'>
								<FormGroup
									labelFor='select-customer--tax-number'
									className='flex-1'>
									<TextWithTooltip
										label={this.state.vat_registered ? 'ID za DDV' : 'Davčna številka'}
										tooltip={this.state.vat_registered ? 'ID za DDV' : 'Davčna številka'} />
									<div className='flex flex-row'>
										{!this.state.vat_registered ? null :
											<HTMLSelect
												id='select-customer--tax-number-prefix'
												options={[
													{ label: 'SI', value: 'SI' },
													{ label: 'AT', value: 'AT' },
													{ label: 'BE', value: 'BE' },
													{ label: 'BG', value: 'BG' },
													{ label: 'CY', value: 'CY' },
													{ label: 'CZ', value: 'CZ' },
													{ label: 'DE', value: 'DE' },
													{ label: 'DK', value: 'DK' },
													{ label: 'EE', value: 'EE' },
													{ label: 'EL', value: 'EL' },
													{ label: 'ES', value: 'ES' },
													{ label: 'FI', value: 'FI' },
													{ label: 'FR', value: 'FR' },
													{ label: 'GB', value: 'GB' },
													{ label: 'HR', value: 'HR' },
													{ label: 'HU', value: 'HU' },
													{ label: 'IE', value: 'IE' },
													{ label: 'IT', value: 'IT' },
													{ label: 'LT', value: 'LT' },
													{ label: 'LU', value: 'LU' },
													{ label: 'LV', value: 'LV' },
													{ label: 'MT', value: 'MT' },
													{ label: 'NL', value: 'NL' },
													{ label: 'PL', value: 'PL' },
													{ label: 'PT', value: 'PT' },
													{ label: 'RO', value: 'RO' },
													{ label: 'SE', value: 'SE' },
													{ label: 'SK', value: 'SK' },
												]}
												value={this.state.vat_prefix || ''}
												onChange={(e) => {
													this.setState({
														vat_prefix: e.currentTarget.value,
													});
												}} />
										}
										<InputGroup
											id='select-customer--tax-number'
											className='flex-1'
											value={this.state.tax_number}
											onChange={event => {
												this.setState({
													tax_number: event.target.value,
												});
											}} />
									</div>
								</FormGroup>
								
								{[ 'test', 'lodgge', 'ank' ].indexOf(this.props.client) === -1 ? null :
									<FormGroup
										labelFor='select-customer--birth-date'
										className='ml-1'>
										<TextWithTooltip
											label='Datum rojstva'
											tooltip='Datum rojstva' />
										<DateInput
											locale='sl'
											{...this.getMomentFormatter('DD. MM. YYYY')}
											inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear(), className: 'w-24' }}
											selectAllOnFocus={true}
											minDate={new Date('1900-01-01')}
											maxDate={moment().toDate()}
											onChange={(selectedDate, isUserChange) => {
												if (isUserChange) this.setState({ birth_date: selectedDate });
											}}
											value={this.state.birth_date} />
									</FormGroup>
								}
							</div>
							
							<FormGroup
								labelFor='select-customer--phone'>
								<TextWithTooltip
									label='Telefon'
									tooltip='Telefon' />
								<InputGroup id='select-customer--phone'
									value={this.state.phone}
									onChange={event => {
										this.setState({
											phone: event.target.value,
										});
									}} />
							</FormGroup>
							
							<FormGroup
								labelFor='select-customer--email'>
								<TextWithTooltip
									label='E-pošta'
									tooltip='E-pošta' />
								<InputGroup id='select-customer--email'
									value={this.state.email}
									onChange={event => {
										this.setState({
											email: event.target.value,
										});
									}} />
							</FormGroup>
							
							<FormGroup
								labelFor='select-customer--address'>
								<TextWithTooltip
									label='Naslov'
									tooltip='Naslov' />
								<InputGroup id='select-customer--address'
									value={this.state.address}
									onChange={event => {
										this.setState({
											address: event.target.value,
										});
									}} />
							</FormGroup>
							
							<FormGroup
								labelFor='select-customer--post-code'>
								<TextWithTooltip
									label='Poštna številka'
									tooltip='Poštna številka' />
								<InputGroup id='select-customer--post-code'
									value={this.state.post_code}
									onChange={event => {
										this.setState({
											post_code: event.target.value,
										});
									}} />
							</FormGroup>
							
							<FormGroup
								labelFor='select-customer--post-office'>
								<TextWithTooltip
									label='Pošta'
									tooltip='Pošta' />
								<InputGroup id='select-customer--post-office'
									value={this.state.post_office}
									onChange={event => {
										this.setState({
											post_office: event.target.value,
										});
									}} />
							</FormGroup>
							
							<FormGroup
								labelFor='select-customer--country'
								className={
									this.state.validation_failed_fields.indexOf('id_country') == -1 ?
										'' : 'validation-failed'
								}>
								<TextWithTooltip
									label='Država'
									tooltip='Država' />
								<SimpleSuggest
									matchKeyName='official_name_local'
									valueKeyName='id_country'
									items={Object.values(this.props.countries)}
									onItemSelect={item => {
										this.setState({
											id_country: item.id_country,
										});
									}}
									selectedItem={this.props.countries[this.state.id_country]}
									placeholder='Išči' />
							</FormGroup>
						</div>
						<div className='flex flex-1 pl-2 flex-col'>
							<Checkbox
								id='select-customer--display-company-long-name-on-invoices'
								checked={this.state.display_company_long_name_on_invoices}
								onChange={event => {
									this.setState({ display_company_long_name_on_invoices: event.target.checked });
								}}>
								Na računu izpiši daljši naziv
							</Checkbox>
							
							<Checkbox
								id='select-customer--supplier'
								checked={this.state.supplier}
								onChange={event => {
									this.setState({ supplier: event.target.checked });
								}}>
								Dobavitelj
							</Checkbox>
							
							<Checkbox checked={true}>
								Stranka aktivna
							</Checkbox>
							
							<FormGroup
								labelFor='select-customer--due-day-count'
								className='mt-4 mb-4'>
								<div className='flex flex-row items-center justify-between'>
								<TextWithTooltip
									label='Število dni za valuto'
									tooltip='Število dni za valuto' />
								<ControlGroup>
									<Button icon='minus' tabIndex='-1' minimal={false} small={true} onClick={() => {
										this.setState({ due_day_count: this.state.due_day_count - 1 });
									}} />
									<div className='w-8'>
										<NumericInput
											id='select-customer--due-day-count'
											className='centered-numeric-input'
											fill={true}
											buttonPosition='none'
											value={this.state.due_day_count}
											min={0}
											onValueChange={val => {
												this.setState({ due_day_count: val });
											}} />
									</div>
									<Button icon='plus' tabIndex='-1' minimal={false} small={true} onClick={() => {
										this.setState({ due_day_count: this.state.due_day_count + 1 });
									}} />
								</ControlGroup>
								</div>
							</FormGroup>
							
							{true ? null :
								<FormGroup
									labelFor='select-customer--role'>
									<TextWithTooltip
										label='Vloge'
										tooltip='Vloge' />
									<Checkbox>
										Cenjen gost
									</Checkbox>
									<Checkbox>
										Drag dobavitelj
									</Checkbox>
								</FormGroup>
							}
							
							<FormGroup
								labelFor='select-customer--note'
								className='flex-1 flex-col select-customer-dialog--note'>
								<TextWithTooltip
									label='Opomba'
									tooltip='Opomba' />
								<TextArea
									id='select-customer--note'
									className=''
									growVertically={true}
									fill={true}
									onChange={(e) => {
										this.setState({
											note: e.target.value,
										});
									}}
									value={this.state.note} />
							</FormGroup>
						</div>
					</div>
					{this.state.similar_customers.length == 0 ? null :
						<div className='text-orange-600 flex flex-row'>
							<Icon icon='warning-sign' className='mr-2' size={32} />
							<div>
								<div>Najdene obstoječe stranke s podobnimi podatki:</div>
								<div>{ this.state.similar_customers.map(customer => {
									let value = customer.name + ' ' + customer.surname;
									
									let additional_data = [];
									if (customer.id_country !== null) {
										additional_data.push(this.props.countries[customer.id_country].official_name_local);
									}
									if (customer.birth_date !== null) {
										additional_data.push('RD ' + moment(customer.birth_date).format('DD.MM.YYYY'));
									}
									
									if (additional_data.length > 0) {
										value += ' (' + additional_data.join(', ') + ')';
									}
									
									return value;
								}).join(', ') }</div>
							</div>
						</div>
					}
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.closeDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={async () => {
								const new_customer = this.state.id_customer != -1 ?
									{...this.props.customers[this.state.id_customer]} :
									{
										id_customer: uuid.v4(),
									};
								
								let vat_prefix = '';
								if (this.state.vat_registered) {
									vat_prefix = this.state.vat_prefix.length == 0 ? 'SI' : this.state.vat_prefix;
								}
								
								new_customer.type                                  = this.state.customer_type;
								new_customer.vat_registered                        = this.state.vat_registered;
								new_customer.vat_prefix                            = vat_prefix;
								new_customer.tax_number                            = this.state.tax_number;
								new_customer.name                                  = this.state.name.trim();
								new_customer.surname                               = this.state.surname.trim();
								new_customer.company_name                          = (this.state.company_name ?? '').trim();
								new_customer.company_long_name                     = (this.state.company_long_name ?? '').trim();
								new_customer.id_country                            = this.state.id_country;
								new_customer.phone                                 = this.state.phone;
								new_customer.email                                 = this.state.email;
								new_customer.address                               = this.state.address;
								new_customer.post_code                             = this.state.post_code;
								new_customer.post_office                           = this.state.post_office;
								new_customer.supplier                              = this.state.supplier;
								new_customer.due_day_count                         = this.state.due_day_count;
								new_customer.display_company_long_name_on_invoices = this.state.display_company_long_name_on_invoices;
								new_customer.note                                  = this.state.note;
								new_customer.birth_date                            = this.state.birth_date === null ? null : moment(this.state.birth_date).format('YYYY-MM-DD');
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (
									new_customer.type == 'natural' &&
									new_customer.name.length == 0 &&
									new_customer.surname.length == 0
								) {
									validation_failed_fields.push('name');
									validation_failed_fields.push('surname');
									validation_failed_field_titles.push('ime in priimek');
								}
								if (
									new_customer.type == 'company' &&
									new_customer.company_name.length == 0
								) {
									validation_failed_fields.push('company_name');
									validation_failed_field_titles.push('naziv');
								}
								if (new_customer.id_country === null) {
									validation_failed_fields.push('id_country');
									validation_failed_field_titles.push('država');
								}
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
										</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return false;
								}
								
								// add to store
								this.props.dispatch(addCustomer({ item: new_customer, token: this.props.token }));
								
								const dispatch = this.props.dispatch;
								const token    = this.props.token;
								
								const new_item = await saveCodeTableItem(this.props.api_url, 'customers', new_customer, token);
								if (new_item !== null) {
									dispatch(addCustomer({
										item: new_item,
										token,
									}));
								}
								
								this.closeDialog(false, new_customer.id_customer, new_customer);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
SelectCustomerDialog.propTypes = {
	closeSelectCustomerDialog: PropTypes.func,
	surname: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		countries: state.CodeTablesSlice.countries,
		customers: state.CodeTablesSlice.customers,
		api_url:   state.CodeTablesSlice.api_url,
		token:     state.UserSlice.token,
		client:    state.ConstantsSlice.client,
	};
}

export default connect(mapStateToProps)(SelectCustomerDialog);
