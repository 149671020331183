import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import { navigate } from 'raviger';

import {
	deleteReservation,
} from '../../slices/ReservationSlice';

import {
	Button,
	ButtonGroup,
	Intent,
	MenuItem,
	Checkbox,
	Dialog,
	Navbar,
	Alignment,
	Classes,
} from '@blueprintjs/core';
import { IDateFormatProps, DateRangeInput } from '@blueprintjs/datetime';
import { Suggest } from '@blueprintjs/select';

import moment from 'moment';

import { useQueryParams } from 'raviger';

import Reservation      from './Reservation';
import Timeline         from './Timeline';
import DatePickerDialog from '../DatePickerDialog';
import { enqueueAvailableAccommodationsPosPrinterQueue } from '../../slices/AppSlice';

function TimelineWithFilter(props) {
	const {
		idAccommodation,
		idAccommodationItem,
	} = props;
	
	const id_accommodation      = idAccommodation;
	const id_accommodation_item = idAccommodationItem;
	
	const [reservation_dialog_open, setReservationDialogOpen] = useState(false);
	const [current_reservation,     setCurrentReservation]    = useState(null);
	
	let [{ currentDate, scaleDays }, setQuery] = useQueryParams();
	
	currentDate = currentDate             || moment().format('YYYY-MM-DD');
	scaleDays   = parseInt(scaleDays, 10) || 30;
	
	const openReservationDialog = (reservation) => {
		setReservationDialogOpen(true);
		setCurrentReservation(reservation);
	};
	const closeReservationDialog = () => {
		setReservationDialogOpen(false);
	};
	
	const delReservation = id_reservation => {
		props.dispatch(deleteReservation(id_reservation));
		//const new_reservations = reservations;
		//let idx = -1;
		//for (let i=0; i<reservations.length; i++) {
		//	if (reservations[i][9] == id_reservation) {
		//		idx = i;
		//		break;
		//	}
		//}
		//
		//if (idx != -1) {
		//	new_reservations.splice(idx, 1);
		//}
		//
		//setReservations(new_reservations);
	};
	
	const accommodation_item_places = {};
	for (let id_accommodation_item_place in props.accommodation_item_places) {
		const disallowed =
			[ 'socaland' ].indexOf(props.client) > -1 &&
			[ 'admin', 'tanja', 'gregor' ].indexOf(props.user.username) == -1 &&
			[ 'd0be0242-7515-4eec-a40e-f687eafb9dac', 'd2866441-8726-45ad-a7a7-e26b56b2c809' ].indexOf(props.accommodation_items[props.accommodation_item_places[id_accommodation_item_place].id_accommodation_item].id_accommodation) > -1;
		
		if (disallowed) continue;
		
		if (
			id_accommodation == 0 || /* 0 is All accommodations */
			(
				id_accommodation_item == -1 && /* -1 is main accommodation item */
				props.accommodation_items[props.accommodation_item_places[id_accommodation_item_place].id_accommodation_item].id_accommodation == id_accommodation
			) ||
			props.accommodation_item_places[id_accommodation_item_place].id_accommodation_item == id_accommodation_item
		) {
			accommodation_item_places[id_accommodation_item_place] = props.accommodation_item_places[id_accommodation_item_place];
		}
	}
	
	const reservations = {};
	for (let id_reservation in props.reservations) {
		if (
			id_accommodation == 0 || /* 0 is All accommodations */
			(
				id_accommodation_item == -1 && /* -1 is main accommodation item */
				props.accommodation_items[props.accommodation_item_places[props.reservations[id_reservation].id_accommodation_item_place].id_accommodation_item].id_accommodation == id_accommodation
			) ||
			props.accommodation_item_places[props.reservations[id_reservation].id_accommodation_item_place].id_accommodation_item == id_accommodation_item
		) {
			reservations[id_reservation] = props.reservations[id_reservation];
		}
	}
	
	const reservation_requests = props.reservation_requests;
	
	return <>
		{!reservation_dialog_open ? null :
			<Reservation
				closeReservation={closeReservationDialog}
				deleteReservation={delReservation}
				reservation={current_reservation} />
		}
		
		<TimelineWithFilterComponent
			{...props}
			openReservationDialog={reservation => openReservationDialog(reservation)}
			setCurrentDate=       {newDate => setQuery({
				currentDate: moment(newDate).format('YYYY-MM-DD'),
				scaleDays,
			})}
			currentDate=          {new Date(currentDate)}
			setScaleDays=         {newScaleDays => setQuery({
				currentDate,
				scaleDays: newScaleDays,
			})}
			scaleDays=            {scaleDays}
			rooms=                {accommodation_item_places}
			items=                {reservations}
			items_requests=       {reservation_requests}
			deleteReservation=    {delReservation}
			user={props.user}
		/>
	</>;
}
function mapStateToProps(state) {
	return {
		accommodation_item_places: state.CodeTablesSlice.accommodation_item_places,
		accommodation_items:       state.CodeTablesSlice.accommodation_items,
		accommodations:            state.CodeTablesSlice.accommodations,
		reservations:              state.ReservationSlice.reservations,
		reservation_customers_by_id_reservations: state.ReservationSlice.reservation_customers_by_id_reservations,
		reservation_requests:      state.ReservationSlice.reservation_requests,
		customers:                 state.CodeTablesSlice.customers,
		client:                    state.ConstantsSlice.client,
		user:                      state.UserSlice.user,
		token:                     state.UserSlice.token,
		api_url:                   state.ReservationSlice.api_url,
	};
}
export default connect(mapStateToProps)(TimelineWithFilter);

class TimelineWithFilterComponent extends Component {
	constructor(props) {
		super(props);
		
		this.openFilterDatePickerDialog        = this.openFilterDatePickerDialog       .bind(this);
		this.closeFilterDatePickerDialog       = this.closeFilterDatePickerDialog      .bind(this);
		this.offsetCurrentDate                 = this.offsetCurrentDate                .bind(this);
		this.filterRooms                       = this.filterRooms                      .bind(this);
		this.printAvailableAccommodations      = this.printAvailableAccommodations     .bind(this);
		this.hideAvailableAccommodationsDialog = this.hideAvailableAccommodationsDialog.bind(this);
		
		this.state = {
			filter_date_picker_dialog_open:       false,
			filter_date_picker_dialog_state_name: null,
			filter_from:                          null,
			filter_to:                            null,
			filter_from_temp:                     null,
			filter_to_temp:                       null,
			deleting_reservations:                false,
			duplicating_reservations:             false,
			rooms:                                null,
			items:                                null,
			reservation_search_query:             '',
			show_hidden_rooms:                    false,
			select_available_rooms_dialog_open:   false,
			select_available_rooms_dialog_items:  {},
		};
		
		const filtered = this.filterRooms(null, null, this.state.show_hidden_rooms, true);
		this.state.rooms = filtered.rooms;
		this.state.items = filtered.items;
	}
	
	openFilterDatePickerDialog(state_name) {
		this.setState({ filter_date_picker_dialog_open: true, filter_date_picker_dialog_state_name: state_name });
	}
	closeFilterDatePickerDialog(newValue) {
		this.setState({ filter_date_picker_dialog_open: false });
		
		if (newValue instanceof Date) {
			this.props.setCurrentDate(newValue);
		}
	}
	
	offsetCurrentDate(offset_days) {
		this.props.setCurrentDate(moment(this.props.currentDate).add(offset_days, 'days').toDate());
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return {
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
			parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
		};
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterRooms(filter_from, filter_to, show_hidden_rooms, return_only) {
		return_only = return_only === undefined ? false : return_only;
		
		const found_id_accommodation_item_places = [];
		
		if (filter_from !== null && filter_to !== null) {
			for (let id_reservation in this.props.items) {
				const reservation = this.props.items[id_reservation];
				if (reservation.status == 'reversed' || reservation.status == 'no-show' || reservation.deleted === true) continue;
				
				if (reservation.check_in >= this.toIsoString(filter_to) ||
					reservation.check_out <= this.toIsoString(filter_from)) {
					continue;
				}
				
				if (found_id_accommodation_item_places.indexOf(reservation.id_accommodation_item_place) == -1) {
					found_id_accommodation_item_places.push(reservation.id_accommodation_item_place);
				}
			}
			
			for (let id_reservation_request in this.props.items_requests) {
				const reservation = this.props.items_requests[id_reservation_request];
				
				if (reservation.status != 'new' && reservation.status != 'confirmed') continue;
				if (reservation.id_accommodation_item_place === null) continue;
				
				if (reservation.check_in >= this.toIsoString(filter_to) ||
					reservation.check_out <= this.toIsoString(filter_from)) {
					continue;
				}
				
				if (found_id_accommodation_item_places.indexOf(reservation.id_accommodation_item_place) == -1) {
					found_id_accommodation_item_places.push(reservation.id_accommodation_item_place);
				}
			}
		}
		
		const rooms = [];
		for (let id_accommodation_item_place in this.props.rooms) {
			if (found_id_accommodation_item_places.indexOf(id_accommodation_item_place) != -1) continue;
			if (
				show_hidden_rooms === false &&
				(this.props.rooms[id_accommodation_item_place] === undefined || this.props.rooms[id_accommodation_item_place].hidden)
			) continue;
			
			rooms.push(this.props.rooms[id_accommodation_item_place]);
		}
		
		rooms.sort((a, b) => {
			const accommodation_item_a = this.props.accommodation_items[a.id_accommodation_item];
			const accommodation_item_b = this.props.accommodation_items[b.id_accommodation_item];
			
			const accommodation_a = this.props.accommodations[accommodation_item_a.id_accommodation];
			const accommodation_b = this.props.accommodations[accommodation_item_b.id_accommodation];
			
			if (accommodation_a.ord < accommodation_b.ord) return  -1;
			if (accommodation_a.ord > accommodation_b.ord) return 1;
			
			if (accommodation_item_a.ord < accommodation_item_b.ord) return  -1;
			if (accommodation_item_a.ord > accommodation_item_b.ord) return 1;
			
			if (a.ord < b.ord) return  -1;
			if (a.ord > b.ord) return 1;
			
			return 0;
		});
		
		const items = {};
		for (let id_reservation in this.props.items) {
			if (found_id_accommodation_item_places.indexOf(this.props.items[id_reservation].id_accommodation_item_place) != -1) continue;
			if (
				show_hidden_rooms === false &&
				(this.props.rooms[this.props.items[id_reservation].id_accommodation_item_place] === undefined || this.props.rooms[this.props.items[id_reservation].id_accommodation_item_place].hidden)
			) continue;
			
			items[id_reservation] = this.props.items[id_reservation];
		}
		
		if (!return_only) {
			this.setState({
				rooms,
				items,
			});
		}
		
		return {
			rooms,
			items,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevState.filter_from       != this.state.filter_from ||
			prevState.filter_to         != this.state.filter_to   ||
			prevProps.rooms             != this.props.rooms       ||
			prevProps.items             != this.props.items       ||
			prevState.show_hidden_rooms != this.state.show_hidden_rooms
		) {
			this.filterRooms(this.state.filter_from, this.state.filter_to, this.state.show_hidden_rooms);
		}
	}
	
	getGroupedForAvailableAccommodations(rooms) {
		const grouped = {};
		for (let i=0; i<rooms.length; i++) {
			const id_accommodation_item_place = rooms[i];
			const accommodation_item_place    = this.props.accommodation_item_places[id_accommodation_item_place];
			const accommodation_item          = this.props.accommodation_items      [accommodation_item_place.id_accommodation_item];
			const accommodation               = this.props.accommodations           [accommodation_item.id_accommodation];
			
			if (grouped[accommodation.id_accommodation] === undefined) {
				grouped[accommodation.id_accommodation] = {
					accommodation,
					accommodation_items: [],
				};
			}
			if (grouped[accommodation.id_accommodation].accommodation_items[accommodation_item.id_accommodation_item] === undefined) {
				grouped[accommodation.id_accommodation].accommodation_items[accommodation_item.id_accommodation_item] = {
					accommodation_item: this.props.accommodation_items[accommodation_item.id_accommodation_item],
					accommodation_item_places: [],
				};
			}
			grouped[accommodation.id_accommodation].accommodation_items[accommodation_item.id_accommodation_item].accommodation_item_places.push(accommodation_item_place);
		}
		return grouped;
	}
	printAvailableAccommodations(language, rooms) {
		if (language === undefined || rooms === undefined) {
			const select_available_rooms_dialog_items = {};
			for (let i=0; i<this.state.rooms.length; i++) {
				select_available_rooms_dialog_items[this.state.rooms[i].id_accommodation_item_place] = false;
			}
			
			this.setState({
				select_available_rooms_dialog_open: true,
				select_available_rooms_dialog_items,
			});
			return;
		}
		
		const grouped = this.getGroupedForAvailableAccommodations(rooms);
		
		this.props.dispatch(enqueueAvailableAccommodationsPosPrinterQueue({
			type: 'available_invoices',
			grouped,
			from: moment(this.state.filter_from).format('YYYY-MM-DD'),
			to:   moment(this.state.filter_to  ).format('YYYY-MM-DD'),
			language,
		}));
	}
	hideAvailableAccommodationsDialog() {
		this.setState({
			select_available_rooms_dialog_open: false,
			select_available_rooms_dialog_items: {},
		});
	}
	
	render() {
		const year_ago_iso = moment().subtract(12, 'month').format('YYYY-MM-DD');
		const reservations_for_search = Object.values(this.props.reservations)
			.filter(x => x.check_in >= year_ago_iso);
		
		const grouped_available_accommodations = this.state.select_available_rooms_dialog_open
			? this.getGroupedForAvailableAccommodations(
				Object.keys(this.state.select_available_rooms_dialog_items)
			)
			: {};
		
		return <>
			{!this.state.filter_date_picker_dialog_open ? null :
				<DatePickerDialog
					closeDatePickerDialog={this.closeFilterDatePickerDialog}
					value={this.props.currentDate} />
			}
			
			<Dialog
				isOpen={this.state.select_available_rooms_dialog_open}
				onClose={this.hideAvailableAccommodationsDialog}
				className='dialog--available-accommodations'>
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp3-dark'>
						<Navbar.Group>
							<Navbar.Heading>Izbor prostih mest</Navbar.Heading>
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button minimal={true} icon='cross' onClick={this.hideAvailableAccommodationsDialog} />
						</Navbar.Group>
					</Navbar>
					<div className={Classes.DIALOG_BODY}>
						<div className='flex flex-col text-base'>
							<ButtonGroup className='mb-2'>
								<Button onClick={() => {
									const select_available_rooms_dialog_items = { ...this.state.select_available_rooms_dialog_items };
									for (let id in select_available_rooms_dialog_items) {
										select_available_rooms_dialog_items[id] = false;
									}
									this.setState({ select_available_rooms_dialog_items });
								}}>
									Izberi nobenega
								</Button>
								<Button onClick={() => {
									const select_available_rooms_dialog_items = { ...this.state.select_available_rooms_dialog_items };
									for (let id in select_available_rooms_dialog_items) {
										select_available_rooms_dialog_items[id] = true;
									}
									this.setState({ select_available_rooms_dialog_items });
								}}>
									Izberi vse
								</Button>
							</ButtonGroup>
							
							{Object.keys(grouped_available_accommodations).map(id_accommodation => {
								const item = grouped_available_accommodations[id_accommodation];
								return <div key={id_accommodation}>
									<div className='pt-1 pb-2'>{ item.accommodation.title }</div>
									{Object.keys(item.accommodation_items).map(id_accommodation_item => {
										const subitem = item.accommodation_items[id_accommodation_item];
										return <div key={id_accommodation_item}>
											{subitem.accommodation_item.title == 'default' ? null :
												<div className='pt-1 pb-2'>{ subitem.accommodation_item.title }</div>
											}
											<div className='flex flex-row flex-wrap'>
											{subitem.accommodation_item_places.map(aip => <Checkbox
												key={aip.id_accommodation_item_place}
												className='mr-3'
												style={{ minWidth: 100 }}
												checked={this.state.select_available_rooms_dialog_items[aip.id_accommodation_item_place]}
												onChange={event => {
													const select_available_rooms_dialog_items = { ...this.state.select_available_rooms_dialog_items };
													select_available_rooms_dialog_items[aip.id_accommodation_item_place] = event.target.checked;
													this.setState({ select_available_rooms_dialog_items });
												}}
												label={aip.title} />
											)}
											</div>
										</div>;
									})}
								</div>;
							})}
						</div>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => {
									this.printAvailableAccommodations(
										'sl',
										Object.keys(this.state.select_available_rooms_dialog_items)
											.filter(id_accommodation_item_place => this.state.select_available_rooms_dialog_items[id_accommodation_item_place])
									);
									this.hideAvailableAccommodationsDialog();
								}}>
								Natisni SL
							</Button>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => {
									this.printAvailableAccommodations(
										'en',
										Object.keys(this.state.select_available_rooms_dialog_items)
											.filter(id_accommodation_item_place => this.state.select_available_rooms_dialog_items[id_accommodation_item_place])
									);
									this.hideAvailableAccommodationsDialog();
								}}>
								Natisni EN
							</Button>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => {
									this.printAvailableAccommodations(
										'de',
										Object.keys(this.state.select_available_rooms_dialog_items)
											.filter(id_accommodation_item_place => this.state.select_available_rooms_dialog_items[id_accommodation_item_place])
									);
									this.hideAvailableAccommodationsDialog();
								}}>
								Natisni DE
							</Button>
						</div>
					</div>
				</div>
			</Dialog>
			
			<div className='pt-4 pb-4 pr-2 flex flex-row justify-between'>
				<ButtonGroup className='items-center'>
					<Button
						active={this.props.scaleDays == 15}
						onClick={() => this.props.setScaleDays(15) }>
						15 dni
					</Button>
					<Button
						active={this.props.scaleDays == 30}
						onClick={() => this.props.setScaleDays(30) }>
						30 dni
					</Button>
					<Checkbox
						className='mb-0 ml-5'
						checked={!this.state.show_hidden_rooms}
						onChange={event => this.setState({ show_hidden_rooms: !event.target.checked })}>
						Prikaži samo aktivne enote
					</Checkbox>
				</ButtonGroup>
				
				<ButtonGroup>
					<Button onClick={() => { this.offsetCurrentDate(-30); }}>
						<svg viewBox='0 0 20 18' width='20' height='18' className='inline-block mr-1'><g fillRule='evenodd'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m14.589 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/></g></svg>
						30 dni
					</Button>
					<Button onClick={() => { this.offsetCurrentDate(-15); }}>
						<svg viewBox='0 0 14 18' width='14' height='18' className='inline-block mr-1'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
						15 dni
					</Button>
					<Button onClick={() => { this.offsetCurrentDate(-1); }}>
						<svg viewBox='0 0 9 18' width='9' height='18' className='inline-block mr-1'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
						1 dan
					</Button>
					<Button
						className='w-24'
						onClick={() => { this.openFilterDatePickerDialog('current_date') }}
						intent={Intent.PRIMARY}>
						{
							this.props.currentDate.toLocaleString(
								'sl-SI',
								{ year: 'numeric', month: 'numeric', day: 'numeric' }
							)
						}
					</Button>
					<Button
						className='w-24'
						onClick={() => { this.props.setCurrentDate(new Date()); }}
						intent={Intent.PRIMARY}
						disabled={moment(this.props.currentDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')}>
						Danes
					</Button>
					<Button onClick={() => { this.offsetCurrentDate(1); }}>
						1 dan
						<svg viewBox='0 0 9 18' width='9' height='18' className='inline-block ml-1' transform='scale(-1,1)'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
					</Button>
					<Button onClick={() => { this.offsetCurrentDate(15); }}>
						15 dni
						<svg viewBox='0 0 14 18' width='14' height='18' className='inline-block ml-1' transform='scale(-1,1)'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
					</Button>
					<Button onClick={() => { this.offsetCurrentDate(30); }}>
						30 dni
						<svg viewBox='0 0 20 18' width='20' height='18' className='inline-block ml-1' transform='scale(-1,1)'><g fillRule='evenodd'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m14.589 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/></g></svg>
					</Button>
				</ButtonGroup>
				
				<Suggest
					itemPredicate={(query, item, idx, exactMatch) => {
						if (query.length == 0) return false;
						
						return item.search_value.indexOf(query.toLowerCase()) > -1;
					}}
					itemRenderer={(item, { handleClick, modifiers }) => {
						const reservation = this.props.reservations[item.id_reservation];
						
						const accommodation_item_place = this.props.accommodation_item_places[
							reservation.id_accommodation_item_place
						];
						const accommodation_item = accommodation_item_place === undefined ? undefined : 
							this.props.accommodation_items[
								accommodation_item_place.id_accommodation_item
							];
						const accommodation = accommodation_item === undefined ? undefined : 
							this.props.accommodations[
								accommodation_item.id_accommodation
							];
						
						let accommodation_title = '';
						if (accommodation !== undefined) {
							accommodation_title = accommodation.title + ' • ';
							
							if (accommodation_item !== undefined && accommodation_item.title != 'default') {
								accommodation_title += accommodation_item.title + ' • ';
							}
							
							if (accommodation_item_place !== undefined) {
								accommodation_title += accommodation_item_place.title;
							}
						}
						
						let customer      = this.props.customers[reservation.id_customer];
						let customer_name = '';
						if (customer !== undefined) {
							customer_name = customer.type == 'natural' ? customer.name + ' ' + customer.surname : customer.company_name;
						}
						
						const statuses = {
							'new':                      'Nova',
							'waiting-for-confirmation': 'V potrjevanju',
							'offer-sent':               'Izdana ponudba',
							'confirmed':                'Potrjena',
							'advance-invoice-sent':     'Izdan avans',
							'closed':                   'Plačana',
							'reversed':                 'Stornirana',
							'no-show':                  'Neprihod',
							'not-for-rent':             'Ni za oddajo',
						};
						
						return <MenuItem
							active={modifiers.active}
							key={item.id_reservation}
							onClick={handleClick}
							text={
								<>
									<div className='flex justify-between'>
										<div>{reservation.internal_code}</div>
										<div>{customer_name}</div>
									</div>
									<div>
										{item.check_in_out_range}
									</div>
									<div className='flex justify-between'>
										<div>{accommodation_title}</div>
										<div>{statuses[reservation.status]}</div>
									</div>
								</>
							}
							className='items-stretch border-b border-gray-400' />;
					}}
					noResults={<div className='p-2'>Ni rezultatov</div>}
					items={reservations_for_search.map(reservation => {
						let customer_name = '';
						let search_value  = reservation.internal_code;
						
						let customer = this.props.customers[reservation.id_customer];
						if (customer !== undefined) {
							customer_name = customer.type == 'natural' ? customer.name + ' ' + customer.surname : customer.company_name;
							search_value += ' ' + customer_name;
						}
						
						const reservation_customers = this.props.reservation_customers_by_id_reservations[reservation.id_reservation];
						for (let id_reservation_customer in reservation_customers) {
							const reservation_customer = reservation_customers[id_reservation_customer];
							let customer               = reservation_customer === undefined ? undefined : this.props.customers[reservation_customer.id_customer];
							if (customer !== undefined) {
								search_value += ' ' + customer.name + ' ' + customer.surname + ' ' + customer.company_name;
							}
						}
						
						return {
							id_reservation: reservation.id_reservation,
							search_value:   search_value.toLowerCase(),
							check_in:       reservation.check_in,
							check_in_out_range:
								moment(reservation.check_in).format('DD. MM. YYYY') +
								' 🡒 ' +
								moment(reservation.check_out).format('DD. MM. YYYY'),
						};
					}).sort((a, b) => {
						if (a.check_in > b.check_in) return -1;
						if (a.check_in < b.check_in) return  1;
						return 0;
					})}
					onItemSelect={item => {
						this.props.openReservationDialog(this.props.reservations[item.id_reservation]);
					}}
					openOnKeyDown={true}
					popoverProps={{
						targetProps: { style: { display: 'block' } },
						minimal: true,
					}}
					selectedItem={null}
					inputProps={{
						leftIcon: 'search',
						placeholder: 'Išči',
						rightElement:
							<Button
								icon='cross'
								minimal={true}
								onClick={() => {
									this.setState({ reservation_search_query: '' });
								}} />,
					}}
					inputValueRenderer={item => item.search_value}
					resetOnSelect={true}
					query={this.state.reservation_search_query}
					onQueryChange={query => {
						this.setState({ reservation_search_query: query });
					}} />
			</div>
			<div className='pt-0 pb-4 pr-2 flex flex-row justify-between'>
				<div className='flex flex-row items-center'>
					<span className='mr-2'>Filtriranje nezasedenih:</span>
					
					<DateRangeInput
						locale='sl'
						{...this.getMomentFormatter('DD. MM. YYYY')}
						allowSingleDayRange={true}
						contiguousCalendarMonths={false}
						shortcuts={false}
						startInputProps={{ placeholder: 'Od', className: 'w-24' }}
						endInputProps={{ placeholder: 'Do', className: 'w-24' }}
						selectAllOnFocus={true}
						minDate={new Date('1900-01-01')}
						maxDate={moment().add('10', 'years').toDate()}
						onChange={(selectedRange) => {
							this.setState({
								filter_from_temp: selectedRange[0],
								filter_to_temp:   selectedRange[1],
							});
						}}
						value={[
							this.state.filter_from_temp,
							this.state.filter_to_temp,
						]} />
					
					<Button
						icon='cross'
						disabled={
							this.state.filter_from_temp === null && this.state.filter_to_temp === null &&
							this.state.filter_from      === null && this.state.filter_to      === null
						}
						onClick={() => {
							this.setState({
								filter_from:      null,
								filter_to:        null,
								filter_from_temp: null,
								filter_to_temp:   null,
							});
						}} />
					
					<Button
						disabled={
							(this.state.filter_from_temp === null && this.state.filter_to_temp === null) ||
							(
								moment(this.state.filter_from_temp).format('YYYY-MM-DD') == moment(this.state.filter_from).format('YYYY-MM-DD') &&
								moment(this.state.filter_to_temp).format('YYYY-MM-DD') == moment(this.state.filter_to).format('YYYY-MM-DD')
							)
						}
						onClick={() => {
							this.setState({
								filter_from: this.state.filter_from_temp,
								filter_to:   this.state.filter_to_temp,
							});
						}}>
						Prikaži
					</Button>
					
					<Button
						disabled={
							this.state.filter_from === null || this.state.filter_to === null
						}
						icon='print'
						onClick={this.printAvailableAccommodations} />
				</div>
				
				<div>
					<Button
						icon='th'
						onClick={() => navigate('/reservation-list')}>
						Seznam rezervacij
					</Button>
				</div>
				
				{true ? null :
					<div>
						<ButtonGroup>
							<Button
								icon='trash'
								intent={Intent.DANGER}
								active={this.state.deleting_reservations}
								onClick={() => {
									this.setState({
										deleting_reservations: !this.state.deleting_reservations,
										duplicating_reservations: false,
									});
								}} />
							<Button
								icon='duplicate'
								intent={Intent.PRIMARY}
								active={this.state.duplicating_reservations}
								onClick={() => {
									this.setState({
										deleting_reservations: false,
										duplicating_reservations: !this.state.duplicating_reservations,
									});
								}} />
						</ButtonGroup>
					</div>
				}
			</div>
			<div className='flex-1' style={{ position: 'relative' }}>
				<Timeline
					openReservation={reservation => {
						this.props.openReservationDialog(reservation);
					}}
					currentDate={this.props.currentDate}
					scaleDays={this.props.scaleDays}
					setDateFilter={(filter_from, filter_to) => {
						this.setState({
							filter_from:      filter_from.toDate(),
							filter_to:        filter_to  .toDate(),
							filter_from_temp: filter_from.toDate(),
							filter_to_temp:   filter_to  .toDate(),
						});
					}}
					filterFrom={this.state.filter_from}
					filterTo={this.state.filter_to}
					rooms={this.state.rooms}
					items={this.state.items}
					deletingReservations={this.state.deleting_reservations}
					deleteReservation={this.props.deleteReservation} />
			</div>
		</>;
	}
}
TimelineWithFilterComponent.propTypes = {};
