import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Intent,
	Navbar,
	ProgressBar,
} from '@blueprintjs/core';

class WaitFiscalVerificationDialog extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.state = {
			show_print_without_eor_button: false,
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	componentDidUpdate(prevProps, prevState) {
		const invoice = this.props.invoices[this.props.id_invoice];
		
		const fiscal_verification =
			invoice.id_invoice_fiscal_verification === undefined || invoice.id_invoice_fiscal_verification === null ?
				null :
				this.props.invoice_fiscal_verifications[invoice.id_invoice_fiscal_verification];
		
		if (
			fiscal_verification !== null &&
			fiscal_verification !== undefined
		) {
			if (
				fiscal_verification.status     === true      &&
				fiscal_verification.signed_zoi !== null      &&
				fiscal_verification.eor        !== null
			) {
				this.props.closeWaitFiscalVerificationDialog(true);
			}
			else if (fiscal_verification.signed_zoi !== null) {
				if (!this.state.show_print_without_eor_button) {
					setTimeout(() => {
						if (!this._ismounted) return;
						this.setState({ show_print_without_eor_button: true });
					}, 4000);
				}
			}
		}
	}
	
	render() {
		const invoice = this.props.invoices[this.props.id_invoice];
		
		const fiscal_verification =
			invoice.id_invoice_fiscal_verification === undefined || invoice.id_invoice_fiscal_verification === null ?
				null :
				this.props.invoice_fiscal_verifications[invoice.id_invoice_fiscal_verification];
		
		const getStatus = status => {
			if (status === null) {
				return 'v pošiljanju';
			}
			else if (status) {
				return 'potrjeno';
			}
			return 'napaka pri potrjevanju';
		};
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={false}
			onClose={() => this.props.closeWaitFiscalVerificationDialog(false)}
			className='wait-fiscal-confirmation-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Čakanje na potrditev</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeWaitFiscalVerificationDialog(false)} />
					</Navbar.Group>
				</Navbar>
				<div className='wait-fiscal-confirmation-dialog-body-wrapper'>
					<div className='h-1'>
						{fiscal_verification !== undefined && fiscal_verification !== null && fiscal_verification.status !== null ? null :
							<ProgressBar animate={true} intent='warning' className='h-1' />
						}
					</div>
					<div className={Classes.DIALOG_BODY}>
						<div className='wait-fiscal-confirmation-dialog-body'>
							{fiscal_verification === undefined || fiscal_verification === null ? null :
								<>
									<div>Stanje:</div>
									<div>{ getStatus(fiscal_verification.status) }</div>
								</>
							}
							{fiscal_verification === undefined || fiscal_verification === null ? null :
								<>
									<div>ZOI:</div>
									<div>{ fiscal_verification.signed_zoi }</div>
									
									<div>EOR:</div>
									<div>{ fiscal_verification.eor }</div>
								</>
							}
						</div>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<>{fiscal_verification === undefined || fiscal_verification === null ?
								<Button
									intent={Intent.DANGER}
									onClick={() => this.props.closeWaitFiscalVerificationDialog(false)}>
									Zapri brez čakanja
								</Button>
								:
								(<>
									{fiscal_verification.eor !== null ?
										<Button
											intent={Intent.PRIMARY}
											onClick={() => this.props.closeWaitFiscalVerificationDialog(true)}>
											Natisni
										</Button>
										:
										(!this.state.show_print_without_eor_button ? null :
											<Button
												intent={Intent.WARNING}
												onClick={() => this.props.closeWaitFiscalVerificationDialog(true)}>
												Natisni brez EOR
											</Button>
										)
									}
									<Button
										intent={Intent.WARNING}
										onClick={() => this.props.closeWaitFiscalVerificationDialog(false)}>
										Zapri brez tiskanja
									</Button>
								</>)
							}</>
						</div>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}

function mapStateToProps(state) {
	return {
		invoices:                                   state.BusinessSlice.invoices,
		invoice_fiscal_verifications:               state.BusinessSlice.invoice_fiscal_verifications,
		invoice_fiscal_verifications_by_id_invoice: state.BusinessSlice.invoice_fiscal_verifications_by_id_invoice,
	};
}

export default connect(mapStateToProps)(WaitFiscalVerificationDialog);
