import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
	Button,
	InputGroup,
	FormGroup,
	TextArea,
	Checkbox,
	Navbar,
	Dialog,
	Alignment,
	HTMLSelect,
	RadioGroup,
	Radio,
	Classes,
	Intent,
	NumericInput,
	ControlGroup,
	Alert,
} from '@blueprintjs/core';
import { DateInput, IDateFormatProps } from '@blueprintjs/datetime';

import moment from 'moment';

import TextWithTooltip       from '../TextWithTooltip';
import CustomerSelect        from '../CustomerSelect';
import { addCustomer }       from '../../slices/CodeTablesSlice';
import { saveCodeTableItem } from '../../api/CodeTables';
import SimpleSuggest         from '../SimpleSuggest';
import { AppToaster }        from '../AppToaster';
import PriceHelper           from '../../helpers/PriceHelper';
import { toLocal }           from '../../numbers';

class AddEditItemWithCountDialog extends Component {
	constructor(props) {
		super(props);
		
		this.closeDialog = this.closeDialog.bind(this);
		
		this.state = {
			id_item:  props.id_item,
			quantity: props.quantity,
		};
	}
	
	closeDialog(id_item, quantity) {
		this.props.closeDialog(id_item, quantity);
	}
	
	render() {
		const item = this.state.id_item === null ? null : this.props.items[this.state.id_item];
		const id_item_price_list = item === null || this.state.date === null ? null :
			PriceHelper.FindBestPriceListMatch(moment(this.props.date), item.price_lists);
		const price = id_item_price_list === null ? 0 : parseFloat(item.price_lists[id_item_price_list].price);
		
		console.log({
			id_item: this.state.id_item,
			item,
		});
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.closeDialog()}
			className=''>
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp3-dark'>
					<Navbar.Group>
						<Navbar.Heading>Artikli/storitve</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-row'>
						<div className='flex flex-1 pr-2 flex-col'>
							<FormGroup>
								<TextWithTooltip
									label='Artikel/storitev'
									tooltip='Artikel/storitev' />
								<SimpleSuggest
									matchKeyName='title_with_code'
									valueKeyName='id_item'
									items={Object.values(this.props.items).map(item => {
										return { ...item, title_with_code: item.internal_code + ' • ' + item.title };
									})}
									onItemSelect={item => {
										this.setState({
											id_item: item.id_item,
										});
									}}
									selectedItem={this.state.id_item === null ? null :
										(() => { return {
											...this.props.items[this.state.id_item],
											title_with_code: this.props.items[this.state.id_item].internal_code + ' • ' + this.props.items[this.state.id_item].title,
										}; })()
									}
									placeholder='Išči' />
							</FormGroup>
							<div className='flex flex-row justify-between items-end'>
								<FormGroup
									className='mb-0'
									label='Število'>
									
									<ControlGroup className='mb-0'>
										<Button icon='minus' tabIndex='-1' minimal={false} small={true} onClick={() => {
											this.setState({
												quantity: Math.max(0, parseFloat(this.state.quantity) - 1),
											});
										}} />
										<div className='w-12'>
											<NumericInput
												className='centered-numeric-input'
												fill={true}
												buttonPosition='none'
												value={toLocal(parseFloat(this.state.quantity), 0)}
												min={0}
												onValueChange={val => {
													this.setState({
														quantity: val,
													});
												}} />
										</div>
										<Button icon='plus' tabIndex='-1' minimal={false} small={true} onClick={() => {
											this.setState({
												quantity: parseFloat(this.state.quantity) + 1,
											});
										}} />
									</ControlGroup>
								</FormGroup>
								<div>
									{this.state.id_item === null ? '' : <div>
										<span>{
											parseFloat(this.state.quantity) + 'x ' +
											toLocal(parseFloat(price), 2) + ' € = '
										}</span>
										<span className='font-bold'>{ toLocal(this.state.quantity * price, 2) + ' €' }</span>
									</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.closeDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={async () => {
								this.closeDialog(
									this.state.id_item,
									this.state.quantity,
								);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditItemWithCountDialog.propTypes = {
	closeAddEditItemWithCountDialog: PropTypes.func,
	surname: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		items:   state.CodeTablesSlice.items,
		api_url: state.CodeTablesSlice.api_url,
		token:   state.UserSlice.token,
		client:  state.ConstantsSlice.client,
	};
}

export default connect(mapStateToProps)(AddEditItemWithCountDialog);
